.c-user-menu-actions
{
    $this: &;

    margin: $size-small - $size-x-small 0;

    @at-root
    {
        .c-main-menu-aside &
        {
            margin: 0;
        }
    }
}
