
//
// rendu générique du bouton play
//
@mixin item-play($this) {

  #{$this}__item-miniature {
    //width: 50px;
    //min-width: 50px;
    //height: 50px;

    margin-right: 10px;
    position: relative;
  }

  #{$this}__item-miniature-link {
    img,
    > .fa {
      transition: ease 150ms;
      transition-property: opacity;

      #{$this}__item:hover & {
        opacity: .3;
      }
    }
  }


  // Ce conteneur est nécessaire pour que la tooltip s'affiche correctement.
  // L'animation de bouton (scale) peut parfois perturber la position de la
  // tooltip.
  #{$this}__item-show-trigger-container {
    position: absolute;
    z-index: $z-index-1;
    top: 50%;
    left: 50%;

    width: $size-small + $size-xx-small;
    height: $size-small + $size-xx-small;

    transform: translate(-50%, -50%);
  }

  #{$this}__item-show-trigger {
    font-size: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: $size-small + $size-xx-small;
    height: $size-small + $size-xx-small;
    padding: 0;

    transition: ease 150ms;
    transition-property: transform;
    transform: scale(0);

    opacity: 0;
    color: white;
    border: none;
    border-radius: 50%;
    background: rgba(black, .7);
    box-shadow: 0 4px 20px 0 rgba(black, .24);

    //backdrop-filter: blur(1px);

    #{$this}__item:hover & {
      transform: none;

      opacity: 1;
    }
  }


}
