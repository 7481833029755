/// ============================================================================
/// # TIMELINE-POST-REACTIONS-LIST
/// ============================================================================
/// Conteneur de la liste des réactions d'un post. Contient des
/// components.timeline-post-reactions-list-item. Affiché dans une sidebar au
/// clic sur le compteur de réactions.
/// ============================================================================
/// @name components.timeline-post-reactions-list
/// @group components
/// @see components.timeline-post-reactions-list-item
/// ============================================================================

.c-timeline-post-reactions-list {
  $this: &;

  overflow-y: auto !important;

  border: none;

  @at-root {
    .k-listview-content {
      @include custom-scrollbar();
    }
  }
}
