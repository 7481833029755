a
{
    color: #656565;
}

a[class=''],
a:not([class])
{
    @include c-link();
}
