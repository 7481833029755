@keyframes blink {
  /**
   * At the start of the animation the dot
   * has an opacity of .2
   */
  0% {
    opacity: .2;
  }
  /**
   * At 20% the dot is fully visible and
   * then fades out slowly
   */
  20% {
    opacity: 1;
  }
  /**
   * Until it reaches an opacity of .2 and
   * the animation can start again
   */
  100% {
    opacity: .2;
  }
}

.c-inscription{
  $this:&;
  @at-root {

    #{$this}__code-container {
      justify-content: center;
    }


    &__container{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__header{
      display: flex;
      align-items: baseline;
    }

    &__container-svg{
      width: 40px;
      height: 40px;
      border: 1px solid transparent;
      border-radius: 50%;
    }

    &__return{
      height: 100%;
      width: 100%;
    }

    &__title{

    }

    &__subTitle{

    }

    &__container-wizard{
      width: 100%;
      margin-top: $size-x-small;

      @media #{$media-desktop}{
        width: 550px;
        padding: $size-xx-small;
      }
    }

    &__overlay{
      position: fixed;
      display: none;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0,0,0,0.5);
      z-index: 2;
      cursor: pointer;
    }

    &__infos{
      margin-bottom: $size-x-small;
      margin-top: $size-x-small;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: $size-x-small;
      line-height: 17px;

      &--sup, &--extra{
        margin-bottom: 0;
        margin-top: $size-xx-small;
        text-align: left;
        padding: 0;
        line-height: 13px;
      }

      &--sup{
        font-size: 12px;
      }
    }

    &__container-activation{

      align-items: center;
      display: flex;
      justify-content: flex-start;

      &__block{
        display: flex;
      }

      &__separate{
        border-radius: 4px;
        display: flex;
        margin: 8px;
        width: 20px;
        border: 1px solid $color-default;
      }

      &__input{
        -moz-appearance: textfield !important;
        font-size: 43px;
        height: 80px;
        max-width: 50px;
        text-align: center;
        width: 100%;
        background: white;
        border: 2px solid $color-default;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          appearance: none;
          margin: 0;
        }

        &:first-child {
          border-bottom-left-radius: $size-xxx-small;
          border-top-left-radius: $size-xxx-small;
        }

        &:last-child{
          border-bottom-right-radius: $size-xxx-small;
          border-top-right-radius: $size-xxx-small;
        }

        &:not(:last-child){
          border-right-style: none;
        }
      }

    }

    &__code-info{
      visibility: visible;
      opacity: 1;
      text-align: left;
      color: #e9573f;
      font-size: 14px;
    }

    &__container-links{
      margin-top: $size-x-small;
      margin-bottom: $size-x-small;

      &__newcode, &__newmail, &__send{
        padding: 0;
        font-size: $size-x-small;
        margin-bottom: $size-xx-small;
      }

      &__newcode{
        display: block;
      }

      &__send{
        display: none;
        color: $color-default;

        & span{
          animation-name: blink;
          animation-duration: 1.4s;
          animation-iteration-count: infinite;
          animation-fill-mode: both;
          color: $color-dark;
        }

        & span:nth-child(2) {
          animation-delay: .2s;
        }

        & span:nth-child(3) {
          animation-delay: .4s;
        }
      }

      .c-inscription__sendcode &{
        &__newcode{
          display: none;
        }
        &__send{
          display: block;
        }
      }
    }

    &__eye{
      @include reset-button();

      position: absolute;
      top: 6px;
      right: 0;

      svg
      {
        width: 2em;
        height: 1.5em;
      }
    }

    &__container-consent{
      margin-bottom: $size-xx-small;
    }

    &__label-consent{
      font-weight: 400;
    }

    &__consent{
      font-weight: lighter;
      font-style: italic;
      font-size: smaller;
      display: block;
      margin-top: $size-xx-small;
    }

    &__container-dns{
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 0 $size-xx-small;
      margin-bottom: $size-x-small;
    }

    &__container-invite{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    &__svg{
      height: 20px;
      width: 20px;
      margin-right: $size-xx-small;
      margin-bottom: 20px;
    }

    &__container-btns{
      margin-left: -$size-x-small;
      margin-right: -$size-x-small;
      display: flex;

      @media #{$media-desktop}{
        margin-left: 0;
        margin-right: 0;
      }

    }

    &__btn{
      height: 50px;
      position: fixed;
      bottom: 0;
      bottom: env(safe-area-inset-bottom); // Variable d'environnement pour palier la barre d'iphone (gesture navigation)

      @media #{$media-desktop}{
        position: relative;
        height: 40px;
      }

      &--prev, &--submit{
        display: none;
      }

      &--prev{
        width: 50%;

        &--half{
          display: block;
          width: calc(50% - 4.5px);
          left: 0;

          @media #{$media-desktop}{
            width: 50%;
            margin-right: 2.5px;
            left: auto;
          }

        }
      }

      &--next, &--submit{
        width: 100%;
      }

      &--next{
        &--half{
          display: block;
          width: calc(50% - 4.5px);
          right: 0;

          @media #{$media-desktop}{
            width: 50%;
            margin-left: 2.5px;
            right: auto;
          }
        }
      }

      &--add{
        border: none;
        background-color: transparent;
      }

    }

  }
}
