/// ============================================================================
/// # TARGET
/// ============================================================================
/// Very simple object to cover a very commonly occurring design pattern.
/// The "target" name comes from the idea of something centered in a container,
///                  .......
/// just like this : |  o  |
///                  .......
/// ============================================================================

.o-target
{
    display: flex;
    align-items: center;
    justify-content: center;
}
