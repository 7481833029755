.c-chat-messages-group
{
    $this: &;

    width: 100%;

    @at-root
    {
        &--user
        {
            margin-left: auto;
            text-align: right;
        }

        #{$this}__thumb
        {
            display: none;

            margin-right: 10px;

            @media #{$media-desktop}
            {
                display: flex;
            }

            .c-tchat-popup &
            {
                display: none;
            }
        }

        #{$this}__content
        {
            position: relative;

            &::before
            {
                content: attr(data-nom);

                font-size: 15px;

                display: none;

                padding-right: 10px;
                padding-left: 10px;

                color: rgba(black, .40);

                @media #{$media-desktop}
                {
                    font-size: 13px;
                }

                .c-tchat-popup &
                {
                    font-size: 15px;
                }
            }

            .c-chat-conversation.has-multiple-recipients &::before,
            #{$this}--reply &::before
            {
                display: block;
            }

            #{$this}--user &
            {
                &:not([data-nom]),
                &[data-nom='']
                {
                    &:before
                    {
                        display: none;
                    }
                }
            }
        }

        #{$this}__views
        {
            display: none;

            margin-top: 2px;

            &::after
            {
                content: '';
                clear: both;
                display: block;
            }

            #{$this}__item.has-views &
            {
                display: block;
            }

            .c-chat-feed__messages-group:not(#{$this}--user) #{$this}__item:last-child &
            {
                position: absolute;
            }

            #{$this}--user &
            {
                text-align: right;
            }

            #{$this}__item.has-reactions &
            {
                margin-top: $reactions-height;
            }
        }

        #{$this}__view
        {
            font-size: 8px !important;
            width: $size-x-small !important;
            height: $size-x-small !important;
            cursor: pointer;
            margin-right: 2px;

            &--right
            {
                float: right !important;
            }
        }
    }
}
