.o-fullscreen-content {
  $this: &;

  display: flex;
  flex-direction: column;

  height: calc(100vh - #{$mobile-menu-height} - #{$header-height});
  height: calc(#{vh(100)} - var(--mobile-menu-height) - #{$header-height});

  // As CSS variables can't be negative (-var(...) is not working),
  // using a calc is the only possible solution
  margin: -$app-main-container-padding-xs;
  margin-right: calc(-1 * var(--app-main-container-padding-right-xs));
  margin-left: calc(-1 * var(--app-main-container-padding-left-xs));

  background: white;

  @media (min-width: $screen-xs-min)
  {
    margin: -$app-main-container-padding-sm;
    margin-right: calc(-1 * var(--app-main-container-padding-right-sm));
    margin-left: calc(-1 * var(--app-main-container-padding-left-sm));
  }

  @media (min-width: $screen-sm-min)
  {
    height: 100vh;
    height: calc(#{vh(100)});
  }

  @at-root {
    #{$this}__header {
      padding: 15px;
      border-bottom: 1px solid #ccd1d9 !important;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    #{$this}__content {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 0;
      padding: 15px;
      justify-content: space-between;

      overflow-x: hidden;
      overflow-y: auto;

      -webkit-overflow-scrolling: touch;
    }

    #{$this}__footer {
      margin-top: 9px;
    }
  }
}