.c-spaces-members-common {
  padding:0;

  $this: &;

  @at-root {

    #{$this}__admins {
      padding: $size-xx-small;
    }

  }


}