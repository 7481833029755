.c-tasks-modal {
  &__dialog {
    width: 700px;

    @media (max-width: $screen-xs-max) {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    border-radius: 10px;
  }

  &__mobile {
   overflow: scroll;
    height: 80svh;
    margin-bottom: 50px;
    min-height: unset !important;
    padding: 10px;
  }

  &__header {
    padding: $size-x-small;
    border: none;
    color: $text-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    border-bottom: 2px solid $color-light;
    &--archived {
      height: 64px;
      display: flex;
      align-items: center;
      gap: 12px;
      justify-content: start;
      padding: 20px;
      border-radius: 12px 12px 0 0;
      background-color: var(--ds-background-warning, #fff7d6);
      background-image: linear-gradient(to bottom right, var(--ds-background-neutral, rgba(0, 0, 0, 0.05)) 25%, transparent 25%, transparent 50%, var(--ds-background-neutral, rgba(0, 0, 0, 0.05)) 50%, var(--ds-background-neutral, rgba(0, 0, 0, 0.05)) 75%, transparent 75%, transparent);
      background-size: 14px 14px;
    }
    &--archivedText{
      margin: 0;
      font-size: 18px;
    }&--iconArchiveContainer{
      margin-left: 25px;
    }
  }

  &__header--bottomSidebar {
    padding: $size-x-small;
    border: none;
    color: $text-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 2;
    height: 46px;
    width: 100%;
  }

  &__header--bottomSidebar--taskCover{
    background-color: rgba(255,255,255,0);
    color: white;
    .c-btn--more {
      color: white;
    }
  }

  &__title-group {

    @media (max-width: $screen-sm-min) {
      margin-right: 0px;
      margin-left: 14px;
      margin-top: 20px;
    }

    width: 90%;
    display: flex;
    align-items: baseline;
    flex-direction: column;
    margin-right: 20px;
    word-break: break-word;

    &--form {
      display: block;
      width: 95%;
    }
  }

  &__title-group--bottomSidebar {

    display: flex;
    align-items: baseline;
    flex-direction: column;
    word-break: break-word;
    margin-top: 56px;
    margin-bottom: 55px;
    visibility: hidden;

  }

  &__title-sub-group {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__input {
    font-size: 21px;
    height: 20px;
  }

  &__input--changeName {
    font-size: 21px;
    height: fit-content;
  }

  &__input--bottomSidebar{
    font-size: 21px;
  }

  &__title,
  &__subtitle {
    margin: 0 ;
  }

  &__title {
    font-size: 21px;
    font-weight: 500;
  }

  &__title--little {
    font-size: 18px;
    font-weight: 100;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    max-width: 60vw;
  }

  &__subtitle {
    font-size: 15px;
    font-weight: normal;
    color: $color-dark-light;

    &--mobile{
      font-size: 14px;
      font-weight: normal;
      color: $color-dark-light;
    }

  }

  &__subtitleSidebar {
    font-size: 15px;
    font-weight: normal;
    color: white;

    &--mobile{
      font-size: 14px;
      font-weight: normal;
      color: white;
    }

  }

  &__close {

     @media (max-width: $screen-sm-min){
       position: absolute;
       top: 10px;
       left: 10px;
     }

     width: 24px;
     height: 24px;
     cursor: pointer;
     position: absolute;
     right: 15px;
     top: 15px;

    background: $color-light;
    border-radius: 3px;
    padding: 3px;
    color: black;
   }
  &__close:hover {
    background: darken($color-light, 10%);
  }
  &__close:active {
    background: darken($color-light, 15%);
  }

  &__button-list-trigger{
    background: rgba(70, 70, 70, 0.5);
    border-radius: 3px;
    padding: 3px;
    color: white;
  }

  &__close--taskCover{
    @media (max-width: $screen-sm-min){
      position: absolute;
      top: 10px;
      left: 20px;
    }

    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
    color: white;
  }

  &__dropdown {
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
  }

  &__body {
    padding: 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 0;
  }

  &__footer-border {
    border-top: 2px solid $color-light;
  }

  &__nav-tabs {
    flex-shrink: 0;
  }

  &__nav {
    border-bottom: 1px solid $color-default-light!important;
  }

  &__steps,
  &__activities,
  &__details {
    min-height: 300px;
  }

  &__steps {
    &.active {
      display: flex;
      flex-direction: column;
    }
  }

  &__tab-content {
    padding: $size-x-small;
    overflow-y: auto;
    overflow-x: hidden;
    border: none!important;
    min-height: 700px;
  }

  &__progress {
    width: 100%;
    margin-bottom: $size-x-small;
  }

  &__info {
    background-color: var(--theme-color-l95);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: $size-xx-small;
    justify-content: space-between;

    p {
      color: var(--theme-color);
      margin: 0;
    }
  }

  &__info--assignation{
    display: inline-block;
    text-align: center;
  }

  &__footer{
    text-align: left;
    position: fixed;
    display: inline-flex;
    bottom: 0;
    width: 100%;
    background-color: white;
    border-top: 1px lightgrey solid;
    padding-bottom: env(safe-area-inset-bottom);
  }

  &__footer--members{
    position: fixed;
    display: inline-flex;
    bottom: 0;
    width: 100%;
    background-color: white;
    border-top: 1px lightgrey solid;
    padding-bottom: env(safe-area-inset-bottom);
  }

  &__footer--membersText{
    font-size: 15px;
    color: #434a54;
    text-align: center;
    margin: auto;
  }

  &__textarea{
    border: 1px solid lightgray;
    border-radius: 15px;
    padding: 9px;
    overflow: hidden;
    overflow-wrap: break-word;
    height: 40px;
    width: 72%;
    font-size: 14px;
  }

  &__paper-plane{
    width: 25px;
    height: 57px;
    margin-left: 10px;
    opacity: 0.2;
  }

  &__paper-plane-MVVM{
    width: 25px;
    height: 57px;
    margin-left: 10px;
  }

  &__bottomSideBarDocumentMenu {
      margin-left: 40px
  }
}