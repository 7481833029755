
.c-chat-documents {
  $this: &;

  @at-root {


    #{$this}__item-miniature  {
      margin-right: 10px;
      position: relative;
      width: 50px;
      height: 50px;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;

      .icon-document {
        font-size: 36px ;
      }

      &__video img {
        width:50px;
        height:50px;
      }
    }

    #{$this}__item {
      display: flex;
      align-items: center;
      align-self: flex-start;
      color: #656565;

      cursor:pointer;

      height:55px;

      &__isactive {
        background: $color-light-light;
        filter: brightness(97%);
      }
    }

    #{$this}__item-chat-right{
      border-bottom:solid;
      border-bottom-width: 1px;
      border-bottom-color: $color-light;

    }


    #{$this}__item:hover {
      background: $color-light-light ;
    }



    #{$this}__item-header {
      padding:$size-xxx-small;
    }

    #{$this}__item-title {
      //width:200px;

      margin-right:$size-xx-small;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    #{$this}__item-title > span {
      font-size: 14px;
      display: block;

      margin-right:$size-xx-small;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }


    // colonne de date de création
    #{$this}__item-sort {
      display: none;
      width:190px ;
      flex-shrink: 0;
      text-align: left;

      height:100%;
      line-height: 35px;

      @media (min-width: $screen-lg-min)
      {
        display: block;
      }
    }



    #{$this}__item-created {
      color:$color-dark-light;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }


    #{$this}__item-dropdown {
      width: 36px;
      flex-shrink: 0;
      margin: 0px;
      padding: 0px;
      text-align: center;
      height: 100%;
      line-height: 30px;
    }


    #{$this}__item-authorname  {
      display: none;
      padding-left:0px;


      @media #{$media-desktop}
      {
        padding-left:$size-xxx-small;
        display: block;
        width:150px; // uniquement desktop, en mobile le nom est caché
      }
    }

    #{$this}__item-link
    {
      //color: var(--theme-color, $color-dark);
      font-size: 15px;

      display: block;

      @media #{$media-desktop}
      {
        width: 38px;
      }
    }




    //
    // section affichée quand il n'y a pas de document, voir list-no-document.twig
    //
    #{$this}__no-docs {
      margin: 0 auto;
      padding: 0 auto;
    }

    #{$this}__no-docs-icon {
      display:block;
      margin:0 auto;
      width:40%;
    }

    #{$this}__no-docs-text {
      font-size: 21px;
      text-align: center;
    }

    #{$this}__no-docs-btn {
      width:100%;
      text-align:center;
    }

    #{$this}__no-docs-btn svg {
      font-size: 21px;
    }



    #{$this}__item-drag-error {    // sur déplacement d'un répertoire
      border: red !important;
      border:solid  !important;
      border-width: 1px !important;

      color:red !important;
      font-weight: bold;
    }



    @include item-play($this);

  }


}

