.c-about {
  $this:&;

  @at-root {
    #{$this}__title{
      font-size: $size-x-small;
    }

    #{$this}__label{
      color: $color-default-light;
    }

    #{$this}__info{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &--wrap {
        white-space: normal;
      }

      &--flex {
        display: flex;
        flex-direction: column;
      }
    }

    #{$this}__icone{
      text-align: center;
    }
    #{$this}__icone--margin{
      margin-right: $size-xx-small;

      @media (min-width: $screen-sm-min) {
        margin-right: $size-x-small;
      }
    }

    #{$this}__flex {
      display: flex;
      align-items: flex-start;
      padding-bottom: $size-xxx-small;

      &--last {
        padding-bottom: 0;
      }

      &--center {
        align-items: center;
      }
    }

    #{$this}__flex-items {
      display: flex;
      flex-direction: column;
    }

    #{$this}__flex-item {
      display: flex;
      align-items: center;
      margin-bottom: $size-xxx-small;

      &--last {
        margin-bottom: 0;
      }
    }
  }
}