/// ============================================================================
/// # TIMELINE-POST-MEDIA-SLIDER
/// ============================================================================
/// Slider des médias des posts (images et miniatures de vidéos). S'affiche au
/// format carré sur tous les navigateurs (sauf exceptions, voir le commentaire
/// sur aspect-ratio plus bas).
/// Profondément lié à la librairie Swiper et à son override
/// ============================================================================
/// @group components
/// @see trumps.overrides.swiper
/// ============================================================================

.c-timeline-post-media-slider
{
    $this: &;

    @at-root
    {
        #{$this}--images-and-videos
        {
            // Fond par défaut du slider. On ne peut le voir que si l'extraction
            // des couleurs de l'image ou de la vidéo du slide courant n'a pas
            // fonctionné.
            background: $color-light;

            // Permet au slider d'être carré sur touts les résolutions.
            // Pas compatible avec les versions de navigateurs de plus d'un an, d'où le
            // supports en dessous.
            aspect-ratio: 1;

            @supports not (aspect-ratio: 1)
            {
                // Si aspect-ratio n'est pas disponible, le slider sera tout de même
                // carré en desktop (car la timeline est limitée à une certaine
                // largeur), mais sur mobile ce sera variable.
                height: $size-xxx-large + $size-large;
            }
        }

        #{$this}--single-slide
        {
            &#{$this}--images-and-videos
            {
                aspect-ratio: auto;

                @supports not (aspect-ratio: auto)
                {
                    height: auto;
                }

                &,
                #{$this}__item-illustration
                {
                    max-height: calc(#{vh(90)});
                }
            }
        }

        #{$this}--document
        {
            $space-color: $color-light-light;
            border: $size-xx-small solid $space-color;
            background: $space-color;
        }

        #{$this}__item
        {
            cursor: pointer;
            transition: opacity ease $speed-fast;

            @mixin highlight()
            {
                opacity: .9;
            }

            @media not all and (hover: hover) and (pointer: fine)
            {
                &:active
                {
                    @include highlight;
                }
            }

            @media (hover: hover) and (pointer: fine)
            {
                &:hover
                {
                    @include highlight;
                }
            }
        }

        #{$this}__item-illustration
        {
            position: relative;

            width: 100%;
            height: 100%;

            object-fit: contain;

            // Cette ombre portée permettait d'ajouter un peu de relief entre
            // l'image et le fond coloré du slide. C'était particulièrement
            // visible avec les fonds clairs, comme le blanc, le crème ou les
            // couleurs pastels. Mais au moment de l'écriture de ce commentaire,
            // ce drop-shadow provoque d'importantes saccades sur le changement
            // de slide sous iOS, et rend l'expérience très désagréable. Il a
            // donc fallu le retirer. Il n'y avait cependant aucun problème avec
            // les autres plateformes.
            // Laissé là en commentaire au cas où "l'envie" de remettre une
            // ombre survienne plus tard, pour ne pas oublier pourquoi il n'y en
            // a pas.
            //#{$this}--images-and-videos &
            //{
            //    filter: drop-shadow(0 0 1px rgba(black, .08)) drop-shadow(0 3px 8px rgba(black, .08));
            //}
        }

        #{$this}__item-play
        {
            position: absolute;
            z-index: $z-index-1;
            top: 50%;
            left: 50%;

            transition: transform ease $speed-fast;
            transform: translate(-50%, -50%);

            @mixin highlight()
            {
                transform: translate(-50%, -50%) scale(1.1);
            }

            @media not all and (hover: hover) and (pointer: fine)
            {
                #{$this}__item:active &
                {
                    @include highlight();
                }
            }

            @media (hover: hover) and (pointer: fine)
            {
                #{$this}__item:hover &
                {
                    @include highlight();
                }
            }
        }
    }
}
