.dropdown-menu > li > a,
.bootstrap-select.btn-group .dropdown-menu li a
{
    border: none;
}


.navbar-fixed-top 
{
    z-index: 1052 !important;
}

.dropdown-menu .divider
{
    border-bottom: 1px solid $panels-border-color;
}

.row--no-padding {
  margin-right: 0;
  margin-left: 0;

  & > [class*='col-']{
    padding-left: 0;
    padding-right: 0;
  }
}
