.c-main-menu-aside
{
    $this: &;

    @at-root
    {
        #{$this}__search-bar
        {
            #{$this}:not(.has-search-bar) &
            {
                display: none;
            }
        }

        #{$this}__title-container
        {
            display: flex;
            align-items: center;
            justify-content: space-between;

            margin-top: $main-aside-padding;
            margin-bottom: $main-aside-padding;
            padding-right: $main-aside-padding;
            padding-left: $main-aside-padding;
        }

        #{$this}__title
        {
            font-size: 26px;

            overflow: hidden;

            margin: 0;

            white-space: nowrap;
            text-overflow: ellipsis;
            width: 75%;
        }

        #{$this}__more-trigger
        {
            font-size: 15px;

            #{$this}:not(.has-more-trigger) &
            {
                display: none;
            }
        }

        #{$this}__content-wrapper,
        #{$this}__search-results-wrapper
        {
            display: flex;
            flex: 1;

            min-height: 0;
        }

        #{$this}__content-wrapper
        {
            #{$this}.has-search-query &
            {
                display: none;
            }
        }

        #{$this}__search-results-wrapper
        {
            #{$this}:not(.has-search-query) &
            {
                display: none;
            }
        }

        #{$this}__content,
        #{$this}__search-results
        {
            overflow-y: auto;
            flex: 1;

            min-height: 0;
            max-height: 100%;
        }

        #{$this}__content
        {
            &.is-loading
            {
                overflow-y: visible;
            }
        }
    }
}
