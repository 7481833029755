.nav-tabs
{
    display: flex;
    overflow-x: auto;

    margin-bottom: -1px;

    border: none;

    &::before
    {
        display: none;
    }
}

.nav-tabs--dropdowns
{
    overflow: visible;
}

.tab-content
{
    position: relative;

    padding-top: $size-x-small;

    border-top: 1px solid $panels-border-color;
}

.tab-content p:last-child
{
    margin-bottom: 0;
}

.tab-content--panel
{
    padding: $size-x-small;

    border-radius: 0 0 3px 3px;
    background-color: white;
    box-shadow: 0 0 1px rgba(black, .08), 0 2px 5px rgba(black, .08);
}

.tab-content--bottom-offset
{
    margin-bottom: $size-x-small;
}

.nav-tabs > li
{
    float: none;
    flex-shrink: 0;

    margin-bottom: 0;
}

.nav.nav-tabs > li > a
{
    font-size: 15px;
    font-weight: 500;

    z-index: $z-index-1;

    min-width: $size-normal;
    margin: 0;
    padding: $size-xx-small $size-x-small;

    transition: ease 150ms;
    transition-property: color, border-color;
    text-align: center;

    border: none;
    border-bottom: solid transparent;
    border-bottom-width: 2px !important;
    border-radius: 0;
    background-color: transparent !important;
}

.nav.nav-tabs > li > a,
.nav.nav-tabs > li > a:focus,
.nav.nav-tabs > li > a:hover,
.nav.nav-tabs > li > a:active
{
    color: $text-color;
}

.nav.nav-tabs > li > a:focus,
.nav.nav-tabs > li > a:hover,
.nav.nav-tabs > li > a:active
{
    border-color: transparent;
    color: var(--theme-color-light);
}

.nav.nav-tabs > li.active > a
{
    color: var(--theme-color);
    border-color: var(--theme-color);
}

.nav.nav-tabs > li.active > a:focus,
.nav.nav-tabs > li.active > a:hover,
.nav.nav-tabs > li.active > a:active
{
    color: var(--theme-color-light);
    border-color: var(--theme-color-light);
    border:
    {
        top: none;
        right: none;
        left: none;
    };
}

.nav.nav-tabs .dropdown > a:active,
.nav.nav-tabs .dropdown > a:focus
{
    color: $color-dark;
    background-color: white;
}

.nav.nav-tabs .dropdown-menu
{
    color: $color-dark;
}
