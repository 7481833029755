.c-team-header
{
  $this: &;

  border:solid;
  border-width: 1px;
  border-color: $color-light;
  margin-bottom: 20px;

  @at-root
  {

    #{$this}__actions
    {
      height: 35px;
      padding:0px;
      padding-top:10px;
    }

    #{$this}__banner
    {
      height:250px;
    }

  }

}

.c-team-management {
  $this: &;

  @at-root {

    #{$this}__linkname:hover
    {
      color:$color-default;
    }

  }
}

.c-timeline-team {
  $this: &;

  @at-root
  {
    #{$this}__grid-avatar {
      display: flex;
      justify-content: start;
      padding-left:15px;
    }

    #{$this}__dropzone
    {
      margin:0px;
      padding-bottom: 5px;
    }

    #{$this}__dropzone .c-adndropzone__dropzone {
      margin-left:0px;
      margin-right:0px;
      padding:0px;
    }

    #{$this}__dropzone-listfiles
    {

    }

    #{$this}__organisation-item
    {
       font-size:13px;
       padding: $size-xxx-small;
    }


    //
    // bloc d'invitation groupe inter entreprise
    //
    #{$this}__invit-bloc {
      margin:0 auto;padding:0 auto;
      max-width:550px;
    }

    #{$this}__invit-creator-logo {
      padding:$size-xx-small;
    }

    #{$this}__invit-creator-logo-content {
      margin:0 auto; padding:0 auto;
    }

    #{$this}__invit-creator-name {
      font-size: 21px;
      text-align: center;
    }

    #{$this}__invit-buttons {
      text-align: center;
      padding:$size-xx-small;
    }

    #{$this}__invit-informations {
      font-size:14px;
      color: $color-dark-light;
      margin-top:$size-x-small;
      text-align: center;
    }



  }
}