.bg-blur-filter {
    background-color: rgba(98, 98, 98, 0.75);
    -webkit-backdrop-filter: contrast(4) blur(20px);
    backdrop-filter: contrast(4) blur(20px);
    animation: opacity 0.3s ease-in;
    height: 200vh;
    position: relative;
    z-index: 998;
}

@keyframes opacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }

}


.blur-effect {
    -webkit-backdrop-filter: contrast(4) blur(20px);
    backdrop-filter: contrast(4) blur(20px);
    animation: opacity 0.3s ease-in;
    position: relative;
    background-color: rgba(98, 98, 98, 0.75);
    z-index: 9999999999;
    height: 100vh;
    display: block;
}

.blur-element {
    position: relative;
    z-index: -1;
    opacity: 0.5;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
