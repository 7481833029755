.c-sort-header-album {

  padding: $size-xxx-small;
  padding-left: $size-x-small;

  $this: &;



  @at-root {

    & .k-state-active {
      color: white !important;
      background-color:  var(--theme-color) !important;
      border-color:  var(--theme-color) !important;
    }

    .select-drag-type-album {
      height: 30px;
      gap: 3px;
      background: $color-light-light;
      padding: 2px;
    }
    & .k-button-solid-base.k-selected {
      color: white;
      background-color: $default-theme-color;
    }
    & .k-button {
      border-radius: 5px!important;
    }


  }

}