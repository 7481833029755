.f_circleG {
    position:absolute;
    background-color:transparent;
    height:22px;
    width:22px;
    border-radius:12px;
    animation-duration:1.2s;
    animation-iteration-count:infinite;
    animation-direction:normal;
    animation-name: f_fadeG-blue;
}
        
#floatingCirclesG {
    position:relative;
    width:125px;
    height:125px;
    margin:auto;
    transform:scale(0.6);
}

#frotateG_01 {
    left:0;
    top:51px;
    animation-delay:0.45s;
}

#frotateG_02 {
    left:15px;
    top:15px;
    animation-delay:0.6s;
}

#frotateG_03 {
    left:51px;
    top:0;
    animation-delay:0.75s;
}

#frotateG_04 {
    right:15px;
    top:15px;
    animation-delay:0.9s;
}

#frotateG_05 {
    right:0;
    top:51px;
    animation-delay:1.05s;
}

#frotateG_06 {
    right:15px;
    bottom:15px;
    animation-delay:1.2s;
}

#frotateG_07 {
    left:51px;
    bottom:0;
    animation-delay:1.35s;
}

#frotateG_08 {
    left:15px;
    bottom:15px;
    animation-delay:1.5s;
}

    @keyframes f_fadeG-blue{
        0%{
            background-color:var(--theme-color);
        }
    
        100%{
            background-color:transparent;
        }
    } 


