.box
{
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: flex-start;

    background-color: white;

    &--mini
    {
        padding-top: 0;
        padding-bottom: 0;
    }
}

#box__contenu, .message__content
{
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
}

.message__content
{
    border-left: 1px solid #e1e2e9;
    overflow: hidden;

    .l-app.is-chat-xs-or-wider &,
    .c-tchat-popup &,
    .c-course-aside &
    {
        height: 100%;
    }
}

.h2-box
{
    border-bottom: 1px solid rgba(0, 0, 0, .10);
    height: 50px;
    line-height: 50px;
    color: #1d2129;
    font-size: 14px;
    margin: 0;
    padding: 0;
    text-transform: none;

    &__txt
    {
        color: rgba(0, 0, 0, 1);
        font-size: 17px;
        font-weight: 500;
        margin: 0 auto;
        max-width: 50%;
        text-align: center;
        vertical-align: middle;
    }

    &__button
    {
        background: transparent;
        border: 0;
        color: var(--theme-color);
        cursor: pointer;
        font-size: 17px;
        line-height: inherit;
        margin: 0;

        font-weight: 400;

        box-sizing: border-box;
        padding: 0 16px;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        position: absolute;
        top: 0;

        &--left
        {
            left: 0;
        }

        &--right
        {
            right: 0;
        }
    }
}

.message-content__scroll
{
    flex: 1;
    overflow: visible !important;
    -webkit-overflow-scrolling: touch;
    margin-top: 50px;

    &--conversation
    {
        margin-bottom: calc(40px + max(5px, env(safe-area-inset-bottom)));

        @supports not (margin-bottom: env(safe-area-inset-bottom))
        {
            margin-bottom: 45px;
        }
    }

    .has-focus-on-editor &
    {
        margin-bottom: 45px;
    }

    .l-app.is-chat-xs-or-wider &,
    .c-tchat-popup &,
    .c-course-aside &
    {
        overflow-y: scroll !important;
    }

    .l-app.is-chat-sm-or-wider &,
    .c-tchat-popup &,
    .c-course-aside &
    {
        margin-top: 0;
        margin-bottom: 0;
        position: relative;
    }
}

.message-content__chat
{
    &,
    &--writing
    {
        padding: 0 15px;
    }

    &--writing
    {
        margin-bottom: 40px;
    }
}

.message-content__bottom
{
    border-top: 1px solid #e1e2e9;
    display: flex;
    padding: 5px 0;
    background: white;

    @supports (padding: env(safe-area-inset-bottom)) and (padding: max(42px, 21px))
    {
        padding-bottom: unquote('max(5px, env(safe-area-inset-bottom))');

        .has-focus-on-editor &
        {
            padding-bottom: 5px;
        }
    }
}

.message-content__editor
{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    background: white;

    @media (orientation: portrait) and (min-width: 768px), (orientation: landscape) and (min-height: 576px), (orientation: landscape) and (min-width: 992px)
    {
        left: 64px;
        width: calc(100% - 64px);
    }

    .l-app.is-chat-sm-or-wider &,
    .c-tchat-popup &,
    .c-course-aside &
    {
        position: static;
        top: auto;
        left: auto;
        width: auto;
    }
}

.message-content__bottom__textarea
{
    border: none!important;
    box-shadow: none!important;
    padding: 6px 24px 6px 12px;
    flex: 1;
    font-size: 16px !important;

    &,
    &.emojionearea .emojionearea-editor
    {
        color: #1d2129;
    }

    /* Copy of the styles of the Emojione Area placeholder, used here to avoid
     * FOUC while the library is not ready */
    &:not(.emojionearea)::before
    {
        content: attr(placeholder);
        display: block;
        color: #BBBBBB;
    }
}

.message-content__bottom
{
    &__buttons
    {
        display: flex;
        justify-content: flex-end;
    }

    &__btn
    {
        border: none;
        background-color: transparent;
        text-align: center;
        flex: 1;

        &--gif
        {
            color: #90949c;
            //font-size: 1.5em;
            //font-weight: 500;
            text-transform: uppercase;
        }

        &--send
        {
            padding-right: 10px;
            font-weight: bold;
            justify-content: flex-end;
            color: var(--theme-color);

            &:disabled
            {
                color: #ccd1d9;
            }

            > img
            {
                pointer-events: none;
            }
        }

        &--retract
        {
            display: none;
        }

        &--preview
        {
            position: absolute;
            top: 5px;
            right: 5px;
        }
    }
}

.message-content__bottom__buttons > button:nth-child(3) > div
{
    border: none;

    > div.emojionearea-editor
    {
        &,
        &.has-placeholder
        {
            display: none;
        }
    }

    > div.emojionearea-button
    {
        > div.emojionearea-button-open,
        > div.emojionearea-button-close
        {
            background-image: none!important;
        }
    }
}

.message-content__bottom > div.emojionearea.form-control.message-content__bottom__textarea > div.emojionearea-button
{
    display: none;
}

.message-content__preview
{
    display: none;
    position: relative;
    border-top: 1px solid #e1e2e9;

    &--padding
    {
        padding-right: 10px;
    }
}

.message-content--no-border
{
    border-top: none;
}

.message-content-reponse
{
    &__content
    {
        padding: 10px;
    }

    &__title
    {
        font-size: 14px;
        font-weight: normal;
        margin: 5px 0;
    }

    &__message
    {
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #656d78;
    }
}

.message-content__gif
{
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    transition: 0.3s ease 0s ;
    transition-property: bottom;
    z-index: 999;
    animation: sidebar-slide-in 0.4s ease-in-out;
    height: 80%;
    background: #FFF;
    border-radius: 15px 15px 0 0;
    padding-inline: 3%;
    width: 100%;
    overflow-y: auto;
    backface-visibility: hidden;
    padding-bottom: env(safe-area-inset-bottom);
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        width: 1px;
    }
    &--no-padding-bottom
    {
        padding-bottom: 0;
    }
}

@keyframes sidebar-slide-in {
    0% {
        bottom: -100vh;
    }
    100% {
        bottom: 0;
    }
}

@keyframes sidebar-slide-out {
    0% {
        bottom: 0;
    }
    100% {
        bottom: -100vh;
    }
}

@media only screen and (min-width: 640px) {
    .message-content__gif {
        border-radius: 0;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
        cursor: pointer;
    }
    video {
        width: 100%;
    }
}

.message-content-gif-container {
    column-count: 2;
    column-gap: 2%;
    overflow-y: auto;
    backface-visibility: hidden;
    -webkit-overflow-scrolling: touch;

    > img {
        padding: 0;
        border-radius: 15px;
        margin-bottom: 5%;
        position: relative;
        width: 100%;
    }
}


.message-content-gif-actions
{
    display: flex;
    flex-direction: column;
    position: sticky;
    z-index: 99999;
    top: 0;
    background-color: #FFF;
    button {
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 17px 0;

        .line {
            background-color: #D9D9D9;
            width: 25%;
            height: 0.7vh;
            border-radius: 20px;
        }
    }
    textarea {
        margin: 0 0 1vh 0;
        border-radius: 8px;
        background-color: #F2F2F2;
        font-size: 16px;
    }
}

.message-content-gif-searchbar
{
    border: none;
    box-shadow: none;

    &:active,
    &:focus
    {
        box-shadow: none!important;
    }
}

.message__nav-sidebar
{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 1px rgba(0, 0, 0, .08), 0 3px 8px rgba(0, 0, 0, .08);
    z-index: 50;
    overflow-x: hidden;
}

.message__header
{
    border-bottom: 1px solid rgba(0, 0, 0, .10);
    box-sizing: border-box;
    height: 50px;
    justify-content: space-between;
    padding: 8px 0;
    width: 100%;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background: white;

    @media (orientation: portrait) and (min-width: 768px), (orientation: landscape) and (min-height: 576px), (orientation: landscape) and (min-width: 992px)
    {
        left: 64px;
        width: calc(100% - 64px);
    }

    .l-app.is-chat-sm-or-wider &,
    .c-tchat-popup &
    {
        position: relative;
        top: auto;
        left: auto;
        width: auto;
    }

    &:not(.message__header--conversation)
    {
        text-align: center;

        @media (orientation: portrait) and (min-width: 768px), (orientation: landscape) and (min-height: 576px), (orientation: landscape) and (min-width: 992px)
        {
            text-align: left;
        }
    }

    @media (min-width: 992px)
    {
        padding: 0 15px;
    }

    &--conversation-creation
    {
        height: auto;
        padding: 0 0 0 15px;

        &,
        .select2-selection__rendered
        {
            min-height: 50px;
            max-height: 80px;
        }

        .select2-selection__rendered
        {
            overflow-y: auto !important;
            padding: 8px 0 !important;
            align-items: center;
        }

        .select2-container--open .select2-dropdown
        {
            width: 100% !important;
        }

        .select2-container--open .select2-dropdown--below
        {
            margin-top: 0;
        }
    }
}

.message__title
{
    font-size: 19px;
    font-weight: 500;
    margin: 0;
    overflow: hidden;
    line-height: 1.28;
    color: #1d2129;
    direction: ltr;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-decoration: none!important;

    &--center
    {
        text-align: center;
    }

    @media (min-width: 992px)
    {
        flex-basis: 100%;
    }

    &--main
    {
        @media (max-width: 767px)
        {
            font-size: 21px;
            line-height: 1;
            font-weight: 500;
            color: inherit;
            width: 100%;
        }
    }
}

.message-title
{
    &__ul
    {
        position:absolute;
        left: 0;
        display: flex;
    }

    &__li:before
    {
        display: none;
    }
}

.message__button
{
    border: none;
    background-color: #fff;
    display: flex;
    font-size: 1.5em;
    padding: 0 11px;
    line-height: 1.5em;

    &--one
    {
        font-size: 2em;
    }

    &__icon
    {
        font-size: 1.3em;
    }
}

.message__infos
{
    display: none;
    padding-left: 0;
    padding-top: 50px;
    padding-right: 0;
    border-left: 1px solid #eee;

    &.is-shown
    {
        display: block;
    }

    .l-app.is-chat-sm-or-wider &,
    .c-tchat-popup &,
    .c-course-aside &
    {
        padding-top: 0;
    }
}

.message__search
{
    display: block;
    padding: 12px 12px;
    position: relative;
    top: 50px;

    @media (min-width: 1056px)
    {
        top: auto;
    }
}

.message-search__searchbar,
.user-search__searchbar
{
    width: 100%;
    border: none;
    background-color: inherit;
}


.message-infos__section
{
    padding: 16px 14px;
    border-bottom: 1px solid #eee;

    &--last
    {
        border-bottom: none;
    }

    &--header
    {
        display: flex;
    }

    &__btn
    {
        border: none;
        background-color: white;
        padding: 0;
        color: rgba(0, 0, 0, .40);
        font-size: 15px;
    }

    &__content
    {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    &__item
    {
        margin-bottom: 10px;
        display: flex;
    }


    &__button
    {
        font-size: 15px;
        color: black!important;
        display: flex;
        align-items: center;
        min-width: 0;

        &__icon
        {
            padding-left: 8px;
            padding-right: 12px;
        }

        &__avatar
        {
            margin-right: 9px!important;
            float: left;
            flex-shrink: 0;
        }
    }
}

.message-info_section_file_list_span
{
    font-size: 15px;
}

.user__list
{
    padding: 9px;
}

.user-list__title
{
    background-color: #f6f7f9;
    border-bottom: 1px solid #dddfe2;
    color: #90949c;
    //font-family: Helvetica, Arial, sans-serif;
    font-family: Apercu, sans-serif;
    font-size: 12px;
    font-weight: 500;
    padding: 8px 12px 6px 12px;
    margin: 0;
}
