.o-rich-module
{
    $this: &;

    display: flex;
    flex-direction: column;

    height: calc(#{vh(100)} - #{$header-height});

    // As CSS variables can't be negative (-var(...) is not working),
    // using a calc is the only possible solution
    margin: -$app-main-container-padding-xs;
    margin-right: calc(-1 * var(--app-main-container-padding-right-xs));
    margin-left: calc(-1 * var(--app-main-container-padding-left-xs));

    background: white;

    .has-enabled-mobile-navbar &
    {
        height: calc(#{vh(100)} - var(--mobile-menu-height) - #{$header-height});
    }

    @media (min-width: $screen-xs-min)
    {
        margin: -$app-main-container-padding-sm;
        margin-right: calc(-1 * var(--app-main-container-padding-right-sm));
        margin-left: calc(-1 * var(--app-main-container-padding-left-sm));
    }

    @media #{$media-desktop}
    {
        height:100vh !important;
    }

    @at-root
    {
        .l-app.has-no-header &
        {
            height: calc(#{vh(100)});
        }

        .l-app.has-no-header.has-enabled-mobile-navbar &
        {
            height: calc(#{vh(100)} - var(--mobile-menu-height));
        }

        #{$this}__header
        {
            display: flex;
            align-items: center;
            flex-direction: row;

            min-height: $size-normal + $size-small;

            border-bottom: 1px solid $panels-border-color;
        }

        #{$this}__content
        {
            display: flex;
            flex: 1;

            min-height: 0;
        }

        #{$this}__container-aside
        {
            overflow-x: hidden;
            overflow-y: auto;

            border-right: 1px solid $panels-border-color;

            -webkit-overflow-scrolling: touch;
        }

        #{$this}__container
        {
            display: flex;
            flex-direction: column;
        }

        #{$this}__container-header
        {
            display: flex;
            align-items: center;
            flex-direction: row;

            min-height: $size-normal + $size-x-small;

            border-bottom: 1px solid $panels-border-color;
        }

        #{$this}__main-content-container
        {
            display: flex;
            flex: 1;

            min-height: 0;
        }

        #{$this}__main-content
        {
            @include custom-scrollbar();

            overflow-x: hidden;
            overflow-y: auto;

            -webkit-overflow-scrolling: touch;

            #{$this}:not(.is-showing-main-content-aside) &
            {
                width: 100%;
            }
        }

        #{$this}__main-content-aside
        {
            overflow-x: hidden;
            overflow-y: auto;

            border-left: 1px solid $panels-border-color;

            -webkit-overflow-scrolling: touch;

            #{$this}:not(.is-showing-main-content-aside) &
            {
                display: none;
            }
        }

        #{$this}__main-content-aside-trigger
        {
            @mixin highlight()
            {
                color: var(--theme-color-light);
            }

            @include reset-button();

            margin-left: auto;
            padding: $size-xx-small $size-x-small;
            transition: color ease $speed-fast;

            color: $text-color;

            &:focus,
            &:active
            {
                @include highlight();
            }

            @media (hover: hover) and (pointer: fine)
            {
                &:hover
                {
                    @include highlight();
                }
            }

            #{$this}.is-showing-main-content-aside &
            {
                @mixin highlight()
                {
                    color: $text-color;
                }

                color: var(--theme-color);

                &:focus,
                &:active
                {
                    @include highlight();
                }

                @media (hover: hover) and (pointer: fine)
                {
                    &:hover
                    {
                        @include highlight();
                    }
                }
            }
        }

        #{$this}__main-content-aside-trigger-icon
        {
            font-size: $size-small;

            transform: rotate(180deg);
        }
    }
}
