$z-index-0: 0;
$z-index-1: 1;
$z-index-2: 10;
$z-index-3: 50;
$z-index-4: 100;
$z-index-5: 200;
$z-index-6: 300;
$z-index-7: 500;
$z-index-8: 1000;
$z-index-9: 2000;
$z-index-10: 3000;
$z-index-11: 5000;
$z-index-12: 10000;
