.c-sidebar-section {
  text-align: justify;

  &__head {
    padding: $size-x-small;

    &--with-action {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
  }

  &__title {
    font-size: $size-x-small;
    margin: 0;
  }

  &__content {
    text-align: justify;
    padding: $size-x-small;
    padding-top: 0;

    &--full-height {
      padding-bottom: 0;
    }

    &--full-width {
      padding-right: 0;
      padding-left: 0;
    }

    &__no-padding {
      padding: 0;
    }
  }
}