// In the library dist file, this piece of code is the same; only the media
// query override is needed here. The original value was 800px instead of 100px.
@media all and (min-width: 100px) {
    .fancybox-button--thumbs {
      display: inline-block; }
    .fancybox-button--thumbs span {
      font-size: 24px; }
    .fancybox-button--thumbs::before {
      width: 3px;
      height: 3px;
      top: calc(50% - 2px);
      left: calc(50% - 2px);
      box-shadow: 0 -4px 0, -4px -4px 0, 4px -4px 0, 0 0 0 32px inset, -4px 0 0, 4px 0 0, 0 4px 0, -4px 4px 0, 4px 4px 0; }
    .fancybox-thumbs {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: auto;
      width: 220px;
      margin: 0;
      padding: 5px 5px 0 0;
      background: #fff;
      word-break: normal;
      -webkit-tap-highlight-color: transparent;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      box-sizing: border-box;
      z-index: 99995; }
    .fancybox-show-thumbs .fancybox-thumbs {
      display: block; }
    .fancybox-show-thumbs .fancybox-inner {
      right: 220px; }
    .fancybox-thumbs > ul {
      list-style: none;
      position: absolute;
      position: relative;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      overflow-x: hidden;
      overflow-y: auto;
      font-size: 0; }
    .fancybox-thumbs > ul > li {
      float: left;
      overflow: hidden;
      max-width: 50%;
      padding: 0;
      margin: 0;
      width: 105px;
      height: 75px;
      position: relative;
      cursor: pointer;
      outline: none;
      border: 5px solid transparent;
      border-top-width: 0;
      border-right-width: 0;
      -webkit-tap-highlight-color: transparent;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      box-sizing: border-box; }
    li.fancybox-thumbs-loading {
      background: rgba(0, 0, 0, 0.1); }
    .fancybox-thumbs > ul > li > img {
      position: absolute;
      top: 0;
      left: 0;
      min-width: 100%;
      min-height: 100%;
      max-width: none;
      max-height: none;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
    .fancybox-thumbs > ul > li:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 2px;
      border: 4px solid #4ea7f9;
      z-index: 99991;
      opacity: 0;
      transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
    .fancybox-thumbs > ul > li.fancybox-thumbs-active:before {
      opacity: 1; } }
