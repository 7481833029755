.table
{
    background-color: white;
}

.table-responsive
{
    border-color: $panels-border-color;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th,
.table > caption + thead > tr:first-child > td,
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > td,
.table > thead:first-child > tr:first-child > th
{
    border-top: 1px solid $panels-border-color;
}

.table > thead > tr > th,
.table > tfoot > tr > th
{
    padding: $size-x-small $size-small;

    text-transform: uppercase;

    border-bottom: 1px solid $panels-border-color;
}

.table > tbody > tr > td,
.table > tfoot > tr > td,
.table > thead > tr > td
{
    padding: 10px $size-small;
}

.table > caption + thead > tr:first-child > td,
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > td,
.table > thead:first-child > tr:first-child > th
{
    border-bottom-width: 0;
}

.table-bordered,
.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th
{
    border: 1px solid $panels-border-color;
}

.table-bordered > caption + thead > tr:first-child > td,
.table-bordered > caption + thead > tr:first-child > th,
.table-bordered > colgroup + thead > tr:first-child > td,
.table-bordered > colgroup + thead > tr:first-child > th,
.table-bordered > thead:first-child > tr:first-child > td,
.table-bordered > thead:first-child > tr:first-child > th
{
    border-top-width: 0;
    border-bottom-width: 0;
}

.table-bordered > tfoot:last-of-type > tr:last-child > td,
.table-bordered > tfoot:last-of-type > tr:last-child > th
{
    border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd)
{
    background-color: #f5f7fa;
}

.hover > tbody > tr:hover
{
    background-color: #e6e9ed;
}

.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td,
.table-condensed > thead > tr > td
{
    padding: 7px $size-x-small;
}

.table-condensed > thead > tr > th,
.table-condensed > tfoot > tr > th
{
    padding: $size-xx-small $size-x-small;
}

.table td *:first-child
{
    margin-top: 0;
}

.table textarea
{
    display: block;
}
