.editable-buttons .editable-submit,
.editable-buttons .editable-cancel {

  text-shadow: unset;
  box-shadow: unset;

  font-size: 12px;
  height: 25px;

  line-height: 1;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  cursor: pointer;
  user-select: none;
  transition: background-color ease 150ms;
  border: 1px solid transparent;
  border-radius: 3px;
  color: var(--button-font-color);

  background-color: var(--theme-color);
}


.editable-buttons .editable-cancel {
  background-color: $color-dark-light;
}

.editable-click {
  border-bottom: none !important;
}

.editable-input input[type=text] {
  margin:0;
}
