.c-module-list-part {
  $this: &;

  @at-root
  {
    #{$this}__title {
      font-weight: 500;
      font-size: 19px;
      line-height: 29px;
      color: var(--theme-color);
      margin: 0;
      //padding: 0 $size-small $size-small $size-small;

      &--first {
        padding-top: $size-small;
      }
    }

    #{$this}--locked {
      #{$this}__title,
      #{$this}__item{
        color: $color-dark-light;
      }
    }

    #{$this}__btn {
      margin-top: $size-x-small;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: $size-normal;
      background-color: transparent;
      border: 1px dashed $color-default;
      border-left: none;
      border-right: none;
      color: $color-dark-light;

      @media #{$media-desktop} {
        margin-top: 21px;
        border: 1px dashed $color-default;
        border-radius: 4px;
      }

      & span {
        font-size: 15px;
        line-height: 29px;
        text-align: center;
        margin-left: $size-xx-small;

        @media #{$media-desktop} {
          font-size: 19px;
        }
      }

      &:hover,
      &:active {
        background-color: $color-light;
      }
    }
  }
}
