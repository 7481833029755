.c-circle {
    $this: &;

    justify-content: space-around ;

    width:$size-small;

    @media (min-width: $screen-xs-min)
    {
        width:$size-normal;
    }


    @at-root
    {
        #{$this}__circular-chart
        {
            display: block;
            background: transparent !important;
            stroke: var(--theme-color);
        }

        #{$this}__bg
        {
            fill: none;
            stroke: $color-light;
            stroke-width: 6.8;
        }

        #{$this}__percent
        {
            fill: none;
            stroke-width: 5.8;
            stroke-linecap: round;
            animation: progress 1s ease-out forwards;

            @keyframes progress {
                0% {
                    stroke-dasharray: 0 100;
                }
            }
        }

        #{$this}__text-percent
        {
            display:none;
            font-weight: normal;
            font-size: 0.75em;
            text-anchor: middle;

            @media (min-width: $screen-xs-min)
            {
                display: block;
            }

            #{$this}--regular & {
                stroke: none;
                fill: var(--theme-color);
            }
        }

    }
}
