.c-chat-conversation
{
    $this: &;

    display: flex;
    flex: 1;
    flex-direction: column;

    width: 100%;
    min-width: 0;
    padding: 0;

    @at-root
    {
        .l-app.is-chat-xs-or-wider #{$this},
        .c-tchat-popup #{$this},
        .c-course-aside #{$this}
        {
            height: 100%;
        }

        #{$this}__header
        {
            display: flex;
            flex-direction: column;
            flex-shrink: 0;
        }

        #{$this}__body
        {
            display: flex;
            flex: 1;
            flex-direction: row;

            .l-app.is-chat-xs-or-wider &,
            .c-tchat-popup &,
            .c-course-aside &
            {
                min-height: 0;
            }
        }

        #{$this}__content-container
        {
            flex: 1;

            min-width: 0;
        }

        #{$this}__nav-item {
            width: 50%;
        }
    }
}
