.c-user-account-snippet
{
    $this: &;

    display: flex;
    align-items: center;
    flex-direction: column;

    @at-root
    {
        #{$this}__thumb
        {
            flex-shrink: 0;

            width: 32px;
            height: 32px;
        }

        #{$this}__name
        {
            font-size: 11px;
            font-weight: 500;

            margin-top: $size-xxx-small;

            text-align: center;
            text-transform: uppercase;

            color: $text-color;
        }
    }
}
