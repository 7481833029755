.c-header
{
    $this: &;

    $inner-child-padding: $size-x-small / 2;
    $inner-child-padding-xs: $size-small / 2;
    $trigger-width: $header-inner-height + ($inner-child-padding * 2);
    $trigger-width-xs: $header-inner-height + ($inner-child-padding-xs * 2);
    $back-trigger-animation-duration: $speed-fast;

    position: fixed;
    z-index: $z-index-4;
    top: 0;

    display: flex;
    align-items: center;

    width: 100%;
    height: $header-height;
    padding: 0 $size-x-small;

    @supports (padding: unquote('max(42px, 21px)'))
    {
        padding-right: unquote('max(#{$size-x-small}, env(safe-area-inset-right))');
        padding-left: unquote('max(#{$size-x-small}, env(safe-area-inset-left))');
    }

    transition: ease $speed-medium;
    transition-property: transform, box-shadow;
    transform: translate3d(0, 0, 0);

    background-color: white;
    box-shadow: 0 0 1px rgba(black, .08), 0 3px 8px rgba(black, .08);

    will-change: transform;

    @media (min-width: $screen-xs-min)
    {
        padding: 0 $size-small;

        @supports (padding: unquote('max(42px, 21px)'))
        {
            padding-left: unquote('max(#{$size-small}, env(safe-area-inset-left))');
            padding-right: unquote('max(#{$size-small}, env(safe-area-inset-right))');
        }
    }

    @media #{$media-desktop}
    {
        display: none;

    // Resetting all that size styles is necessary to AdnSticky to work.
    // Because jQuery only reads what comes from the getComputedStyle native
    // function, display: none doesn't suffice.
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
    }

    @at-root
    {
        #{$this}.is-hidden
        {
            transform: translate3d(0, -100%, 0);

            box-shadow: none;
        }

        #{$this}.is-extended
        {
            box-shadow: none;
        }

        #{$this}__inner
        {
            display: flex;
            align-items: center;
            flex: 1;

            min-width: 0;
            height: $header-inner-height;
            margin: 0 (-($inner-child-padding));

            @media (min-width: $screen-xs-min)
            {
                margin: 0 (-($inner-child-padding-xs));
            }

            & > *
            {
                height: 100%;
                padding: 0 ($inner-child-padding) !important;

                @media (min-width: $screen-xs-min)
                {
                    padding: 0 ($inner-child-padding-xs) !important;
                }
            }
        }

        #{$this}__back-trigger
        {
            @include reset-button();

            box-sizing: content-box;
            width: $header-inner-height;
            margin-left: -($header-inner-height + ($inner-child-padding * 2));

            transition: transform ease $back-trigger-animation-duration;
            transition-delay: 0ms;
            transform: scale(0);

            #{$this}.is-showing-back-trigger &
            {
                transition-delay: $back-trigger-animation-duration;
                transform: none;
            }
        }

        #{$this}__back-trigger-icon
        {
            width: $size-small;
            height: $size-small;
        }

        #{$this}__search-trigger
        {
            @include reset-button();

            display: flex;
            align-items: center;
            justify-content: flex-end;

            width: $trigger-width;

            color: $color-dark-light;

            @media (min-width: $screen-xs-min)
            {
                width: $trigger-width-xs ;
            }
        }

        #{$this}__search-trigger-icon
        {
            width: $size-small;
            height: $size-small;
        }

        #{$this}__title-container
        {
            display: flex;
            align-items: center;
            flex: 1;

            min-width: 0;
        }

        #{$this}__title
        {
            font-size: 21px;

            overflow: hidden;
            flex: 1;

            min-width: 0;
            margin: 0;
            padding: $size-xxx-small 0;

            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        #{$this}__logo-container
        {
            transition: transform ease $back-trigger-animation-duration;
            transition-delay: $back-trigger-animation-duration;

            #{$this}.is-showing-back-trigger &
            {
                transition-delay: 0ms;
                transform: scale(0);
            }
        }
    }
}
