.c-chapter-form-contents {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__btn {
    float: right;
  }
}