.c-content-detail {
  $this: &;

  margin: 0;

  height: calc(#{vh(100)} - var(--mobile-menu-height) - #{$header-height} - (65px + 55px));

  @media (min-width: $screen-sm-min)
  {
    height: calc((#{vh(100)}) - (65px + 55px));
  }

  @at-root
  {
    #{$this}__header {
      border-bottom: none!important;
      justify-content: space-between;
    }

    #{$this}__title-group {
      display: flex;
      align-items: center;
    }

    #{$this}__title {
      margin: 0;
      margin-left: 9px;
      font-size: 26px;
      font-weight: 400;
    }

    #{$this}__back-btn {
      font-size: 2em;
    }

    #{$this}__iframe-container {
      &:not(&--no-ratio-forcing) {
        position: relative;
        overflow: hidden;
        padding-top: 56.25%;
      }
    }

    #{$this}__iframe {
      #{$this}__iframe-container:not(#{$this}__iframe-container--no-ratio-forcing) & {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
        border: 0;
      }
    }

    #{$this}__iframe-description {
      margin-top: $size-x-small;
      margin-bottom: $size-x-small;
    }

    #{$this}__quiz {
      margin: 25px 0;
    }

    #{$this}__actions {
      padding: 5px;
      text-align: center;
    }

    #{$this}__title-action {
      margin-left: $size-xx-small;
    }
  }
}
