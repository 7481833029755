/// ============================================================================
/// # TIMELINE-COMMENT-PUBLISHER-URL-PREVIEW
/// ============================================================================
/// Aperçus d'URLs dans le publicateur de commentaires. Similaire, mais
/// différent du style des aperçus dans les commentaires.
/// ============================================================================
/// @group components
/// @require objects.flag
/// ============================================================================

.c-timeline-comment-publisher-url-preview
{
    $this: &;
    $border-width: 1px;
    $border: $border-width solid $color-light;
    $height: $size-normal + $size-xx-small;

    position: relative;

    @at-root
    {
        #{$this}__content-container
        {
            overflow: hidden;

            height: $height;

            border: $border;
            border-radius: 5px;
        }

        #{$this}__remove-trigger
        {
            @include reset-button;

            $size: $size-small;

            font-size: 15px;

            position: absolute;
            top: -($size / 4);
            right: -($size / 4);

            display: flex;
            align-items: center;
            justify-content: center;

            width: $size;
            height: $size;

            transition: background-color ease $speed-fast;

            border: $border;
            border-radius: 50%;
            background: white;

            &:hover,
            &:focus,
            &:active
            {
                background: $color-light-light;
            }
        }

        #{$this}__illustration
        {
            width: $height - $border-width * 2;
            height: 100%;

            border-right: $border;
            background: white;

            object-fit: cover;

            @media (min-width: $screen-xs-min)
            {
                width: round(($height - $border-width * 2) * (16/9));
            }
        }

        #{$this}__metas
        {
            height: 100%;
            padding: $size-xx-small;

            color: $color-dark;
            background: $color-light-light;
        }

        #{$this}__origin,
        #{$this}__title
        {
            @include clamp-lines-to(1);

            margin: 0;
        }

        #{$this}__origin
        {
            font-size: 11px;
            font-weight: normal;

            margin-bottom: $size-xxx-small;

            text-transform: uppercase;
        }

        #{$this}__title
        {
            font-size: 14px;
        }
    }
}
