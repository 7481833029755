.c-device-history {
  &__container {
    padding: 0;
  }

  &__content {
    padding: 0;
  }

  &__title {
    margin: $size-x-small;
  }
}