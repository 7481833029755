.c-adndropzone-grid{

  $this: &;
  @at-root
  {
    #{$this}__container{
      display: grid;
      grid-template-columns: repeat(3, auto);
      grid-gap: 3px;
      background-color: #FFFFFF;
      list-style: none;

      @media #{$media-desktop} {
        .c-sidebar--large & {
          grid-template-columns: repeat(5, auto);
        }
      }
    }

    #{$this}__li{
      position: relative;
      height: 0;
      padding-bottom: 100%;
    }

    #{$this}__contentImg{
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    #{$this}__cropContent{
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      cursor: pointer;

      @media #{$media-desktop} {
        #{$this}__li:hover & {
          opacity: 1;
        }
      }
    }

    #{$this}__crop{
      top: 50%;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 40px;
      height: 40px;

      #{$this}__li:hover &{
        opacity: 1;
      }
    }

    #{$this}__crop-svg{
      padding: 6px;
      width: 100%;
      height: 100%;
      color: #FFFFFF;
      transition: transform ease $speed-medium;

      &:hover {
        transform: scaleX(-1);
      }

    }

    #{$this}__close{
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 25px;
      height: 25px;
      padding: 2px;
      border: none;
      background-color: rgba(red, 0.5);
      color: #FFFFFF;

      .can-hover &{
        display: none;
      }

      #{$this}__li:hover &{
        display: block;
      }
    }

    #{$this}__svg{
      width: 100%;
      height: 100%;

      transition: transform ease $speed-medium;

      #{$this}__close:hover & {
        transform: rotateZ(180deg);
      }
    }

    #{$this}__edit{
      position: absolute;
      width: 100%;
      height: 15%;
      background-color: rgba(white, 0.5);
      color: black;
      transform: translateY(0);
      transition: ease 300ms;
      transition-property: transform, visibility;
      bottom: 0;
      left: 0;
      padding: 3px;
      border: none;

      .can-hover &{
        transform: translateY(100%);
        padding: 3px;
      }

      .can-hover #{$this}__li:hover &{
        transform: translateY(0);
      }
    }

    #{$this}__name{
      color: #FFFFFF;
      line-height: 1;
      white-space: nowrap;
      padding: 0;
      text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
      text-rendering: optimizelegibility;
      font-size: 15px;
      &:hover{
        text-decoration: underline;
      }
    }

    #{$this}__step2{
      margin-top: 25px;
    }

    #{$this}__mini{
      width: 150px;
      height: 150px;
      margin: 20px 5px;
    }

    #{$this}__form{
      margin: 10px;
    }

    #{$this}__submit{
      display: none;
    }

    #{$this}__cancel{
      display: none;
    }

  }
}