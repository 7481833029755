.c-spaces-parameters {


  .c-color-picker__content {
    margin-top:5px;
  }

  .c-color-picker__content ul {
    margin-top:10px;
  }


  .c-color-picker__item-content {
    padding: 0;
  }

  .c-color-picker__item {
    margin: 0 15px 15px 0;
  }



  .k-colorpicker{
    width: 85px;
  }

  .k-colorpicker .k-selected-color{
    width: 58px;
  }


  $this: &;

  @at-root {

    #{$this}__link {
      display:flex;
      align-items: center;
      flex-direction: row;
      margin-bottom: $size-x-small;
    }


    #{$this}__multiselect {

      & .k-focus {
        background: #ffffff !important;
      }

      & .k-hover {
        background: #efefef !important;
      }

      & .k-selected {
        background: #efefef !important;
        color: #444 !important;
      }

    }


    #{$this}__toggle-secure .k-selected {
      background-color: var(--theme-color) !important;
    }


    #{$this}__container-secure {
      padding: 10px;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 20px;

      & .k-switch-on .k-switch-track {
        box-shadow: white  !important;
        outline-color:white !important ;
        border-color: var(--theme-color) !important;
        color: white;
        background-color: var(--theme-color) !important;
      }


      & input {
        user-select: none;
        pointer-events: none;
      }

      & .btn-manage-users {
        margin-top:5px;
        margin-bottom:10px;
      }


      & .k-list-item .k-focus {
          background: #ffffff !important;
      }

    }


    // spécifique formulaire du header
    #{$this}__headerform .c-adndropzone__dropzone {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }

    #{$this}__dropzonelogo .c-adndropzone__dropzone {
      width: 160px;
      margin-left: 0px;
    }
    #{$this}__dropzonebackground .c-adndropzone__dropzone {
      width: 100%;
      margin-left: 0px;
    }

    #{$this}__dropzone-metaimage--placeholder {
      margin: 5px 0 15px 0;
      width: 100%;
      height: $size-x-large;
    }

    #{$this}__dropzone-metaimage .c-adndropzone__dropzone {
      margin-left: 0;
      margin-right: 0;
      height: $size-x-large;
      width : 100%;
    }


    // sélection d'une couleur à la place d'une bannière
    #{$this}__color-selector-container {
      padding: 5px 5px 5px 0px;
      margin: 0px 0px 15px;
    }

    #{$this}__color-selector-title {
      font-weight: bold;
    }


    //region spécifique pour la gestion des admins
    #{$this}__admin-item-container {
      width: 100%;
    }

    #{$this}__admin-item-features {
      width: 100%;
      display: flex;

      padding: 5px 5px 0px 0px;
    }


    #{$this}__admin-labels {
      margin-right: 10px;
    }
    //endregion



    //region spécifique pour la gestion des favoris
    #{$this}__favoris-item-container {
      width: 100%;
    }

    #{$this}__favoris-item-features {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

    }


    #{$this}__favoris-labels {
      margin-left: 10px;
      margin-right: 10px;
    }
    //endregion



    //region communs admins / favoris
    #{$this}__btn {
      min-width: 50px;
      margin-left: auto;
    }

    #{$this}__btn-delete {
      min-width: 50px;
    }
    //endregion


    //region Skin du champ de la combo de recherche utilisateur
    #{$this} .k-dropdown-wrap.k-state-default {
      border:none !important;
    }

    #{$this} .k-dropdown-wrap.k-hover,
    #{$this} .k-dropdown-wrap.k-focus {
      box-shadow: none !important;
    }

    #{$this} .k-dropdown-wrap .k-select {
      display: flex;
      align-items: center;
      justify-content: center;

    }
    //endregion

  }

}
