.c-module-list {
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    margin-top: $size-small;
  }

  &__item {
    display: flex;
    align-items: center;
    background-color: var(--theme-color);
    height: $size-medium;
    cursor: pointer;

    @media (min-width: $screen-sm-min) {
      height: 96px;
      overflow: hidden;
    }

    &--not-first {
      margin-top: $size-x-small;
    }

    &--locked {
      background-color: $color-dark-light;
      cursor: auto;
    }

    &.is-open {
      margin-bottom: 0;
    }
  }

  &__title-group {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;

    @media (min-width: $screen-sm-min) {
      padding: 0 $size-x-small;
    }
  }

  &__title-with-action {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (min-width: $screen-sm-min) {
      align-items: baseline;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 29px;
    color: white;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (min-width: $screen-sm-min) {
      //margin-bottom: $size-xx-small;
    }
  }

  &__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 17px;
    color: white;
    margin: 0;
  }

  &__img-group {
    @media (min-width: $screen-lg-min) {
      width: 218px;
      height: 100%;
    }
  }

  &__img {
    overflow: hidden;
    min-width: 0;
    min-height: 0;
    display: none;

    @media (min-width: $screen-lg-min) {
      display: block;
    }
  }

  &__btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $size-normal;
    background-color: transparent;
    border: 1px dashed $color-default;
    border-left: none;
    border-right: none;
    margin-bottom: $size-small;
    margin-top: $size-small;

    @media (min-width: $screen-sm-min) {
      margin-top: $size-small;
      border: 1px dashed $color-default;
      border-radius: 4px;
    }


    & span {
      font-size: 15px;
      line-height: 29px;
      margin-left: $size-xx-small;

      @media (min-width: $screen-lg-min) {
        font-size: 19px;
      }
    }

    &:hover,
    &:active {
      background-color: $color-light;
    }
  }

  &__content {
    position: relative;
    color: $color-dark-light;

    &--unlocked {
      color: var(--theme-color);
    }
  }

  &__action {
    min-width: 0;
    color: white;
    height: auto;
    padding: $size-xxx-small;
    margin: 0;
  }
}
