.c-mobile-main-menu-user
{
    $this: &;

    display: flex;
    flex-direction: column;

    @at-root
    {
        #{$this}__text
        {
            display: flex;
            flex-direction: column;

            margin-top: $size-xx-small;
        }

        #{$this}__name
        {
            font-size: 17px;
        }

        #{$this}__organization-name
        {
            font-size: 14px;

            color: $color-default;
        }
    }
}
