.c-AdnDocsViewer {
  $this: &;

  $headerFontColor: #555555;
  $headerBackgroundColor: #e0e0e0;

  $footerFontColor: #ffffff;

  @at-root {

    //
    // CONTAINER PRINCIPAL DU COMPOSANT avec overlay et loader
    //
    #{$this} a {
      color: unset;
      text-decoration: unset;

      :hover {
        color: unset;
        text-decoration: unset;
      }
    }

    #{$this}__overlay,
    #{$this}__container {
      position: relative;
      bottom: 0;
      left: 0;
      top: 0;
      right: 0;

      height: 100%;
      width: 100%;

      overflow: hidden;

      white-space: nowrap;
    }

    #{$this}__overlay {
      //z-index: 8000;
    }

    #{$this}__loader {
      position: absolute;
      width: 100px;
      height: 100px;

      top: calc(50% - 50px);
      left: calc(50% - 50px);

      z-index: 9000;
    }

    //
    // PAGE (image) EN COURS dans le container
    //
    @media (min-width: 768px) {
      #{$this}__container .pages {
        padding: 0 !important;
      }
    }
    #{$this}__container .pages {
      bottom: 0;
      left: 0;
      top: 0;
      right: 0;

      height: 100%;
      width: 100%;

      /**
      Spécifique viewer d'album
       */
      .lg-backdrop {
        background: #fff;
      }

      .lg-thumb-outer {
        background: #dbdbdb;
      }

      .lightGallery-captions {
        h3 {
          color: #5b5b5b;
          font-size: 17px;
        }
      }

      .lg-next, .lg-prev {
        background-color: #efefef;
        color: #555;
      }

    }

    #{$this}__container img {
      max-width: 100%;
    }

    //
    // BOUTONS DE NAVIGATION page suivante et précédente
    //
    #{$this}__navigation-right,
    #{$this}__navigation-left {
      position: absolute;
      top: calc(50% - 30px);
      left: 0;
      z-index: 100;
      width: 60px;

      font-size: 31px;

      display: none;
    }

    #{$this}__navigation-right {
      left: unset;
      right: 0;
    }

    #{$this}__container .left a,
    #{$this}__container .right a,
    #{$this}__container .left a:hover,
    #{$this}__container .right a:hover {
      display: block;
      text-decoration: none;
      border: none;

      padding: 35px;
      padding-left: 20px;
    }

    //
    //  HEADER
    //
    #{$this}__header {
      z-index: 10;
      position: absolute;
      left: 50%;
      top: -2%;
      transform: translate(-50%, 0);
      display: flex;
      justify-content: center;
      @media (min-width: 768px) {
        top: -1%;
      }
    }

    #{$this}__header-menu-wrapper {
      width: 100vw;
    }

    #{$this}__dropdown-sidebar {
      border-radius: 25px 25px 0 0;
      .c-sidebar__content a {
        text-decoration: none !important;
        border: none !important;
      }

      // bouton d'accès aux miniatures
      #{$this}__header-leftcontainer {
        position: relative;
        z-index: 1;
      }
    }

    #{$this}__header-title {
      max-width: 40vw;
      font-weight: 600;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: none;
      margin-right: 20px;
      position: relative;
      z-index: 1;

      @media (min-width: 768px) {
        max-width: 70vw;
      }
    }

    #{$this}__header-spaces-dropdown,
    #{$this}__header-logo {
      position: absolute;

      max-height: 50px;
      max-width: 200px;
      padding: 0;

      @media (min-width: $screen-xs-min) {
        max-height: 50px;
        max-width: 250px;
        padding: 0;
        margin: 0;
      }
    }

    #{$this}__header-logo img {
      max-height: 50px;

      display: none;
    }


    #{$this}__header-spaces-dropdown {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      width: 35vw;
      max-width: 450px;
      min-width: 150px;


      .k-picker-solid {
        border: none;
        background: none;
        box-shadow: none;
        max-width: 700px;
        font-weight: 600;
        color: #555555;

        @media (min-width: 768px) {
          width: 35vw;
          max-width: 700px;
        }

        .k-input-inner {
          position: relative;
          padding: 10px 0;
          margin-right: -40px; // à améliorer, pas le meilleur moyen de faire du tout.
          top: 0;

          .k-input-value-text {
            margin: 0 40px 0 5px;
            position: relative;
            z-index: 1;

            @media (min-width: 768px) {
              margin: 0 40px 0 20px;
            }
          }
        }

        .k-input-inner:hover {
          background: darken(#D7D7D7, 7%);
          transition: 0.2s ease-in-out;
          border-radius: 50px;
        }
      }

      .k-input-button {
        width: 40px;
        padding-block: 0 !important;
        position: relative;
        z-index: 1;
        margin: 0;
        border-radius: 50px !important;
      }

      .k-input-button:hover {
        background: darken(#D7D7D7, 7%);
      }

      .k-focus .k-input-button {
        background: transparent;
      }
    }

    #{$this}__header-spaces-dropdown > span {
      width: 170px;

      @media (min-width: $screen-xs-min) {
        width: 300px;
      }
      @media (min-width: $screen-sm-min) {
        width: 400px;
      }
    }

    #{$this} {
      &__header-spaces-dropdown-component {
        width: 90% !important;
        position: relative !important;
        top: -42px;
        border: 2px solid white;
        border-radius: 25px;

        .section {
          position: absolute;
          width: calc(100% - 80px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        h3 {

          width: calc(100% - 80px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          font-size: 1.2em;
          font-weight: 600;
          margin: 0 0 0 5px;
        }

        p, .section {
          margin: 0;
          padding: 0;
        }

        .page-count {
          display: block;
          margin-left: 5px;
          margin-top: 3px;
        }

        .section {
          margin-top: 5px;
          padding-left: 8px !important;
          font-size: 17px;
          font-weight: bold;
          width: 100%;
        }

        .k-list-scroller {
          @include custom-scrollbar();
        }

        .k-list-item {
          line-height: 1em;
          min-width: 0;
          min-height: 40px;
          padding-inline: 4px;

          &:hover, &.k-selected {
            background: darken(#D7D7D7, 7%);
          }

          .img-album {
            width: 50px;
            height: 50px;
            padding: 3px;
          }

          .k-list-item-text {
            display: flex;
            align-items: center;

             & .k-state-default {
              box-sizing: border-box;
              display: flex;
              align-items: flex-start;
              margin: 0;
              flex-direction: column;
              justify-content: left;
              width: 100%;

              &.single-image {
                flex-shrink:0;
              }

              &:first-child {
                border-radius: 50px;
                box-shadow: inset 0 0 30px rgba(0, 0, 0, .3);
                margin: 5px;
                width: 50px;
                height: 50px;
                background-size: 100%;
                background-repeat: no-repeat;
              }
            }
          }
        }
      }

        .c-AdnDocsViewer__wrapper {
          display: flex;
          align-items: center;
          padding: 12px 10px !important;
          border-radius: 50px;
          font-size: 15px;
          position: relative;
          top: clamp(50px, 5vh, 100px);
          border: 2px solid white;

          z-index: 1;

          @media (min-width: 768px) {
            border: solid 2px transparent;
          }

          &--code, &--close {
            margin-left: 2vw;
          }

          &--close {
            padding: 12px 12px !important;
          }

          &--btn, &--dropdown {
            padding: 2px!important;
          }

          &:hover::after {
            transition: 0.2s ease-in-out;
          }

          &:before, &:after {
            content: '';
            position: absolute;
            border-radius: 50px;
            z-index: -1;
          }

          &:before {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }

          @media  (min-width: 768px) {
            &:before {
              top: -5px;
              left: -5px;
              right: -5px;
              bottom: -5px;
              background: rgba(255, 255, 255, 0.01);
              backdrop-filter: blur(49.5px);
            }
          }

          &:after {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #D7D7D7;
            transition: 0.2s ease-in-out;
            z-index: 0;
          }
        }
      }

      &__header-features-btn {
        margin-right: clamp(5px, 1vw, 10px);
        display: block;
        position: relative;

        &--more {
          margin-left: auto;
          margin-right: 2vw;

          .btn-group {
            .c-btn--more {
              transform: rotate(90deg);
              width: 40px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50px;
              z-index: 1;
              transition: 0.2s ease-in-out;

              &:hover {
                background: darken(#D7D7D7, 7%) !important;
                border-radius: 50px;
                color: black;
                height: 40px;
                transition: 0.2s ease-in-out;
              }

              &:not(:disabled):hover, &:not(:disabled):focus {
                background: transparent;
              }
            }

            .dropdown-menu {
              border-radius: 25px;
              border: solid 2px white;

              > li > a {
                padding: 25px 20px;
              }

              > li > a:hover {
                background: darken(#D7D7D7, 7%) !important;
                color: black !important;
                transition: 0.2s ease-in-out;
              }
            }
          }
        }
      }


    #{$this}__header-menu-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: start;
      height: 0;
      gap:5px;

      @media  (min-width: 768px) {
        gap:25px;
        }
      }

    #{$this}__header-menu-desktop-item {
      margin-left: 11px;
    }

    #{$this}__header-codepresentation {
      display: none;
      text-align: left;
      position: relative;
      z-index: 1;
    }

    #{$this}__header-codepresentation div {
      font-weight: bold;
      font-size: 13px;
    }

    #{$this}__header-thumbs {
      display: none;
      position: relative;
      z-index: 1;
      margin: 0 15px 0 2px;

      @media  (min-width: 768px) {
        margin: 0 20px 0 2px;
      }
    }

    #{$this}__header-closesidebar {
      display: none;
      position: relative;
      z-index: 1;
    }

    #{$this}__header-closesidebar .header-icon,
    #{$this}__header-thumbs .header-icon,
    #{$this}__header-menu .header-icon {
      position: relative;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #434a54;

      &::after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 200%;
        height: 200%;
        border-radius: 50%;
        background-color: transparent;
        transition: background-color 0.3s ease;
      }

      &:hover {
        color: black;

        &::after {
          background: darken(#D7D7D7, 7%);
        }
      }
    }


    //
    // FOOTER
    //
    #{$this}__footer {
      position: absolute;
      height: 50px;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 2vh;

      .c--AdnDocsViewer__wrapper-zoom{
        margin: 0 10px;
      }
    }




      #{$this}__footer-float {
      border-radius: 50px;
      background: rgba(50, 50, 50, 0.8);
      margin: -20 auto;
      width: 262px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    // container de l'information Page X / XX dans le footer
    #{$this}__footer-paging {
      text-align: center;
      color: $footerFontColor;

      display: none;

      font-size: 15px;
      padding: 0 10px;
      font-weight: 600;
    }


    #{$this}__footer-paging-text,
    #{$this}__footer-paging input {
      width: 25px;
      display: inline-block;
      text-align: center;
      color: $footerFontColor;
      border-bottom: none;

      font-size: 15px;
    }

    #{$this}__footer-paging input::-webkit-outer-spin-button,
    #{$this}__footer-paging input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none !important;
      margin: 0;
    }

    #{$this}__footer-paging input[type=number] {
      -moz-appearance: textfield;
    }

    // style commun des boutons dans les features du footer
    #{$this}__footer-features-btn {
      display: inline-block;
      border-radius: 50px;
      padding: 10px 14px;
      transition: 0.2s ease-in-out;
    }

    #{$this}__footer-features-btn:not(:last-child) {
      margin-right: -13px;
    }

    #{$this}__footer-features-btn:hover {
      display: inline-block;
      padding: 10px 14px;
      background: darken(rgba(50, 50, 50, 0.8), 7%);
      transition: 0.2s ease-in-out;

    }


    #{$this}__footer-rotation {
    }

    #{$this}__footer-zoomout {
    }

    #{$this}__footer-zoomin {
    }

    #{$this}__footer-reset {
    }

    #{$this}__footer-zoomin .footer-icon,
    #{$this}__footer-zoomout .footer-icon,
    #{$this}__footer-rotation .footer-icon,
    #{$this}__footer-reset .footer-icon {
      font-size: 15px !important;
      color: $footerFontColor;
    }

    //
    // SECTION SIDEBAR THUMBS
    //
    #{$this}__thumbs-sidebar {
      max-width: unset;

      @media (min-width: $screen-xs-min) {
        max-width: 295px;
        border-radius: 0 25px 25px 0;
      }
    }

    #{$this}__thumbs-sidebar .c-sidebar__head {
      display: none;
    }

    #{$this}__thumbs-sidebar .c-sidebar__close {
      color: white;
    }

    #{$this}__thumbs-sidebar .c-sidebar__title {
      font-size: 21px;
      color: white;
      padding-top: 6px;
    }

    #{$this}__thumbs-sidebar .c-sidebar__content a {
      text-decoration: none !important;
      border: 2px solid white;
    }

    #{$this}__thumbs-item {
      background: #efefef;
      width: 130px;
      display: inline-flex;
      text-align: center;
      margin: 5px 5px 5px 0;
      height: 100px;
      border-radius: 15px;
      position: relative;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      box-shadow: 1px 1px 5px rgba(68, 68, 68, 0.3);
    }

    #{$this}__tumbs-item-selected {
      box-shadow: 1px 1px 20px 4px rgba(160, 160, 160, 0.9);
    }

    #{$this}__thumbs-item img {
      position: absolute;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    #{$this}__thumbs-number {
      color: #222222;
      position: absolute;
      padding: 5px 8px;
      border-radius: 50px;
      margin: 5px;
      background: white;
      font-size: 11px;
      top: 0;
      left: 0;
      z-index: 1;
    }

    /*
      -------------------------------------------------------
       Configuration du viewer vertical pour le mode desktop
      -------------------------------------------------------
     */
    #{$this}__vertical-main {
      width: 100%; /* percentage fixes the X axis white space when zoom out */

      height: 100vh !important; /* this is still an issue where you see white space when zoom out in the Y axis */

      overflow: auto; /* needed for safari to show the x axis scrollbar */
      overflow-x: hidden;
      text-align: center;
      font-size: 0;
    }

    #{$this}__vertical-container {
      height: 0;
      transform-origin: center top;
      display: inline-block;
    }

    #{$this}__vertical-page {
      width: 40vw;
      //min-height: 30vh; // pour les documents qui sont à la fois landscape et portrait

      //background: white;

      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
      display: block;
    }
    #{$this}__vertical-page:first-child {
      margin-top: 2vh;
    }

    //#{$this}__vertical-page-portrait {min-height: 60vh !important;}
    //#{$this}__vertical-page-landscape { min-height: 40vh !important;}
    #{$this}__vertical-page-last {
      margin-bottom: 100px;
    }

    #{$this}__vertical-page img:first-child {
      display: block;
      width: 100%;
    }
  }
}