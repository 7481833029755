.c-tasks-tagCreate {

  float: left;
  height: 32px;
  background-color: #b3bac5;
  border-radius: 4px;
  color: #fff;
  display: flex;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  &__container{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 9px;
    margin-bottom: 9px;
  }

  &__visuelDemo{
    height: 32px;
    background-color: #EEF6EC;
    border-radius: 4px;
    color: #fff;
    display: flex;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    align-items: center;
    padding-left: 12px;
  }

  &__pastilleTag{
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: #B7DDB0;
    margin-right: 5px;
  }

  &__visuelDemoTexte{
    color: black;
  }

  &__greenLight {
    background-color: #B7DDB0;
    &:hover{
      background-color: #7BC86C;
    }
  }

  &__greenLight--background {
    background-color: #EEF6EC;
  }

  &__green {
    background-color: #7BC86C;
    &:hover{
      background-color: #5AAC44;
    }
  }

  &__green--background {
    background-color: #D6ECD2;
  }

  &__greenDark {
    background-color: #5AAC44;
    &:hover{
      background-color: #49852E;
    }
  }

  &__greenDark--background {
    background-color:#B7DDB0 ;
  }

  &__yellowLight {
    background-color: #F5EA92;
    &:hover{
      background-color: #F5DD29;
    }
  }

  &__yellowLight--background {
    background-color: #FDFAE5 ;
  }

  &__yellow {
    background-color: #F5DD29;
    &:hover{
      background-color: #E6C60D;
    }
  }

  &__yellow--background {
    background-color: #FAF3C0;
  }

  &__yellowDark {
    background-color: #E6C60D;
    &:hover{
      background-color: #CCA42B;
    }
  }

  &__yellowDark--background {
    background-color: #F5EA92;
  }

  &__orangeLight {
    background-color: #FAD29C;
    &:hover{
      background-color: #FFAF3F;
    }
  }

  &__orangeLight--background {
    background-color: #FDF4E7;
  }

  &__orange {
    background-color: #FFAF3F;
    &:hover{
      background-color: #E79217;
    }
  }

  &__orange--background {
    background-color: #FCE6C6;
  }

  &__orangeDark {
    background-color: #E79217;
    &:hover{
      background-color: #B4760F;
    }
  }

  &__orangeDark--background {
    background-color: #FAD29C;
  }

  &__redLight {
    background-color: #EFB3AB;
    &:hover{
      background-color: #EF7564;
    }
  }

  &__redLight--background {
    background-color: #FBEDEB;
  }

  &__red {
    background-color: #EF7564;
    &:hover{
      background-color: #CF513D;
    }
  }

  &__red--background {
    background-color: #F5D3CE;
  }

  &__redDark {
    background-color: #CF513D;
    &:hover{
      background-color: #933B27;
    }
  }

  &__redDark--background {
    background-color: #EFB3AB;
  }

  &__purpleLight {
    background-color: #DFC0EB;
    &:hover{
      background-color: #CD8DE5;
    }
  }

  &__purpleLight--background {
    background-color: #F7F0FA;
  }

  &__purple {
    background-color: #CD8DE5;
    &:hover{
      background-color: #A86CC1;
    }
  }

  &__purple--background {
    background-color: #EDDBF4;
  }

  &__purpleDark {
    background-color: #A86CC1;
    &:hover{
      background-color: #6C547B;
    }
  }

  &__purpleDark--background {
    background-color: #DFC0EB;
  }

  &__blueLight {
    background-color: #8BBDD9;
    &:hover{
      background-color: #298FCA;
    }
  }

  &__blueLight--background {
    background-color: #E4F0F6;
  }

  &__blue {
    background-color: #5BA4CF;
    &:hover{
      background-color: #0079BF;
    }
  }

  &__blue--background {
    background-color: #BCD9EA;
  }

  &__blueDark {
    background-color: #026AA7;
    &:hover{
      background-color: #094C72;
    }
  }

  &__blueDark--background {
    background-color: #8BBDD9;
  }

  &__skyLight {
    background-color: #8FDFEB;
    &:hover{
      background-color: #29CCE5;
    }
  }

  &__skyLight--background {
    background-color: #E4F7FA;
  }

  &__sky {
    background-color: #29CCE5;
    &:hover{
      background-color: #00AECC;
    }
  }

  &__sky--background {
    background-color: #E4F7FA;
  }

  &__skyDark {
    background-color: #00AECC;
    &:hover{
      background-color: #0082A0;
    }
  }

  &__skyDark--background {
    background-color: #8FDFEB;
  }

  &__limeLight {
    background-color: #B3F1D0;
    &:hover{
      background-color: #6DECA9;
    }
  }

  &__limeLight--background {
    background-color: #ECFBF3;
  }

  &__lime {
    background-color: #6DECA9;
    &:hover{
      background-color: #4ED583;
    }
  }

  &__lime--background {
    background-color: #D3F6E4;
  }

  &__limeDark {
    background-color: #4ED583;
    &:hover{
      background-color: #49AA54;
    }
  }

  &__limeDark--background {
    background-color: #B3F1D0;
  }

  &__pinkLight {
    background-color: #F9C2E4;
    &:hover{
      background-color: #FF8ED4;
    }
  }

  &__pinkLight--background {
    background-color: #FEF1F9;
  }

  &__pink {
    background-color: #FF8ED4;
    &:hover{
      background-color: #E568AF;
    }
  }

  &__pink--background {
    background-color: #FCDCEF;
  }

  &__pinkDark {
    background-color: #E568AF;
    &:hover{
      background-color: #AE4270;
    }
  }

  &__pinkDark--background {
    background-color: #F9C2E4;
  }

  &__blackLight{
    background-color: #505F79;
    &:hover{
      background-color: #42526E;
    }
  }

  &__blackLight--background{
    background-color: #EBECF0;
  }

  &__black{
    background-color: #344563;
    &:hover{
      background-color: #172B4D;
    }
  }

  &__black--background{
    background-color: #DFE1E6;
  }

  &__blackDark{
    background-color: #091E42;
    &:hover{
      background-color: #000000;
    }
  }

  &__blackDark--background{
    background-color: #C1C7D0;
  }

  &__check{
    font-size: 17px;
    display: none;

    &--checked{
      display: flex;
    }
  }

  &__buttonLeft{
    @media (max-width: $screen-sm-min) {
      float: left;
      margin-top: 10px;
    }
  }

  &__buttonRight{
    @media (max-width: $screen-sm-min) {
      float: right;
      margin-top: 10px;
    }
  }

}