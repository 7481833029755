.c-timeline-publisher-editor
{
    $this: &;

    position: relative;

    padding:0px;
    padding-top:$size-xx-small;

    @media (min-width: $screen-xs-min)
    {
        padding: $size-xx-small;
    }

    @at-root
    {
        #{$this}__mobile {
            margin-left:0px !important;
        }

        // #{$this},
        // #{$this}::before,
        // #{$this}::after
        // {
        //     background-color: white;
        // }

        //#{$this}::before,
        //#{$this}::after
        //{
        //    content: '';
        //
        //    position: absolute;
        //    top: 16.5px;
        //    left: 0;
        //
        //    width: $size-x-small;
        //    height: $size-x-small;
        //}

        #{$this},
        #{$this}::before
        {
            border:none;

            @media (min-width: $screen-xs-min)
            {
                border: 1px solid $panels-border-color;
            }
        }

        //#{$this}:before
        //{
        //    z-index: -1;
        //
        //    transform: translateX(calc(-50% - 1px)) rotateZ(-45deg);
        //}

        //#{$this}:after
        //{
        //    transform: translateX(calc((-#{$size-x-small}/ 2) + .5px)) rotateZ(-45deg);
        //}

        #{$this}.is-focused
        {
            &,
            &:before
            {
                border-color: var(--theme-color);
            }
        }

        #{$this}.is-user-dragging
        {
            &,
            &:before
            {
                border-style: dashed;
                border-color: var(--theme-color);
            }
        }

        #{$this}__text
        {
            display: flex;
            flex-direction: column;
        }

        #{$this}__input
        {
            width: 100%;
            padding: 0;

            resize: none;

            border: none;
        }

        #{$this}::placeholder
        {
            font-size: 17px;
            font-style: italic;

            color: $color-default;
        }

        #{$this}__actions
        {
            font-size: 15px;

            display: flex;
            align-self: flex-end;

            width: auto;
        }

        #{$this}__actions_button_under_text{
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: flex-end;
        }

        #{$this}__actionsmobile
        {
            font-size: 15px;
            width: auto;
            padding-bottom: env(safe-area-inset-bottom);
        }


        #{$this}__actionsmobile button,
        #{$this}__actionsmobile select,
        #{$this}__actionsmobile button:visited
        {
            width: 100%;
            height:45px;
            border-top:solid;
            border-width:2px;
            border-top-color: $color-light;
            background:$color-light-light;
        }


        #{$this}__action
        {
            height: 100%;
            padding: 0 $size-xxx-small;

            color: $color-default-light;
            border: none;
            background: none;


            &:hover
            {
                color: inherit;
            }

            &:last-child
            {
                padding-right: 0;
            }

            &--emoji > i
            {
                transform: scale(1.2);
            }

            &--gif > i:before
            {
                font-size: inherit;
                font-style: normal;
                line-height: 1;

                color: inherit;
            }
        }

        #{$this}__footer
        {
            display: none;

            margin-top: $size-xxx-small;
            padding-top: $size-xx-small;

            border-top: 1px solid $color-light;

            &.has-files
            {
                display: block;
            }
        }

        #{$this}__files
        {
            display: flex;
            flex-wrap: wrap;

            margin-right: -2.5px;
            margin-bottom: -$size-xxx-small;
            margin-left: -2.5px;
        }
    }
}
