.c-main-aside
{
    $this: &;
    $padding: $size-small;
    $trigger-width: $app-main-container-padding-sm - $size-xxx-small;
    $trigger-animation-offset: 5px;
    $toggle-animation-duration: $speed-medium;

    position: fixed;
    z-index: $z-index-3;
    top: 0;
    left: $main-menu-width;
    left: var(--main-menu-width);

    width: $main-aside-width;
    height: 100%;

    @at-root
    {
        &::before
        {
            content: '';

            position: absolute;
            z-index: -1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            transition: opacity ease $toggle-animation-duration;

            box-shadow: 0 0 1px rgba(black, .08), 0 3px 8px rgba(black, .08);
        }

        #{$this}--right
        {
            right: 0;
            left: auto;
        }

        #{$this}__content
        {
            display: flex;
            flex-direction: column;

            height: 100%;

            background: white;
        }

        #{$this}__trigger
        {
            @include reset-button();

            position: absolute;
            z-index: -1;
            top: 50%;
            right: -$trigger-width;

            display: none;
            align-items: center;

            width: $trigger-width;
            height: $size-normal + $size-xxx-small;

            transition: transform ease $speed-fast;
            transform: translateY(-50%);

            background: white;

            // Necessary for the tooltip placement.
            // Without it, its position would be calculated from the full width
            // of the trigger, and not only the visible part, causing the
            // tooltip to overflow.
            &::before
            {
                content: '';

                position: absolute;
                top: 0;
                bottom: 0;
                left: -$trigger-animation-offset;

                width: $trigger-animation-offset;

                background: white;
            }

            &,
            &::after
            {
                border-radius: 0 3px 3px 0;
            }

            // Only useful to create a shadow which wrap the trigger AND its
            // before pseudo-element
            &::after
            {
                content: '';

                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: -$trigger-animation-offset;

                box-shadow: 0 0 1px rgba(0, 0, 0, .08), 0 3px 8px rgba(0, 0, 0, .08);
            }

            &:hover
            {
                transform: translateY(-50%) translateX($trigger-animation-offset);
            }

            #{$this}--right &
            {
                right: auto;
                left: -$trigger-width;

                &::before
                {
                    right: -$trigger-animation-offset;
                    left: auto;
                }

                &,
                &::after
                {
                    border-radius: 3px 0 0 3px;
                }

                &::after
                {
                    right: -$trigger-animation-offset;
                    left: 0;
                }

                &:hover
                {
                    transform: translateY(-50%) translateX(-$trigger-animation-offset);
                }
            }

            @each $name, $values in $breakpoints
            {
                // We don't want the max values here
                @if map-get($values, 'min')
                {
                    // The slash (before the @) is only necessary in CSS; don't put
                    // it in the HTML
                    #{$this}--maskable\@#{$name} &
                    {
                        @media (min-width: #{map-get($values, 'min')})
                        {
                            display: flex;
                        }
                    }

                    #{$this}--visible\@#{$name} &
                    {
                        @media (min-width: #{map-get($values, 'min')})
                        {
                            display: none;
                        }
                    }
                }
            }
        }

        #{$this}__trigger-icon
        {
            font-size: 19px;

            margin-right: 2px;

            transition: transform ease $speed-medium;
            transition-delay: $toggle-animation-duration;

            #{$this}.is-shown &
            {
                transform: rotate(-180deg);
            }

            #{$this}--right &
            {
                margin-right: 0;
                margin-left: 2px;
            }

            #{$this}--right.is-shown &
            {
                transform: rotate(180deg);
            }
        }

        #{$this}__search
        {
            flex-shrink: 0;

            margin: $padding 0;
            padding: 0 $padding;

            &::before
            {
                content: '';

                position: absolute;
                z-index: $z-index-2;
                bottom: -$size-xxx-small;
                left: 0;

                width: 100%;
                height: $size-xxx-small;

                background: linear-gradient(white, rgba(white, 0));
            }
        }

        // This piece of code creates some responsive modifiers which look like
        // this:
        // - c-main-aside--maskable@xs
        // - c-main-aside--maskable@sm
        // - c-main-aside--maskable@md
        // - ...
        // - c-main-aside--visible@xs
        // - c-main-aside--visible@sm
        // - c-main-aside--visible@md
        // - etc.
        // Check this out https://csswizardry.com/2015/08/bemit-taking-the-bem-naming-convention-a-step-further/#responsive-suffixes

        // By default, a main aside is visible. These modifiers hides it, and
        // let you specify at which breakpoint it will have a trigger to show it.
        @mixin --maskable()
        {
            display: block !important; // Overrides the display: none from --visible

            transition: transform ease $toggle-animation-duration;
            transform: translateX(-100%);

            &#{$this}--right
            {
                z-index: $z-index-8 + 1; // Used to be be displayed over floating action buttons

                transform: translateX(100%);
            }

            &::before
            {
                opacity: 0;
            }

            &.is-shown
            {
                transform: none;

                &::before
                {
                    opacity: 1;
                }
            }
        }

        // By default, a main aside is visible. These modifiers hides it, and
        // let you specify at which breakpoint it will be displayed.
        // Overrides the --maskable modifier, if they are used on the same
        // element.
        @mixin --visible()
        {
            display: block;

            transform: none !important;

            &::before
            {
                opacity: 1;
            }
        }

        @each $name, $values in $breakpoints
        {
            // We don't want the max values here
            @if map-get($values, 'min')
            {
                #{$this}--maskable\@#{$name}
                {
                    display: none;

                    @media (min-width: #{map-get($values, 'min')})
                    {
                        @include --maskable();
                    }
                }

                // The slash (before the @) is only necessary in CSS; don't put
                // it in the HTML
                #{$this}--visible\@#{$name}
                {
                    display: none;

                    @media (min-width: #{map-get($values, 'min')})
                    {
                        @include --visible();
                    }
                }
            }
        }
    }
}
