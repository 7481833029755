.c-contacts-tags {

  & .header
  {
    border-bottom: solid;
    border-bottom-width: 1px;
    border-bottom-color: $color-light;
    padding: $size-x-small $size-xx-small $size-xx-small;


    & .title
    {
      margin: 0;
      font-size: 15px;
      color:$text-color;
    }

  }

  & .content {


    & .form {

      padding: $size-xxx-small;

      & input[type=text] {
        padding: 0;margin: 0 0  $size-xxx-small;
      }

      & .color-item
      {
        float: left;
        height: 32px;
        background-color: #b3bac5;
        border-radius: 4px;
        color: #fff;
        display: flex;
        max-width: 100%;
        overflow: hidden;
        position: relative;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        align-items: center;
        justify-content: center;

        &__container{
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 9px;
          margin-bottom: 9px;
        }


      }

    }


    & .list {

      height:auto;
      overflow-y: auto;

      & .hidden {
        display: none;
      }

      & .custom-row-container {
        cursor: pointer;
        display: flex;
        padding: $size-xxx-small;

        &:hover {
          background: $color-light-light;
          padding-top: 0;
          padding-bottom: 0;

          & .custom-remove {
            display:block;
            padding: $size-xxx-small;
          }
        }

        & .custom-row {
          border-radius: $size-xxx-small;
          padding-inline: $size-xxx-small;
          font-size: 14px;
          color: white;
          width: 170px;
          height:18px;
        }

        & .custom-remove {
          display: none;
          text-align: right;
          padding: $size-xxx-small;
        }

      }


    }

  }

}
