.c-label
{
    display: inline-block;
    padding: 2px 6px;
    color: white;
    background-color: var(--theme-color);
    border-radius: 3px;
    font-size: 13px;
    margin: 1.5px 0;
    margin-right: 3px;

    @mixin c-label--color($bg-color) {
        background-color: $bg-color;

        @if (lightness($bg-color) > 80) {
            color: $color-dark;
        }
    }

    &--success
    {
        @include c-label--color($color-success);
    }

    &--info
    {
        @include c-label--color($color-info);
    }

    &--warning
    {
        @include c-label--color($color-warning);
    }

    &--danger
    {
        @include c-label--color($color-danger);
    }

    &--light
    {
        @include c-label--color($color-light);
    }

    &--dark
    {
        @include c-label--color($color-dark);
    }

    &--mint
    {
        @include c-label--color($color-mint);
    }

    &--purple
    {
        @include c-label--color($color-purple);
    }


    &--pink
    {
        @include c-label--color($color-pink);
    }

    &--red
    {
        @include c-label--color($color-red);
    }
}

