.c-header-area {
  $this: &;

  margin: (-$size-x-small) (-$size-x-small) $size-x-small;

  box-shadow: 0 0 1px rgba(black, .08), 0 2px 5px rgba(black, .08);

  background: white;

  @media (min-width: $screen-xs-min)
  {
    margin: (-$size-small) (-$size-small) $size-small;
  }

  @at-root {

    &__cover-group {
      display: block;

      position: relative;

      background: $color-light;
    }

    &__cover {
      overflow: hidden;
      position: relative;
      padding-bottom: 30%;

      &::after {
        content: '';

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        transition: opacity ease $speed-medium;
        pointer-events: none;

        opacity: 0;
        background: rgba(black, .4);

        @supports (backdrop-filter: blur(1px)) {
          background: rgba(black, .2);

          backdrop-filter: blur(5px);
        }
      }

      #{$this}.is-stuck &::after {
        opacity: 1;
      }

      #{$this}__banner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    &__show-bar {
      position:absolute;
      right:10px;
      bottom:10px;

      @media (min-width: $screen-sm-min) {
        display: none;
      }
    }


    &__title-group {
      color: white;
      bottom: $size-xx-small;
      left: $size-x-small;
      position: absolute;
      margin-top: $size-xx-small;
      display: flex;
      align-items: center;

      @media (min-width: $screen-sm-min) {
        bottom: $size-x-small;

        margin-top: $size-x-small;
      }
    }

    #{$this}__titles-wrapper {
      transition: transform ease $speed-medium;
      transform: translateX(-($size-small + $size-xx-small));

      #{$this}.is-stuck & {
        transform: none;
      }
    }

    &__title, &__subtitle {
      margin-bottom: 0;
      text-shadow: 0 0 3px rgba(0, 0, 0, .8);
      text-rendering: optimizelegibility;
      white-space: nowrap;
    }

    &__title {
      margin-top: 0;
      font-size: $size-x-small;

      @media (min-width: $screen-sm-min) {
        font-size: $size-small;
      }
    }

    &__subtitle-group,
    &__subtitle {
      margin-top: $size-xxx-small;
    }

    &__subtitle {
      font-size: $size-xx-small;

      @media (min-width: $screen-sm-min){
        font-size: $size-x-small;
      }

      #{$this}__subtitle-group & {
        margin-top: 0;
      }

      &:nth-child(1) {
        margin-left: 0;
      }
    }

    &__subtitle-group {
      display: flex;
      align-items: center;
    }

    &__svg {
      margin-left: $size-xx-small;
      color: $text-color;
      font-size: 1.5em;

      &:nth-child(1) {
        margin-left: 0;
      }
    }

    &__action-group {


      &--small {
       // display: none;
        display: flex;

        opacity: 0;
        max-height: 0px;
        visibility: hidden;

        transition:  $speed-medium ease;
        transition-property:  padding, max-height, opacity, visibility;

        flex-direction: row;
        justify-content: center;
        padding:  0;
        border-bottom: 1px solid $panels-border-color;

        @media (min-width: $screen-sm-min) {
          display: none;
        }
      }




      &--small-active {
        padding: $size-xxx-small;
        max-height: 100px;
        opacity: 1;
        visibility: visible;
      }

      &--large {
        display: none;

        @media (min-width: $screen-sm-min) {
          display: block;
          position: absolute;
          right: $size-x-small;
          bottom: $size-xx-small;
        }
      }

      &.is-empty
      {
        display: none;
      }
    }

    &__action {
      display: inline-block;
      margin-right: $size-xxx-small;
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.8);

      &--abonnement{
        display: none;

        @media (max-width: $screen-sm-min) {
          display: block;
          position: absolute;
          top: 9px;
          right: 0;
        }
      }

      &--last {
        margin-right: 0;
      }
    }

    &__dropdownTaskListTeam{
      right: 0;
      left: auto;
    }

    &__dropdownTaskListTeam--newList{
      background-color: #ccd1d9;
    }

    &__popupAddTasklistTeam--input{
      background-color: #e6e9ed;
      padding: 9px;
      border-radius: 4px;
      color: #434a54;
      display: flex;
      align-items: center;
      cursor: text;
      margin: auto;
      width: 95%;
    }
    &__popupAddTasklistTeam--footer {
      border: 0;
    }

    &__nav
    {
      background-color: white;
      width: 100%;

      &.is-stuck
      {
        z-index: $z-index-3;
        box-shadow: 0 0 1px rgba(black, .08), 0 2px 5px rgba(black, .08);
        position: fixed;
        top: 0;
      }
    }

    &__list {
      display: flex;
      flex-direction : row;

      overflow: auto;
    }

    &__link {
      box-sizing: border-box;
      padding: $size-xx-small $size-x-small;
      display: block;
      text-align:center;
      text-decoration: none;
      white-space: nowrap;
      border-bottom: 3px solid transparent;
      border-top: 3px solid transparent;
      transition: ease $speed-medium;
      transition-property: border-bottom-color, color;

      &:hover, &:focus, &:active, #{$this}__item.is-active &
      {
        color: $color-dark;
      }

      &:hover, &:focus, &:active {
        text-decoration: none;
        outline: none;
        border-bottom-color: var(--theme-color-light);
      }

      #{$this}__item.is-active & {
        border-bottom-color: var(--theme-color);
      }


      &--first {
        margin-left: 0;
      }
    }

    &__content {
      display: none;

      &.is-active { display: block; }

      &--narrow {
        width: 100%;
        margin: 0 auto;

        @media (min-width: $screen-xs-min) {
          max-width: $size-xxx-large + $size-x-large;
        }
      }
    }

    #{$this}__profile-picture {
      width: 80px;
      height: 80px;
      border: 1px solid $color-dark;
      position: absolute;
      bottom: 43px;
      left: $size-xx-small;
      transition: ease $speed-fast;
      transition-property: opacity, background-color;
      overflow: hidden;
      border-radius: 50%;

      @media (min-width: $screen-sm-min) {
        width: 130px;
        height: 130px;
        left: $size-x-small;
        bottom: 56px;
      }

      #{$this}--user & {
        &:hover {
          .c-adndropzone__overlay {
            background-color: rgba(0, 0, 0, 0.6);
          }
          .c-adndropzone__btn-more{
            visibility: hidden;
          }
          .c-adndropzone__container-btns{
            visibility: visible;
          }
        }
      }

      #{$this}--promotor & {
        background-color: white;
        padding: 2px;
      }

      #{$this}.is-stuck & {
        opacity: 0;
      }
    }

    #{$this}__btn-avatar {
      width: 35px;
      height: 35px;
    }

    #{$this}__title-group {
      left: $size-xx-small;

      @media (min-width: $screen-sm-min) {
        left: $size-x-small;
      }
    }

    #{$this}__title-profile-picture {
      margin-right: $size-xx-small;

      transition: transform ease $speed-medium;
      transform: scale(0);

      #{$this}.is-stuck & {
        transform: none;
      }
    }
  }
}
