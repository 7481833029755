.c-mobile-navbar
{
    $this: &;

    position: fixed;
    z-index: $z-index-4;
    bottom: 0;

    visibility: hidden;

    width: 100%;
    padding-bottom: env(safe-area-inset-bottom);

    transition: ease 300ms;
    transition-property: transform, visibility;
    transform: translateY(100%);

    background-color: white;
    box-shadow: 0 0 1px rgba(black, .08), 0 -2px 5px rgba(black, .08);

    .has-enabled-mobile-navbar &
    {
        visibility: visible;

        transform: none;
    }

    @media #{$media-desktop}
    {
        display: none;
    }

    @at-root
    {
        #{$this}__items
        {
            display: flex;
            align-items: center;

            height: 100%;
            padding-left: env(safe-area-inset-left);
        }

        #{$this}__item
        {
            flex: 1;

            height: 100%;
        }

        #{$this}__trigger
        {
            position: relative;

            display: flex;
            align-items: center;
            justify-content: center;

            width: 100%;
            height: 100%;
            padding: 0;
            padding-top: $mobile-menu-triggers-padding;
            padding-bottom: $mobile-menu-triggers-padding;
            padding-bottom: calc(#{$mobile-menu-triggers-padding} - env(safe-area-inset-bottom));

            transition: ease $speed-medium color;

            color: $color-default;
            border: none;
            background: none;
            background-color: white;

            &:hover,
            &:focus,
            &.is-active
            {
                color: var(--theme-color);
            }

            &::before
            {
                content: '';

                position: absolute;
                top: 0;
                left: 0;

                width: 100%;
                height: $mobile-menu-height;

                transition: ease $speed-medium background-color;
            }

            &:active::before
            {
                background-color: $color-light-light;
            }
        }

        #{$this}__trigger-icon-container
        {
            z-index: $z-index-1;

            display: flex; // Used only to avoid whitespace issue

            padding: $mobile-menu-icons-padding;
        }

        #{$this}__trigger-icon
        {
            width: $mobile-menu-icons-height;
            height: $mobile-menu-icons-height;

            &.pr-graduation-hat
            {
                transform: scale(1.1);
            }
        }
    }
}
