.c-module-list-part-item {
  $this: &;

  color: var(--theme-color);
  padding: $size-xx-small;
  border-bottom: 2px solid $color-default-light;

  @media #{$media-desktop} {
    padding: $size-xx-small 0;
  }

  @at-root
  {
    #{$this}__content {
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      margin: 0 $size-xx-small;
    }

    #{$this}__title-container {
      display: flex;
      flex-direction: column;
    }

    #{$this}__title {
      font-weight: 500;
      font-size: 15px;
      line-height: 12px;
      color: var(--theme-color);
      margin: 0;
      text-transform: uppercase;

      @media #{$media-desktop} {
        line-height: 20px;
      }
    }

    #{$this}__subtitle {
      font-weight: normal;
      font-size: 13px;
      line-height: 12px;
      color: var(--theme-color);
      margin: 0;

      @media #{$media-desktop} {
        font-size: 15px;
      }
    }

    #{$this}--locked {
      color: $color-dark-light;

      #{$this}__title,
      #{$this}__subtitle {
        color: $color-dark-light;
      }
    }

    #{$this}__title-group:hover{
      cursor: pointer;
    }

    #{$this}__title-group {
      display: flex;
      align-items: center;
    }

    #{$this}__chevron-container {
      width: 12px;
      height: 12px;
      margin-right: $size-xx-small;

      &:hover {
        cursor: pointer;
      }
    }

    #{$this}__chevron {
      width: 100%;
      height: 100%;
      transition: transform ease $speed-medium;

      &--down {
        transform: rotate(90deg);
      }
    }

    #{$this}__content-list {
      display: none;
      padding: $size-xx-small 0 $size-xx-small $size-x-small;

      @media (min-width: $screen-sm-min) {
        padding: $size-xx-small $size-normal;
      }

      &.is-open {
        display: block;
      }
    }

    #{$this}__content-item {
      position: relative;
      margin: $size-xx-small 0;

      &--locked {
        color: black;

        & a {
          pointer-events: none;
          cursor: default;
        }
      }

      &--checked {
        font-weight: bold;
      }
    }

    #{$this}__content-link {
      padding-left: $size-small;
    }
  }
}
