.c-chat-url-preview {
  $this: &;

  display: flex;
  border: none;
  flex-direction: column;
  width: $size-large + $size-xx-large;
  max-width: 100%;

  @at-root {
    #{$this}:hover,
    #{$this}:active,
    #{$this}:focus {
      text-decoration: none;
    }

    #{$this}__img-container {
      flex-shrink: 0;

      // Le jour où la propriété aspect-ratio sera suffisamment supportée, il
      // suffira de supprimer ce bloc.
      @supports not (aspect-ratio: 16 / 9) {
        position: relative;

        height: 0;
        padding-bottom: 56.25%;
      }
    }

    #{$this}__img {
      user-select: none;

      aspect-ratio: 16 / 9;

      // Le jour où la propriété aspect-ratio sera suffisamment supportée, il
      // suffira de supprimer ce bloc.
      @supports not (aspect-ratio: 16 / 9) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    #{$this}__infos {
      min-width: 0;
      padding: $size-xx-small;
    }

    #{$this}__title {
      @include single-line;

      margin-top: 0;
      margin-bottom: $size-xx-small;
      font-size: inherit;
    }

    #{$this}__description {
      @include clamp-lines-to(2);

      margin-bottom: $size-xx-small;
      padding: 0;
    }

    #{$this}__origin {
      @include single-line;

      font-size: 13px;
      font-weight: normal;

      margin: 0;
    }
  }
}
