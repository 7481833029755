
.c-contacts-aside-menu {

  $this: &;

  $selected-background : #efefef;
  $selected-fontweight : 500;

  @at-root {

    #{$this}__header-add-button {
      display:none;
      padding: 10px ;

      width: 100%;

      button {
        width: 100%;
      }

      @media #{$media-desktop}
      {
        display:block;
      }
    }


    #{$this}__title-container {
      display: flex;
      align-items: center;
    }

    #{$this}__title {
      font-size: 15px;
      font-weight: normal;
      margin: 0;
      margin-left: $size-xx-small;
      color: $text-color;

      &--list{
        width: 200px;
        padding-top: 3px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
      }

    }

    #{$this}__item-menu,
    #{$this}__item {
      padding: $size-xx-small $size-x-small;
      cursor: pointer;

      &:hover {
        background-color: $color-light-light;

        #{$this}__title {
          font-weight: 500;
        }
      }

      &.is-active {
        color: var(--theme-color);

        #{$this}__title {
          font-weight: normal!important;
          color: var(--theme-color);
        }
      }
    }

    #{$this}__item-menu {
      padding: $size-xxx-small $size-xxx-small $size-xxx-small $size-small;

      & .icon {
        margin-right: 0px;
      }

    }


    // vos contacts (tous) sélectionné
    & .is-all-selected {
      font-weight: $selected-fontweight;
      background: $selected-background;
    }

    #{$this}__grouplist {

      & .itemtag,
      & .item {
        padding: $size-xxx-small $size-xxx-small $size-xxx-small $size-small;

        cursor: pointer;

        &:hover {
          background-color: $color-light-light;
        }

        &.is-team-selected, // un groupe sélectionné
        &.is-tag-selected { // un tag sélectionné
          font-weight: $selected-fontweight;
          background: $selected-background;
        }

        & .team-nom {
          text-transform: capitalize;
        }

        & .tagscolor {
          padding: $size-xxx-small;
          color: white;
          border-radius: $size-xxx-small;
        }

      }

      & .itemtag {
        padding-left: 40px;
      }


      & .showmore {
        display: flex;
        justify-content: center;
        cursor: pointer;
      }

    }


    #{$this}__icon {
      width: 20px;
      height: 20px;
      transform: translateY(-2px);
      margin-right: 8px;
    }

  }

}
