.c-adnpublisher {

  $this: &;

  padding-bottom: $size-xxx-small;
  border: none;


  @at-root {

    #{$this}__gifinput {
      width: 100%;
      margin: 5px;
    }

    #{$this}__search {
      width: 100%;
      position: fixed;
      background: white;
      margin-top: 0;
      padding-top: 0;
    }

    #{$this}__select {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      padding-bottom: 20px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom:0;
        right: 0;
        left: 0;
        height: 2px;
        background:#ccd1d9;
      }

      &--title {
        font-weight: 500;
        margin-right: 10px;
        font-size: 15px;
      }
    }


    #{$this}__filescontainer,
    #{$this}__photoscontainerclone,
    #{$this}__gifcontainer {
      display: grid;
      grid-template-columns:auto auto auto;
      width: 100%;
    }

    #{$this}__photoscontainer {
      display: block;
    }

    #{$this}__gifcontainer {
      padding-top: 45px;
    }

    #{$this}__gifitem {
      height: 100%;
      padding: 0;
      object-fit: cover;
      width: 100%;
    }

    #{$this}__sondagecontainer {
      padding-top: $size-x-small;
      padding-right: $size-xxx-small;
      margin-left: -$size-xx-small;
    }


    #{$this}__addfilemobile {
      height: 120px;
      min-width: 105px;
      max-width: 150px;
      background: $color-light;
      color: $color-dark;
      text-align: center;
    }

    #{$this}__addphotomobile {
      display: flex;
      align-items: center;
      justify-content: center;

      position: fixed;
      bottom: 0;

      width: 100%;
      height: 60px;

      text-align: center;

      font-size: 18px;

      background: $color-light-light;
      border-top: solid;
      border-top-width: 1px;
      border-top-color: $color-light;
    }

    #{$this}__addphotomobile svg {
      font-size: 45px;
      padding-right: 10px;
    }

    #{$this}__addfilemobile i {
      margin-top: 30px;
    }


    #{$this}__preloading {
      display: none;
      width: 100%;
      font-size: 17px;

      margin-left: -5px;

      @media (min-width: $screen-xs-min) {
        margin-left: $size-xxx-small;
      }
    }

    #{$this}__mobilepreloader {
      text-align: center;
    }


    #{$this}__preloadingtext {
      height: 100%;
      padding-top: $size-x-small;
      padding-left: $size-x-small;
    }


    #{$this}__mobile {
      padding-top: $size-xxx-small;
      padding-right: 0px;
      padding-left: 0px;
    }

    #{$this}__logotextmobile {
      margin-left: 35px;
      margin-top: $size-xxx-small;
      font-weight: bold;
    }

    #{$this}__sidebarmobile,
    #{$this} form {
      padding: 0px !important;
    }

    #{$this}__prev-photoslink {
      display: block;
      padding: $size-xxx-small;
    }

    #{$this}__prev-lieu,
    #{$this}__prev-url,
    #{$this}__prev-fichier,
    #{$this}__prev-gif {
      margin-bottom: 10px;
    }

    #{$this}__prev-url {
      margin-right: 40px;
    }

    #{$this}__prev-url-ytb {
      width: 100%;
      height: 200px;

      @media #{$media-desktop}
      {
        height: 400px;
      }
    }


    #{$this}__prev-url-content {
      cursor: pointer;
      display: block;
      margin: 0 (-$size-xx-small);
    }

    #{$this}__prev-url-content a {
      cursor: pointer;
    }

    #{$this}__prev-url-content div {
      word-wrap: break-word;
    }

    #{$this}__prev-url-content h4 {
      word-wrap: break-word;
      margin: $size-xxx-small;
      margin-left: 0px;
    }

    #{$this}__prev-url-content h5 {
      word-wrap: break-word;
      font-weight: normal;
      margin: 0px;
      padding: 0px;
    }


    #{$this}__prev-fichier-namepreview,
    #{$this}__prev-fichier-namemobile {
      display: none;
      background: #efefef;
      padding: 10px;
      padding-right: 20px
    }

    #{$this}__prev-fichier-namemobile {
      padding-right: 30px
    }

    #{$this}__prev-fichier-namepreview {
      display: block;
    }

    #{$this}__lieu {
      padding: $size-xx-small;

      @media (min-width: $screen-xs-min) {
        padding: 0px;
      }

    }

    #{$this}__lieu label {
      display: flex;
      flex: 1;
    }

    #{$this}__lieu span {
      width: 25px;
      padding-top: 6px;
      padding-left: 0px;
    }

    #{$this}__lieu input {
      flex: 3;
      font-weight: normal;
      width: 100%;
      margin: 0px;
      margin-bottom: 10px;
    }

    #{$this}__editor {
      min-height: 40px;
      //font-family: Rubik, Arial;
      font-family: Apercu, Arial;
      font-size: 15px;
      border: none !important;
      overflow: hidden;
      padding-left: $size-xx-small;

      display: block;
      white-space: pre-wrap;
      outline: none;
      word-wrap: break-word;
    }

    #{$this}__editormobile {
      min-height: 60px;
      overflow-y: auto;

      padding: 0px;
      margin: 0px;
    }


    #{$this}__linkified {
      padding: 2px 2px;
      word-wrap: break-word;
    }


    #{$this}__editor[contenteditable=true]:empty:before {
      color: #bcbcbc;
      content: attr(placeholder);
      display: block; /*  Firefox */
    }

    #{$this}__editor[contenteditable]:focus {
      outline: 0px solid transparent;
    }


    #{$this}__modalcontent {
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      border-radius: 3px !important;
    }

    #{$this}__livepreview {
      width: 100%;
      margin-top: $size-x-small;

      display: none;

      .k-upload {
        border: none;
      }
    }

    #{$this}__features {
      margin-top: $size-x-small;
      display: none;
    }


    #{$this}__upload {
      display: none;

      @media #{$media-desktop}
      {
        display: block;
      }


      & .k-dropzone {
        display: none;
      }

      & .k-upload-files {
        @include custom-scrollbar();

        overflow-y: scroll;
        max-height: 92px;
        margin: 0;
        padding: 0;
        border: none;

        & .k-file {
          padding: 3px;
        }

        & .k-file-success .k-file-name,
        & .k-i-success {
          color: $color-success !important;
        }
      }

    }


    #{$this}__deletepreview-mobilefile,
    #{$this}__deletepreview {
      position: absolute;
      right: 5px;
      z-index: 1;
    }

    #{$this}__deletepreview-mobilefile {
      display: none;
      margin-top: $size-xxx-small;
    }

    #{$this}__deletepreview-mobilefile i,
    #{$this}__deletepreview i {
      font-size: 24px;
    }


    #{$this}__btnbar {
      margin-right: $size-xxx-small;
      margin-bottom: $size-xxx-small;
      height: 35px;
      font-weight: bold;
      width: 100%;
      text-transform: none;
      border-radius: 20px;
      justify-content: unset;

      background-color: $color-light !important;

      &--select-team {
        width: 70%;
        margin: 0;
      }
    }

    #{$this}__btnbar:hover {
      background-color: $color-light-light !important;

      //  @extend .c-btn--primary-inverse;
    }


    #{$this}__footer {
      padding-top: 10px;
    }

    #{$this}__headermobile {
      height: 35px;
      padding-top: $size-xxx-small;
      padding-right: $size-xxx-small;
    }

    #{$this}__footermobile {
      padding: 0px;
      display: grid;
      grid-template-columns: 55px auto 150px;
      background: $color-light-light;
      border-bottom: solid;
      border-bottom-width: 1px;
      border-bottom-color: $color-light;
    }


    #{$this}__publish {
      float: right;
    }

    #{$this}__publishmobile {
      margin-top: $size-x-small;
      margin-right: $size-x-small;
      font-size: 15px;
      border-radius: 20px !important;
      width: 130px;
      height: 30px;
    }


    #{$this}__backmobile svg,
    #{$this}__cancelmobile svg {
      font-size: 30px;

      color: var(--theme-color) !important;
    }

    #{$this}__backmobile,
    #{$this}__cancelmobile {

    }

    #{$this}__back-cancel-mobile {
      padding: $size-x-small;
    }

    #{$this}__backmobile {
      display: none;
    }


    #{$this}__footertitre {
      text-align: center;
      padding: 5px;
    }


    #{$this}__titremobile {
      display: flex;
    }

    #{$this}__titremobile-content {
      margin-top: auto;
      margin-bottom: auto;
      min-height: 21px;
      font-size: 15px;
    }


    #{$this}__cancelsondage {
      float: right;
      margin-right: $size-xxx-small;
    }

    #{$this}__cancelembed {
      float: right;
      margin-right: $size-xxx-small;
    }


    #{$this}__btn-bloc {
      display: flex;
      width: 100%;
      padding-left: $size-x-small;
      text-align: left;
    }


    #{$this}__btn-icon svg,
    #{$this}__btn-icon svg use,
    #{$this}__btn-icon img {
      width: 24px;
      font-size: 24px;
    }

    #{$this}__btn-text {
      padding-left: $size-x-small;
    }

    #{$this}__btn-disablefeature {
      opacity: 0.4;
    }

    #{$this}__notifphotos-container {
      margin-left: 20px;
      //  display:none;
    }

    #{$this}__notifphotos.c-badge:before {
      background-color: var(--theme-color) !important;
    }


    #{$this}__notifphotos-container .c-adndropzone-async__loader-img {
      position: absolute;
      width: 35px;
      top: 0;
      margin-top: $size-xxx-small;
      display: none;
    }

    #{$this}__preview-photos {
      border: none;
    }

    #{$this}__preview-photos-hint {

      background: #fff;
      opacity: 1 !important;

      & .item-remove {
        display: none !important;
      }

    }

    #{$this}__preview-photos-hint,
    #{$this}__preview-photos {

      display: flex;

      min-height: 0;

      overflow: hidden;
      font: inherit;
      max-height: calc(100vh - 129px);

      @media #{$media-desktop}
      {
        @include custom-scrollbar();

        overflow: auto;
        max-height: 325px;
      }


      & .k-listview-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        overflow: hidden;
        height: 100% !important;

        @media #{$media-desktop}
        {
          justify-content: flex-start;
        }
      }

      & .item {
        position: relative;
        padding: 2px;

        @media #{$media-desktop}
        {
          width: 100px;
          height: 110px;
          margin: 0 5px;
          padding: 0;
        }

        &:hover {
          .item-content {
            opacity: 0.5;
          }

          .item-remove {
            display: flex;
          }
        }
      }

      & .item-content {
        width: 118px;
        height: 118px;

        @media #{$media-desktop}
        {
          width: 100px;
          height: 100px;
        }

        background: #efefef;

        opacity: 1;

        @at-root &:hover {
          opacity: 0.5;
        }
      }


      & .item-content img {
        @media #{$media-desktop}
        {
          width: 100px;
          height: 100px;
        }
      }

      & .item-placeholder {
        width: 118px;
        height: 118px;

        @media #{$media-desktop}
        {
          width: 100px;
          height: 100px;
        }
      }

      & .item-remove {
        display: none;
        position: absolute;

        justify-content: center;
        align-items: center;

        width: 24px;
        height: 24px;

        font-size: 13px;
        border-radius: 20px;
        color: #fff !important;
        top: 3px;
        right: 3px;


        text-align: center;

        padding: 5px;
        text-decoration: none !important;

        background: rgba(255, 0, 0, .8);
        opacity: 1;
      }

    }

      #{$this}__wrapper-select {
        padding-bottom: 20px;

        .k-combobox {
          display:flex;
          align-items:center;
          border: solid 2px rgba(0, 0, 0, 0.1);

          .k-input-button {
            margin:3px;
          }
        }
      .c-adnpublisher__btn-select {
        width:100%;
        margin:0 auto;
      }
        button {
          width:auto;
        }
        .k-input-button {
          margin:0;
          width:40px;
        }
      }
    }
  }


.c-adnpublisher .c-adndropzone__dropzone {
  font-size: 13px !important;
  text-align: left !important;
  border: none !important;
  margin-bottom: 0 !important;
  display: block !important;
  align-items: stretch !important;
}

.c-adnpublisher:not(.c-adnpublisher__mobile) .c-adndropzone__dropzone {
  box-shadow: 0 0 1px rgba(black, .08), 0 2px 5px rgba(black, .08);
  border-radius: 3px;
}

.c-adnpublisher:not(.c-adnpublisher__mobile).modal-content .c-adndropzone__dropzone {
  box-shadow: none;
  border-radius: 0;
}

.c-adnpublisher .c-adndropzone__dropzone.is-active {
  border: 2px dashed $color-default !important;
}

.c-adnpublisher .c-adndropzone__dropzone.has-error {
  background: #ffffff;
}


.c-timeline-publisher-editor {
  //margin-left:20px;
  width: 100%;
  border: none;
}

/* suppr la petite flèche à gauche de l'éditeur pendant le dragdrop */
.c-adnpublisher .c-adndropzone__dropzone.is-active .c-timeline-publisher-editor:after,
.c-adnpublisher .c-adndropzone__dropzone.is-active .c-timeline-publisher-editor:before {
  background: $color-light-light;
}

.c-adnpublisher .c-adndropzone__dropzone.is-active .c-timeline-publisher-editor:before {
  border: dashed;
  border-color: $color-default;
}

.atwho-utilisateur {
  color: var(--theme-color-light) !important;
  text-decoration: none;
}


.webui-popover-gif-mobile {
  left: 0px !important;
  top: 0px !important;
}
