.c-tasks-tagKanban {

  &__tag{
    height: 20px;
    line-height: 17px;
    max-width: 198px;
    padding: 0 8px;
    float: left;
    font-size: 13px;
    font-weight: 700;
    margin: 0 4px 4px 0;
    min-width: 40px;
    text-shadow: none;
    width: auto;
    border-radius: 4px;
    color: #fff;
    display: block;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  &__container{
    display: inline-block;
    margin: 9px 9px 0px 9px;
  }

  &__mini{
    float: left;
    font-size: 13px;
    font-weight: 700;
    height: 8px;
    line-height: 100px;
    margin: 0 4px 4px 0;
    max-width: 40px;
    min-width: 40px;
    padding: 0;
    text-shadow: none;
    width: auto;
    display: none;
    border-radius: 4px;
    color: #fff;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__tagCircle{
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin-right: 5px;
  }

  &__tagName{
    color: #434a54;
    font-size: 13px;
    font-weight: 400;
  }

}