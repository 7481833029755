.c-documents-container
{
    $this: &;

    @at-root
    {
        #{$this}__icon {
            width: 20px;
            height: 20px;
            transform: translateY(-2px);
            margin-right: 8px;
        }


        #{$this}__icon-office {
            font-size:21px !important;
            margin-right: $size-xx-small;
        }



        #{$this}__tabs {
            display: none;

            position: absolute;
            width: 100%;
            left:0; right:0;
            text-align: center;

            height:62px;

            @media (min-width: $screen-xs-min)
            {
              display: block;
            }
        }

        #{$this}__tabs ul {
            display: inline-flex;
        }

        #{$this}__tabs li a {
            padding-top: 23px !important;
            padding-bottom: 18px !important;
        }


        #{$this}--standalone
        {

            min-height: calc(#{vh(100)}  - #{$header-height});

            .has-enabled-mobile-navbar &
            {
                min-height: calc(#{vh(100)} - #{$header-height} - var(--mobile-menu-height)   ) ;
            }

            // As CSS variables can't be negative (-var(...) is not working),
            // using a calc is the only possible solution
            margin: -$app-main-container-padding-xs;
            margin-right: calc(-1 * var(--app-main-container-padding-right-xs));
            margin-left: calc(-1 * var(--app-main-container-padding-left-xs));

            border-radius: 0;

            @media (min-width: $screen-xs-min)
            {
                margin: -$app-main-container-padding-sm;
                margin-right: calc(-1 * var(--app-main-container-padding-right-sm));
                margin-left: calc(-1 * var(--app-main-container-padding-left-sm));
            }

            @media (min-width: $screen-sm-min)
            {
                min-height: 100vh;
                min-height: calc(#{vh(100)});
            }

            @media (min-width: $screen-md-min)
            {
                display: flex;
                flex-direction: column;
            }
        }

        #{$this}__content
        {
            padding-top: 0px;
            #{$this}--standalone &
            {
                display: flex;
                flex-direction: column;

                padding: 0;

                @media (min-width: $screen-md-min)
                {
                    height: calc(100vh - #{$size-medium});
                    height: calc(#{vh(100)} - #{$size-medium});
                }

            }

            .c-document {
                overflow-y: hidden;
            }
        }

        #{$this}__title
        {
            #{$this}--standalone &
            {
                display: none;

                @media #{$media-desktop}
                {
                    display: flex;
                }

                @supports (padding: unquote('max(42px, 21px)'))
                {
                    padding-right: unquote('max(#{$size-x-small}, env(safe-area-inset-right))');
                    padding-left: unquote('max(#{$size-x-small}, env(safe-area-inset-left))');
                }
            }
        }

        #{$this}__dropdown-add {
            float:right;
        }

        #{$this}__dropdownbutton button,
        #{$this}__dropdownbutton {
            font-size: 19px;
            min-width: 200px;
        }

        #{$this}__dropdownbutton ul {
            position: fixed;
            left:80px;
            top:40px;
        }

        #{$this}__actions
        {
            & .history {
                margin-right: 0px;
            }

            #{$this}:not(#{$this}--standalone) &
            {
                display: none;

                @media #{$media-desktop}
                {
                    display: initial;
                }
            }
        }

        // en attendant que le bouton flottant soit actif sur le module on le fait disparaitre en mobile
        #{$this}__history
        {
            display:none !important;
            @media (min-width: $screen-md-min)
            {
                display:inline-flex !important;
            }
        }

    }
}
