.c-course-metas
{
    $this: &;

    padding: $size-x-small;

    @media (min-width: $screen-xs-min)
    {
        padding: $size-small;
    }

    @at-root
    {
        #{$this}__video-link
        {
            position: relative;
            display: block;

            margin-bottom: $size-normal;
        }

        #{$this}__video-thumbnail
        {
            max-width: $size-xxx-large;
            max-height: $size-xx-large;

            border-radius: 3px;
            box-shadow: 0 0 1px rgba(black, .08), 0 3px 8px rgba(black, .08);
        }

        #{$this}__description
        {
            font-size: 17px;
            line-height: 1.7;

            margin-bottom: $size-normal;
        }

        #{$this}__responsible-users
        {
            margin-bottom: $size-normal;
        }

        #{$this}__responsible-users-list-label
        {
            font-size: 17px;

            width: $size-large;

            /* Ugly trick to align the label with the first list item */
            padding-top: 3px;
        }

        #{$this}__responsible-users-list-container
        {
            display: flex;

            margin-bottom: $size-xx-small;
        }

        #{$this}__responsible-user
        {
            display: flex;
            align-items: center;

            margin-bottom: $size-xx-small;

            &:last-child
            {
                margin-bottom: 0;
            }
        }

        #{$this}__responsible-user-name
        {
            font-size: 15px;

            margin-left: $size-xx-small;
        }

        #{$this}__resume {
            text-align: center;
        }
    }
}
