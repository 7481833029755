h1,
h2,
h3,
h4,
h5,
h6
{
    font-weight: 500;

    margin-bottom: $size-x-small;
}

h1,
h2,
h3
{
    margin-top: $size-small;
}

h4,
h5,
h6
{
    margin-top: $size-x-small;
}

h1
{
    font-size: 51px;
}

h2
{
    font-size: 41px;
}

h3
{
    font-size: 31px;
}

h4
{
    font-size: 20px;
}

h5
{
    font-size: 19px;
}

h6
{
    font-size: 15px;
}
