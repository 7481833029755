.o-loader-overlay {
  $this: &;

  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 9999;
  cursor: not-allowed;
}

.o-loader-overlay--active {
  display: block;
}

.o-loader-overlay--section {
  position: absolute;
}

