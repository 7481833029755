.c-exposer{
  $this: &;

  margin-bottom: $size-x-small * 2;
  padding: 0px;
  box-shadow: 0 0 1px rgba(black, 0.08), 0 2px 5px rgba(black, 0.08);
  border-radius: 3px;

  @at-root
  {
    #{$this}__body{
      width: 100%;
      height: 0;
      padding-bottom: 60%;
      position: relative;
    }

    #{$this}__cover {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      .u-img-cover{
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }
    }

    #{$this}__link:hover {
      border-bottom : none;
    }

    #{$this}__action {
      position: absolute;
      right: $size-xxx-small;
      top: $size-xxx-small;
      &__action{
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
        font-size: $size-xx-small;
      }

    }

    #{$this}__action-heart{
      font-size: 2em;
      color: #dc1f38;
      cursor: pointer;
    }

    #{$this}__action-group {
      position: absolute;
      right: $size-xxx-small;
      bottom: $size-xx-small;

      &-action{
        margin-right: 5px;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
        font-size: $size-xx-small;
      }
    }

    #{$this}__tag-group{
      position: absolute;
      left: $size-xx-small;
      top: $size-xx-small;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &--inline {
        flex-direction: row;
      }
    }

    #{$this}__title-group {
      color: white;
      bottom: $size-xx-small;
      left: $size-x-small;
      position: absolute;
    }

    #{$this}__title {
      font-size: $size-x-small;
      margin-top: 0px;
      margin-bottom: 5px;
      text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);

      &--content{
        color: #aab2bd;
        font-size: 20px;
        text-shadow: none;
      }
    }

    #{$this}__subtitle {
      font-size: $size-xx-small;
      text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);

      @media (min-width: $screen-md-min){
        font-size: 13px;
      }
      margin: 0;

      &--content{
        font-size: 20px;
        text-shadow: none;
        text-transform: uppercase;
      }
    }

    #{$this}__text {
      padding: 0px;
    }

    #{$this}__footer{
      padding: $size-x-small;
    }

    #{$this}__label{
      font-size: 13px;
      display: inline-block;
      padding: 2px 6px;
      color: white;
      background-color: var(--theme-color);
      border-radius: 3px;
      margin-right: 1.5px;
      margin-bottom: 3px;

      &--tag{
        font-size: 9px;
        margin: 1.5px 0;

        &:first-child {
          margin-top: 0;
        }

        #{$this}__tag-group--inline & {
          margin-top: 0;
          margin-right: 3px;
          margin-bottom: 3px;
        }
      }
    }

    #{$this}__rating{
      display: inline-block;
    }

    #{$this} .c-rating__text,
    #{$this} .c-rating__label {
      margin-bottom: 0;
    }
  }
}
