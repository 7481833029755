.c-tchat-filelist {
  display: flex;
  min-width: 0;
  overflow-x: auto;
  padding: $size-xx-small;
  border-top: 1px solid #e1e2e9;
  align-items: flex-end;
  border-bottom: 3px dashed transparent;
  border-left: 3px dashed transparent;
  border-right: 3px dashed transparent;

  &__drop{
    border: 3px dashed #ccd1d9;
    border-radius: 3px;
    min-height: 123px;
  }

  @media (max-width: $screen-xs-min) {
    padding-top: 45px;
    padding-bottom: 12px;
    touch-action: auto !important;
  }


  &__item {
    width: $size-large;
    margin-right: $size-xx-small;
    flex-shrink: 0;
  }

  &__button {
    width: 100%;
    justify-content: normal;
    text-transform: none;
    font-size: 15px;
    height: 32px;
  }
}