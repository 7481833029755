.c-chat-message-media-grid
{
    $this: &;
    $grid-corner-radius: $size-x-small;
    $grid-inner-radius: $size-xxx-small;

    display: grid;

    margin: 2px 0;

    gap: 2px;
    grid-template-columns: 1fr;

    .message__content.is-xs-or-wider &
    {
        margin: 3px 0;

        gap: 3px;
    }

    @at-root
    {
        #{$this}--2-columns,
        #{$this}--3-columns
        {
            width: 250px;

            .message__content.is-xs-or-wider &
            {
                width: 333px;
            }
        }

        #{$this}--2-columns
        {
            grid-template-columns: 1fr 1fr;
        }

        #{$this}--3-columns
        {
            grid-template-columns: 1fr 1fr 1fr;
        }

        #{$this}__item
        {
            overflow: hidden;
            position: relative; // Nécessaire pour les GIFs au formats WebM et MP4, pour que les bords arrondis fonctionnent bien

            #{$this}--2-columns &,
            #{$this}--3-columns &
            {
                position: relative;

                height: 0;
                padding-bottom: 100%;
            }

            .c-chat-messages-group--user #{$this}--2-blank-cells-on-end &:last-child
            {
                grid-column-start: 3;
            }

            .c-chat-messages-group--user #{$this}--1-blank-cell-on-end &:nth-last-child(2)
            {
                grid-column-start: 2;
            }

            &,
            &::after
            {
                border-radius: $grid-inner-radius;
            }

            &::after
            {
                content: '';

                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                border: 1px solid rgba(64, 64, 64, .1);
            }

            // =================================================================
            // Rounded corners handling (absolute)
            // The below rules DON'T consider the message position in the feed
            // (= "absolute"). Check the next part for the relative rules.
            //
            &:only-child,
            #{$this}--2-columns &:nth-child(2),
            #{$this}--3-columns &:nth-child(3)
            {
                .c-chat-messages-group:not(.c-chat-messages-group--user) &
                {
                    &,
                    &::after
                    {
                        border-top-right-radius: $grid-corner-radius;
                    }
                }
            }

            .c-chat-messages-group--user &:first-child
            {
                &,
                &::after
                {
                    border-top-left-radius: $grid-corner-radius;
                }
            }

            &:last-child,
            #{$this}--2-blank-cells-on-end &:nth-last-child(2),
            #{$this}--1-blank-cell-on-end &:nth-last-child(3)
            {
                .c-chat-messages-group:not(.c-chat-messages-group--user) &
                {
                    &,
                    &::after
                    {
                        border-bottom-right-radius: $grid-corner-radius;
                    }
                }
            }

            &:only-child,
            #{$this}--2-columns &:nth-last-child(2),
            #{$this}--1-blank-cell-on-end &:nth-last-child(2),
            #{$this}--1-blank-cell-on-end &:nth-last-child(5),
            #{$this}--2-blank-cells-on-end &:last-child,
            #{$this}--2-blank-cells-on-end &:nth-last-child(4),
            #{$this}--3-columns:not(#{$this}--1-blank-cell-on-end):not(#{$this}--2-blank-cells-on-end) &:nth-last-child(3)
            {
                .c-chat-messages-group--user &
                {
                    &,
                    &::after
                    {
                        border-bottom-left-radius: $grid-corner-radius;
                    }
                }
            }

            // =================================================================
            // Rounded corners handling (relative)
            // The below rules DOES consider the message position in the feed
            // (= "relative"). Check the previous part for the absolute rules.
            //
            .c-chat-message-container:first-child &:first-child
            {
                &,
                &::after
                {
                    border-top-left-radius: $grid-corner-radius;
                }
            }

            &:only-child,
            #{$this}--2-columns &:nth-child(2),
            #{$this}--3-columns &:nth-child(3)
            {
                .c-chat-messages-group--user .c-chat-message-container:first-child &
                {
                    &,
                    &::after
                    {
                        border-top-right-radius: $grid-corner-radius;
                    }
                }
            }

            &:only-child,
            #{$this}--2-columns &:nth-last-child(2),
            #{$this}--1-blank-cell-on-end &:nth-last-child(2),
            #{$this}--2-blank-cells-on-end &:last-child,
            #{$this}--3-columns:not(#{$this}--1-blank-cell-on-end):not(#{$this}--2-blank-cells-on-end) &:nth-last-child(3)
            {
                .c-chat-messages-group:not(.c-chat-messages-group--user) .c-chat-message-container:last-child &
                {
                    &,
                    &::after
                    {
                        border-bottom-left-radius: $grid-corner-radius;
                    }
                }
            }

            .c-chat-messages-group--user .c-chat-message-container:last-child &:last-child
            {
                &,
                &::after
                {
                    border-bottom-right-radius: $grid-corner-radius;
                }
            }
        }

        #{$this}__medium-container
        {
            font-size: 0; // Nécessaire pour les GIFs au formats WebM et MP4

            #{$this}--2-columns &,
            #{$this}--3-columns &
            {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
        }

        #{$this}__medium
        {
            padding: 0; // Nécessaire pour les GIFs au formats WebM et MP4
            width: auto; // Nécessaire pour les GIFs au formats WebM et MP4

            min-width: $size-normal;
            max-width: 100%;
            min-height: $size-normal;
            max-height: $size-xx-large;

            object-fit: cover;

            .message__content.is-xs-or-wider &
            {
                max-width: $size-xxx-large;
            }

            .c-tchat-popup &,
            .c-course-aside &
            {
                max-height: $size-large * 2;
            }

            #{$this}--2-columns &,
            #{$this}--3-columns &
            {
                width: 100%;
                height: 100%;
                min-height: $size-normal;
                max-height: $size-xx-large;

                object-fit: cover;
            }
        }
    }
}
