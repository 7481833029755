/// ============================================================================
/// # FONT-AWESOME
/// ============================================================================
/// Helpers pour la librairie d'icônes Font Awesome 4.7.
/// ============================================================================
/// @group tools
/// ============================================================================



/// Insère l'icône dont l'unicode est passé en paramètre dans une règle. Permet
/// d'afficher des icônes de Font Awesome dans des cas où l'utilisation des
/// classes n'est pas possible (exemple : avec un pseudo-élément).
/// @link https://fontawesome.com/v4/icons/
/// @param {string} $code Unicode du caractère à afficher, sans l'antislash
@mixin font-awesome($code)
{
    content: #{"'\\" + $code + "'"};

    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
