.c-tasks-filters-members {
  $this: &;

  @at-root {

    #{$this}__container{
      padding-left: 0 !important;
      padding-top: 4px;
      padding-bottom: 4px;
      cursor: pointer;
      text-align: left;
      display:flex;
    }

    #{$this}__containerCheckBoxMembers{
      padding-top: 5px;
      padding-bottom: 5px;
      cursor: pointer;
      font-weight: 400;
    }

    #{$this}__containerMultiselectMembers{
      margin-top: 5px;
      display: flex;
    }

    #{$this}__check--unckecked {
      height: 17px;
      width: 17px !important;
      display: inline-block;
      left: 16px;
      position: absolute;
      margin-top: 4px;
    }

    #{$this}__check--checked {
      height: 17px;
      width: 17px !important;
      display: inline-block;
      left: 16px;
      position: absolute;
      margin-top: 4px;
    }

    #{$this}__member {
      font-weight: 700;
      color: #fff;
      display: inline-flex;
      height: 25px;
    }

    #{$this}__avatar {
      display: inline-flex;
      float: unset;
      width: 25px;
      height: 25px;
    }

    #{$this}__avatar--userConnected {
      display: inline-flex;
      float: unset;
      margin-right: 5px;
    }

    #{$this}__memberName{
      color: #434a54;
      font-size: 13px;
      font-weight: 400;
      margin-left: 12px;
      align-items: center;
    }

    #{$this}__title{
      margin-bottom: 0;
      margin-top: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 15px;
    }

    #{$this}__subtitle{
      margin-bottom: 0;
      margin-top: 1px;
      margin-left: 2px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 12px;
      font-weight: normal;
      color: #aab2bd;
    }
  }
}