.c-societes-content {


  @include gridStyles("&__grid", 100px, 202px);

  $this: &;

  #{$this}.k-table-th, .k-table-td {
    border: none!important;
  }

  @at-root {

    #{$this}__gestion-container {
      padding: $size-xx-small 0px;
      background: white;
    }


    #{$this} .k-sort-icon {
      color: $default-theme-color;
    }


  }

}


