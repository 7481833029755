.c-academylist {
  &__actions {
    position: relative;
  }

  &__add {
    border: $color-aqua solid 1px;
    background-color: var(--theme-color);
    border-radius: 45px;
    position: absolute;
    right: $size-x-small;
    top: 45px;
    @media #{$media-desktop}{
      top: 11px;
    }

    &:hover{
      cursor: pointer;
      background-color: var(--theme-color-light);
    }
  }

  &__svg {
    width: $size-small;
    height: $size-small;
    padding: $size-xxx-small;
    color: white;
  }
}
