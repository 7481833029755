.c-documents-search
{
  $this: &;

  width: 100%;

  padding: $size-x-small $size-x-small 5px;

  $trigger-width : 3em;

  @at-root
  {

    #{$this}__link {
      display: flex;

      padding-bottom:  $size-xx-small;
      padding-top:  $size-xx-small;
    }

    #{$this}__link a {
      text-decoration: none !important;
      color: white;
      font-weight: 500;
    }

    #{$this}__link a:hover, #{$this}__link a:visited, #{$this}__link a:active {
      color: white;
      border-bottom-color: white;
    }

    #{$this}__link-title {
      padding-right: $size-xx-small;
    }

    #{$this}__link-text {
      display: flex;
      align-items: center;
      border-left: 1px white;
      border-left: solid 1px white;

      padding-left: $size-xx-small;
      padding-right: $size-xxx-small;
    }




    #{$this}__item {
      display: flex;
      align-items: center;
      align-self: flex-start;

      padding: 0 0 0 $size-xx-small;
      height:50px ;

      border-bottom: none!important;

      background: transparent!important;

      @include item-select();
    }


    #{$this}__item-right {
      margin-top: 10px;
    }


    #{$this}__item-highlight {
      background-color: yellow;
    }


    // en entête de la combo pour insérer un titre par exemple à la recherche
    #{$this}__header-container {

    }

    // item de la combo
    #{$this}__item-container {
      padding:0 !important;
      width: 100%;
    }


    // nom du groupe affiché en statique en haut de la combo
    #{$this}__fixed-group-container {

    }

    // étiquette du groupe
    #{$this}__group-container {

    }

    // à la fin de la combo, pour indiquer le nb de résultats par exemple
    #{$this}__footer-container {
      padding:$size-xx-small;
    }




    #{$this}__folder-icon {
      font-size: 27px;
    }


    #{$this}__input-container {
      overflow: hidden;
      border-radius: 3px;
    }

    #{$this}__input {
      width: 100%;
    }

    #{$this}__input:not(.k-widget) {
      height: 40px;
      padding: $size-xxx-small 0 $size-xxx-small $size-xxx-small;
      display: flex;
      align-items: center;
      background:#F5F5F5;

    }

    //cible le placeholder
    #{$this}__input:not(.k-widget)::placeholder {
      color: #d9d9d9;
      font-size: 13px;
    }
    #{$this}__input:not(.k-widget)::placeholder {
      color: #d9d9d9;
      font-size: 13px;
    }


    .k-dropdown-wrap.k-focus {
      .k-select {
        font-size: 13px;
        background: #ececec ;
      }
    }
    .k-input-button {
      transition: .2s ease;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: $size-xxx-small;
      margin: 0 $size-xxx-small;
      padding: 3px;
    }
    .k-focus {
      .k-input-button {
        background: #ececec ;
      }
    }
    .k-input-button:hover {
      background: #ececec ;
      transition: .2s ease;
    }
    #{$this} .k-input-button {
      border: none;
      border-radius: 5px;
    }

    //region Skin de la kendo combo
    #{$this} .k-animation-container {
      border-radius: 0;
      border: none;
      box-shadow: none;
      padding: 0;
    }


    #{$this} .k-animation-container .k-child-animation-container {
      border-radius: 0;
      border: none;
    }

    #{$this} .k-animation-container {
      left : auto !important;
      top: auto !important;
    }

    //region Skin du champ de la combo
    #{$this} .k-dropdown-wrap {
      padding-right: $trigger-width ;
    }

    #{$this} .k-dropdown-wrap.k-state-default {
      border:none !important;
    }

    #{$this} .k-dropdown-wrap.k-hover,
    #{$this} .k-dropdown-wrap.k-focus {
      box-shadow: none !important;
    }

    #{$this} .k-dropdown-wrap .k-select {
      display: flex;
      align-items: center;
      justify-content: center;

      width: $trigger-width;
    }

    #{$this} .k-dropdown-wrap .k-select .k-icon:before {
      content: "\e13e";
    }
    //endregion

    //region Skin de la liste dans la kendo combo

    //.k-child-animation-container {
    //  width:40vw!important;
    //}

    #{$this} .k-child-animation-container
    {
      min-width: 60vw !important;
      max-width: 900px;

      padding: 0;
      margin: 0;

      border:none;
      border-radius: $size-xxx-small;
      overflow: hidden;

      box-shadow: 0 0 1px rgba(black, 0.08), 0 2px 5px rgba(black, 0.08)
    }

    #{$this} .k-child-animation-container .k-virtual-content {
      overflow-y: auto;
    }

    #{$this} .k-child-animation-container .k-item {
      border-bottom:solid;
      border-bottom-width: 1px;
      border-bottom-color: $color-light;
      position: absolute!important;
      width: 100%;

      background: none;
      padding: 0;
      margin: 0;
    }

    #{$this} .k-child-animation-container .k-first:before {
      border: none;
    }

    #{$this} .k-child-animation-container .k-item:hover {
      background: $color-light-light ;
    }
    //endregion

    //region Skin de group dans la kendo combo
    #{$this} .k-popup > .k-group-header, .k-popup > .k-virtual-wrap > .k-group-header {
      border-bottom: 1px solid $color-default-light;
      font-size: 15px;
      text-align: left;
      color: $text-color;
      font-weight: 500;
      background: white;
      padding: $size-xx-small $size-x-small;
      text-transform: none;
    }


    #{$this} .k-popup .k-list .k-list-item > .k-list-item-group-label {
      padding: 0 0.7em;
      font-size: 13px;
      line-height: 14px;
      text-transform: uppercase;
      position: absolute;
      top: 0;
      right: 0;

      color: #fff;
      background :  var(--theme-color);
    }

    #{$this} .k-popup .k-list .k-list-item > .k-list-item-group-label:before {
      content: "";

      border-color:  var(--theme-color)  var(--theme-color) transparent transparent;

      width: 14px;
      height: 14px;
      border-width: 7px;
      border-style: solid;
      box-sizing: border-box;
      display: block;
      position: absolute;
      left: -14px;
      bottom: 0;
    }
    #{$this} .k-height-container {
      display: none;
    }
    .c-documents__item {
      border: none;
      background: transparent;
    }

    .k-input::placeholder {
      font-size: 13px;
      opacity: 0.3;
    }

    .k-input {
      font-size: inherit;
      border: none;
    }

    .k-dropdown-wrap .k-select {
      margin-right: -99999px;
    }

    .k-list {
      .k-item {
        cursor: pointer;
      }

      .k-hover {
        color: #444;
        background-color: #ebebeb;
      }
      .k-focus {
        color: #444;
        background-color: #ebebeb;
      }
    }

    .k-list-group-sticky-header {
      box-shadow: none;
      height: 3vh;
    }

    .k-callout {
      display: none;
    }

    .k-icon {
      vertical-align: baseline;
    }

    .k-tooltip {
      width: max-content;
    }

    .k-last {
      .k-bot {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .k-child-animation-container {
      .k-focus {
        box-shadow: none;
      }
      .k-virtual-content {
        .k-list-group-sticky-header {
          box-shadow: none;
          height: 5vh;
          display: flex;
          align-items: center;
          padding: 0 15px;
        }
      }
    }

    .k-popup {
      overflow-y: auto;
      overflow-x: hidden;
    }
    .k-list {
      overflow: visible;
    }
    .k-multiselect-wrap {
      .k-button {
        gap: 0;
        padding-right: $size-xxx-small;
      }

    }

    .k-list-item-text {
      font-size: 13px;
      width: 100%;
    }
    .k-list-item-text::before {
      content:"";
    }

  }
}