.webui-popover .webui-popover-content
{
    overflow:hidden;
}

// Sélecteur de réactions
.webui-popover-react > div.webui-popover-inner > div
{
    padding: 5px 0;
}

.react__container
{
    display: flex;
    margin: 5px 5px 0 5px;
    text-align: center;
}

.react__item
{
    margin: auto;
}

.react__item__zoom
{
    height: 30px;
    width: 30px;
}

.react__item_img
{
    width: 25px;
}

.react__item_img:hover
{
    width: 30px;
    overflow: hidden;
}

// Sélecteur de GIFs
.webui-popover-gif .webui-popover-content
{
    padding: 0;
}

.popover-gif__search
{
    margin: 10px;
}

.popover-gif__content
{
    height: calc(100% - 55px);
    margin-top: 10px;
    font-size: 0;
}

.popover-gif__content__img
{
    width: 100%;
    margin: 1px;
    padding: 0;
}
