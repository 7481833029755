.c-timeline-post-reactions
{
    $this: &;

    @include reset-button;

    text-align: inherit;

    @at-root
    {
        #{$this}__icon
        {
            $size: 17px;

            display: none;

            width: $size;
            height: $size;
            margin-left: -$size-xxx-small;

            border-right: 1px solid white;
            border-radius: 50%;
            background-color: white;

            @media (min-width: $screen-xs-min)
            {
                $size: $size-x-small + $size-xxx-small;

                width: $size;
                height: $size;

                border-width: 2px;
            }

            &:nth-child(1)
            {
                z-index: 6;
            }
            &:nth-child(2)
            {
                z-index: 5;
            }
            &:nth-child(3)
            {
                z-index: 4;
            }
            &:nth-child(4)
            {
                z-index: 3;
            }
            &:nth-child(5)
            {
                z-index: 2;
            }
            &:nth-child(6)
            {
                z-index: 1;
            }
        }
        #{$this}__icons
        {
            display: flex;

            padding-left: $size-xxx-small;

            &.has-like #{$this}__icon--like,
            &.has-love #{$this}__icon--love,
            &.has-haha #{$this}__icon--haha,
            &.has-wow #{$this}__icon--wow,
            &.has-sad #{$this}__icon--sad,
            &.has-angry #{$this}__icon--angry
            {
                display: block;
            }
        }

        #{$this}__label
        {
            margin-left: $size-xxx-small;
        }
    }
}
