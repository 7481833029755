.c-sidebar-mobile-dropdown
{
    $this: &;
    $icon-spacing: $size-x-small;
    $icon-size: 20px;
    $thumbnail-size: $size-small;
    $item-padding: $size-x-small;
    $item-height: $icon-size + $size-x-small * 2;

    @at-root
    {
        #{$this}__header
        {
            display: flex;
            align-items: center;

            padding: $item-padding 0;

            border-bottom: 1px solid $color-light;
        }

        #{$this}__thumbnail-container
        {
            position: relative;

            flex-grow: 0;
            flex-shrink: 0;

            box-sizing: content-box;
            width: $icon-size;
            height: $icon-size;
            padding: 0 $icon-spacing;

            text-align: center;

            @supports (padding: env(safe-area-inset-left)) and (padding: unquote('max(1px, 0px)'))
            {
                padding-left: unquote('max(#{$icon-spacing}, env(safe-area-inset-left))');
            }

            > img
            {
                $centering-offset: -($thumbnail-size - $icon-size) / 2;

                width: $thumbnail-size;
                height: $thumbnail-size;
                margin-top: $centering-offset;
                margin-left: $centering-offset;
            }

            .fa
            {
                font-size: $icon-size !important;
            }
        }

        #{$this}__title
        {
            font-size: 15px;
            font-weight: 500;

            overflow: hidden;
            flex: 1;

            min-width: 0;
            padding: 0 $size-x-small;

            white-space: nowrap;
            text-overflow: ellipsis;

            @supports (padding: env(safe-area-inset-right)) and (padding: unquote('max(1px, 0px)'))
            {
                padding-right: unquote('max(#{$icon-spacing}, env(safe-area-inset-right))');
            }

            #{$this}__thumbnail-container + &
            {
                padding-left: 0;
            }
        }

        #{$this}__items
        {
            @supports (padding: env(safe-area-inset-bottom))
            {
                padding-bottom: env(safe-area-inset-bottom);
            }
        }

        #{$this}__trigger
        {
            @mixin highlight()
            {
                &::before
                {
                    background: $color-light-light;
                }
            }

            @include reset-button();

            font-size: 15px;

            position: relative;

            display: flex;
            align-items: center;

            width: 100%;
            padding: $item-padding 0;

            user-select: none;

            &,
            &:hover,
            &:focus,
            &:active
            {
                text-decoration: none;

                color: $text-color;
            }

            &::before
            {
                content: '';

                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;

                width: 100%;
                height: $item-height;

                transition: background-color ease $speed-fast;
            }

            &:focus,
            &:active
            {
                @include highlight();
            }

            @media (hover: hover) and (pointer: fine)
            {
                &:hover
                {
                    @include highlight();
                }
            }

            &--danger
            {
                color: $color-danger !important;
            }

            #{$this}__item:last-child &
            {
                @supports (padding: env(safe-area-inset-bottom))
                {
                    // If the device have safe-areas, removing the
                    // padding-bottom. The safe-areas will be included in the
                    // __items padding-bottom instead, to avoid firing the item
                    // click/tap event when using gestures bars.
                    padding-bottom: calc(#{$item-padding} - env(safe-area-inset-bottom));
                }
            }
        }

        #{$this}__trigger-icon-container
        {
            font-size: $icon-size;

            position: relative;

            display: flex;
            align-items: center;

            box-sizing: content-box;
            width: $icon-size;
            height: $icon-size;
            padding: 0 $icon-spacing;

            @supports (padding: env(safe-area-inset-left)) and (padding: unquote('max(1px, 0px)'))
            {
                padding-left: unquote('max(#{$icon-spacing}, env(safe-area-inset-left))');
            }
        }

        #{$this}__trigger-icon
        {
            width: 100%;
            height: 100%;
        }

        #{$this}__trigger-label
        {
            padding: 0 $icon-spacing;

            @supports (padding: env(safe-area-inset-right)) and (padding: unquote('max(1px, 0px)'))
            {
                padding-right: unquote('max(#{$icon-spacing}, env(safe-area-inset-right))');
            }

            #{$this}__trigger-icon-container + &
            {
                padding-left: 0;
            }
        }
    }
}
