.c-chat-feed
{
    $this: &;

    @at-root
    {
        #{$this}__section
        {
            margin-bottom: $size-normal;
        }

        #{$this}__section-time
        {
            display: flex;
            justify-content: center;
            align-items: center;
            color: grey;
            margin-bottom: $size-x-small;
            margin-top: $size-x-small;
        }

        #{$this}__messages-group
        {
            margin-bottom: 20px;

            .c-chat-conversation.has-multiple-recipients &,
            &.c-chat-messages-group--reply
            {
                margin-bottom: 25px;

                @media #{$media-desktop}
                {
                    margin-bottom: 20px;
                }
            }
        }
    }
}
