.c-spaces-front-item
{
    $this: &;

    @at-root
    {
        #{$this}__illustration-image
        {
            position: relative;

            z-index: $z-index-2;

            width: 100%;
            height: 100%;

            object-fit: contain;
            filter: drop-shadow(0 3px 8px rgba(black, .25));
        }

        #{$this}__album-count--card
        {
            position: absolute;
            right: 4px;
            top: 4px;
            background-color: #6c757d;
            color: white;
            width: 35px;
            height: 17px;
            font-size: 11px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 0.25rem;
            border-radius: 0.25rem;
            font-weight: 700;
        }

        #{$this}__album-count__doc-icon {
            fill: white;
            width: 10px;
            margin-right: 0.25rem;
        }

        #{$this}__album-count--list
        {
            position: absolute;
            right: -7px;
            top: -7px;
            background-color: rgba(0, 0, 0, 0.8);
            color: white;
            width: 22px;
            height: 22px;
            font-size: 13px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: solid 2px white;
            border-radius: 50%;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
        }
    }
}
