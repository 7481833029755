.c-user-menu-action
{
    $this: &;

    @at-root
    {
        #{$this}__trigger
        {
            display: flex;
            align-items: center;

            width: 100%;
            padding: ($size-small / 2) $size-small;

            transition: background-color ease $speed-fast;
            text-align: left;

            border: none;
            background: none;

            &,
            &:hover,
            &:focus,
            &:active
            {
                text-decoration: none;
            }

            &:hover,
            &:focus,
            &:active
            {
                background: $color-light-light;
            }
        }

        #{$this}__icon
        {
            width: $size-small;
            height: $size-small;

            transition: color ease $speed-fast;

            color: $color-dark-light;

            #{$this}--positions &
            {
                transform: translateY(-2px);
            }

            #{$this}--disconnect &
            {
                transform: translateY(-3px);
            }

            #{$this}:hover &,
            #{$this}:focus &,
            #{$this}:active &
            {
                color: var(--theme-color);
            }
        }

        #{$this}__label
        {
            font-size: 17px;

            margin-left: $size-x-small;

            transition: color ease $speed-fast;

            color: $text-color;

            #{$this}:hover &,
            #{$this}:focus &,
            #{$this}:active &
            {
                color: var(--theme-color);
            }
        }
    }
}
