/* Apercu Bold */
@font-face {
  font-family: 'Apercu';
  src: url('/assets/fonts/apercu-bold.otf') format('opentype');
  font-weight: 500 900;
  font-style: normal;
}

/* Apercu ExtraLight */
@font-face {
  font-family: 'Apercu';
  src: url('/assets/fonts/apercu-extralight.otf') format('opentype');
  font-weight: 100 300; /* Extra light weight */
  font-style: normal;
}

/* Apercu Italic */
@font-face {
  font-family: 'Apercu';
  src: url('/assets/fonts/apercu-italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

/* Apercu Regular */
@font-face {
  font-family: 'Apercu';
  src: url('/assets/fonts/apercu-regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}