.c-tasks-tagList {

  & li{
    padding-right: 36px;
    position: relative;
  }

  &__tag{
    cursor: pointer;
    font-weight: 700;
    margin: 0 0 4px;
    min-height: 32px;
    padding: 6px 12px;
    position: relative;
    background-color: #b3bac5;
    border-radius: 4px;
    color: #fff;
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  &__tagCircle{
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin-right: 5px;
  }

  &__tagName{
    color: #434a54;
    font-size: 13px;
    font-weight: 400;
  }

  &__iconModificationTag{
    border-radius: 3px;
    padding: 6px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    cursor: pointer;

    @media (min-width: $screen-md-max) {
      &:hover{
        background-color: #caced6;
      }
    }
  }

  &__check {
    position: absolute;
    right: 6px;
    display: none;
    color: black;

    &--checked {
      display: block;
    }
  }
}