.k-datetime-wrap {
  $this: &;

  @at-root {
    #{$this} .k-datetime-buttongroup {
      .k-button-group {
        padding: 5px;
        background: $color-light;
        border-radius: 5px;
        gap: 5px;
        .k-button {
          background: #fafafa;
          border: 1px solid #e6e6e6;
          border-left: none;
          color: #026aa7;
          &:hover {
            background:#026aa7 ;
            color: #fafafa;
            transition: 0.2s ease-in;
          }
          &:first-child {
            border-left: 1px solid #e6e6e6;
            border-radius: 3px 0 0 3px;
          }
          &:last-child {
            border-radius: 0 3px 3px 0;
          }
        }
        .k-selected {
          background: #026aa7;
          color: #fafafa;
        }
      }
    }

    #{$this} .k-datetime-selector {
      .k-calendar-nav {
        .k-nav-today {
          color: #026aa7;
          transition: 0.2s ease-in;
          border-radius: 2px;
          padding: 2px 5px;
          text-decoration: none;
          cursor: pointer;
          &:hover {
            background:#026aa7 ;
            color: #fafafa;
          }
        }
      }
      .k-calendar-view {
        height: 200px;
      }
      .k-calendar-nav-today {
        border-radius: 4px;
        border: solid 2px #e6e6e6;
        color: #026aa7;
      }
      .k-time-now {
        color: #026aa7;
      }
    }

    #{$this} .k-datetime-footer {
      border: 1px solid #e6e6e6;
      border-top: none;
      padding: $size-xx-small;
      .k-button {
        border-radius: 3px;
        background: #fafafa;
        border: 1px solid #e6e6e6;
        color: #026aa7;
        &:hover {
          background:#026aa7 ;
          color: #fafafa;
        }
      }
    }

    #{$this} .k-button {
      background: transparent;
    }

    #{$this} .k-calendar-title, #{$this} .k-title {
      font-weight: 500;
    }

    #{$this} .k-item:hover {
      color: #026aa7;
    }
  }
}
