.c-adn-banner-editor
{
    $this: &;

    @media (min-width: $screen-xs-min)
    {
        $padding: $size-small;

        padding: $padding;

        @supports (padding: env(safe-area-inset-left)) and (padding: unquote('max(42px, 21px)'))
        {
            padding-right: unquote('max(#{$padding}, env(safe-area-inset-right))');
        }
    }

    @at-root
    {
        & .c-adndropzone__dropzone
        {
            margin-right: 0;
            margin-left: 0;
        }
    }
}
