.c-tasks {
  &__header {
    display: none;

    @media (min-width: $screen-md-min) {
      display: flex;
      align-items: baseline;
      padding: $size-x-small;
    }
  }

  &__modal{
    z-index: 1999 !important;
  }

  &__container-header {
    justify-content: space-between;
  }

  &__sub-header {
    display: flex;
    align-items: center;
    padding: $size-xx-small;
  }

  &__sub-header-icon {
    color: $text-color;
    width: 1.5em;
    height: 1.5em;
    margin-right: 10px;

    @media (min-width: $screen-md-min) {
      display: none;
    }
  }

  &__tab-content {
    padding: 0!important;
    height: 100%;
    border: none!important;
  }

  &__kanban {
    display: none;

    &.active {
      display: block;
      height: 100%;
    }
  }

  &__archive {
    display: none;

    &.active {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  &__kanban--noselect {
    -webkit-touch-callout: none !important; /* iOS Safari */
    -webkit-user-select: none !important; /* Safari */
    -moz-user-select: none  !important; /* Old versions of Firefox */
    -ms-user-select: none !important; /* Internet Explorer/Edge */
    user-select: none  !important; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }

  &__kanban--overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    z-index: 2;
    cursor: pointer;
  }

  &__kanban--overlayCancelAddNewColumn {

    display: none;

    @media (min-width: $screen-sm-min) {

      position: fixed;
      display: none;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      z-index: 105;
      cursor: pointer;
    }
  }

  &__kanban-container {
    height: 100%;
    padding: $size-xx-small;
  }
  

  &__kanban-draggin {
    transform: rotate(3deg);
  }

  &__list {
    display: none;

    &.active {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  &__aside-trigger {
    display: none;
  }

  &__update-card {
    display: none;

    @media (min-width: $screen-sm-min) {
      display: block;
    }
  }

  &__update-card-mobile {
    margin-bottom: $size-xx-small;

    @media (min-width: $screen-sm-min) {
      display: none;
    }
  }
}