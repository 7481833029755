.c-document-thirdparty {

  $this: &;

  @at-root {

    // --------- ENTETE ----------------------
    #{$this}__header {
      display: flex;
      padding: $size-x-small;
      align-items: center;
    }

    #{$this}__title {  // titre du fichier
      margin-left: $size-x-small;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

    }

    #{$this}__title a,  #{$this}__title > span {  // lien dans le titre du fichier
      font-size: 21px;

      display: block;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }


    #{$this}__miniature {  // miniature en haut à gauche
      width:50px;
      height:50px;
      flex-shrink: 0;

        .icon-document {
          font-size: 36px;
        }

    }
    // --------- FIN ENTETE -------------------



    // -------- TABS ----------------
    #{$this}__tabs-content {  // container des éléments du tabs sous l'entête
      border-top:none !important;
      padding-top: 0px !important;
    }

    #{$this}__tabs li {  // éléments du tabs sous l'entête
      flex:1;
      text-align:center;
      display: none;

      @media #{$media-desktop}
      {
        display: block;
      }
    }
    // -------- FIN TABS ----------------


    // --------- ONGLET DETAILS ----------------------
    #{$this}__detail {
      padding:$size-x-small;
      padding-top: $size-x-small;
    }

    #{$this}__detail-miniature {  // miniature
      padding-bottom: $size-xx-small;
      text-align: center;

        .icon-document {
          font-size: 36px ;
        }
    }

    #{$this}__detail-miniature img {
      max-height: 250px;
      max-width: 100%;
    }


    #{$this}__detail-container-text { // contient les éléments textuels
      margin-top: $size-x-small;
    }

    #{$this}__detail-text { // élément textuel : taille, type, titre etc...
      display: inline-block;
      color:$color-dark-light;
      width:80px;
    }

    #{$this}__detail-text span {
      color:$color-dark;
    }


    #{$this}__detail-user-oof-item {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    #{$this}__detail-user-oof-btn {
      min-width: 50px;
      margin-left: auto;
    }
    // --------- FIN ONGLET DETAILS -------------------





    // --------- ONGLET LIENS PARTAGES ----------------------
    //#{$this}__liensshare { }

    #{$this}__liensshare-create,
    #{$this}__document-access
    {
       display:flex;
       justify-content: right;
       width: 100%;
       gap: $size-xx-small;
       row-gap: $size-xx-small;

       padding: $size-x-small;
       padding-bottom: $size-x-small;
       padding-right: $size-xx-small;

       & .access {
          font-size:20px;
          margin-right: $size-xxx-small;
       }
    }

    #{$this}__document-access {
      padding-top:0;
    }
    // --------- FIN ONGLET LIENS PARTAGES ----------------------
  }




  // --------- ONGLET MODIFICATION FICHIER OFFICE  --------------
  #{$this}__grid-history {
    border:none !important;
  }

  #{$this}__grid-history .k-grid-header {
    padding-right: 7px !important;  // webkit-scrollbar est en width 7px, on diminue donc la taille réservée par défaut de 16px à 8px
  }

  #{$this}__grid-history .k-grid-header
  {
    display: none;
  }

  #{$this}__grid-history .k-header .k-link {
    color:  var(--theme-color-light);
    font-weight: 400 ;
    font-size: 13px;
    padding:5px 0 5px 5px;
  }

  #{$this}__grid-history .k-grid-content.k-auto-scrollable {
    @include custom-scrollbar();

    height: calc(#{vh(100)} - #{$header-height} - var(--mobile-menu-height) - 100px  ) !important;

    @media #{$media-desktop}
    {
      height: calc(#{vh(100)}  - 259px) !important;
    }
  }

  #{$this}__grid-history-row  {
    color: $color-black !important;
    padding:8px;
    font-size: 14px;
  }
  // --------- FIN ONGLET MODIFICATION FICHIER OFFICE  --------------


}
