.bootstrap-datetimepicker-widget {
  left:-15px !important;
  top: 40px !important;

  @media #{$media-desktop} {

    inset:revert;

    left:unset;

  }


  &.dropdown-menu {
    height:300px !important;
  }

}