blockquote
{
    font-size: 25px;
    line-height: 1.2;

    margin: $size-small 0;
    padding: $size-x-small $size-small;

    color: $color-dark;
    border-left: 3px solid $color-light;

    p,
    ul,
    ol
    {
        &:last-child
        {
            margin-bottom: 0;
        }
    }

    footer,
    small
    {
        font-size: 15px;
        line-height: 1.2;

        display: block;

        margin-top: $size-x-small;

        color: $color-dark;

        &:before
        {
            content: '\002D \00A0';
        }
    }
}
