.notification-menu
{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%!important;

    &__scroll
    {
        flex: 1;
    }
}
