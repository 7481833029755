 .c-checkbox
{
    $this: &;

    position: relative;

    display: block;

    @at-root
    {
        .c-input-group #{$this}
        {
            margin-bottom: 0;
        }

        #{$this}__input
        {
            z-index: -1;

            display: none;
            visibility: hidden;

            width: 0;
            height: 0;
        }

        #{$this}__label
        {
            font-size: 13px;

            display: flex;
            float: none;
            align-items: center;

            width: auto;
            min-height: $size-small;
            margin: 0;
            margin-left: -7.5px; /* = checkmarkWidth */
            padding: 0;

            cursor: pointer;

            &::before,
            &::after
            {
                content: '';

                width: $size-x-small;
                height: $size-x-small;
                flex-shrink: 0;
            }

            &::before
            {
                order: -1;

                margin-right: $size-xxx-small;

                border: 1px solid $panels-border-color;
                background-color: white;
            }

            &:empty::before
            {
                margin-right: 0;
            }

            &::after
            {
                order: -2;

                width: 7.5px;

                transform: translateX(calc(100% - 1.25px)) translateY(-25%) scale(.6) rotateZ(45deg); /* calc = -100% - ((checkmarkWidthWithoutRotate / 2) - 1) */
                transform-origin: bottom right;

                opacity: 0;
                border-width: 0 3px 3px 0;
                border-style: solid;
                border-color: white;
            }
        }

        #{$this}__input:checked + #{$this}__label
        {
            &:after
            {
                opacity: 1;
            }

            &:before
            {
                border-color: var(--theme-color);
                background-color: var(--theme-color);
            }
        }

       

        #{$this}__input:disabled + #{$this}__label
        {
            color: lighten($panels-border-color, 3%);

            &:before
            {
                border-color: currentColor !important;
            }
        }

        #{$this}__input:checked:disabled + #{$this}__label:before
        {
            background-color: currentColor;
        }
    }
}
