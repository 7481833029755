.l-app-main
{
    position: relative;

    overflow-x: hidden;

    min-height: 100%;
    padding: $app-main-container-padding-xs;
    padding-right: var(--app-main-container-padding-right-xs);
    padding-left: var(--app-main-container-padding-left-xs);

    @media (min-width: $screen-xs-min)
    {
        padding: $app-main-container-padding-sm;
        padding-right: var(--app-main-container-padding-right-sm);
        padding-left: var(--app-main-container-padding-left-sm);
    }

    &--tchat
    {
        display: flex;
        flex-direction: column;

        padding: 0;

        .l-app.is-chat-xs-or-wider &
        {
            height: 100%;
        }
    }

    &--no-padding {
        padding: 0;
    }
}
