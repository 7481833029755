.c-timeline-comment-publisher
{
    $this: &;

    display: flex;

    @at-root
    {
        #{$this}__thumb
        {
            flex-shrink: 0;

            margin-top: 4px;
            margin-right: $size-x-small;
        }

        #{$this}__editor
        {
            position: relative;

            display: flex;
            flex: 1;
            flex-wrap: wrap;
            justify-content: flex-end;

            min-width: 0;
            padding: $size-xx-small;

            border-radius: 5px;

            &,
            &::before
            {
                border: 1px solid $panels-border-color;
            }

            &,
            &::after
            {
                background-color: white;
            }

            &::before,
            &::after
            {
                content: '';

                position: absolute;
                top: 14.5px;
                left: 0;

                width: $size-xx-small;
                height: $size-xx-small;
            }

            &::before
            {
                transform: translateX(calc(-50% - 1px)) rotateZ(-45deg);
            }

            &::after
            {
                transform: translateX(calc((-#{$size-xx-small} / 2) + .5px)) rotateZ(-45deg);
            }

            &.is-focused
            {
                &,
                &::before
                {
                    border-color: var(--theme-color-light);
                }
            }

            &.is-multiline
            {
                flex-direction: column;
            }
        }

        #{$this}__input
        {
            font-size: 15px;

            flex-grow: 1;
            flex-shrink: 1;

            min-width: 0;

            cursor: text;
            white-space: break-spaces;

            &:empty::before
            {
                content: attr(placeholder);

                display: block; /*  Firefox */

                color: $color-default-light;
            }
        }

        #{$this}__footer
        {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            &.has-url-preview
            {
                // Pas de flex: 1 ici car s'il n'y a pas d'illustration,
                // l'aperçu d'URL devient extrêmement étroit. Aucun souci avec
                // width: 100%.
                width: 100%;
            }
        }

        #{$this}__url-preview
        {
            flex: 1;

            margin-top: $size-x-small;
        }

        #{$this}__actions // !!s
        {
            font-size: 15px;

            display: flex;
            align-items: center;

            height: 20px;
            margin-left: $size-xxx-small;
        }

        #{$this}__action // prefix:.c-timeline-comment-publisher
        {
            display: inline-flex;
            align-items: center;

            height: 100%;
            padding: 0 $size-xxx-small;

            color: $color-default-light;
            border: none;
            background: none;


            &:hover
            {
                color: inherit;
            }
            &--gif{
                .pr-gif2{
                    width:1.25em;
                    height:1.25em;
                }
            }
            &--gif > i:before
            {
                font-size: inherit;
                font-style: normal;
                line-height: 1;

                color: inherit;
            }

            &--emoji
            {
                padding: 0 7px;


                > i
                {
                    margin-top: 1px;

                    transform: scale(1.2);
                }
            }

            &--file{
                .pr-add_file{
                    width:1.25em;
                    height:1.25em;
                }
            }
            &--file > i
            {
                transform: scale(1.2);
            }

            &--submit
            {
                font-size: 13px;

                position: relative;

                color: white;
                border: 1px solid var(--theme-color);
                border-radius: 50%;
                background-color: var(--theme-color);

                &[disabled]
                {
                    color: $color-default-light;
                    border-color: $color-default-light;
                    background-color: white;
                }

                &:not([disabled]):hover
                {
                    color: var(--theme-color);
                    background-color: white;
                }

                & > i
                {
                    position: absolute;
                    top: 50%;
                    left: 50%;

                    margin-top: .5px;
                    margin-left: 1px;

                    transform: translate(-50%, -50%);
                }

                #{$this}.is-loading &
                {
                    display: none;
                }
            }
        }

        #{$this}__loader
        {
            display: none;

            #{$this}.is-loading &
            {
                display: block;
            }
        }

        #{$this}__action--submit,
        #{$this}__loader
        {
            width: 20px;
            height: 20px;
            margin-left: $size-xx-small;
        }

        #{$this}__ia-icon-comment {
            width:20px;
        }
    }
}
