:root {
  --dynamic-spaces-container-height: 120px;
}

.c-spaces-container {

  $this: &;

  display: flex;
  flex-direction: column;


  min-height: calc(#{vh(100)}  - #{$header-height});

  .has-enabled-mobile-navbar &
  {
    min-height: calc(#{vh(100)} - #{$header-height} - var(--mobile-menu-height)   ) ;
  }


  @media #{$media-desktop} {
    height:100% !important;
    min-height:100% !important;
  }

  @at-root {

    #{$this}__user-open-members {
      font-size:14px;
      margin-top:4px;
      margin-right:4px;
      cursor:pointer;
      background:var(--theme-color);
      width:22px;
      height:22px;
      color: var(--button-font-color);

      display:inline-block;
      line-height: 22px;
      text-align: center;
      border-radius: 50%;
      transition: box-shadow 0.2s ease-in-out;
      box-shadow: 0 0 0 1px var(--theme-color);
    }

    #{$this}__user-open-members:hover  {
      box-shadow: 0 0 0 1px var(--theme-color-light);
      background: var(--theme-color-light);
    }




    #{$this}__user-is-admin {
      & ::after
      {
        content:'';
        background-image: url('/assets/icons/chevron.png');
        background-size: contain;
        position: absolute;
        z-index: $z-index-3;
        top: 14px;
        right:16px;
        border:none;

        width: 9px;
        height: 9px;
      }
    }

    #{$this} .c-spaces-container__item-check a {
      width:100%;
      height:100%;
    }

    #{$this} .c-spaces-container__item-check a[title="Administrateur"] {
      display: inline-block;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 50%;
      transition: box-shadow 0.2s ease-in-out;
      box-shadow: 0 0 0 1px $color-light;
    }

    #{$this}__item:hover .c-spaces-container__item-check a[title="Administrateur"] {
      box-shadow: 0 0 0 2px $color-default-light;
      background: $color-light;
    }



    #{$this}__users-admin-list {
      display: none;
      margin-left: $size-xxx-small;
      margin-top: 2px;

      @media #{$media-desktop} {
        display: flex;
      }

      & .user {
        margin-left: -5px;
        height: 25px;
        width: 25px;
        font-size: 13px;
        border: white 1px solid;
      }
    }

    #{$this}__users-admin-more {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: $color-light;
      text-align: center;
      font-size: 13px;
      padding-top: 5px;
      padding-right: 2px;
      //font-family: 'Rubik', sans-serif;
      font-family: 'Apercu', sans-serif;
      color: #656565;
      margin-right: 1px;
      margin-left: 1px;
      cursor: pointer;
    }



    #{$this}__load-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0,0,0,0.1);
      z-index: 2;
      cursor: pointer;
    }

    #{$this}__load-overlay .c-loader {
      position: fixed;
      left: calc(50% - 100px);
    }

    #{$this}__default-message {
      position: relative;
      padding: $size-x-small;
      bottom: 100%;
    }


    #{$this}__sidebar-parameters {
      display: flex;
      height: 100%;
    }

    #{$this}__sidebar-parameters .c-sidebar__content {
      padding:$size-xx-small !important;
    }


    #{$this}__search {
      margin:0;
      margin-left: $size-xxx-small;
      margin-right:$size-xxx-small;
      padding: 0;
      width:100%;

      @media #{$media-desktop} {
        margin-right:0;
        width:310px;
      }
    }



    #{$this}__item-miniature  {
      margin-right: $size-xx-small;
    }

    #{$this}__item-color {
      width:100%;
      height:100%;
    }

    #{$this}__listview {
        border:none;

        & .k-listview-content-bordered {
          border:0 !important;
        }

        & .k-listview-content {
          @include custom-scrollbar();

          height: calc(#{vh(100)} - var(--dynamic-spaces-container-height)  ) !important;
        }
    }

    #{$this}__listview-background {
      flex: 1;
      background: linear-gradient(180deg, white, white 68px, $color-light 68px, $color-light 68px);
      background-size: 100% 69px;
    }


    // ---------------- item de listview ----------------------
    #{$this}__item-container {
      width:100%;
      height: auto;
      padding:0px;
      margin:0px;

      @include item-select();
    }

    #{$this}__item-container:hover  {
      display: block;
      cursor: pointer;
    }



    #{$this}__item {
        border-bottom: 1px solid $color-light;

        padding:$size-xx-small;
        padding-right: 0;
        margin-right: 0;
        display: flex;
    }

    #{$this}__item:first-child {
      border-bottom-width: 1px;
    }

    #{$this}__item:hover {
      background: $color-light-light;
    }
    #{$this}__item:hover  #{$this}__item-dropdown-button {
      display: flex;
    }


    #{$this}__item-columns-left  {
      display: flex;
      width: calc(100% - 50px);

      @media (min-width: $screen-sm-min)
      {
        width: calc(100% - 250px);
      }

      @media (min-width: $screen-lg-min)
      {
        width: calc(100% - 450px);
      }
    }


    #{$this}__item-creator {
      display: none;
      text-align: right;

      margin-right:4px;
      margin-top: 4px;

      color:$color-dark-light;
      font-size: 12px;

      @media (min-width: $screen-sm-min)
      {
        display: block;
      }

      &::before {
        content: 'Crée par ';
      }

    }


    #{$this}__item-columns-right {
      display: flex;
      width: 100%;
      flex-direction: row-reverse;
    }



    #{$this}__item-miniature .c-entity-thumb__initial {
      font-size: 15px;
    }






    #{$this}__item-title {
      font-size: 14px;
      margin-top: $size-xxx-small;
      min-width: 200px;
    }

    #{$this}__item-title strong {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
    }

    #{$this}__item-description {
      color:$color-dark-light;
      font-size: 12px;
      margin-top:$size-xxx-small;

      width:100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }


    #{$this}__item-dates{
      margin-top: 0;
      min-width: 160px;

      display:none;

      @media (min-width: $screen-lg-min)
      {
         display: block;
      }
    }


    #{$this}__item-dropdown {
       width: 45px;
       display: block;

    }

    #{$this}__item-dropdown-button {
        display: flex;

        @media #{$media-desktop} {
          display: none;
        }
    }


    #{$this}__item-check {
      margin-top:$size-xxx-small;
      margin-left:$size-xx-small;
      margin-right:$size-xx-small;
      min-width: 30px;
      text-align: center;
      font-size: 17px;

      display:none;

      @media (min-width: $screen-sm-min)
      {
        display: block;
      }
    }

    #{$this}__item-link a {
      width:85px;
      margin-right: 10px;
      display: none;

      @media (min-width: $screen-sm-min)
      {
        display: inline-flex;
      }
    }
    // ---------------- fin item de listview ----------------------



    #{$this} .c-adndropzone__dropzone{
      width: unset;
    }



    #{$this}__menu {
      padding:0px;
      margin:0px;
      width:100%;
    }

    #{$this}__menu-btn {
      padding:5px
    }

    #{$this}__menu-btn a {
      width:100%;
    }

    @include item-play($this);

  }

}
