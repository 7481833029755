.c-chat-message
{
    $this: &;

    max-width: $size-xxx-large + $size-x-large;
    padding: $size-xxx-small 10px;
    background-color: #f1f0f0;
    border-radius: 3px 15px 15px 3px;
    color: black;
    font-size: 16px;
    -webkit-touch-callout: none;
    user-select: none;
    text-align: left;

    overflow-wrap: break-word;
    word-wrap: break-word;

    word-break: break-word;

    hyphens: auto;
    position: relative;

    -webkit-tap-highlight-color: transparent;

    @media (hover: hover) and (pointer: fine)
    {
        -webkit-touch-callout: behavior;
        user-select: text;
    }

    @at-root
    {
        &--deleted-notme,
        &--deleted {
            background: white !important;
            border-width: 1px;
            border-style: dashed;
            border-color: var(--theme-color);
            font-style: italic;
            color: var(--theme-color) !important;

            + .c-chat-message-container__actions {
                display: none;
            }
        }

        &--deleted-notme {
            border-color: $color-dark-light;
            color: $color-dark-light !important;
        }



        &::after
        {
            content: '';
            box-shadow: inset 0 0 10px #fc0;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 15px;
            pointer-events: none;
        }

        &.is-highlighted::after
        {
            opacity: 1;
        }

        &--url-preview,
        &--replied-message-preview,
        &--file:not(&--img)
        {
            border: 1px solid #f1f0f0;
        }

        &--url-preview,
        &--replied-message-preview
        {
            background-color: white !important;
            color: black !important;
        }

        &--url-preview
        {
            padding: 0;
            overflow: hidden;
            margin-top: 3px;
        }

        &--replied-message-preview
        {
            &:hover
            {
                cursor: pointer;
                background-color: rgba(black, .05) !important;
            }
        }

        .c-chat-messages-group--user &
        {
            color: white;
            border-radius: 15px 3px 3px 15px;
            background-color: var(--theme-color);
        }

        .c-chat-message-container:first-child > .c-chat-message-container__url-preview-wrapper:first-child &,
        .c-chat-message-container:first-child > .c-chat-message-container__replied-message-wrapper:first-child &,
        .c-chat-message-container:first-child .c-chat-message-container__main-content:first-child &
        {
            border-top-left-radius: 15px;

            .c-chat-messages-group--user &
            {
                border-top-right-radius: 15px;
            }
        }

        .c-chat-message-container:last-child > .c-chat-message-container__url-preview-wrapper:nth-last-child(2) &,
        .c-chat-message-container:last-child > .c-chat-message-container__replied-message-wrapper:nth-last-child(2) &,
        .c-chat-message-container:last-child .c-chat-message-container__main-content:nth-last-child(2) &
        {
            border-bottom-left-radius: 15px;

            .c-chat-messages-group--user &
            {
                border-bottom-right-radius: 15px;
            }
        }

        &--img,
        &--file
        {
            padding: 0;
            overflow: hidden;

            background-color: transparent !important;

            -webkit-touch-callout: none;
            user-select: none;
        }

        &--img
        {
            border-radius: 0 !important;
        }

        &--truncate-response
        {
            width: 70ch;
            @include single-line();
        }

        &__waiting
        {
            animation-name: blink;
            animation-duration: 1.4s;
            animation-iteration-count: infinite;
            animation-fill-mode: both;
            color: $text-color;
            line-height: 1px;
            font-size: 31px;

            &:nth-child(2)
            {
                animation-delay: .2s;
            }

            &:nth-child(3)
            {
                animation-delay: .4s;
            }
        }
    }
}
