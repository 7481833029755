/// ============================================================================
/// # REACTION-IN-TEXT
/// ============================================================================
/// Fait en sorte que les images des réactions s'affichent correctement au
/// milieu d'un texte.
/// Le style est une reprise de la classe .emojione de la librairie du même nom,
/// ainsi que de notre override. Mais puisque nos réactions ne sont pas des
/// émojis de cette librairie (il s'agit d'images récupérées çà et là sur le
/// web), il n'est pas logique ni maintenable d'utiliser la classe .emojione
/// dessus.
/// De plus, ces balises HTML <img> sont aujourd'hui stockées dans les clés
/// Redis des notifications de réactions, faute de pouvoir faire mieux avec le
/// système actuel. Pour que cela reste tout de même maintenable, il vaut mieux
/// ne surtout pas stocker de classe HTML dedans. Pour cette raison, on s'en
/// remet donc complètement au CSS avec une sélection par rapport à l'URL.
/// ============================================================================
/// @group tools
/// ============================================================================

@mixin reaction-in-text
{
    img[src^='/assets/images/reactions/']
    {
        font-size: inherit;
        line-height: normal;

        display: inline-block;

        width: auto;
        min-width: 16px;
        height: 1.1em;
        min-height: 16px;
        margin: -.2ex .15em .2ex;

        vertical-align: middle;
    }
}
