.c-fab
{
    $this: &;
    $offset: $size-x-small;
    $trigger-reveal-animation-duration: $speed-fast;

    position: fixed;
    z-index: $z-index-8;

    @at-root
    {
        #{$this}__overlay
        {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            visibility: hidden;

            transition: ease $speed-medium;
            transition-property: visibility, opacity;

            opacity: 0;
            background: rgba(white, .95);

            #{$this}.is-expanded &
            {
                visibility: visible;

                opacity: 1;
            }
        }

        #{$this}__container
        {
            position: fixed;
            right: $offset;
            bottom: $offset;

            display: flex;
            align-items: center;
            flex-direction: column-reverse;

            pointer-events: none;

            // The floating action button must not be displayed above the online
            // users aside of the app (displayed at $screen-xl-min)
            @media #{$media-online-users-aside}
            {
                .has-right-aside &
                {
                    right: $main-aside-width + $offset;
                }
            }

            #{$this}--items-down &
            {
                flex-direction: column;
            }

            .has-enabled-mobile-navbar &
            {
                bottom: $offset + $mobile-menu-height;
                bottom: calc(#{$offset} + var(--mobile-menu-height));

                @media #{$media-desktop}
                {
                    bottom: $offset;
                }
            }

            #{$this}.is-expanded &
            {
                pointer-events: all;
            }
        }

        #{$this}__main-trigger
        {
            @mixin highlight()
            {
                background: var(--theme-color-light);
            }

            @include reset-button();

            font-size: 18px;

            // Safari needs this position: relative rule to override a strange
            // clipping behavior of the shadow of this element... It was
            // appearing after the first hide of the FAB (on scroll).
            position: relative;

            display: flex;
            align-items: center;
            justify-content: center;

            width: $size-normal + $size-x-small;
            height: $size-normal + $size-x-small;

            transition: background-color ease $speed-fast, transform ease $speed-medium;
            pointer-events: all;

            border-radius: 50%;
            background: var(--theme-color);
            box-shadow: 0 3px 5px rgba(black, .2), 0 6px 10px rgba(black, .14), 0 1px 18px rgba(black, .12);

            &,
            &:hover,
            &:focus,
            &:active
            {
                color: var(--button-font-color, white);
            }

            &:focus,
            &:active
            {
                @include highlight();
            }

            @media (hover: hover) and (pointer: fine)
            {
                &:hover
                {
                    @include highlight();
                }
            }

            #{$this}.is-hidden &
            {
                transform: scale(0);
            }
        }

        #{$this}__main-trigger-icon
        {
            // The plus icon from the linear icons library is not centered:
            // these negative margins fix this
            margin-top: -1px;
            margin-right: -1px;

            transition: transform ease $speed-fast;

            // The rotation animation is only ready with a "+" icon, the default
            // one
            #{$this}:not(.has-custom-icon).is-expanded &
            {
                transform: rotateZ(45deg);
            }
        }

        #{$this}__items
        {
            visibility: hidden;

            transition: visibility $trigger-reveal-animation-duration;

            #{$this}.is-expanded &
            {
                visibility: visible;
            }
        }

        #{$this}__item
        {
            margin-bottom: $size-x-small;

            #{$this}--items-down &
            {
                margin-top: $size-x-small;
                margin-bottom: 0;
            }

            &.is-hidden
            {
                display: none;
            }
        }

        #{$this}__trigger
        {
            @include reset-button();

            position: relative;

            display: block;

            cursor: pointer;

            &,
            &:hover,
            &:focus,
            &:active
            {
                color: inherit;
            }

            &:disabled
            {
                cursor: not-allowed;

                opacity: .6;
            }
        }

        #{$this}__trigger-button
        {
            @mixin highlight()
            {
                background: $color-light-light;
            }

            font-size: 18px;

            // Safari needs these two rules to override the strange clipping
            // behavior of the <button> elements (the __trigger element is a
            // <button>)... It was clipping the box-shadow.
            position: relative;
            z-index: 1;

            display: flex;
            align-items: center;
            justify-content: center;

            width: $size-normal;
            height: $size-normal;

            transition: ease $trigger-reveal-animation-duration;
            transition-property: background-color, transform, visibility;
            transform: scale(0);

            color: $text-color;
            border-radius: 50%;
            background: white;
            box-shadow: 0 0 1px rgba(black, .08), 0 3px 5px rgba(black, .16);

            #{$this}__trigger:not(:disabled):focus &,
            #{$this}__trigger:not(:disabled):active &
            {
                @include highlight();
            }

            @media (hover: hover) and (pointer: fine)
            {
                #{$this}__trigger:not(:disabled):hover &
                {
                    @include highlight();
                }
            }

            #{$this}__trigger:disabled &
            {
                background: $color-default-light;
            }

            #{$this}.is-expanded &
            {
                transform: none;
            }
        }

        #{$this}__trigger-label
        {
            @mixin cover()
            {
                visibility: hidden;

                transform: translateY(-50%) translateX($size-x-small);

                opacity: 0;
            }

            @mixin reveal()
            {
                visibility: visible;

                transform: translateY(-50%);

                opacity: 1;
            }

            font-size: 14px;
            line-height: 1;

            position: absolute;
            top: 50%;
            right: 100%;

            margin-right: $size-x-small;
            padding: $size-xx-small $size-x-small;

            transition: ease $trigger-reveal-animation-duration;
            transition-property: transform, opacity, visibility;
            transform: translateY(-50%);
            white-space: nowrap;

            border-radius: 3px;
            background: white;
            box-shadow: 0 0 1px rgba(black, .08), 0 3px 5px rgba(black, .16);

            #{$this}__trigger:disabled &
            {
                background: $color-default-light;
            }

            // If the device can't hover, the labels are always visible when
            // the component is expanded
            @media not all and (hover: hover) and (pointer: fine)
            {
                @include cover();

                #{$this}.is-expanded &
                {
                    @include reveal();
                }
            }

            // Else, if the device can hover, the labels appear only when the
            // __trigger-button is hovered, focused or clicked
            @media (hover: hover) and (pointer: fine)
            {
                @include cover();

                // The animation duration is slower when it's launched by a
                // hover interaction
                transition-duration: $speed-medium;

                #{$this}__trigger-button:hover + &,
                #{$this}__trigger-button:focus + &,
                #{$this}__trigger-button:active + &
                {
                    @include reveal();
                }
            }

            #{$this}__overlay + #{$this}__container &
            {
                font-size: 16px;

                margin: 0;

                border-radius: 0;
                background: none;
                box-shadow: none;
            }
        }

        @media (min-width: $screen-sm-min) {
            #{$this}__buttonAddTask {
                top: 5px;
                position: fixed;
                right: 15px;
            }

            #{$this}__buttonAddTask ul {
                top: 60px;
                position: fixed;
                right: 21px;
            }
        }

            #{$this}__buttonAddTask--dashboard {
                top: 4px;
                position: fixed;
                right: 15px;
                width: 45px;
                height: 45px;
            }
    }
}
