.c-contacts-modal-shares {

  $this: &;

  @at-root {

    #{$this} .modal-footer {
      display: block;
      text-align: center;

      @media #{$media-desktop}
      {
        display: none;
      }
    }

    & .modal-body {
      padding:0;
    }

    #{$this} .modal-content {
      .modal-header > .modal-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }


    #{$this}__searchusers {
      width: 100%;

      .k-input-button {
        margin: 0;
      }
    }

    .k-list-scroller .c-user-column {
      padding:5px !important;
    }

    #{$this}__switch {
      position: absolute;
      right: 13px;
      top: 13px;
      width: 80px;

      span:first-child {
        width: 100%;
      }
    }
  }
}
