.c-course-detail {
  padding: $size-x-small 0;

  &__about {
    text-align: center;
  }

  &__footer {
    border-bottom: 1px solid $color-default-light;
  }

  &__modules {
    margin: 0 0 $size-x-small 0;
  }

  &__footer-info {
    display: flex;
    align-items: baseline;
    padding-bottom: $size-xx-small;
  }

  &__info-title {
    margin-right: $size-xxx-small;
    font-weight: bold;
  }

  &__video {
    max-width: $size-xxx-large;
    max-height: $size-xx-large;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin-bottom: $size-small;
  }

  &__description {
    margin: 0 0 $size-small 0;
    font-size: 15px;
    font-weight: 400;
    padding: 0;

    @media (min-width: $screen-sm-min) {
      margin-left: $size-medium;
      margin-right: $size-medium;
    }
  }
}
