.c-history-content {
  $this: &;
  @at-root {
    #{$this}__month {
      color: #90949c;
      font-size: 16px;
      font-weight: bold;
      margin: 10px;
      text-align: center;
    }

    #{$this}__history {
      border-top: 1px solid $panels-border-color;
      &:hover {
        #{$this}__date {
          visibility: visible;
        }
        #{$this}__action {
          visibility: visible;
        }
      }
      &:last-of-type {
        border-bottom: 1px solid $panels-border-color;
      }
    }

    #{$this}__date {
      visibility: hidden;
      font-size: 11px;
      color: $color-default-light;
    }

    #{$this}__section {
      display: flex;
      position: relative;
      margin-left: -15px;
      margin-right: -15px;
    }

    #{$this}__infos {
      width: 135px;
      padding: 10px 15px;

      @media (min-width: $screen-sm-min) {
        width: 230px;
      }
    }

    #{$this}__user {
      color: $color-info;
    }

    #{$this}__day {
      font-size: 13px;
      font-weight: 600;
      padding: 10px 15px;
      text-transform: uppercase;
      border-bottom: 1px solid $panels-border-color;
      margin-bottom: 10px;

    }

    #{$this}__description {
      padding: 10px 15px;
      vertical-align: top;
      flex: 1;
      margin-right: 30px;
    }

    #{$this}__action {
      position: absolute;
      top: 9px;
      right: 10px;
      visibility: hidden;
    }
  }
}
