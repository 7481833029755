.k-loading-mask{
    display: none !important;
}


.c-artificialintelligence-conversation {
    $this: &;
    $width-column-left: 280px;

    @at-root {

        #{$this}__dropdown-button{

            &.c-btn--more {
                &:hover,
                &:focus,
                &:active {
                    background-color: transparent !important;
                    color: inherit !important;
                    box-shadow: none !important;
                    border-color: transparent !important;
                }
            }
        }
        #{$this}__layout-sidebar {
            @media (max-width: $screen-xs-max) {
                display: block !important;
                position: fixed;
                height: 87.5svh;
                max-height: 100%;
                top: 6svh;
                z-index: 1050;
            }
            width: 0;
            overflow-x: hidden;
            transition: width 0.5s ease;
            border: none !important;
            background-color: light-dark($artificial-intelligence-left-sidebar-bg-color-light, $artificial-intelligence-left-sidebar-bg-color-dark);
        }


        #{$this}__layout-sidebar--open {
            width: 350px;
            @include custom-scrollbar;
            &::-webkit-scrollbar-thumb {
                background-color: light-dark($artificial-intelligence-conversation-scrollbar-bg-color-light,$artificial-intelligence-conversation-scrollbar-bg-color-dark);
            }

            &:hover::-webkit-scrollbar-thumb {
                background-color: light-dark($artificial-intelligence-conversation-scrollbar-bg-color-light,$artificial-intelligence-conversation-scrollbar-bg-color-dark);
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: light-dark($artificial-intelligence-conversation-scrollbar-bg-color-light,$artificial-intelligence-conversation-scrollbar-bg-color-dark);
            }
            @media (max-width: $screen-xs-max) {
                width: 80%;
                left:0;
            }

        }

        #{$this}__container-conversation {
        }

        #{$this}__row-title{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: 10px;
            height: auto;
            padding-left: 10px;
            padding-right: 10px;
            margin-top: 25px;
            font-weight: 600;
            font-size: 13px;
            color: light-dark($artificial-intelligence-texte-sidebar-color-light, $artificial-intelligence-texte-sidebar-color-dark);

        }
        #{$this}__add-row{
            max-width: 100%;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height:56px;
            padding-top: 0;
            padding-left: 10px;
            padding-right: 10px;
            margin-bottom: 0;
            padding-bottom: 0;
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            background-color: light-dark($artificial-intelligence-left-sidebar-bg-color-light, $artificial-intelligence-left-sidebar-bg-color-dark);
            z-index: 186;
        }
        #{$this}__add-link{
            display:flex;
            &-sidebar-switch {
                padding:7.5px;
            }

            &-pencil {
                padding-bottom:7.5px;
                padding-top:7.5px;
                padding-left: 10px;
                padding-right: 10px;
            }

            &:hover{
                background-color: light-dark($artificial-intelligence-left-sidebar-hover-bg-color-light, $artificial-intelligence-left-sidebar-hover-bg-color-dark);
                border-radius: 7.5px;
            }
        }
        #{$this}__add-span-icon{
            font-size: 20px;
            color: light-dark($artificial-intelligence-texte-sidebar-color-light, $artificial-intelligence-texte-sidebar-color-dark);
            cursor: pointer;

            &-sidebar-switch {
                font-size: 17px;
                fill: none !important;
                stroke: light-dark($artificial-intelligence-texte-sidebar-color-light, $artificial-intelligence-texte-sidebar-color-dark);
                stroke-width: 2px !important;
            }

            &-pencil {
                fill: light-dark($artificial-intelligence-texte-sidebar-color-light, $artificial-intelligence-texte-sidebar-color-dark) !important;
            }
            @media(max-width: $screen-xs-max){
                font-size: 25px;
                &-sidebar-switch {
                    font-size: 22px;
                }
            }

        }
        #{$this}__row-conversation{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top:5px;
            margin-bottom:5px;
            margin-left: 10px;
            margin-right: 10px;
            padding: 0;
            height: auto;

            &:hover{
                background-color: light-dark($artificial-intelligence-left-sidebar-hover-bg-color-light, $artificial-intelligence-left-sidebar-hover-bg-color-dark);
                color: light-dark($artificial-intelligence-texte-sidebar-color-light, $artificial-intelligence-texte-sidebar-color-dark);
                border-radius: 5px;

                #{$this}__dropdown{
                    display:block;
                }
                #{$this}__dropdown button{
                    color: light-dark($artificial-intelligence-texte-sidebar-color-light, $artificial-intelligence-texte-sidebar-color-dark);
                }
                #{$this}__dropdown ul>li>a{
                    background-color: light-dark($artificial-intelligence-left-sidebar-dropdown-bg-color-light, $artificial-intelligence-left-sidebar-dropdown-bg-color-dark) !important;
                    color: light-dark($artificial-intelligence-texte-sidebar-color-light, $artificial-intelligence-texte-sidebar-color-dark) !important;

                    &:hover{
                        background-color: light-dark($artificial-intelligence-left-sidebar-hover-bg-color-light, $artificial-intelligence-left-sidebar-hover-bg-color-dark) !important;
                        color: light-dark($artificial-intelligence-texte-sidebar-color-light, $artificial-intelligence-texte-sidebar-color-dark) !important;
                    }
                }
                #{$this}__input{
                    color: light-dark($artificial-intelligence-texte-sidebar-color-light, $artificial-intelligence-texte-sidebar-color-dark);
                }
            }
        }
        #{$this}__is-active-element{
            background-color: light-dark($artificial-intelligence-left-sidebar-hover-bg-color-light, $artificial-intelligence-left-sidebar-hover-bg-color-dark);
            color: light-dark($artificial-intelligence-texte-sidebar-color-light, $artificial-intelligence-texte-sidebar-color-dark);
            border-radius: 5px;

            #{$this}__dropdown{
                display:block;
                background-color: light-dark($artificial-intelligence-left-sidebar-hover-bg-color-light, $artificial-intelligence-left-sidebar-hover-bg-color-dark);

            }

            #{$this}__dropdown ul>li>a{
                background-color: light-dark($artificial-intelligence-left-sidebar-dropdown-bg-color-light, $artificial-intelligence-left-sidebar-dropdown-bg-color-dark) !important;
                color: light-dark($artificial-intelligence-texte-sidebar-color-light, $artificial-intelligence-texte-sidebar-color-dark) !important;

                &:hover{
                    background-color: light-dark($artificial-intelligence-left-sidebar-hover-bg-color-light, $artificial-intelligence-left-sidebar-hover-bg-color-dark) !important;
                    color: light-dark($artificial-intelligence-texte-sidebar-color-light, $artificial-intelligence-texte-sidebar-color-dark) !important;
                }
            }
            #{$this}__dropdown button{
                color: light-dark($artificial-intelligence-texte-sidebar-color-light, $artificial-intelligence-texte-sidebar-color-dark);
            }
            #{$this}__input{
                color: light-dark($artificial-intelligence-texte-sidebar-color-light, $artificial-intelligence-texte-sidebar-color-dark);
            }

        }
        #{$this}__div-is-editing{
            display:flex;
            align-items: center;
            max-width:100%;
            width:100%;
            position:relative;
        }
        #{$this}__div-content {
            right: 0;
            top: 0;
            margin: 0;
            width:100%;
            max-width:100%;
            overflow-x: hidden;

            &:hover{
                overflow-x: hidden;
            }
            &:active{
                overflow-x: hidden;
            }

            .editable-input{
                width: 90% !important;
            }
        }
        #{$this}__dropdown {
            position: absolute;
            top: 0;
            right: 0;
            display: none;
            background-color: light-dark($artificial-intelligence-left-sidebar-hover-bg-color-light, $artificial-intelligence-left-sidebar-hover-bg-color-dark);
            line-height: 2.75rem;
            border-radius: 5px;

        }
        #{$this}__title {
            font-size: 15px;
            padding-left: 4%;
            padding-right: 4%;
            padding-top: 2%;
            padding-bottom: 2%;
            color: light-dark($artificial-intelligence-texte-sidebar-color-light, $artificial-intelligence-texte-sidebar-color-dark);
            overflow-x: hidden;
            width: 100vw;
            cursor: pointer;
            &::-webkit-scrollbar {
                display: none;
            }

        }
        #{$this}__title-information {
            font-size: 6px;
            max-width: 100%;
            width: 100%;
            padding-left: 4%;
            padding-right: 4%;
        }
        #{$this}__input {
            font-size: 14px;
            max-width: 100%;
            width: 85%;
            margin-left: 4%;
            margin-right: 4%;
        }

        #{$this}__endless-list-conversation{
            height:30px;
        }
    }
}
