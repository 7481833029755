.c-ia-menu {
    $this: &;

    @at-root {
        #{$this}__overlay {
            position: fixed;
            display: none;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            z-index: 9010;
            cursor: pointer;
        }

        #{$this}__loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 9020;
        }

        #{$this}__loader-spinner {
            border: 4px solid rgba(0, 0, 0, 0.1);
            border-left-color: #fff;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            animation: spin 1s linear infinite;
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }

        #{$this}__context{
            color: #434a54 !important;
            font-size: 15px;
            list-style: none;
            margin: 0;
            border-radius: 5px;
            position: fixed;
            min-width: 16em;
            z-index: 9000;
            background: linear-gradient(145deg, var(--theme-color-lighten90), var(--theme-color-lighten90));
            box-shadow: 2px 5px 16px -4px #141321;
            will-change: transform, opacity, filter;
            transition: transform, opacity, visibility, filter;
            transition-duration: 0.5s, 0.2s, 0.4s, 0.3s;
            transition-delay: 0.1s, 0s, 0.4s, 0.2s;
            transition-timing-function: ease;
            transform: rotate3d(-1, -1, 0, 30deg) scale(1);
            transform-origin: 0 0;
            opacity: 0;
            visibility: hidden;
            filter: blur(6px);
            padding: 0 !important;

            .div {
                border-bottom: 1px solid rgba(255, 255, 255, 0.16);
                padding: 0;
                margin-top: 0.3em;
                margin-bottom: 0.35em;
            }
            .f {
                font-style: normal;
                position: absolute;
                transform: translateX(-2.4em);

                &[class*=chevron-right] {
                    right: 0;
                    transform: none;
                }
                &.f-circle {
                    fill: red;
                }
            }

            &, *{
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                cursor: default;
            }
            &.is-visible {
                opacity: 1;
                transform: none;
                transition-delay: 0s, 0s, 0s, 0s;
                visibility: visible;
                filter: none;
            }
            &.sub {
                background: var(--theme-color-lighten90);
                width: -webkit-max-content;
                width: -moz-max-content;
                width: max-content;
                min-width: 14em;
                left: 100%;
                top: -0.4em;
                transform: translateX(-0.7em);
                transition: transform, opacity, width, min-width, visibility;
                transition-timing-function: ease;
                transition-duration: 0.4s, 0.25s, 0.15s, 0.15s, 0.01s;
                transition-delay: 0.4s, 0.25s, 0.3s, 0.3s, 0.35s;
                overflow: hidden;
                filter: none;

                &.changeStylizeText{
                    top: 12.5%;
                }
                &.changeOptimizedText{
                    top: 37.5%;
                }
                &.changeTonality{
                    top: 50%;
                }
                &.changeLanguage{
                    top: 62.5%;
                }

                &.oppositeX {
                    right: 100%;
                    left: auto;
                    transform: translateX(0.7em);
                }
                &.oppositeY {
                    top: auto;
                    bottom: auto;
                }

                .f{
                    transform: translateX(-2.25em);
                }

                &#{$this}__loader-sub{
                    visibility: hidden !important;
                }
            }

            > li {
                padding: 0.3em 1.5em 0.35em 2.8em;
                border-radius: 3px;
                position: relative;
                height: 40px;
                display: flex;
                align-items: center;

                i {
                    font-style: normal;
                    text-decoration: underline;
                    -webkit-text-decoration-color: rgba(255, 255, 255, 0.35);
                    text-decoration-color: rgba(255, 255, 255, 0.35);
                }

                .f{
                    opacity: 0.75;
                    transition: all 0.2s ease;
                }

                &.hilight {
                    font-weight: 500;
                    padding-bottom: 0.5em;
                    color: white;

                    > .f {
                        opacity: 1;
                    }
                }
                &.nope {
                    color: rgba(255, 255, 255, 0.3);
                }
                &.active {
                    -webkit-animation: flash 0.5s ease 1;
                    animation: flash 0.5s ease 1;
                }

                &:hover{
                    background-color: var(--theme-color);
                    > .f {
                        opacity: 1;
                    }
                }
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    border-radius: 3px;
                    z-index: -1;
                    background-color: rgba(97, 97, 97, 0.37);
                    mix-blend-mode: color-dodge;
                    transition: opacity 0.15s cubic-bezier(0.55, 0.06, 0.68, 0.19);
                    opacity: 0;
                }
                &:not(.context > li.nope) {
                    .sub{
                        opacity: 1;
                        transform: translateX(0);
                        transition-delay: 0.2s, 0.25s, 0.2s, 0.2s, 0s;
                        border-radius: 0 3px 3px 3px;
                        visibility: hidden;
                    }

                    &:hover {
                        color: white;
                        &:before {
                            opacity: 1;
                            transition: opacity 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
                        }
                        .sub {
                            opacity: 1;
                            transform: translateX(0);
                            visibility: visible;
                        }
                    }
                }
            }
        }

        #{$this}__*,
        #{$this}__*:after,
        #{$this}__*:before {
            box-sizing: border-box;
        }

        #{$this}__hide {
            display: none;
        }

        #{$this}__f {
            width: 1.2em;
            height: 1.2em;
            stroke: currentColor;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
            fill: none;
        }

        #{$this}__colors {
            position: absolute;
            bottom: 1.5em;
            left: 0;
            padding: 0;
            margin: 30px 30px 0.2em;
            text-align: left;

            h4 {
                margin: 1em 0;
                font-size: 0.875rem;
            }

            input {
                padding: 0;
                border: none;
                border-radius: 3px;
                outline: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
            }
        }
    }
    @-webkit-keyframes flash {
        0% {
            background: rgba(255, 255, 255, 0);
        }
        7% {
            background: rgba(255, 255, 255, 0.2);
        }
        14% {
            background: rgba(255, 255, 255, 0);
        }
        21% {
            background: rgba(255, 255, 255, 0.3);
        }
    }

    @keyframes flash {
        0% {
            background: rgba(255, 255, 255, 0);
        }
        7% {
            background: rgba(255, 255, 255, 0.2);
        }
        14% {
            background: rgba(255, 255, 255, 0);
        }
        21% {
            background: rgba(255, 255, 255, 0.3);
        }
    }
}
