.c-documents-selector {
  $this: &;

  @at-root {

    // COPIE de Documents pour la gestion de taille -> voir Jon si doit etre modifié
    #{$this}--standalone
    {
      min-height: calc(100vh - #{$mobile-menu-height} - #{$header-height});
      min-height: calc(#{vh(100)} - var(--mobile-menu-height) - #{$header-height});

      border-radius: 0;

      @media (min-width: $screen-sm-min)
      {
        min-height: 100vh;
        min-height: calc(#{vh(100)});
      }

      @media (min-width: $screen-md-min)
      {
        display: flex;
        flex-direction: column;
      }
    }


    #{$this}__content
    {
      padding-top: 0px;
      #{$this}--standalone &
      {
        display: flex;
        flex-direction: column;

        padding: 0;

        @media (min-width: $screen-md-min)
        {
          height: calc(100vh - 55px);
          height: calc(#{vh(100)} - 55px);
        }

      }
    }


    #{$this}__owner {
      display: none;
      width:190px ;
      flex-shrink: 0;

      height:100%;
      line-height: 35px;

      @media (min-width: $screen-lg-min)
      {
        display: block;
      }
    }



    #{$this}__column-documents { // colonne des documents

      border:none;
      overflow-x:hidden !important;

      padding-left: 0px;
      padding-right:0px;

      @media (min-width: $screen-md-min)
      {
        border: 1px $panels-border-color;
        border-right-style: solid;
      }

      @media #{$media-desktop}
      {
        height:calc(100vh - 92px);
      }
    }

    #{$this}__column-documents-ios {
      height: calc((var(--vh, 1vh) * 100) - 75px);
    }




    #{$this}__column-documents > .has-loader {
      overflow: hidden !important;
    }
    // end copie de Documents





    #{$this}__container {
      min-height: 0;
    }

    #{$this}__sidebar-content {
      padding:0 !important;
    }

    #{$this}__column-tree {
      overflow-y: auto;

      display: none;
      @media (min-width: $screen-md-min)
      {
        display: initial;
      }
    }




    // start BREADCRUMB
    #{$this}__mobile-bread {
      white-space: nowrap ;
      height:35px;
      min-width: 0;
      flex-grow: 1;

      font-size:14px;

      display: block;
      @media (min-width: $screen-md-min) {display: none;}
    }

    #{$this}__mobile-bread-separator {
      font-weight: bold;
      padding: 0 9px;
    }

    #{$this}__mobile-bread-text {
        border-bottom:solid !important;
        border-bottom-width:1px !important;
        border-color: $color-light !important;
        background: white;
        z-index: 10;

        padding:$size-xxx-small;
        padding-top: $size-xx-small;

        height:35px;
        display: flex;

        width: 100%;
        position: fixed;

        @media (min-width: $screen-sm-min) {
          position: relative;
        }
    }

    #{$this}__desktop-bread {

      display: none;
      @media (min-width: $screen-md-min) {display: block;}
    }
    // end BREADCRUMB





    // colonne LISTE DES DOCS SELECTIONNES
    #{$this}__column-review {
      display: none;
      @media (min-width: $screen-md-min) {display: block;}
    }

    #{$this}__column-review-info {
      padding: $size-xxx-small;
    }
    // end colonne LISTE DES DOCS SELECTIONNES



    #{$this}__btn-selected-files  {
      display: inline-flex;
      @media (min-width: $screen-md-min) {display: none;}
    }

    #{$this}__btn-save-all  {
      display: none;
      @media (min-width: $screen-md-min) {display: inline-flex;}
    }



    /**
      Surcharge spécifique de la modale pour être affichée en fullscreen
      Utilisée par le composant uniquement sur IOS
     */
    #{$this}__modal {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
      padding:0;
      margin:0;
      border:none;
      border-radius: 0;
    }

    #{$this}__modal .c-sidebar__footer {
      display: none;
    }

    #{$this}__modal-dialog {
      position: fixed;
      margin: 0;
      width: 100%;
      height: 100%;
      padding: 0;
      border-radius: 0;
      overflow: auto;
    }

    #{$this}__modal-content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: none;
      box-shadow: none;
      padding:0;
      border-radius: 0;
    }

    #{$this}__modal-body {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      overflow: auto;
      padding:0;
    }

    #{$this}__modal-footer {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 9px;
      padding-bottom: unquote('max(9px, env(safe-area-inset-bottom))');
      background: white;
      text-align: center;
    }
    // end surcharge



    #{$this}__checkbox-label-notify {
      margin-left:0;
      line-height:12px;
    }


  }


}

.sidebarSelectorfiles {
  display: flex;
  @media (min-width: $screen-md-min) {display: none;}
}
