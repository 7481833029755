.c-timeline-publisher-add-file
{
    $this: &;

    font-weight: bold;

    padding: 0;

    color: var(--theme-color);
    border: none;
    background: none;

    @at-root
    {
        #{$this}__icon
        {
            font-size: $size-x-small;

            width: $size-small;
            height: $size-small;
            padding: $size-xxx-small;

            color: white;
            border-radius: 50%;
            background-color: var(--theme-color);
        }
    }
}
