.c-main-menu
{
    $this: &;
    //$icon-size: $size-normal - $size-xx-small;
    $icon-size: $size-light - $size-xx-small;
    $icon-horizontal-offset: ($main-menu-width - $icon-size) / 2;
    $icons-offset: $size-small;
    $icons-background-offset: $size-xxx-small;
    $icon-padding: ($icons-offset / 2) - ($icons-background-offset / 2);
    $icon-padding-vertical: $icon-padding - ($icon-padding/4);

    @include layer-promotion();

    position: fixed;
    z-index: $z-index-5;
    top: 0;

    display: none;
    flex-direction: column;
    justify-content: space-between;

    width: $main-menu-width;
    width: var(--main-menu-width);
    height: 100%;
    padding: 0;
    padding-left: env(safe-area-inset-left);

    background-color: white;
    box-shadow: 0 0 1px rgba(black, .08), 0 3px 8px rgba(black, .08);

    &.has-open-sidebar
    {
        z-index: $z-index-10;
    }

    @media #{$media-desktop}
    {
        display: flex;
    }

    @at-root
    {
        #{$this}__items
        {
            overflow-x: hidden;
            overflow-y: auto;

            max-height: 100%;
        }

        #{$this}__item
        {
            display: flex;
            align-items: center;
            justify-content: center;

            border: none;
        }

        #{$this}__separator
        {
            height: $size-x-small;
        }

        #{$this}__trigger
        {
            @include reset-button();

            position: relative;

            display: flex;
            align-items: center;

            transition: background-color ease $speed-medium;

            &:hover,
            &:focus
            {
                text-decoration: none;
            }

            #{$this}__item > &
            {
                justify-content: center;

                height: auto;
                margin: ($icons-background-offset / 2) 0;
                padding: $icon-padding-vertical $icon-padding;

                border-radius: 5px;

                &--extension
                {
                    &.is-active
                    {
                        background: var(--theme-color-l95);
                    }

                    &:hover,
                    &:focus,
                    &:active
                    {
                        background: var(--theme-color-l90);
                    }
                }
            }

            #{$this}__items:first-child > #{$this}__item:first-child > &
            {
                margin-top: 0;
                padding-top: $icon-horizontal-offset;
            }
        }

        // Used to avoid whitespace issues
        #{$this}__trigger-icon-wrapper
        {
            display: flex;
        }

        #{$this}__trigger-icon
        {
            width: $icon-size;
            height: $icon-size;

            transition: color ease $speed-fast;

            color: $color-black;

            #{$this}__item--home &
            {
                color: white;
            }

            #{$this}__trigger:hover &,
            #{$this}__trigger:focus &,
            #{$this}__trigger.is-active &
            {
                color: var(--theme-color);

                #{$this}__item--home &
                {
                    color: white;
                }
            }
        }
        #{$this}__artificial-intelligence{

            font-size: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        #{$this}__trigger-title
        {
            line-height: 1;

            position: absolute;
            top: 0;
            left: 100%;

            visibility: hidden;

            margin-left: $size-xx-small;
            padding: $size-xx-small $size-x-small;

            transition: ease $speed-medium;
            transition-property: transform, visibility, opacity;
            transform: translateX(-$size-x-small);
            white-space: nowrap;
            text-transform: uppercase;

            opacity: 0;
            color: white;
            border-radius: 5px;
            background-color: var(--theme-color);

            &:before
            {
                // Used to avoid some scaling issues with the browser, when
                // the OS scaling is above 1.0 / 100 %
                $scaling-fix: 1px;

                content: '';

                position: absolute;
                top: 50%;
                left: (0 + $scaling-fix);

                width: 0;
                height: 0;

                transform: translate(-100%, -50%);

                border-width: ($size-xxx-small + $scaling-fix) ($size-xxx-small + $scaling-fix) ($size-xxx-small + $scaling-fix) 0;
                border-style: solid;
                border-color: transparent var(--theme-color) transparent transparent;
            }

            #{$this}__trigger:hover ~ &,
            #{$this}__trigger:focus ~ &,
            #{$this}__trigger.is-active ~ &
            {
                color: var(--theme-color);
            }

            #{$this}__trigger:hover ~ &,
            #{$this}__trigger:focus ~ &
            {
                visibility: visible;

                transform: translateX(0);

                opacity: 1;
                color: white;
            }
        }

        #{$this}__dropdown
        {
            top: 0;
            left: $main-menu-width;
            left: var(--main-menu-width);

            margin-top: 0;
            margin-left: 3px;
        }
    }
}
