.c-tchat-filelist-item {
  position: relative;
  width: $size-xx-large;

  &__container {
    display: flex;
    align-items: center;
    border: 1px solid #e1e2e9;
    border-radius: $size-xx-small;
    padding: $size-xx-small;
  }

  &__icon-container {
    color: var(--theme-color);
  }

  &__icon {
    width: 2.5em;
    height: 2.5em;
  }

  &__text-container {
    margin-left: $size-xx-small;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    min-width: 0;
  }

  &__text-content {
    width: 100%;
    max-width: 200px;
  }

  &__text {
    font-size: 14px;
    margin: 0;
    margin-bottom: $size-xxx-small;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--subtitle {
      margin-bottom: 0;
      font-weight: normal;
    }
  }

  &__delete {
    color: var(--theme-color);
    cursor: pointer;
  }

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #e1e2e9;
    border-radius: $size-xx-small;
    background-color: rgba(0, 0, 0, 0.6);
    cursor:wait;
  }
}
