.c-files-list {
  &__name {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__icon {
    font-size: $size-small;
    margin-right: $size-x-small;
  }



  &__timeline img {
    border:solid;
    border-width:1px;
    border-radius: 3px;
    border-color:$color-light;
  }
}