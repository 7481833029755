.c-tasks-menu {
  $this: &;

  @at-root {
    #{$this}__inline-trigger {
      position: absolute;
      right: 30px;
      z-index: 2;
      top: 1px;
      cursor: pointer;
      visibility: hidden;
    }

    @media(min-width: $screen-md-min) {
      #{$this}__swipe:hover .c-tasks-menu__button-list-trigger {
        visibility: visible;
      }
    }

    #{$this}__swipe {
      height: 50px;
      &--item, .item-swipe {
        display:flex!important;
        align-items: center;
        background: white;
        border-radius: 3px;
        padding: 5px;
        margin: 5px 40px 5px 5px;
        cursor: pointer;
      }
      @media (min-width: 768px) {
        height:unset;
      }
    }

    #{$this}__item-title-wrapper {
      padding: 10px 0 5px 0;
      margin-left: $size-x-small;

      #{$this}__main-title {
        font-size: 17px;
        font-weight: 500;
        margin: 0 auto;
        color: $text-color;

        @media (min-width: 768px) {
          font-size: 14px;
        }
      }
    }

    #{$this}__group {
      &--folder {
        margin: 0 0 0 10px;
      }

      &--teams {
        margin: 0 0 0 30px;

        @media (min-width: 768px) {
          margin: 0 0 0 20px;
        }
      }

      #{$this}__item {
        padding-left: 30px;
      }
    }

    #{$this}__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $size-xxx-small $size-xxx-small $size-xxx-small $size-xx-small;
      margin: 5px;
      cursor: pointer;
      border-radius: 3px;
      height: 40px;

      &:hover {
        background: rgba(#374191, 0.05);
        margin: 5px;
      }

      &:active {
        background: rgba(#374191, 0.1);
        margin: 5px;
      }

      &.is-active {
        color: black;
        background: #F5F5F5;

        #{$this}__title {
          font-weight: 500;
          color: black !important;
        }
      }

      .pr-plus {
        font-size: 31px;
        color: var(--theme-color);
        border-radius: 50px;
        margin-right: 0;
        top: 5px;
        position: relative;
        padding: 3px;
        cursor: pointer;
        border: solid 2px var(--theme-color);
        stroke-width: 3px;

        transition: all ease $speed-medium;

        &:hover {
          background-color: var(--theme-color);
          color: white;
        }

       @media (min-width: 768px) {
         font-size: 23px;

       }
      }

      &--list {
        padding: 5px 2px 5px 9px;
      }

      @media (min-width: 768px) {
        height: 28px;
      }
    }

    #{$this}__listInFolder {
      align-items: center;
      justify-content: space-between;
      padding: $size-xx-small $size-x-small;
      cursor: pointer;
      padding-left: 60px;

      &:hover {
        background: rgba(#374191, 0.05);

        #{$this}__title {
          font-weight: 500;
        }
      }

      &:hover .u-more-trigger {
        visibility: visible;
      }

      &.is-active {
        color: var(--theme-color);

        #{$this}__title {
          font-weight: normal !important;
          color: var(--theme-color);
        }
      }
    }

    #{$this}__folder-menu-cross {
      display: none;
    }

    #{$this}__title-container {
      display: flex;
      align-items: center;

      .pr-list {
        position: relative;
        top: 1px;
      }
    }

    #{$this}__title {
      font-size: 17px;
      font-weight: normal;
      margin: 0;
      color: $text-color;

      &--list {
        width: calc(100% - 10vw);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        align-items: center;
        margin-left: 17px;

        @media (min-width: 768px) {
          width: calc(100% - 60px);
        }
    }

    &--teams {
      margin-left: $size-xx-small;
    }

    @media (min-width: 768px) {
      font-size: 14px;
    }
  }


  #{$this}__icon {
    font-size: 23px;
    transform: translateY(-2px);
    margin-right: 19px;
    stroke-width: 0.5px;

    @media (min-width: $screen-sm-min) {
      font-size: 19px;
      margin-right: 9px;
    }

    &--rotate {
      transition: transform 0.2s ease;
      font-size: 14px;
    }
  }

    #{$this}__iconStar, #{$this}__iconStarEmpty {
      font-size: 31px;
      position: absolute;
      right: 9px;
      z-index: 2;
      top: 10px;
      display: none;
      cursor: pointer;

      @media (min-width: 768px) {
        font-size: 1.4em;
        top: 3px;
      }
    }

  #{$this}__iconStar {
    color: #efd163;
    display: none;
  }

  #{$this}__iconStarEmpty {
    display: inline-block;
    color: #434a54;
    visibility: visible;

    &:hover .u-more-trigger {
      visibility: visible;
    }

    @media (min-width: 768px) {
      visibility: hidden;
    }
  }

  #{$this}__icon--folderTasklist {
    vertical-align: sub;
  }

  #{$this}__chevron-container {
    width: 12px;
    height: 12px;
    margin-right: $size-xx-small;
  }

  #{$this}__chevron {
    width: 100%;
    height: 100%;
    transition: transform ease $speed-medium;

    &--down {
      transform: rotate(90deg);
    }
  }

  #{$this}__more {
    display: flex;
    justify-content: center;
    text-decoration: underline;
    padding: $size-xx-small 0;
    color: $text-color;
  }

  #{$this}__form-group {
    margin: $size-xx-small $size-xxx-small $size-xxx-small 35px;
  }
}

}
