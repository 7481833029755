/**
 * Button class. Have to be used on every action button or link.
 *
 * It has two type of modifiers:
 *  - size
 *  - color
 *
 * When used as bare, without any modifier, it has the default color and the
 * default size.
 *
 * @example html - Usage as default
 * <button class="c-btn">Action</button>
 *
 * @example html - Usage with size modifier
 * <button class="c-btn c-btn--small">Action</button>
 *
 * @example html - Usage with color modifier
 * <button class="c-btn c-btn--primary">Action</button>
 *
 * @example html - Usage with size and color modifier
 * <button class="c-btn c-btn--small c-btn--primary">Action</button>
 */

/**
 * 1. Make sure text is always vertically and horizontally centered.
 * 2. Used to always take at least a certain width.
 * 3. When it's larger than the min-width, a padding add some air.
 * 4. Force all button-styled elements to appear clickable.
 */

.c-btn-clear
{
    transition: background-color ease $speed-fast;
    border:none;
    background: #ffffff;

    $this: &;

    @at-root
    {
        #{$this}:hover
        {
            text-decoration: none;
            color: white;
            background-color: lighten($color-default, 10%);
        }
    }
}


.c-btn
{
    $this: &;

    line-height: 1;

    position: relative;

    display: inline-flex; /* [1] */
    align-items: center; /* [1] */
    justify-content: center; /* [1] */

    min-width: $size-large; /* [2] */
    height: $size-normal;
    padding: $size-xxx-small 10px; /* [3] */

    cursor: pointer; /* [4] */
    user-select: none;
    transition: background-color ease $speed-fast;
    text-transform: uppercase;

    color: white;
    border: 1px solid transparent;
    border-radius: 3px;
    background: none;
    background-color: $color-default;





    ///
    /// We apply the border-radius on the button itself and on its filters (in
    /// pseudo-elements)
    ///

    @at-root
    {
        /// ====================================================================
        /// States:
        ///  - :disabled
        ///  - :hover
        ///  - :focus
        ///  - :active
        ///

        #{$this}:disabled
        {
            cursor: not-allowed;
            background-color: lighten($color-default, 20%);
        }

        ///
        /// In case of this c-btn class is used on a link
        ///
        #{$this}:hover,
        #{$this}:focus,
        #{$this}:active
        {
            text-decoration: none;

            color: white;
        }

        ///
        /// Cursor action states appearance.
        /// If the button is disabled, no state can change its appearance.
        ///
        #{$this}:not(:disabled)
        {
            &:hover,
            &:focus,
            &:active
            {
                background-color: $color-default-light;
            }
        }

        /// ====================================================================
        /// Size modifiers
        ///
        #{$this}--small
        {
            font-size: 12px;

            min-width:$size-medium;
            height: $size-small;

            & span {
                font-size:12px;
                vertical-align: text-top;
            }

            & .caret {
                vertical-align: initial !important;
                margin-bottom: 4px;
            }
        }

        #{$this}--mini
        {
            font-size: 9px;
            height: 17px;
            min-width: 45px;
            padding: 0;
        }

        #{$this}--icon-only
        {
            min-width: 52px;
        }

        #{$this}--icon-only svg {
            font-size: 20px;
        }


        //
        // Icon only with round
        //
        #{$this}--icon-only-round
        {
            border-radius: 50%;

            min-width: auto;
            padding: 0;

            flex-shrink: 0;
            flex-grow: 0;

            width: $size-small;
            height: $size-small;

            svg {
                font-size: 14px;
            }
        }


        /// ====================================================================
        /// Special modifiers
        ///

        ///
        /// Code text appearance
        ///
        #{$this}--code
        {
            font-family: monospace;

            text-transform: none;
        }



        ///
        /// More button
        ///
        #{$this}--more {
            padding: 0;
            min-width: 32px;
            height: 20px;
            background-color: transparent;

            &,
            &:hover,
            &:focus,
            &:active
            {
                color: $text-color;
            }

            &::after {
                content: '';
                background-color: currentColor;
                border-radius: 50%;
                width: 4px;
                height: 4px;
                box-shadow: -7px 0 0 0 currentColor, 7px 0 0 0 currentColor;
            }

            &:not(:disabled)
            {
                &:hover,
                &:focus,
                &:active
                {
                    background: rgba(black, 0.05);
                }
            }
        }

        #{$this}--more-above-primary
        {
            &,
            &:hover,
            &:focus,
            &:active
            {
                color: var(--button-font-color);
            }

            &:not(:disabled)
            {
                &:hover,
                &:focus,
                &:active
                {
                    background: rgba(var(--button-font-rgb-color), 0.2);
                }
            }
        }

        ///
        /// Fake button
        ///
        #{$this}--fake {
            background-color: transparent;
            color: $color-default;
            font-size: $size-x-small;
            text-transform: none;
            height: 30px;

            &:not(:disabled):hover,
            &:not(:disabled):focus,
            &:not(:disabled):active
            {
                background-color: $color-default;
                border-color: transparent;
                color: #e6e9ed;
            }
        }

        /// ====================================================================
        /// Color modifiers
        ///

        ///
        /// No color
        ///
        #{$this}--blank {
            background-color: transparent;

            &:not(:disabled):hover,
            &:not(:disabled):focus,
            &:not(:disabled):active
            {
                background-color: transparent;
                border-color: transparent;
            }
        }

        ///
        /// Mixin to create the c-btn color modifiers
        ///
        @mixin c-btn--color($bg-color, $bg-color-hover, $etat:'')
        {
            background-color: $bg-color;

            &:disabled {
                background-color: lighten($bg-color, 25%);
            }

            &:not(:disabled):active,
            &:not(:disabled):focus {
                background-color: darken($bg-color-hover, 25%);
            }

            ///
            /// Cursor action states appearance.
            /// Again, if the button is disabled, no state can change its
            /// appearance.
            ///
            &:not(:disabled):hover,
            {
                background-color: darken($color-light, 10%);
            }
            &:not(:disabled):focus,
            &:not(:disabled):active {
                background-color: darken($color-light, 15%);
            }

            ///
            /// In case of light background color, we apply a dark text color
            ///
            @if (lightness($bg-color) > 80)
            {
                &,
                &:hover,
                &:focus,
                &:active
                {
                    color: $color-dark;
                }

                &:disabled {
                    background-color: lighten($bg-color, 5%);
                }
            }

            @if (lightness($bg-color) < 30)
            {
                &:disabled {
                    background-color: lighten($bg-color, 40%);
                }
            }


            @if ($etat == 'inverse') {
                border:solid;
                border-width: 1px;
                border-color: $bg-color ;
                background:#ffffff ;
                color: $bg-color  ;

                &:not(:disabled):hover
                {
                    //background-color: lighten($bg-color-hover, 20%) ;
                    color: $bg-color  ;
                }
            }
        }
        #{$this}--primary
        {
            background-color: var(--theme-color);
            color: var(--button-font-color);

            &:disabled {
                background-color: var(--button-color-disabled);
                &:hover{
                    color: var(--button-font-color);
                }
            }

            &:not(:disabled):active,
            &:not(:disabled):focus {
                border-color: var(--theme-color-darken20);
            }

            &:not(:disabled):hover,
            &:not(:disabled):focus,
            &:not(:disabled):active
            {
                background-color: var(--theme-color-light);
                color: var(--button-font-color);
            }

            &-inverse{
                border:solid;
                border-width: 1px;
                border-color: var(--theme-color) ;
                background:#ffffff ;
                color: var(--theme-color);

                &:not(:disabled)
                {
                    &:hover,
                    &:focus,
                    &:active
                    {
                        background-color: var(--button-color-disabled);
                        color: var(--button-font-color) ;
                        border: none;
                    }
                }
            }

            &-ghost{

                &:not(:disabled)
                {
                    border:solid 1px;
                    text-transform: none;
                    color: var(--theme-color);

                    &,
                    &:focus,
                    &:active
                    {
                        border-color: transparent ;
                        background-color: transparent;
                    }

                    &:hover
                    {
                        border-color: var(--theme-color) ;
                        background-color: transparent;
                    }
                }
            }
        }

        #{$this}--inverse
        {
            @include c-btn--color($color-default, $color-default, 'inverse');
        }


        #{$this}--success
        {
            @include c-btn--color($color-success, $color-success-light);
        }

        #{$this}--success-inverse
        {
            @include c-btn--color($color-success, $color-success-light, 'inverse');
        }

        #{$this}--info
        {
            @include c-btn--color($color-info, $color-info-light);
        }

        #{$this}--info-inverse
        {
            @include c-btn--color($color-info, $color-info-light,'inverse');
        }

        #{$this}--warning
        {
            @include c-btn--color($color-warning, $color-warning-light);
        }

        #{$this}--warning-inverse
        {
            @include c-btn--color($color-warning, $color-warning-light,'inverse');
        }

        #{$this}--danger
        {
            @include c-btn--color($color-danger, $color-danger-light);
        }

        #{$this}--danger-inverse
        {
            @include c-btn--color($color-danger, $color-danger-light,'inverse');
        }

        #{$this}--light
        {
            @include c-btn--color($color-light, $color-light-light);
        }

        #{$this}--light-inverse
        {
            @include c-btn--color($color-light, $color-light-light,'inverse');
        }

        #{$this}--dark
        {
            @include c-btn--color($color-dark, $color-dark-light);
        }

        #{$this}--dark-inverse
        {
            @include c-btn--color($color-dark, $color-dark-light,'inverse');
        }

        #{$this}--mint
        {
            @include c-btn--color($color-mint, $color-mint-light);
        }

        #{$this}--mint-inverse
        {
            @include c-btn--color($color-mint, $color-mint-light,'inverse');
        }

        #{$this}--purple
        {
            @include c-btn--color($color-purple, $color-purple-light);
        }

        #{$this}--purple-inverse
        {
            @include c-btn--color($color-purple, $color-purple-light,'inverse');
        }


        #{$this}--pink
        {
            @include c-btn--color($color-pink, $color-pink-light);
        }

        #{$this}--pink-inverse
        {
            @include c-btn--color($color-pink, $color-pink-light,'inverse');
        }

        #{$this}--red
        {
            @include c-btn--color($color-red, $color-red-light);
        }

        #{$this}--red-inverse
        {
            @include c-btn--color($color-red, $color-red-light, 'inverse');
        }
    }
}
