.k-loading-mask{
    display: none !important;
}
.k-i-arrow-up::before {
    font-size: 26px;
    @media (max-width: $screen-xs-max) {
        font-size: 22px;
    }
}
.k-i-stop::before {
    font-size: 15px;
    @media (max-width: $screen-xs-max) {
        font-size: 11px;
    }
}



.c-artificialintelligence-footer {
    $this: &;
    $width-column-left: 280px;

    @at-root {
        #{$this}__row-button{
            display: flex;
            justify-content: center;
            position:relative;
            bottom: 0;

            &-is-empty{
                position: absolute;
                bottom: calc(45% - var(--dynamic-artificial-intelligence-footer-position-bottom)) !important;
                width:100%;
                display:flex;
                flex-direction: column;
                align-items:center;
            }

            h1 {
                font-size: 31px;
                color: light-dark($artificial-intelligence-texte-chat-color-light,$artificial-intelligence-texte-chat-color-dark);
                margin-left: 0;
                margin-right: 0;
                margin-top: 0;
                padding-top: 0;
                padding-bottom: 0;
                padding-left: 10px;
                padding-right: 10px;
                text-align: center;
                margin-bottom: 4vh;
                font-weight: bold;
            }
        }

        #{$this}__bloc-send-button{
            border-radius:25px;
            border:none;
            display: flex;
            align-items: flex-end;
            padding-top:5px;
            padding-bottom:5px;
            padding-left:10px;
            padding-right:5px;
            margin:0;
            background-color: light-dark($artificial-intelligence-message-bg-color-light, $artificial-intelligence-message-bg-color-dark);
            color: light-dark($artificial-intelligence-texte-chat-color-light,$artificial-intelligence-texte-chat-color-dark);
            width:760px;
            min-height:44px;

            @media (max-width: 1124px) {
                width:75%;
            }
            @media (max-width: 800px) {
                width:95%;
            }
        }

        #{$this}__send-textarea{
            border:none;
            border-radius: 5px;
            margin-right: 5px;
            width: 95%;
            min-height:33px;
            max-height: 350px;
            overflow-y: auto;
            color: light-dark($artificial-intelligence-texte-chat-color-light,$artificial-intelligence-texte-chat-color-dark);
            align-content: center;
            padding-top: 2px;
            white-space: pre-wrap;
        }

        #{$this}__send-button{
            color: light-dark($artificial-intelligence-texte-chat-color-dark,$artificial-intelligence-texte-chat-color-light);
            background-color: light-dark($artificial-intelligence-chat-bg-color-dark, $artificial-intelligence-chat-bg-color-light);
            border: none;
            cursor: pointer;

            display: flex;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            height: 35px;
            width: 35px;

            &:hover{
                background-color: light-dark($artificial-intelligence-chat-bg-button-hover-color-dark, $artificial-intelligence-chat-bg-button-hover-color-light);
            }

            @media (max-width: $screen-xs-max) {
                &-icon{
                    line-height: 1.5 !important;
                }
            }
        }

        #{$this}__warning-bloc{
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            padding-top:5px;
            padding-bottom:5px;
            padding-left:10px;
            padding-right:5px;
            margin:0;
            color: light-dark($artificial-intelligence-texte-chat-color-light,$artificial-intelligence-texte-chat-color-dark);
            width:760px;
            min-height:44px;

            @media (max-width: 1124px) {
                width:75%;
            }
            @media (max-width: 800px) {
                width:95%;
            }
        }

        #{$this}__warning-message{
            color: light-dark($artificial-intelligence-texte-chat-color-dark,$artificial-intelligence-texte-chat-color-light);
            font-size:13px;
            margin-bottom:10px;
        }

        #{$this}__warning-button{
            color: light-dark($artificial-intelligence-texte-chat-color-dark,$artificial-intelligence-texte-chat-color-light);
            background-color: light-dark($artificial-intelligence-chat-bg-color-dark, $artificial-intelligence-chat-bg-color-light);
            border: none;
            cursor: pointer;

            display: flex;
            border-radius: 17.5px;
            justify-content: center;
            align-items: center;
            height: 35px;
            width: auto;
            padding: 10px;
            font-size: 15px;

            &:hover{
                background-color: light-dark($artificial-intelligence-chat-bg-button-hover-color-dark, $artificial-intelligence-chat-bg-button-hover-color-light);
            }

            &-text{
                margin-top:3px;
            }
        }
        #{$this}__warning-icon{
            color: light-dark($artificial-intelligence-texte-chat-color-dark,$artificial-intelligence-texte-chat-color-light);
            font-size: 17px;
            margin-left: 5px;
            margin-right: 5px;
        }
    }
}
