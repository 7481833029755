.c-list-membres {

  $this:&;
  @at-root {


    #{$this}__content {
      padding: 0 !important;
    }

    #{$this}__tabs {
      display: flex;
    }

    #{$this}__tabs li { // éléments du tabs sous l'entête
      flex: 1;
      text-align: center;
    }


    #{$this}__list-name, #{$this}__list-description {
      font-size:14px;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      width:100%;
    }

    #{$this}__list-name {
      font-weight: bold !important;
    }


    #{$this}__title-group {
      margin-left:0;
    }

    #{$this}__dropdown {
      margin:$size-xxx-small;
    }



    //region pour toutes les grilles de ce composant
    #{$this}__grid-members td,
    #{$this}__grid td  {
        border: none !important;
    }
    //endregion


    //region grille des listes
    #{$this}__grid {
      border:none !important;

      &--actions {
        margin-left: auto;
      }

      & .k-detail-row:hover {
        background: none !important;
      }

    }

    #{$this}__grid .k-detail-cell {
      padding:0 !important;
    }

    #{$this}__grid .k-grid-header {
      display: none;
    }

    #{$this}__grid .k-grid-content.k-auto-scrollable {
      @include custom-scrollbar();

      padding:0!important;

      height: calc(#{vh(100)} - #{$header-height} - 105px)  !important;
    }

    #{$this}__grid {
      & .c-user-column__title-group {
        width:100% !important;
      }

      & .editable-input {
        font-size:14px;
        font-weight: bold !important;
      }
    }
    //endregion


    //region sous grille des membres
    #{$this}__grid-members {
      border:none !important;

      &--actions {
        visibility:hidden;
        margin-left: auto;
      }

      & .k-master-row:hover {
        .c-list-membres__grid-members--actions  {
          visibility:visible;
        }
      }


      & .k-grid-content.k-auto-scrollable{
        @include custom-scrollbar();

        height: auto !important;
        min-height: 40px !important;
        max-height: 680px !important; // 20 éléments de 34 px
      }

      & .k-grid-delete-child {
        background-color: white !important;
        border-color:  #cfcfcf !important;

        min-width: 0 !important;
        padding: 6px !important;

        display: none;
      }

      & .k-grid-content tr:hover {
        .k-grid-delete-child {
          display: block;
        }
      }

    }


    #{$this}__grid-members .k-grid-header {
      display: none;
    }
    //endregion


  }

}
