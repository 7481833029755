.c-rating {
  $this:&;

  border: none;
  float: left;
  padding: 0;

  @at-root
  {
    #{$this}--full-width {
      width: 100%;
    }

    #{$this}__element {
      display: none;
    }

    #{$this}__label {
      color: #ddd;
      float: right;
      cursor: pointer;

      &:before {
        margin: 3px;
        font-size: $size-x-small;
        font-family: FontAwesome;
        display: inline-block;
        content: "\f005";

        @media (min-width: $screen-sm-min) {
          font-size: $size-small;
        }
      }

      &:hover,
      &:hover ~ & {
        #{$this}--edit & {
          color: #FFD700;
        }

        #{$this}--edit#{$this}--theme & {
          color: var(--theme-color);
        }
      }

      &:hover ~ #{$this}__element:checked ~ & {
        color: #FFED85;

        #{$this}--theme & {
          color: var(--theme-color-lighten15);
        }
      }

      #{$this}__element:checked ~ & {
        color: #FFD700;

        #{$this}--theme & {
          color: var(--theme-color);
        }
      }

      #{$this}__element:checked + &:hover,
      #{$this}__element:checked ~ &:hover,
      #{$this}__element:checked ~ &:hover ~ & {
        color: #FFED85;

        #{$this}--theme & {
          color: var(--theme-color-lighten15);
        }
      }
    }

    #{$this}__text {
      font-size: $size-x-small;
      display: inline-block;
      margin-right: 2px;
      padding: 4px;
      padding-left: 0;

      @media (min-width: $screen-sm-min) {
        font-size: $size-small;
      }
    }

    #{$this}__content {
      float: right;
    }

    #{$this}__notice {
      float: right;
      font-size: $size-xx-small;
      padding-top: 9px;

      @media (min-width: $screen-sm-min) {
        font-size: $size-x-small;
        padding-top: $size-xxx-small;

      }
    }

    ///
    /// Mixin to create the c-rating size modifiers
    ///
    @mixin c-rating--size($size){
      #{$this}__label {
        &:before {
          font-size: $size;
        }
      }

      #{$this}__text {
        font-size: $size;
      }

      #{$this}__notice {
        font-size: $size;
        padding-top: 5px;
      }
    }

    #{$this}--xxx-small {
      @include c-rating--size($size-xxx-small);
    }

    #{$this}--xx-small {
      @include c-rating--size($size-xx-small);
    }

    #{$this}--x-small {
      @include c-rating--size($size-x-small);
    }

    #{$this}--small {
      @include c-rating--size($size-small);
    }

    #{$this}--normal {
      @include c-rating--size($size-normal);
    }

    #{$this}--medium {
      @include c-rating--size($size-medium);
    }

    #{$this}-form {
      &__label {
        font-size: $size-x-small;

        @media (min-width: $screen-sm-min) {
          font-size: $size-small;
        }
      }

      &__textarea {
        width: 100%;
      }

      &__submit {
        float: right;
      }
    }
  }
}
