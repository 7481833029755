/* Gestion promoteur */
#tab-pane-listPromotor .button-recover{
  display: none;
}

#tab-pane-listArchive
{
  .button-archive,
  .button-sendId ,
  .button-giveRight,
  .button-block {
    display: none;
  }
}

.gestion-promotor-head{
  margin-bottom: 10px;
  position: relative;
}

.add-button-promotor{
  position: absolute;
  top: 22px;
  right: 14px;
}

.button-sendId, .button-block,.button-giveRight{
  margin-right: 5px;
  margin-bottom: 2px;
}
#tab-pane-listPromotor .img-promotor-size {
  width: 60%;
  height: auto;
}

#tab-pane-listArchive .img-promotor-size {
  width: 10%;
  height: auto;
}

/* Detail promoteur */
// show_promotor.twig
.promotor-form-update--logo, .promotor-form-update--info, .promotor-form-update--contact, .promotor-form-update--plateforme, .promotor-form-update--option, .promotor-form-update--socialNetwork{
  display: none;
}

.promotor-fa-logo, .form-promotor-contact--cancel{
  cursor: pointer;
}
.promotor-fa-info, .promotor-fa-contact, .promotor-fa-plateforme, .promotor-fa-option, .promotor-fa-socialNetwork{
  float: right;
  cursor: pointer;
}

.promotor-form-update hr{
  color: #d6cbcb;
}

// Agence //
.promotor-list-agencies{
  margin-top: 35px;
}

.promotor-list-agencies--title {
  margin-left: 20px;
}

.button--manage-contact, .button--update-agency {
  margin-right: 5px;
  margin-bottom: 2px;

}

.add-button-promotor--agency, .add-button-promotor--contact{
  position: absolute;
  top: 0;
  right: 1px;
}

#tab-pane-listAgencies .button--recover-archive{
  display: none;
}

#tab-pane-archiveAgencies
{
  .button--archive-agency,
  .button--manage-contact,
  .button--update-agency {
    display: none;
  }
}

// Contact //
.promotor--formContact{
  display: none;
}

.form-promotor-contact--submit{
  margin-top: 20px;
}

.form-promotor-contact--cancel{
  margin-right: 10px;
}

#tab-pane-listContacts .button--recover-archiveContact{
  display: none;
}

#tab-pane-archiveContacts
{
  .button--update-contact,
  .button--hide-contact,
  .button--mail-contact,
  .button--archive-contact{
    display: none;
  }
}

.form-promotor-contact--submit{
  text-align: center;
}

.button--contact-submitEdit{
  display: none;
}

#tab-pane-tab-pane-listContacts {
  .button--update-contact,
  .button--hide-contact,
  .button--mail-contact,
  .button--archive-contact {
    margin-right: 5px;
    margin-bottom: 2px;
  }
}

// Annuaire //
.border-promotor{
  border: 1px solid black;
  margin-bottom: 30px;
}





