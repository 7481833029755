
.c-timeline-evenement {
  $this: &;

  @at-root
  {

    #{$this}__dropzone
    {
      margin:0px;
      padding-bottom: 5px;
    }

    #{$this}__dropzone .c-adndropzone__dropzone {
      margin-left:0px;
      margin-right:0px;
      padding:0px;
    }

    #{$this}__dropzone-listfiles
    {

    }

  }
}