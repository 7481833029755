.c-timeline
{
    $this: &;

    @at-root
    {
        #{$this}__posts
        {
            margin-right: calc(-1 * var(--app-main-container-padding-right-xs));
            margin-left: calc(-1 * var(--app-main-container-padding-left-xs));

            @media (min-width: $screen-xs-min)
            {
                margin-right: calc(-1 * var(--app-main-container-padding-right-sm));
                margin-left: calc(-1 * var(--app-main-container-padding-left-sm));
            }

            @media (min-width: $screen-sm-min)
            {
                margin-right: 0;
                margin-left: 0;
            }
        }
    }
}
