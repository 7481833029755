.c-cropper-content {

  .cropper-modal{
    background-color: #FFFFFF;
  }
  $this:&;
  @at-root {
    #{$this}__container-img{
      max-height: 500px;
      min-height: 400px;
    }

    #{$this}__img{

      .modal-content &{
        max-height: 300px;
        min-height: 200px;
      }
    }

    #{$this}__container-btn{
      //margin-top: 20px;
      margin-bottom: 20px;
    }

    #{$this}__btn-space{
      margin-top: 15px;
    }

    #{$this}__btn {
      display: flex;
    }

    #{$this}__svg{
      color: white;
    }

    #{$this}__loader{
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
}
