.l-app
{
    &.has-no-header,
    &--tchat
    {
        display: flex;

        background-color: white;

        &.is-chat-xs-or-wider
        {
            height: calc(#{vh(100)});
        }
    }

    &--task .textcomplete-dropdown {
        z-index: 2001!important;
    }

    &--tchat
    {
        user-select: none;
    }
}
