.c-mcq {
  margin-bottom: $size-x-small;

  &__title-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-size: 15px;
  }

  &__action {
    background-color: transparent;
    border: none;
    font-size: 1.5em;
  }

  &__question {
    margin-bottom: 0;
    flex: 1;
    margin-right: $size-xxx-small;
  }

  &__content {
    display: none;

    &.is-open {
      display: block;
    }
  }
}