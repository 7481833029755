
.c-documents {
  $this: &;

  @at-root {


    #{$this}__item-miniature  {
      margin-right: 10px;
      position: relative;
      width: 34px;
      height: 34px;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;

       .icon-document {
         font-size: 36px ;
       }

      &__video img {
        width:34px;
        height:34px;
      }
    }

    #{$this}__item-miniature__album {
      position: relative;
    }

    #{$this}__item-miniature__album-count  {
      position: absolute;
      right: -7px;
      top: -7px;
      background-color: rgba(0, 0, 0, 0.8);
      color: white;
      width: 22px;
      height: 22px;
      font-size: 13px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: solid 2px white;
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
    }

    #{$this}__item {
      display: flex;
      align-items: center;
      align-self: flex-start;

      border-bottom:solid!important;
      border-bottom-width: 1px!important;
      border-bottom-color: $color-light!important;

      padding: $size-xx-small;

      cursor:pointer;

      height:55px;

      @include item-select();


      &--no-padding-right {
        padding-right: 0;
      }

    }

    #{$this}__item-history {
      height:85px;
    }


    #{$this}__item:hover {
      background: $color-light-light ;
    }



    #{$this}__item-header {
      padding:$size-xxx-small;
    }

    #{$this}__item-title {
      width:100%;

      margin-right:$size-xx-small;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    #{$this}__item-title > span {
      font-size: 14px;
      display: block;

      margin-right:$size-xx-small;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }


    // colonne de date de création
    #{$this}__item-sort,
    #{$this}__item-sort-typefile
    {
      display: none;
      width:190px ;
      flex-shrink: 0;
      text-align: left;

      height:100%;
      line-height: 35px;

      @media (min-width: $screen-lg-min)
      {
        display: block;
      }
    }

    #{$this}__item-sort-typefile
    {
      width:60px ;
    }



    #{$this}__item-created {
      color:$color-dark-light;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }


    #{$this}__item-shared {
      color:$color-dark-light;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }


    #{$this}__item-typefile {
      color:$color-dark-light;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }


    #{$this}__item-dropdown {
      width: 36px;
      flex-shrink: 0;
      margin: 0px;
      padding: 0px;
      text-align: center;
      height: 100%;
      line-height: 30px;
    }


    #{$this}__item-authorname  {
      display: none;
      padding-left:0;

      @media #{$media-desktop}
      {
        padding-left:$size-xxx-small;
        display: block;
        width:150px; // uniquement desktop, en mobile le nom est caché
      }
    }

    #{$this}__item-link
    {
      //color: var(--theme-color, $color-dark);
      font-size: 15px;

      display: block;
    }

    #{$this}__item-lock
    {
      font-size: $size-small;
      padding-right:$size-xx-small;
    }




    //
    // section affichée quand il n'y a pas de document, voir list-no-document.twig
    //
    #{$this}__no-docs {
      margin: 0 auto;
      padding: 0 auto;
    }

    #{$this}__no-docs-icon {
      display:block;
      margin:0 auto;
      width:40%;
    }

    #{$this}__no-docs-text {
      font-size: 21px;
      text-align: center;
    }

    #{$this}__no-docs-btn {
      width:100%;
      text-align:center;
    }

    #{$this}__no-docs-btn svg {
      font-size: 21px;
    }



    #{$this}__item-drag-error {    // sur déplacement d'un répertoire
      border: red !important;
      border:solid  !important;
      border-width: 1px !important;

      color:red !important;
      font-weight: bold;
    }



    @include item-play($this);

  }


}

