.c-course-progress-indicator
{
    $this: &;
    $module-height: $size-small;
    $module-padding: $size-xxx-small;
    $module-border-width: 1px;
    $module-border-radius: $module-height / 2;
    $content-height: $module-height - ($module-padding * 2) - ($module-border-width * 2);
    $content-border-width: 1px;
    $content-border-radius: $content-height / 2;

    display: flex;
    align-items: center;
    flex: 1;

    min-width: 0;
    padding-left: $size-x-small;

    @media (min-width: $screen-xs-min)
    {
        padding-right: $size-x-small;
        padding-left: $size-small;
    }

    @at-root
    {
        #{$this}__progress-bar
        {
            display: flex;
            align-items: center;
            flex: 1;

            min-width: 0;
        }

        #{$this}__content
        {
            position: relative;

            flex: 1;

            min-width: 0;
            height: $content-height;

            cursor: not-allowed;
            transition: background-color ease $speed-fast;
            pointer-events: none;

            &,
            &:hover,
            &:focus,
            &:active
            {
                background: $color-default-light;
            }

            @media (min-width: $screen-sm-min)
            {
                pointer-events: all;

                border: 1px solid $color-default-light;
            }

            &::before
            {
                content: '';

                position: absolute;
                top: -($module-padding + $module-border-width + $content-border-width);
                left: -$content-border-width;

                display: none;

                width: calc(100% + #{$content-border-width * 2});
                height: $module-height;

                border: solid $color-light;
                border-width: 1px 0;

                @media (min-width: $screen-sm-min)
                {
                    display: block;
                }
            }

            &--module-start
            {
                @media (min-width: $screen-sm-min)
                {
                    margin-left: $module-padding + $module-border-width;

                    border-top-left-radius: $content-border-radius;
                    border-bottom-left-radius: $content-border-radius;

                    &::before
                    {
                        width: calc(100% + #{($content-border-width * 2) + $module-padding + $module-border-width});
                        margin-left: -($module-padding + $content-border-width);

                        border-left-width: 1px;
                        border-top-left-radius: $module-border-radius;
                        border-bottom-left-radius: $module-border-radius;
                    }
                }
            }

            &--module-end
            {
                @media (min-width: $screen-sm-min)
                {
                    margin-right: $module-padding + $module-border-width;

                    border-top-right-radius: $content-border-radius;
                    border-bottom-right-radius: $content-border-radius;

                    &::before
                    {
                        width: calc(100% + #{($content-border-width * 2) + $module-padding + $module-border-width});

                        border-right-width: 1px;
                        border-top-right-radius: $module-border-radius;
                        border-bottom-right-radius: $module-border-radius;
                    }
                }
            }

            // This dirty selector replaces "&--module-start&--module-end",
            // because SASS can't handle it
            @at-root #{$this}__content--module-start#{$this}__content--module-end
            {
                @media (min-width: $screen-sm-min)
                {
                    &::before
                    {
                        width: calc(100% + #{($content-border-width * 2) + ($module-padding * 2) + ($module-border-width * 2)});
                    }
                }
            }

            &.is-accessible
            {
                cursor: pointer;

                @media (min-width: $screen-sm-min)
                {
                    border-color: var(--theme-color);
                    background: white;

                    &:hover,
                    &:focus,
                    &:active
                    {
                        background: var(--theme-color-light);
                    }
                }
            }

            &.is-completed
            {
                background: var(--theme-color);
            }

            &--placeholder
            {
                background: none !important;

                border: 1px $color-default-light;
                border-top-style: solid;
                border-bottom-style: solid;

                &:first-child
                {
                    border-left-style: solid;
                }

                &:last-child
                {
                    border-right-style: solid;
                }

                @media (min-width: $screen-sm-min)
                {
                    border-style: solid;
                    border-color: transparent !important;
                }
            }
        }

        #{$this}__separator
        {
            display: none;

            width: 1px;
            height: $module-height;

            background: $color-light;

            &--chapter
            {
                width: $size-xxx-small;
            }

            &--module
            {
                width: $size-xxx-small;

                background: transparent;

                @media (min-width: $screen-xs-min)
                {
                    width: $size-xx-small;
                }
            }

            @media (min-width: $screen-sm-min)
            {
                display: block;
            }
        }

        #{$this}__count
        {
            font-size: 15px;

            margin-left: $size-xx-small;

            @media (min-width: $screen-xs-min)
            {
                margin-left: $size-x-small;
            }
        }
    }
}
