.c-login-form
{
    $this: &;

    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    //font-family:Helvetica, Arial, sans-serif;
    font-family:Apercu, Helvetica, Arial, sans-serif;

    @media screen and (max-width: 768px) {
        width:100%;
        height:100vh;
        display:flex;
        justify-content:center;
    }

    @at-root
    {
        #{$this}__logo
        {
            display: block;

            height: 50px;
            margin: $size-xx-small auto $size-normal;
        }

        #{$this}__form
        {
            padding: $size-normal;

            width:100%;
            max-width: 480px;
            background: #ffffff;
            border-radius: 8px;
            box-shadow: 0 14px 28px rgba(0,0,0,0.15);

        @media screen and (max-width: 768px){
            display:flex;
            flex-direction:column;
            justify-content:center;
            height: 100vh;
            }
        }


        #{$this}__title
        {
            font-size: 25px;
            font-weight: bold;
            text-align: center;
            margin-bottom: $size-normal;
            color: $color-black;
        }


        #{$this}__field
        {

        }

        #{$this}__label
        {
            font-size: 17px;
            font-weight: 500;
            color:#666666;
        }

        #{$this}__input
        {
            margin: 0 0 $size-x-small 0;
            padding: 19px;
            border-radius: 4px;
            border: 1px solid #ccc;
            background: #F5F5F5;
            font-size: 17px;
        }

        #{$this}__submit
        {
            width: 100%;
            font-size:17px;
            font-weight: 600;
            padding: $size-small 0;
            background: linear-gradient(to right, #667EEA, #764BA2);
            text-transform: initial;
        }

        #{$this}__submit:hover
        {
            background: linear-gradient(to right, #764BA2, #667EEA);
        }

        #{$this}__lost-password,#{$this}__join
        {
            display: block;
            margin-top: 20px;
            text-align: center;
            color: #667EEA;
            text-decoration: none;
            font-size: 15px;
        }

        #{$this}__password-reset-instruction
        {
            text-align:center;
            font-size: 15px;
            color: $color-black;
            margin-bottom: $size-x-small;

        }
        #{$this}__info {
            text-align: center;
        }
    }
}
