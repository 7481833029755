.c-aside
{
    $this: &;

    @include layer-promotion();

    position: fixed;
    z-index: $z-index-3;
    top: 0;
    left: 0;

    display: none;
    overflow-x: hidden;

    height: 100%;
    padding: 18px 20px;

    transition: ease $speed-fast;
    transition-property: transform, height;

    background-color: white;
    box-shadow: 0 0 1px rgba(black, .08), 0 3px 8px rgba(black, .08);

    @media (min-width: $screen-md-min)
    {
        display: block;
    }

    @at-root
    {
        #{$this}:not(#{$this}--message)
        {
            width: $aside-width;
        }

        #{$this}.is-offbeat-for-big-header
        {
            height: calc(100% - #{$size-medium});

            transform: translateY($size-medium);
        }

        #{$this}--right
        {
            right: 0;
            left: auto;

            border-right: none;
        }
    }
}
