.c-panel
{
    $this: &;

    margin-right: -$size-x-small;
    margin-left: -$size-x-small;
    padding: $size-x-small;

    background-color: white;
    box-shadow: 0 0 1px rgba(black, .08), 0 2px 5px rgba(black, .08);

    @media (min-width: $screen-xs-min)
    {
        margin-right: 0;
        margin-left: 0;

        border-radius: 3px;
    }

    @media (min-width: $screen-sm-min)
    {
        padding: $size-small;
    }

    @at-root
    {
        #{$this} > *:first-child
        {
            margin-top: 0;
        }

        #{$this}--narrow
        {
            padding: $size-x-small;
        }
    }
}
