.c-sondage-question {
  margin-top:20px;


  width: calc(100% - 39px) ;

  $this: &;

  @at-root {

    #{$this}__inputTextOption {
      width: 100%;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }


    #{$this}__btn-valid{
       height:40px;
    }


    #{$this}__image-link a {
        border:none
    }

    #{$this}__image-link svg {
      width:42px; height: 42px; margin-top:3px;
    }

    #{$this}__image-preview {
      width:40px !important;
      height:40px !important;
      padding:0px;
      margin-left:5px;
      border-width: 1px;
      border-radius: 3px;
    }


  }


  @media #{$media-desktop}
  {
    padding-right:0px;
  }

}

.c-sondage-questions,
.c-sondage-question-item {
  padding-right:0px  !important;

  margin-top:20px;

    @media #{$media-desktop}
    {
      padding-right:0px  !important;
    }
}


.c-sondage-stats-reponse {

  $this: &;

  @at-root {

    #{$this}__titre
    {
       width:100%;
    }

    #{$this}__link
    {
      text-align: left !important;
    }

  }

}


.c-sondage-question-post {

  $this: &;

  padding:$size-xx-small;

  @at-root {

      #{$this}__texte
      {
        padding-bottom: 10px
      }

      #{$this}__item
      {
        padding-bottom: 5px;
      }

      #{$this}__label
      {
        font-weight: normal;
        height:auto;
      }
      #{$this}__label.has-image {
        min-height: 40px; padding-top: 10px;
      }


      #{$this}__image {
        padding:0px;border:0px;
      }

      #{$this}__image img {
        width:40px;height:40px;
      }


      #{$this}__fakeremove
      {
        width:34px;
        background:none;
        border:none
      }

      #{$this}__dropdownsuppr
      {
        padding: 0px;

        background:transparent;
        border:none;
        padding-left:5px;
      }


      #{$this}__stats
      {
        top:0;
        left:0;
        z-index:-1;
        height:100%;
        background:#ebeff6;
        position:absolute;
      }

      #{$this}__reponses {
         width:250px;
      }

    #{$this}__reponses-avatars {
      padding-left: 7px;
    }

    #{$this}__reponses-badge:before {
       background: rgba(255, 0, 0, 0.50);
    }

    #{$this}__reponses span{
      padding-right: 3px;
      padding-bottom:10px;
      display: inline-block;
    }


  }

}

.editSondageModal {
  padding-left: 15px;

  @media #{$media-desktop}
  {
    padding-left: unset;
  }

}
