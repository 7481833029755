.u-img-cover
{
    font-family: 'object-fit: cover;';

    width: 100%;
    height: 100%;

    -o-object-fit: cover;
       object-fit: cover;
}

.u-img-contain
{
    font-family: 'object-fit: contain;';

    width: 100%;
    height: 100%;

    -o-object-fit: contain;
       object-fit: contain;
}

.u-ellipsis {
    @include single-line();
}

@media (hover: hover) and (pointer: fine)
{
    .u-more-trigger {
        visibility: hidden;
    }

    tr:hover .u-more-trigger, .u-more-trigger-container:hover > .u-more-trigger {
        visibility: visible;
    }
}

.u-textarea-height-auto {
    height: auto;
}

@each $position in ('top', 'right', 'bottom', 'left')
{
    .u-no-margin-#{$position}
    {
        margin-#{$position}: 0;
    }

    .u-no-padding-#{$position}
    {
        padding-#{$position}: 0;
    }
}

.u-list-input {
    max-height: $size-small;
    margin-bottom: 0;
}

.u-full-width {
    width: 100%;
}

.u-viewport-height
{
    height: 100vh;
    height: calc(#{vh(100)});
}

.u-viewport-width
{
    height: 100vw;
}

.u-justify-content-center {
    display: flex;
    justify-content: center;
}

// This piece of code creates some responsive text utilities which look like
// this:
// - u-text-left
// - u-text-left@xs
// - u-text-left@sm
// - u-text-left@md
// - ...
// - u-text-center
// - u-text-center@xs
// - u-text-center@sm
// - u-text-center@md
// - etc.
// Check this out https://csswizardry.com/2015/08/bemit-taking-the-bem-naming-convention-a-step-further/#responsive-suffixes
$text-positions: (left, center, right);

@each $position in $text-positions
{
    .u-text-#{$position}
    {
        text-align: $position;
    }
}

@each $breakpoint-name, $breakpoint-values in $breakpoints
{
    @each $position in $text-positions
    {
        // We don't want the max values here
        @if map-get($breakpoint-values, 'min')
        {
            @media (min-width: #{map-get($breakpoint-values, 'min')})
            {
                // The slash (before the @) is only necessary in CSS; don't put
                // it in the HTML
                .u-text-#{$position}\@#{$breakpoint-name}
                {
                    text-align: $position;
                }
            }
        }
    }
}

.decorationNone
{
    &,
    &:hover,
    &:active,
    &:focus
    {
        text-decoration: none;
    }
}

.pointer
{
    cursor: pointer;
}

.u-color
{
    &,
    &:hover,
    &:active,
    &:focus
    {
        color: var(--theme-color);
        text-decoration: none;
    }
}
.u-format-placeholder
{
    width: 55px;
    height: 45px

}

.u-z-index-1{
    z-index:1;
}