.c-dashboard-table {
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-light;
    padding: $size-xx-small 0;

    &--first {
      padding-top: 0;
    }

    &--last {
      padding-bottom: 0;
      border: none;
    }
  }

  &__user {
    display: flex;
    align-items: center;
  }

  &-user {
    &__name {
      margin: 0 $size-xx-small;
      font-style: normal;
      font-weight: 500;
      font-size: $size-x-small;
      text-transform: uppercase;
      color: $color-dark;
    }
  }

  &__count {
    font-style: normal;
    font-weight: bold;
    font-size: $size-x-small;
    text-align: right;
    color: var(--theme-color);


    &--blue {
      color: $dashboard-color-blue!important;
    }

    &--red {
      color: $dashboard-color-red!important;
    }

    &--green {
      color: $dashboard-color-green!important;
    }

    &--orange {
      color: $dashboard-color-orange!important;
    }

    &--purple {
      color: $dashboard-color-purple!important;
    }
  }
}
