.scroll,
.emojionearea-editor
{
    overflow: auto;
}

.scroll--y
{
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
}

.scroll,
.emojionearea-editor
{
    &::-webkit-scrollbar-track
    {
        background-color: white;
    }

    &::-webkit-scrollbar
    {
        width: 6px;
        background-color: white;
    }

    &::-webkit-scrollbar-thumb
    {
        background-color: var(--theme-color);
    }
}
