/// ============================================================================
/// Color collection
///
$color-aqua: #3bafda;
$color-aqua-light: #4fc1e9;

$color-success: #60b000;
$color-success-light: #a0d468;

$color-info: #4a89dc;
$color-info-light: #5d9cec;

$color-warning: #f6bb42;
$color-warning-light: #ffce54;

$color-danger: #e9573f;
$color-danger-light: #fc6e51;

$color-mint: #37bc9b;
$color-mint-light: #48cfad;

$color-purple: #967adc;
$color-purple-light: #ac92ec;

$color-pink: #d770ad;
$color-pink-light: #ec87c0;

$color-red: #da4453;
$color-red-light: #ed5565;

$color-adn: #374191;
$color-adn-light: #375B9D;

/// ============================================================================
/// Shades of grey
///
$color-black: #1d2129;

$color-dark: #434a54;
$color-dark-light: #656d78;

$color-default: #aab2bd;
$color-default-light: #ccd1d9;

$color-light: #e6e9ed;
$color-light-light: #f5f7fa;

/// ============================================================================
/// Inherited colors
///
$text-color: $color-dark;
$default-theme-color: $color-adn;
$app-background-color: $color-light-light;
$panels-border-color: $color-default-light;

/// ============================================================================
/// Dashboard colors
///
$dashboard-color-red: #CC99A0;
$dashboard-color-red-light: #DFB0B6;

$dashboard-color-orange: #FCC992;
$dashboard-color-orange-light: #FDDBB6;

$dashboard-color-blue: #8F9FE0;
$dashboard-color-blue-light: #ACB8E8;

$dashboard-color-green: #ADC5A5;
$dashboard-color-green-light: #C3D5BD;

$dashboard-color-purple: #BEAECB;
$dashboard-color-purple-light: #CABDD5;


/// ============================================================================
/// Artificial Intelligence colors
///
$artificial-intelligence-scrollbar-color-light: #e3e3e3;
$artificial-intelligence-scrollbar-color-dark: #676767;

$artificial-intelligence-left-sidebar-bg-color-light: #f9f9f9;
$artificial-intelligence-left-sidebar-hover-bg-color-light: #ececec;
$artificial-intelligence-left-sidebar-dropdown-bg-color-light: #f9f9f9;
$artificial-intelligence-chat-scrollbar-bg-color-light: #e3e3e3;
$artificial-intelligence-conversation-scrollbar-bg-color-light: #e3e3e3;
$artificial-intelligence-chat-bg-color-light: #ffffff;
$artificial-intelligence-chat-bg-button-hover-color-light: #f9f9f9;
$artificial-intelligence-textarea-bg-color-light: #f4f4f4;
$artificial-intelligence-message-bg-color-light: #f4f4f4;
$artificial-intelligence-chat-textarea-bg-color-light: #ececec;
$artificial-intelligence-chat-table-bg-color-light: #E5E5E5;
$artificial-intelligence-chat-table-border-color-light: #D9D9D9;
$artificial-intelligence-chat-warning-container-background-color-light: #fff5f5;
$artificial-intelligence-chat-warning-container-border-color-light: #fedad9;
$artificial-intelligence-chat-warning-icon-color-light: #f94543;
$artificial-intelligence-chat-floating-button-border-color-light: #E5E5E5;
$artificial-intelligence-texte-sidebar-color-light: #000000;
$artificial-intelligence-texte-chat-color-light: #000000;
$artificial-intelligence-texte-textarea-color-light: #000000;

$artificial-intelligence-left-sidebar-bg-color-dark: #171717;
$artificial-intelligence-left-sidebar-hover-bg-color-dark: #212121;
$artificial-intelligence-left-sidebar-dropdown-bg-color-dark: #171717;
$artificial-intelligence-chat-scrollbar-bg-color-dark: #676767;
$artificial-intelligence-conversation-scrollbar-bg-color-dark: #676767;
$artificial-intelligence-chat-bg-color-dark: #212121;
$artificial-intelligence-chat-bg-button-hover-color-dark: #2f2f2f;
$artificial-intelligence-textarea-bg-color-dark: #2f2f2f;
$artificial-intelligence-message-bg-color-dark: #2f2f2f;
$artificial-intelligence-chat-textarea-bg-color-dark: #424242;
$artificial-intelligence-chat-table-bg-color-dark: #383838;
$artificial-intelligence-chat-table-border-color-dark: #424242;
$artificial-intelligence-chat-warning-container-background-color-dark: #2c2222;
$artificial-intelligence-chat-warning-container-border-color-dark: #212121;
$artificial-intelligence-chat-warning-icon-color-dark: #f93A37;
$artificial-intelligence-chat-floating-button-border-color-dark: #363636;
$artificial-intelligence-texte-sidebar-color-dark: #ffffff;
$artificial-intelligence-texte-chat-color-dark: #ffffff;
$artificial-intelligence-texte-textarea-color-dark: #ffffff;

/// Set the lightness of the provided color, as there is no native way to do it
/// in SASS.
/// This function should be placed in the "tools" layer of ITCSS, but its needed
/// here in the "settings" layer, so...
/// @param {color} $color
/// @param {number} $value
/// @throw Error if the $color is not of type color
/// @throw Error if the $value is not a number between 0% and 100%
/// @return {color} Modified color
@function set-lightness($color, $value)
{
    @if type-of($color) != color
    {
        @error "\"#{$color}\" is not a valid color.";
    }

    @if type-of($value) != number or $value < 0% or $value > 100%
    {
        @error "\"#{$value}\" is not a valid number between 0% and 100%.";
    }

    $offset: $value - lightness($color);

    @if $offset > 0
    {
        $color: lighten($color, $offset);
    }
    @else
    {
        $color: darken($color, $offset * -1);
    }

    @return $color;
}

:root {
    --theme-color:#{$default-theme-color};
    --theme-color-light: #{lighten($default-theme-color, 5%)};
    --theme-color-lighten15: #{lighten($default-theme-color, 15%)};
    --theme-color-lighten20: #{lighten($default-theme-color, 20%)};
    --theme-color-lighten25: #{lighten($default-theme-color, 25%)};
    --theme-color-lighten30: #{lighten($default-theme-color, 30%)};
    --theme-color-lighten40: #{lighten($default-theme-color, 40%)};
    --theme-color-lighten50: #{lighten($default-theme-color, 50%)};
    --theme-color-lighten55: #{lighten($default-theme-color, 55%)};
    --theme-color-lighten60: #{lighten($default-theme-color, 60%)};
    --theme-color-lighten70: #{lighten($default-theme-color, 70%)};
    --theme-color-lighten80: #{lighten($default-theme-color, 80%)};
    --theme-color-lighten90: #{lighten($default-theme-color, 90%)};
    --theme-color-darken20: #{darken($default-theme-color, 20%)};

// This loop generates all the lightness nuances of the default theme-color from
// 5 to 95, with a step of 5.
// 0 and 100 are not useful, as they are always black and white.
// The CSS custom properties will look like this:
// - --theme-color-l20
// - --theme-color-l35
// - --theme-color-l70
// - etc.
// They will be overriden if the user select in theme in the preferences UI.
    @for $lightness from 5 through 95
    {
        @if $lightness % 5 == 0
        {
            --theme-color-l#{$lightness}: #{set-lightness($default-theme-color, $lightness)};
        }
    }

    --button-font-color: white;
    --button-font-rgb-color: 255, 255, 255;
    --button-color-disabled: #{lighten($default-theme-color, 25%)};
    --background-theme-color: #{$app-background-color};
}
