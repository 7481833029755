.context-menu-list,
.context-menu-item
{
    background-color: #f5f7fa;
}

.context-menu-list
{
    font-size: 15px;

    overflow: hidden;

    padding: 0;

    border: none;
    border-radius: $size-xxx-small;
    box-shadow: 0 2px $size-xxx-small rgba(0,0,0,.16);
}

.context-menu-item
{
    color: $text-color;
}

.context-menu-icon.context-menu-icon--fa::before,
.context-menu-icon::before
{
    color: inherit !important;
}

.context-menu-icon:before,
.context-menu-icon.context-menu-icon--fa:before
{
    font-size: 1.2em;

    width: 2.3em;
}

.context-menu-item
{
    line-height: $size-small;

    padding-left: 2.8em;
}

.context-menu-item.context-menu-hover
{
    background-color: var(--theme-color-light);
}

.context-menu-separator
{
    margin: 0;

    border-color: $panels-border-color;
}

// Applying the same style to our custom icon set than in the FontAwesome one
// (the below code is only copy pasting from the library, except a few tweaks)
.context-menu-item svg.pr
{
    font-size: 1.2em;
    line-height: 1;

    position: absolute;
    top: 50%;
    left: 0;

    width: 2.3em;

    transform: translateY(-50%);
    text-align: center;

    color: inherit;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
