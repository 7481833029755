.c-loader {
  // scaling... any units
  $width: 100px;

  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: $width;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  $this: &;

  @at-root {

    #{$this}--middle {
      height: 100%;
    }

    #{$this}--modal{
      top: 50%;
      transform:  translateY(-50%);
    }

    #{$this}__circular {
      animation: rotate 2s linear infinite;
      height: 100%;
      transform-origin: center center;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }

    #{$this}__path {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      animation: dash 1.5s ease-in-out infinite;
      stroke-linecap: round;
      stroke: var(--theme-color);

      /* Si le parent à ce modifier alors  l'élément path prend la couleur blanc */
      #{$this}--white & {
        stroke: white;
      }
    }

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes dash {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
      }
      100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
      }
    }
  }
}
