.k-grid-toolbar {
  background-color: var(--theme-color-lighten15) !important;

  .k-button {
    background-color: var(--theme-color) !important;
    border-color: var(--theme-color) !important;
    color:white !important;
  }
}




.k-grid-delete {
  background-color: white !important;
  border-color:  #cfcfcf !important;

  min-width: 0 !important;
  padding: 6px !important;

  display: none;
}

.k-grid-content tr:hover {
  .k-grid-delete {
    display: block;
  }
}


.k-loading-mask {
  display:none;
}

