.s-chat-message
{
    $this: &;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol
    {
        font-size: inherit !important;

        margin-top: 0;
        margin-bottom: 1.2em;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol,
    p,
    pre
    {
        &:last-child
        {
            margin-bottom: 0;
        }
    }

    a
    {
        &,
        &:hover,
        &:focus,
        &:active
        {
            text-decoration: underline;

            color: inherit;
            border: none;
        }

        &[href^='/?page=detailUtilisateur']
        {
            text-decoration: none;

            &,
            &:hover,
            &:focus,
            &:active
            {
                font-weight: 500;
            }

            &:hover,
            &:focus,
            &:active
            {
                text-decoration: underline;
            }
        }
    }

    ul,
    ol
    {
        padding: initial;
        padding: revert;

        list-style: initial;
        list-style: revert;

        padding-inline-start: $size-small;
    }

    li
    {
        padding: initial !important;
        padding: revert !important;

        list-style: initial;
        list-style: revert;

        @at-root
        {
            #{$this} ul > li
            {
                list-style-type: disc;
            }
        }

        &::before
        {
            content: none !important;
        }
    }

    blockquote
    {
        font-size: inherit;

        margin: $size-xxx-small 0;
        padding: 0 $size-x-small;

        color: inherit;
        border-left-width: .2em;
        border-left-color: inherit;
    }

    pre,
    code
    {
        color: inherit;
        background-color: rgba(white, .2);
    }

    pre
    {
        border-color: rgba(255,255,255,0.2);

        code
        {
            background: none;
        }
    }
}
