.c-mobile-main-menu
{
    $this: &;
    $container-padding: $size-small;
    $hide-trigger-padding: $size-x-small;
    $trigger-padding: $size-x-small;

    width: 80%;

    @at-root
    {
        #{$this}__display-trigger
        {
            @include reset-button();

            display: flex;
            align-items: center;
            justify-content: center;

            color: $color-dark-light;

            &--tchat
            {
                position: absolute;
                top: 50%;
                left: $size-x-small;

                transform: translateY(-50%);

                @media #{$media-desktop}
                {
                    display: none;
                }
            }

            &--course {
                @media #{$media-desktop}
                {
                    display: none;
                }
            }
        }

        #{$this}__title
        {
            font-size: 17px;
            font-weight: 500;
            color: #434A54FF;

            margin: $size-xx-small $size-small;
        }

        #{$this}__display-trigger-icon
        {
            height: 100%;
        }

        #{$this}__display-trigger-icon
        {
            width: $size-small;
            height: $size-small;
        }

        #{$this}__container
        {
            display: flex;
            flex-direction: column;

            max-height: 100%;
            padding: $container-padding $container-padding 0;
        }

        #{$this}__hide-trigger
        {
            @include reset-button();

            position: absolute;
            top: $container-padding - $hide-trigger-padding;
            right: $container-padding - $hide-trigger-padding;

            width: $size-small + ($hide-trigger-padding * 2);
            height: $size-small + ($hide-trigger-padding * 2);
            padding: $hide-trigger-padding;
        }

        #{$this}__hide-trigger-icon
        {
            width: 100%;
            height: 100%;
        }

        #{$this}__user
        {
            flex-shrink: 0;

            margin-bottom: $size-x-small;
        }

        #{$this}__items
        {
            overflow: auto;
            flex: 1;

            margin-right: -$container-padding;
            margin-left: -$container-padding;
            padding-bottom: $container-padding - $trigger-padding;

            -webkit-overflow-scrolling: touch;
        }

        #{$this}__trigger
        {
            @include reset-button();

            height: 40px;
            line-height: 1;

            position: relative;

            display: flex;
            align-items: center;

            width: 100%;
            padding: $size-xxx-small $size-xxx-small $size-xxx-small $size-small ;

            transition: background-color ease $speed-fast;

            &,
            &:hover,
            &:focus,
            &:active
            {
                text-decoration: none;
            }

            &:hover,
            &:focus,
            &:active
            {
                background: $color-light-light;
            }

            &::before
            {
                right: $container-padding - 2px;
            }
        }

        #{$this}__trigger-icon
        {
            font-size: 17px;

            color: $color-dark-light;

            &--positions
            {
                transform: translateY(-1px);
            }

            &--documents,
            &--promopartage
            {
                transform: translateY(-1.5px);
            }

            &--agenda
            {
                transform: translateY(-1px);
            }

            &--disconnect
            {
                transform: translateY(-1px);
            }
            &--artificialIntelligence
            {
                width: 16px;
                transform: translateY(-1px);
            }

            #{$this}__trigger:hover &,
            #{$this}__trigger:focus &,
            #{$this}__trigger:active &
            {
                color: var(--theme-color);
            }
        }

        #{$this}__trigger-label
        {
            font-size: 17px;

            margin-left: $size-xx-small;

            color: $text-color;

            #{$this}__trigger:hover &,
            #{$this}__trigger:focus &,
            #{$this}__trigger:active &
            {
                color: var(--theme-color);
            }
        }
    }
}
