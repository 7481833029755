$size-xxx-small: 5px;
$size-xx-small: 9px;
$size-x-small: 15px;
$size-small: 25px;
$size-light: 35px;
$size-normal: 40px;
$size-medium: 64px;
$size-large: 104px;
$size-x-large: 167px;
$size-xx-large: 269px;
$size-xxx-large: 433px;



/// ============================================================================
/// Inherited sizes
///
$main-menu-width: $size-medium;

/// The mobile menu height is made of:
/// - icons height
/// - icons top and bottom padding
/// - triggers top and bottom padding
/// - border top width
$mobile-menu-icons-height: $size-small;
$mobile-menu-icons-padding: $size-xxx-small;
$mobile-menu-triggers-padding: $size-xx-small;
$mobile-menu-height: $mobile-menu-icons-height + ($mobile-menu-icons-padding * 2) + ($mobile-menu-triggers-padding * 2);

$tchat-popup-header-height: $size-small * 2;

$header-height: $size-normal + $size-xx-small;
$header-inner-height: $size-small + $size-xx-small;
$aside-width: $size-x-large + $size-medium;
 $main-aside-width: $size-xx-large + $size-medium;

/// App main container
$app-main-container-padding-xs: $size-x-small;
$app-main-container-padding-sm: $size-small;

:root
{
    --main-menu-width: #{$main-menu-width};
    --mobile-menu-height: #{$mobile-menu-height};

    /// App main container CSS variables
    --app-main-container-padding-left-xs: #{$app-main-container-padding-xs};
    --app-main-container-padding-right-xs: #{$app-main-container-padding-xs};
    --app-main-container-padding-left-sm: #{$app-main-container-padding-sm};
    --app-main-container-padding-right-sm: #{$app-main-container-padding-sm};

    @supports (width: env(safe-area-inset-left))
    {
        // We use a CSS variable to be able to use this value inside another
        // calc function (calc inside calc is not possible)
        --main-menu-width: calc(#{$main-menu-width} + env(safe-area-inset-left));

        // Checking if the browser supports the max function before using it
        // Using unquote to prevent SASS to use it's own max function (we want
        // the CSS one here)
        @supports (padding: unquote('max(42px, 21px)'))
        {
            // If the device safe-area-inset-bottom value is bigger than the padding
            // bottom value, replacing it
            $mobile-menu-height: $mobile-menu-height - $mobile-menu-triggers-padding;
            $mobile-menu-padding-bottom: unquote("max(#{$mobile-menu-triggers-padding}, env(safe-area-inset-bottom))");
            --mobile-menu-height: calc(#{$mobile-menu-height} + #{$mobile-menu-padding-bottom});

            // If the safe-area-inset horizontal values of the device are bigger
            // than the horizontal padding of the main app container, it will be
            // overwritten
            --app-main-container-padding-left-xs: #{unquote('max(#{$app-main-container-padding-xs}, env(safe-area-inset-left))')};
            --app-main-container-padding-right-xs: #{unquote('max(#{$app-main-container-padding-xs}, env(safe-area-inset-right))')};
            --app-main-container-padding-left-sm: #{unquote('max(#{$app-main-container-padding-sm}, env(safe-area-inset-left))')};
            --app-main-container-padding-right-sm: #{unquote('max(#{$app-main-container-padding-sm}, env(safe-area-inset-right))')};
        }
    }
}

/// ============================================================================
/// Sidebar sizes
///
$sidebar-header-size: 77px;
$sidebar-footer-size: 55px;
