.c-listview-history {

  $this: &;
  border:none;


  @at-root {

    & .k-listview-content {
      @include custom-scrollbar();

      overflow-y: auto!important;
      height: unset;

      @media (min-width: $screen-sm-min) {
        height: calc(#{vh(100)} - 95px) !important;
      }

    }

    & .c-documents__item {
      font-size: 13px;
    }
  }
}