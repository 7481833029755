.c-timeline-play-trigger
{
    $this: &;

    font-size: 31px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: $size-medium;
    height: $size-medium;

    color: white;
    border: 3px solid;
    border-radius: 50%;
    background: rgba(black, .4);
    box-shadow: 0 0 1px rgba(black, .08), 0 3px 8px rgba(black, .08);

    backdrop-filter: blur(2px) saturate(130%);

    @at-root
    {
        #{$this}__icon
        {
            // The 12 % is only here to avoid the optical illusion
            // that the icon not horizontally centered.
            transform: translateX(12%);
        }
    }
}
