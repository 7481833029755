.c-detail-section {
  $this:&;

  margin: 0 (-$size-x-small) $size-medium;

  background-color: white;
  box-shadow: 0 0 1px rgba(black, .08), 0 2px 5px rgba(black, .08);

  @media (min-width: $screen-xs-min)
  {
    margin-right: 0;
    margin-left: 0;

    border-radius: 3px;
  }

  @at-root {
    #{$this}--full-height {
      padding-bottom: 0;
    }

    #{$this}__header-fullpage,
    #{$this}__header {
      padding: $size-x-small;
      border-bottom: 1px solid $panels-border-color;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &--sidebar {
        border-bottom: none;
      }

      #{$this}--large-padding &
      {
        @media (min-width: $screen-xs-min)
        {
          padding: $size-small;
        }
      }
    }

    #{$this}__header-fullpage {
      padding : 0;
      padding-left: $size-xxx-small;
      justify-content: start;
      height: $size-medium;

      @media (min-width: $screen-xs-min)
      {
        padding:0;
        padding-left: $size-x-small;
      }
    }


    #{$this}__header-fullpage h3 {
      font-size: 23px;
      margin: 0;

      @media (min-width: $screen-xs-min)
      {
        font-size: 31px;
      }
    }



    #{$this}__header-fullpage-actions {
      width: 100%;
    }
    #{$this}__header-fullpage-actions button {
      float:right;
      margin-right: $size-xxx-small;

      @media (min-width: $screen-xs-min)
      {
        margin-right: $size-x-small;
      }
    }
    #{$this}__header-fullpage-actions button svg {
       font-size: 21px;
    }

    #{$this}__header-fullpage-icon {
      font-size: $size-small;
      margin-top: 0px;
      margin-right: $size-xxx-small;
      flex-shrink: 0;
    }




    #{$this}__title {
      margin: 0;
      font-size: $size-small;
      font-weight: 400;

      &--sidebar {
        font-size: $size-x-small;
        font-weight: 500;
        margin-bottom: 1px;
      }
    }

    #{$this}__content {
      padding: $size-x-small;

      #{$this}--large-padding &
      {
        @media (min-width: $screen-xs-min)
        {
          padding: $size-small;
        }
      }

      &--full-width {
        padding-left: 0;
        padding-right: 0;
      }

      &--full-height {
        padding-top: 0;
        padding-bottom: 0;
      }

      #{$this}__header--sidebar + &
      {
        padding-top: 0;
      }
    }

    #{$this}__nav {
      display: flex;
      flex-direction : row;

      .is-active {
        a {
          box-shadow: inset 0 -3px 0 0 var(--theme-color);
        }
      }
    }

    #{$this}__link {
      box-sizing: border-box;
      padding: $size-xx-small;
      display: block;
      text-align:center;
      text-decoration: none;
      margin: 0 $size-xxx-small;
      font-size: $size-x-small;

      &:hover, &:focus, &:active {
        text-decoration: none;
        outline: none;
        box-shadow: inset 0 -3px 0 0 var(--theme-color-light);
      }
    }

    #{$this}-content__item {
      display: none;

      &.is-active { display: block; }
    }
    #{$this}__nbUser{
      font-size: 15px;
      font-weight: bold;
      line-height: 24px;
      margin-left: 4px;
      color: $color-default;
    }
  }
}
