/// @require o-flag Must be used with this block
/// @see o-flag
///
.c-entity-list-item
{
    $this: &;
    --background: white;

    padding: $size-xxx-small $size-xx-small;

    transition: background-color ease $speed-fast;

    background: var(--background);

    &,
    &:hover,
    &:focus,
    &:active
    {
        text-decoration: none;
    }

    &:hover:not(&--no-hover),
    &:focus:not(&--no-hover),
    &:active:not(&--no-hover)
    {
        --background: #{$color-light-light};
    }

    @at-root
    {
        #{$this}__text
        {
            display: flex;
            flex-direction: column;
        }

        #{$this}__title
        {
            font-size: 15px;

            color: $color-black;
        }

        #{$this}__subtitle
        {
            font-size: 14px;

            color: $color-default;
        }

        &--no-padding
        {
            padding: 0;
        }

    }
}
