.c-sidebar {
  $this: &;
  $header-vertical-padding: $size-small;
  $header-horizontal-padding: $size-normal;
  $close-trigger-padding: $size-x-small;
  $close-trigger-width: $size-small + ($close-trigger-padding * 2);
  $close-trigger-height: $close-trigger-width;

  position: fixed;
  top: 0;
  right: 0;
  z-index: $z-index-9;

  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  visibility: hidden;

  background-color: white;
  box-shadow: -2px 0 5px 0 rgba(0,0,0,0.16);

  transform: translateX(100%);
  transition: ease $speed-medium;
  transition-property: transform, visibility, width, height;

  @at-root
  {
    // Ouverture sidebar
    #{$this}.is-open {
      transform: none;
      visibility: visible;
    }

    // Taille
    @media (min-width: $screen-sm-min) {
      #{$this} {
        width: 500px;

        &--large {
          width: 66.66666667%;
        }

        &--small {
          width: 33.33333333%;
        }

        &--mini {
          width: $size-xx-large + $size-medium;
        }

        &--full-screen {
          width: 100%;
        }

        &--auto{
          width: auto;
        }
      }
    }

    // Position
    #{$this}--top,
    #{$this}--bottom {
      left: 0;
      width: 100%;
      height: 100%;

      @media (min-width: $screen-sm-min) {
        height: 500px;
      }

      &#{$this}--full-screen {
        height: 100%;
      }

      &#{$this}--large {
        height: calc(100% - 104px);
      }

      &#{$this}--small {
        height: 50%;
      }

      &#{$this}--mini {
        height: 33.33333333%;
      }

      &#{$this}--auto {
        height: auto;
        max-height: 100%;
      }

    }

    #{$this}--top {
      transform: translateY(-100%);
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16);
    }

    #{$this}--bottom {
      top: auto;
      bottom: 0;
      transform: translateY(100%);
      box-shadow: 0 -2px 5px 0 rgba(0,0,0,0.16);
    }

    #{$this}--left {
      left: 0;
      right: auto;
      transform: translateX(-100%);
      box-shadow: 2px 0 5px 0 rgba(0,0,0,0.16);
    }

    @media #{$media-desktop} {
      #{$this}--from-main-menu {
        left: $main-menu-width;
        left: var(--main-menu-width);
      }
    }

    #{$this}__head {
      background-color: var(--theme-color);
      color: white;
      display: flex;
      justify-content: space-between;
      padding: $header-vertical-padding 15px;
      position: relative;
      box-shadow: 0 0 1px rgba(black, .08), 0 2px 5px rgba(black, .08);

      @supports (padding: env(safe-area-inset-left)) and (padding: unquote('max(42px, 21px)'))
      {
        padding-right: unquote('max(#{$header-horizontal-padding}, env(safe-area-inset-right))');

        #{$this}--left:not(#{$this}--full-screen) & {
          padding-right: $header-horizontal-padding;
        }
      }
    }

    #{$this}__switchButton{
      .k-switch-on .k-switch-container {
        background-color: var(--theme-color);
      }

      .k-switch-on .k-switch-handle {
        background-color: var(--theme-color-lighten30);
      }
    }

    #{$this}__titles-container {
      position: relative;
      width: calc(100% - #{$close-trigger-width - $close-trigger-padding});
    }

    #{$this}__title,
    #{$this}__subtitle {
      transition: ease $speed-fast;
    }

    #{$this}__title {
      margin: 0;
      font-size: $size-small;
      transition-property: transform;

      #{$this}.has-subtitle & {
        transform: translateY(-15px);
      }
    }

    #{$this}__subtitle
    {
      font-size: 17px;

      position: absolute;
      bottom: 0;
      left: 0;

      visibility: hidden;
      overflow: hidden;

      width: 100%;
      margin: 0;

      transition-property: transform, opacity, visibility;
      white-space: nowrap;
      text-overflow: ellipsis;

      opacity: 0;

      #{$this}.has-subtitle &
      {
        visibility: visible;

        transform: translateY(8px);

        opacity: 1;
      }
    }

    #{$this}__close {
      position: absolute;
      top: 50%;
      right: $header-horizontal-padding - $close-trigger-padding;

      width: $close-trigger-width;
      height: $close-trigger-height;
      padding: $close-trigger-padding;

      transform: translateY(-50%);

      border: none;
      background-color: transparent;
    }

    #{$this}__cross {
      width: 100%;
      height: 100%;

      transition: transform ease $speed-medium;

      #{$this}__close:hover & {
        transform: rotateZ(180deg);
      }
    }

    #{$this}__container {
      flex: 1;
      display: flex;
      flex-direction: column;
      min-height: 0;

      &--iframe {
        padding: 0;
      }
    }

    #{$this}__content {
      flex: 1;
      overflow-x: hidden;
      overflow-y: auto;
      padding: $size-x-small;

      &--no-padding
      {
        padding: 0;
      }

      @supports (padding: env(safe-area-inset-left)) and (padding: unquote('max(42px, 21px)'))
      {
        padding-right: unquote('max(#{$size-x-small}, env(safe-area-inset-right))');

        &--no-padding
        {
          padding-right: env(safe-area-inset-right);
        }

        #{$this}--left &,
        #{$this}--top &,
        #{$this}--bottom &,
        #{$this}--full-screen & {
          padding-left: unquote('max(#{$size-x-small}, env(safe-area-inset-left))');

          &--no-padding
          {
            padding-left: env(safe-area-inset-left);
          }
        }

        #{$this}--left:not(#{$this}--full-screen) &:not(&--no-padding) {
          padding-right: $size-x-small;
        }
      }

      &--hideSection {
        position: relative;
        padding: 0;
      }
    }

    #{$this}__section {
      border: 1px solid $panels-border-color;
      border-radius: 3px;
      margin-bottom: $size-x-small;

      &--last {
        margin-bottom: 0;
      }

      &--dropdown {
        display: none;
        background-color: $color-light;
        border-bottom: 2px solid $color-light;
        margin: -15px;
        border-radius: 0;
      }

      &--firstVisible {
        margin-top: 30px;

      }

      &__svg {
        transition: transform ease $speed-fast;
      }
    }

    #{$this}__footer {
      display: flex;
      justify-content: center;
      box-shadow: 0 0 1px rgba(0,0,0, .08), 0 -2px 5px rgba(black, .08);
      z-index: $z-index-1;

      @supports (padding: env(safe-area-inset-left)) and (padding: unquote('max(42px, 21px)'))
      {

        padding: 0.8vh;

        #{$this}--left &,
        #{$this}--top &,
        #{$this}--bottom &,
        #{$this}--full-screen & {
          padding-left: env(safe-area-inset-left);
        }

        #{$this}--left:not(#{$this}--full-screen) & {
          padding-right: 0;
        }
      }

      & button {
        margin: 0 5px;
      }
    }

    #{$this}--dropdown {
      #{$this}__dropdownTrigger {
        width: 50px;
        height: 29px;
        position: absolute;
        left: 50%;
        bottom: -14px;
        border: none;
        border-radius: 0 0 15% 15%;
        transform: translateX(-50%);
      }

      #{$this}__content--hideSection{
        &.is-open {

          #{$this}__dropdownTrigger{
            bottom: -29px;
          }
          + #{$this}__section--firstVisible {
            margin-top: 60px;
          }
          #{$this}__section__svg{
            transform: rotate(180deg);
          }
        }
      }

    }

    #{$this}__overlay {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0,0,0,0.5);
      z-index: $z-index-9 - 1;
      display: none;
      transition: opacity $speed-medium ease;
      opacity: 0;

      @media #{$media-desktop} {
        &--from-main-menu {
          left: $main-menu-width;
          left: var(--main-menu-width);

          width: calc(100% - #{$main-menu-width});
          width: calc(100% - var(--main-menu-width));
        }
      }
    }



    //
    // SidebarMobileDropdown
    //

    // header
    #{$this}--dropdown #{$this}__head { display:none;}

    // pour que la hauteur s'aligne bien à la hauteur du div contenu
    #{$this}--dropdown { height:unset; }

    // contenu avec padding minimum, cette sidebar étant uniquement pour du mobile
    #{$this}--dropdown #{$this}__content { padding: 0; }

    #{$this}__nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $size-x-small 0;
      margin-bottom: $size-xxx-small;
    }
  }
}
