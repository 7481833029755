.c-chat-message-container
{
    $this: &;

    position: relative;

    @at-root
    {
        #{$this}__url-preview-wrapper,
        #{$this}__replied-message-wrapper
        {
            display: flex;

            .c-chat-messages-group--user &
            {
                justify-content: flex-end;
            }
        }

        #{$this}__main-content
        {
            position: relative;

            display: flex;
            margin-top: 3px;

            .c-chat-messages-group--user &
            {
                justify-content: flex-end;
            }

            #{$this}:first-child &:first-child
            {
                margin-top: 0;
            }
        }

        #{$this}__reactions
        {
            display: none;

            position: absolute;
            bottom: -14px;
            left: 1px;

            color: var(--theme-color);
            font-weight: bold;
            background-color: white;
            box-shadow: 0 2px 4px rgba(black, .15);
            line-height: $reactions-height;

            padding: 0 7px;
            border-radius: 10px;
            text-align: left;

            #{$this}.has-reactions &
            {
                display: inline-block;
            }

            .c-chat-messages-group--user &
            {
                left: auto;
                right: -1px;
            }
        }

        #{$this}.has-reactions + #{$this}
        {
            margin-top: $reactions-height;
        }

        #{$this}__reactions-icon
        {
            width: 13px;
            color: var(--theme-color);
        }

        #{$this}__actions
        {
            visibility: hidden;
            display: flex;
            align-items: center;
            margin: 0 $size-xxx-small;

            .c-chat-messages-group--user &
            {
                order: -1;
            }

            &.is-shown
            {
                visibility: visible;

                @media (hover: hover) and (pointer: fine)
                {
                    visibility: hidden;
                }
            }

            @media (hover: hover) and (pointer: fine)
            {
                #{$this}__main-content:hover > &
                {
                    visibility: visible;
                }
            }
        }



        #{$this}__action
        {
            border: none;
            color: #999;
            background-color: #f1f0f0;
            border-radius: 50%;
            margin: 0 1px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 31px;
            width: 31px;
            padding: 0;
            font-size: 18px;

            @media (hover: hover) and (pointer: fine)
            {
                background-color: transparent;

                &:hover
                {
                    background-color: #f1f0f0;
                }
            }
        }

        #{$this}__url-preview
        {
            #{$this}.has-reactions &
            {
                margin-top: $reactions-height;
            }
        }
    }
}
