.c-adndropzone {
  $this: &;

  $url: "/assets/images/placeholders/";

  margin: $size-xxx-small 0;

  @at-root
  {
    #{$this}__dropzone {
      border: 3px dashed $panels-border-color;
      border-radius: 3px;
      display: flex;
      flex-direction: row;
      //justify-content: center;
      align-items: center;
      //padding: $size-x-small;
      padding: 13px 27px;
      font-size: $size-x-small;
      margin-bottom: $size-x-small;
      margin-left: $size-x-small;
      margin-right: $size-x-small;
      background-color: white;

      &.is-active {
        border-style: solid;
        border-color: var(--theme-color);
        background-color: $color-light-light;
      }

      &.has-error {
        border-style: solid;
        border-color: $color-danger;
        background-color: $color-light-light;
      }

      & a {
        text-decoration: none;
      }

      .c-adndropzone__img &{
        flex-direction: column;
        justify-content: space-evenly;
        height: $size-x-large;
        padding: $size-xxx-small;
        position: relative;
        background-clip: padding-box;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
      }

      .c-adndropzone__img--avatar &{
        width: $size-x-large;
        background-image: url($url + "avatar.png");

      }

      .c-adndropzone__img--metaimage &{
        width: $size-xx-large;
        background-image: url($url + "landscape.png");

      }

      .c-adndropzone__img--landscape &{
        height: 160px;
        background-image: url($url + "landscape.png");
      }

      .c-adndropzone__img--logo &{
        width: $size-xx-large;
        background-image: url($url + "logo.png");
      }
    }

    #{$this}__title {
      visibility: hidden;
      padding: 0;
      position: absolute;
      top: $size-xxx-small;
      left: $size-xxx-small;
      font-weight: bold;

      .c-adndropzone__img--avatar &,
      .c-adndropzone__img--metaimage &,
      .c-adndropzone__img--landscape &,
      .c-adndropzone__img--logo &{
        visibility: visible;
      }
    }

    #{$this}__icon {
      color: $color-default;
      //width: $size-small;  // Modif julien contenu dropzone pas centré
      //height: $size-small;
      width: 30px;
      height: 30px;
      margin-right: 10px;

      #{$this}__dropzone.is-active & {
        color: var(--theme-color);
      }

      #{$this}__dropzone.has-error & {
        color: $color-danger;
      }

      .c-adndropzone__img--avatar &,
      .c-adndropzone__img--metaimage &,
      .c-adndropzone__img--landscape &,
      .c-adndropzone__img--logo &{
        display: none;
      }
    }

    #{$this}__action {
      margin-top: 10px;
      //margin-left: $size-xxx-small; // Modif julien contenu dropzone pas centré

      #{$this}__dropzone.is-active &,
      #{$this}__dropzone.is-active & a
      {
        color: var(--theme-color);
      }

      #{$this}__dropzone.has-error &
      {
        color: $color-danger;
        border-color: $color-danger;
      }

      .c-adndropzone__img--avatar & {
        margin-top: 10px;
      }

      .c-adndropzone__img--metaimage & {
        margin-top: 10px;
      }

      .c-adndropzone__img--landscape & {
        margin-top: 40px;
      }

    }

    #{$this}__action-txt {
      display: none;

      @media #{$media-desktop} {
        display: block;
      }

      #{$this}__dropzone.has-error & {
        color: $color-danger;
      }
    }

    #{$this}__info {
      margin-left: $size-xxx-small;
      display: flex;

      .c-adndropzone__img--logo & {
        width: 60%;
      }

      #{$this}__dropzone.is-active & {
        color: var(--theme-color);
      }

      #{$this}__dropzone.has-error & {
        color: $color-danger;
      }
    }

    #{$this}__info-icon {
      color: $color-dark;
      width: 30px;
      margin-right: 3px;
      cursor: pointer;
    }

    #{$this}__info-txt {
      font-size: $size-xx-small;
      color: $color-default;
      display: none;

      @media #{$media-desktop} {
        font-size: 12px;
      }

      .c-adndropzone__img--avatar &,
      .c-adndropzone__img--metaimage &,
      .c-adndropzone__img--landscape &,
      .c-adndropzone__img--logo &{
        display: block;
      }
    }

    #{$this}__files {
      display: none;

      &:not(:empty){
        display: flex;
        flex-direction: column;
      }
    }

    #{$this}__ContainernewImg {
      display: none;
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: #ffffff;
    }

    #{$this}__overlay{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(67, 74, 84, 0) 50%);
      transition: background-color ease $speed-fast;

      #{$this}__ContainernewImg:hover &{
        background-color: rgba(0, 0, 0, 0.6);
      }
    }

    #{$this}__btn-more{
      position: absolute;
      right: 10px;
      top: 10px;
      color: #FFFFFF;
      visibility: visible;

      #{$this}__ContainernewImg:hover &{
        visibility: hidden;
      }
    }

    #{$this}__container-btns{
      visibility: hidden;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 3px;

      #{$this}__ContainernewImg:hover &{
        visibility: visible;
      }
    }

    #{$this}__btn{
      width: 40px;
      height: 40px;
      margin: 10px;
      padding: 10px;
      border: transparent;
      border-radius: 50%;
      flex-shrink: 0;
      flex-grow: 0;

      @media #{$media-desktop} {
        padding: 3px;
      }

      &--edit, &--crop{
        background-color: #FFFFFF;
      }

      &--delete{
        background-color: rgba(red, 0.5);
        color: #FFFFFF;
      }
    }

    #{$this}__svg-delete, #{$this}__crop-svg{
      width: 100%;
      height: 100%;
      padding: 0;

      @media #{$media-desktop} {
        padding: 3px;
      }

      transition: transform ease $speed-medium;

      #{$this}__btn--delete:hover &, #{$this}__btn--crop:hover & {
        transform: scaleX(-1);
      }
    }
  }

}
