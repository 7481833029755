.c-timeline-embed {

  $this: &;

  @at-root {

  }

}



.editEmbedModal {
  padding: 15px;


}
