.c-spaces-members-parameters {

  padding:$size-xx-small !important;

  @include gridStyles("&__grid", 5px, 119px);

  $this: &;

  @at-root {

    #{$this}__tab-members-open {
      padding-top:0 !important;
    }

    #{$this}__grid .k-grid-header {
      display: none;
    }


  }

}