.c-images-collection {

  $this: &;
  @at-root {
    #{$this}__container{
      display: grid;
      grid-template-columns: repeat(3, auto);
      grid-gap: 3px;
      background-color: #FFFFFF;
      list-style: none;

      @media #{$media-desktop} {
        .c-sidebar--large & {
          grid-template-columns: repeat(5, auto);
        }
      }
    }

    #{$this}__li{
      position: relative;
      height: 0;
      padding-bottom: 100%;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    }

    #{$this}__contentImg{
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    #{$this}__overlay{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      cursor: pointer;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(67, 74, 84, 0) 50%);
      transition: background-color ease $speed-fast;

      #{$this}__li:hover &{
        background-color: rgba(0, 0, 0, 0.6);
      }
    }

    #{$this}__btn-more{
      position: absolute;
      right: 10px;
      top: 10px;
      color: #FFFFFF;
      visibility: visible;

      #{$this}__li:hover &{
        visibility: hidden;
      }
    }

    #{$this}__container-btns{
      visibility: hidden;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 3px;

      #{$this}__li:hover &{
        visibility: visible;
      }
    }

    #{$this}__btn{
      width: 30px;
      height: 30px;
      border: transparent;
      border-radius: 50%;
      margin: 2px;
      padding: 0;
      flex-shrink: 0;
      flex-grow: 0;

      .can-hover &{
        width: 40px;
        height: 40px;
        margin: 10px;
        padding: 7px;
      }

      &--edit, &--crop{
        background-color: #FFFFFF;
      }

      &--delete{
        background-color: rgba(red, 0.5);
        color: #FFFFFF;
      }
    }

    #{$this}__svg-pencil, #{$this}__crop-svg{
      width: 100%;
      height: 100%;
      padding: 7px;

      .can-hover & {
        padding: 3px;
      }

      transition: transform ease $speed-medium;

      #{$this}__btn--edit:hover &, #{$this}__btn--crop:hover & {
        transform: scaleX(-1);
      }
    }

    #{$this}__svg-cross{
      width: 100%;
      height: 100%;
      padding: 7px;

      .can-hover & {
        padding: 3px;
      }

      transition: transform ease $speed-medium;

      #{$this}__btn--delete:hover & {
        transform: rotateZ(180deg);
      }
    }
  }
}