.c-spaces-authenticated-users-parameters {

  padding:$size-xx-small !important;

  @include gridStyles("&__grid", 175px, 274px);
  @include gridStyles("&__askaccessusers", 175px, 136px);

  $this: &;

  @at-root {

    #{$this}__grid .k-grid-header,
    #{$this}__askaccessusers .k-grid-header
    {
        display: none;
    }

    #{$this}__icon {
      font-size:20px
    }


  }

}