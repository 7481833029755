.c-members-content {

  $this: &;
  @at-root {
    #{$this}__title{
      font-size: $size-x-small;
      padding-left: $size-x-small;
    }

    #{$this}__img {
      border-radius: 50%;
      width: 45px;
      height: 45px;

      @media #{$media-desktop} {
        width: 72px;
        height: 72px;
      }
    }

    #{$this}__grid{

      & .k-grid-header {
        display:none;
      }

      & .k-grid,
      & .k-grid-header-wrap,
      & .k-grid-header th,
      & .k-grid-content > table > tbody > tr > td
      {
        border:none;
        overflow: visible;
      }

    }

    #{$this}__grid .k-grid-content.k-auto-scrollable {
      @include custom-scrollbar();
    }

    #{$this}__grid-profil {
      display: flex;
      flex-direction : row;
    }

    #{$this}__group {
      display: flex;
      margin-bottom: $size-x-small;
      position: relative;
      &:hover {
        #{$this}__action {
          visibility: visible;
        }
      }
    }

    #{$this}__container-icons{
      min-width: 20px;
    }

    #{$this}__admin,#{$this}__observateur {
      margin-left:8px;
      margin-bottom: 2px;
      & svg{
        transform: translateY(3px);
      }
    }

    #{$this}__profile {
      margin: auto 30px auto 20px;
    }


    #{$this}__action {
      position: absolute;
      right: 10px;
      top: 40%;
      text-align: right;
    }

    #{$this}__action ul li {
      margin:0px !important;
      padding:0 !important;
    }
    #{$this}__action ul li:before {
       content: '' !important;
    }



    #{$this}__fullname {
      font-size: 15px;
      padding:0px;
      &--locked{
        opacity: 0.5;
      }
    }

    #{$this}__fullname-link {
      font-size: 15px;
      color:unset;
    }

    #{$this}__organisation {
      font-size: 12px;
      padding:0;
    }

    #{$this}__name {
      font-size: $size-xx-small;
      color: $color-info;
      font-weight: bold;
      line-height: 18px;
      padding-bottom: 1px;

      @media (min-width: $screen-sm-min) {
        font-size: 14px;
      }
    }

    #{$this}__details {
      line-height: 16px;
      padding: 0;
      font-size: 11px !important;


    }
  }
}
