$reactions-height: $size-small - $size-xxx-small;

@import 'chat';
@import "tchat-popup";
@import "tchat-ia";
@import "tchat-filelist";
@import "tchat-filelist-item";
@import 'chat-conversation';
@import 'chat-feed';
@import 'chat-messages-group';
@import 'chat-message-container';
@import 'chat-message';
@import 'chat-message-media-grid';
@import 'chat-url-preview';
@import 'chat-editor-url-preview';
@import 'chat-conversations-list';
@import 'chat-conversation-list-item';
@import 'chat-documents';
