.c-adndropzone-files {
  &__preview {
    color: $color-default;
    width: $size-small;
    height: $size-small;
    border-radius: 3px;

    &.has-loaded {
      color: $color-success;
    }
  }

  &__title {
    margin: 0 $size-xx-small;
    font-size: $size-x-small;
  }

  &__form {
    margin-left: $size-xxx-small;
  }

  &__submit {
    margin-left: $size-xxx-small;
    width: $size-xxx-small;
    padding-left: $size-xxx-small;
    padding-right: $size-xxx-small;
  }

  &__progressbar {
    margin: $size-xx-small 0;
    display: flex;
    align-items: baseline;
  }

  &__size {
    font-style: italic;
  }

  &__upload-btn {
    margin-right: $size-xx-small;
  }
}