.dataTables_wrapper
{
    label
    {
        display: inline;
    }

    .toolbar
    {
        float: left;
    }
}
.dataTable.table-bordered > caption + thead > tr:nth-child(2) > td,
.dataTable.table-bordered > caption + thead > tr:nth-child(2) > th,
.dataTable.table-bordered > colgroup + thead > tr:nth-child(2) > td,
.dataTable.table-bordered > colgroup + thead > tr:nth-child(2) > th,
.dataTable.table-bordered > thead:first-child > tr:nth-child(2) > td,
.dataTable.table-bordered > thead:first-child > tr:nth-child(2) > th
{
    border-top: 0;
    border-bottom: 0;
}

.dataTable thead input,
.dataTable tfoot input
{
    width: 100%;
    margin: 0;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after,
table.dataTable.table-condensed .sorting:after,
table.dataTable.table-condensed .sorting_asc:after,
table.dataTable.table-condensed .sorting_desc:after
{
    top: 50%;
    bottom: auto;

    transform: translateY(-50%);
}

.dataTable > thead > tr > th,
.dataTable > tfoot > tr > th
{
    vertical-align: middle;
}

.dataTable tr[role='row'] + tr:not([role='row']) td
{
    padding: $size-small;

    border-top: 0;
}

.dataTable.table-condensed tr[role='row'] + tr:not([role='row']) td
{
    padding: $size-x-small;
}


/**
    In counter to the Bootstrap use of :nth-of-type(odd) or :nth-of-type(even),
    here we use the DataTable .odd and .even classes because they're not
    updated when we insert a child line to a tr.
    So we can apply the same color to a line and its child without breaking the
    rest of the table.
*/
.dataTable.table-striped > tbody > tr.odd,
.dataTable.table-striped > tbody > tr.odd + tr:not([role='row'])
{
    background-color: #f5f7fa;
}

.dataTable.table-striped > tbody > tr.even,
.dataTable.table-striped > tbody > tr.even + tr:not([role='row'])
{
    background-color: white;
}


.dataTable.table-striped > tbody > tr.odd + tr:not([role='row']) input:disabled,
.dataTable.table-striped > tbody > tr.odd + tr:not([role='row']) textarea:disabled
{
    border-color: $panels-border-color;
}

/* Setting back the Bootstrap hover behavior */
.dataTable.table-striped.hover > tbody > tr:hover
{
    background-color: $color-light;
}


/**
    Expandable lines
 */
.dataTable.has-expandable-lines
{
    width: calc(100% - 30px) !important;
    margin-left: 30px;
}

.dataTable.has-expandable-lines > tbody > tr.is-expandable
{
    cursor: pointer;
}

.dataTable.table-condensed.has-expandable-lines
{
    width: calc(100% - #{$size-small});
    margin-left: $size-small;
}


/**
    Expandable lines arrow
 */
.dataTable.has-expandable-lines > tbody > tr.is-expandable td:first-child
{
    position: relative;
}

.dataTable.has-expandable-lines > tbody > tr.is-expandable td:first-child:before
{
    content: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" fill="#{$text-color}" viewBox="-7.999 0 30.399 30.999" height="100%" width="100%"><path d="m 0.8,30.398 c -0.205,0 -0.41,-0.078 -0.566,-0.234 -0.312,-0.312 -0.312,-0.819 0,-1.131 L 14.068,15.199 0.234,1.365 c -0.312,-0.312 -0.312,-0.819 0,-1.131 0.312,-0.312 0.819,-0.312 1.131,0 l 14.4,14.4 c 0.312,0.312 0.312,0.819 0,1.131 l -14.4,14.4 c -0.157,0.157 -0.362,0.234 -0.566,0.234 z"></path></svg>');

    position: absolute;
    top: 0;
    left: -$size-normal;

    width: $size-normal;
    height: $size-normal;
    padding: 10px;

    transition: transform ease 200ms;
    transform: rotate3d(0,0,0,0);
}

.dataTable.has-expandable-lines > tbody > tr.is-expanded td:first-child:before
{
    transform: rotate3d(0,0,1,90deg);
}

.dataTable.table-condensed.has-expandable-lines > tbody > tr.is-expandable td:first-child:before
{
    left: -30px;

    width: 30px;
    height: 30px;
    padding: 7px;
}


/**
    Expandable lines borders
*/
.dataTable.has-expandable-lines > tbody > tr > td:first-child
{
    border-left: 1px solid transparent;
}

.dataTable.has-expandable-lines > tbody > tr > td:last-child
{
    border-right: 1px solid transparent;
}

.dataTable.has-expandable-lines > tbody > tr.is-expanded > td
{
    border-top-color: var(--theme-color-light);
}

.dataTable.has-expandable-lines > tbody > tr.is-expanded > td:first-child
{
    border-left-color: var(--theme-color-light);
}

.dataTable.has-expandable-lines > tbody > tr.is-expanded > td:last-child
{
    border-right-color: var(--theme-color-light);
}

.dataTable.has-expandable-lines > tbody > tr.is-expanded + tr > td
{
    border-width: 0 1px 1px;
    border-style: solid;
    border-color: var(--theme-color-light);
}

.dataTable.has-expandable-lines > tbody > tr.is-expanded + tr + tr > td
{
    border-top: none;
}

table.dataTable {
    margin-top: 0!important;
}

table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before,
table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before {
    display: none;
}
