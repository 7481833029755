.c-kanban-card {
  $this: &;

  margin-bottom: $size-xx-small;

  box-sizing: border-box;
  position: relative;
  width: 100%;
  min-height: 4em;
  border-radius: 15px;
  background: #fff;
  cursor: pointer;
  box-shadow: 0 1px 0 #091e4240;box-shadow: 0 1px 0 #091e4240;
  &:hover{
    opacity: 1;
    outline-color: -webkit-focus-ring-color;
    outline-style: solid;
    outline-width: 2px;
    border-radius: 8px;
  }
  @at-root {
    #{$this}:hover {
      background-color: $color-light-light;

      @media (min-width: $screen-sm-min) {
        #{$this}__action {
          display: block;
          z-index: 2;
        }
      }
    }

    #{$this}:last-of-type {
      margin-bottom: 1px;
    }

    #{$this}__containerInfo{
        padding: 0px 9px 9px 9px;
    }

    #{$this}__wrapper{

    }

    #{$this}__containerInfo--withoutTags{
      padding: 9px;
    }

    #{$this}__title {
      font-size: 15px;
      margin: 0;
      font-weight: normal;
      margin-bottom: $size-xxx-small;
      word-break: break-word;
      padding-left: 4px;
    }

    #{$this}__infos {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
    }

    &__tags-group {
      display: flex;
      align-items: center;
      margin-bottom: $size-xxx-small;
    }

    #{$this}__text-info {
      margin:0;
      font-size: 13px;
      line-height: 14px;
      color: $color-default;
      padding: 0;
    }

    #{$this}__date-info {
      vertical-align: text-top;
    }

    #{$this}__circle-container--kanban {
      min-width: 24px;
      padding-left: 4px;
      color: white;

      &--checked--no-date{
        background-color: #61bd4f;
        border-radius: 2px;
        height: 20px;
        display: inline-block;
        width: 72px;
        color: white;
        &:hover{
          background-color: #519839;
        }
      }

      &--checked--with-date{
        background-color: #61bd4f;
        border-radius: 2px;
        height: 20px;
        display: inline-block;
        width: fit-content;
        padding-right: 5px;
        padding-left: 5px;
        color: white;
        &:hover{
          background-color: #519839;
        }
      }

      &--progress--with-date{
        border-radius: 2px;
        height: 20px;
        display: inline-block;
        width: fit-content;
        padding-right: 5px;
        color: #5e6c84;
        margin-right: 10px;
        &:hover{
          background-color: #ebecf0;
        }
        @media (max-width: $screen-sm-max) {
          background-color: #ebecf0;
        }
      }

      &--progress--no-date{
        border-radius: 2px;
        height: 20px;
        display: inline-block;
        width: fit-content;
        padding-right: 5px;
        color: #5e6c84;
        &:hover{
          background-color: #ebecf0;
        }
      }

      &--today--with-date{
        background-color: #f2d600;
        border-radius: 2px;
        height: 20px;
        display: inline-block;
        width: fit-content;
        padding-right: 5px;
        &:hover{
          background-color: #d9b51c;
        }
      }

      &--tolate--with-date{
        background-color:#ec9488;
        border-radius: 2px;
        height: 20px;
        display: inline-block;
        width: fit-content;
        padding-right: 5px;
        color: white;
        &:hover{
          background-color: #eb5a46;
        }
      }

      &--checked--kanban {
        #{$this}__circle--kanban {
          display: none;
        }

        #{$this}__check--kanban {
          display: block;
        }
      }
    }

    #{$this}__circle--kanban {
      height: 11px;
      width: 11px;
      border: 1px solid white;
      border-radius: 1px;
      display: inline-block;

      position: relative;
      top:1px;
    }

    #{$this}__circle--kanban--progress {
      height: 11px;
      width: 11px;
      border: 1px solid #5e6c84;
      border-radius: 1px;
      display: inline-block;

      position: relative;
      top: 1px;
    }

    #{$this}__check--kanban {
      width: 11px;
      height: 11px;
      color: white;
      margin-left: 4px;

      position:relative;
      top: 1px;
    }

    #{$this}__action {
      position: absolute;
      top: $size-xx-small;
      right: $size-xx-small;
      background-color: #fff;
      border: 1px solid $color-light;
      border-radius: 3px;
      padding-top: 2px;
      padding-left: 5px;
      padding-right: 5px;

      display: none;

      &:hover {
        background-color: $color-light;
      }

      @media (min-width: $screen-sm-min) and (max-width: $screen-md-min) {
        display: block;
      }
    }

    #{$this}__sortable-placeholder {
      display: block;
      background-color: $color-light-light;
      height: 80px;
      min-width: 262px;
      margin: 0;
      margin-bottom: $size-xxx-small;
      border-radius: 3px;
    }

    #{$this}__avatars {
      display: flex;
      padding-left: 9px;
      margin-top: 2px;
    }
  }
}