/// ============================================================================
/// # TOAST-MEDIA-PREVIEW
/// ============================================================================
/// Aperçu des media dans les toasts de notification des posts (GIFs, photos et
/// miniatures de vidéos).
/// Profondément lié à la librairie Toastify.js et à son override, notamment la
/// valeur de la variable $toast-padding.
/// ============================================================================
/// @group components
/// @see trumps.overrides.toastify
/// ============================================================================

.c-toast-media-preview
{
    $this: &;
    $toast-padding: $size-x-small;

    display: flex;
    flex-direction: column;

    height: calc(100% + (#{$toast-padding} * 2));
    margin-top: -$toast-padding;
    margin-left: $size-xx-small;

    border-left: 1px solid $color-light;

    @at-root
    {
        #{$this}__item-container
        {
            flex: 1;

            width: 80px;
            min-height: 0;

            &:nth-child(2)
            {
                border-top: 1px solid $color-light;
            }
        }

        #{$this}__item
        {
            width: 100%;
            height: 100%;

            object-fit: cover;
        }
    }
}
