.c-dashboard-nav {
  $this:&;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  @at-root
  {
    @mixin c-dashboard-nav-item--color($bg-color, $bg-color-hover){
      #{$this}__count {
        background-color: $bg-color!important;
      }

      @media (min-width: $screen-sm-max) {
        background-color: $bg-color!important;

        &:hover {
          background-color: $bg-color-hover!important;

          #{$this}__count {
            background-color: $bg-color-hover!important;
          }
        }
      }
    }

    #{$this}__item {
      position: relative;

      @media (min-width: $screen-sm-max) {
        position: inherit;
        border-radius: $size-xxx-small;
        display: flex;
        align-items: center;
        padding: $size-xxx-small $size-x-small;
        min-width: $size-large;
        height: $size-medium;
        justify-content: center;
        background-color: var(--theme-color);
        &:hover{
          background-color: var(--theme-color-light);

          #{$this}__count {
            background-color: var(--theme-color-light);
          }
        }
      }

      @media (min-width: $screen-lg-min) {
        padding: $size-x-small;
        min-width: $size-x-large;
        height: $size-normal * 2;
      }

      &--blue {
        @include c-dashboard-nav-item--color($dashboard-color-blue, $dashboard-color-blue-light);
      }

      &--red {
        @include c-dashboard-nav-item--color($dashboard-color-red, $dashboard-color-red-light);
      }

      &--orange {
        @include c-dashboard-nav-item--color($dashboard-color-orange, $dashboard-color-orange-light);
      }

      &--green {
        @include c-dashboard-nav-item--color($dashboard-color-green, $dashboard-color-green-light);
      }

      &--purple {
        @include c-dashboard-nav-item--color($dashboard-color-purple, $dashboard-color-purple-light);
      }
    }

    #{$this}__item,
    #{$this}__item:hover,
    #{$this}__item:active,
    #{$this}__item:focus {
      color: #ffffff;
      text-decoration: none;
    }

    #{$this}__logo {
      width: $size-normal;
      height: $size-normal;
      color: $color-default-light;
      font-weight: bold;

      @media (min-width: $screen-sm-max) {
        width: $size-x-small * 2;
        height: $size-x-small * 2;
        color: #ffffff;
      }

      @media (min-width: $screen-lg-min) {
        width: $size-small * 2;
        height: $size-small * 2;
      }
    }

    #{$this}__title-section {
      position: absolute;
      top: 0;
      right: 0;

      @media (min-width: $screen-sm-max) {
        position: inherit;
        padding: 0 $size-xxx-small;

        @media (min-width: $screen-lg-min) {
          padding: 0 $size-x-small;

          &--empty-count {
            #{$this}__title {
              font-size: $size-xx-small * 2;
            }
          }
        }
      }
    }

    #{$this}__title {
      display: none;

      @media (min-width: $screen-sm-max) {
        display: block;
        font-size: $size-x-small;
        margin: 0;
      }
    }

    #{$this}__count {
      color:  #ffffff;
      padding: 3px;
      border-radius: 3px;
      background-color: var(--theme-color);

      @media (min-width: $screen-sm-max) {
        font-size: $size-x-small;
      }

      @media (min-width: $screen-lg-min) {
        font-size: $size-small;
      }
    }
  }
}
