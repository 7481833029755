.c-contacts-form {

  $this: &;

  @at-root {

    #{$this}__tags {
      border: solid 1px #efefef;
      border-radius: 1px;
      .k-input {
        font-size: 13px;
        color: rgba(67, 74, 84, 0.5);
        background: transparent;
      }
      .k-chip-md {
        line-height: inherit;
      }
      .k-chip-solid-base {
        border: none;
        background: transparent;
      }
      .k-chip-label {
        overflow: initial;
      }
      .custom {
        padding: 1px $size-xxx-small;
        border-radius: 5px;
        color: #fff;
      }
      .k-chip-md {
        padding: 0;
      }
    }

    #{$this}__header-infos {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      //align-items: center;

      &__names {
        width: 100%;
        padding-right: $size-x-small;
        margin-top: $size-x-small;
      }
    }


    // correspont au dropdown k-animation-container de la recherche (kendo multiselect)
    #{$this}__searchtags {

      & .k-item > *:first-child {
        padding: $size-xxx-small;
      }

      & .k-item.k-selected {
        background: #efefef;
      }

      & .custom-row-container {
        cursor: pointer;
        display: flex;

        & .custom-row {
          border-radius: $size-xxx-small;
          padding: $size-xxx-small;
          font-size: 14px;
          color: white;
        }
      }

    }

    #{$this}__tags {

       & .k-button {
         border-width: 0px;
         padding: 0;
         background: white;
         color: white;

         & span:first-of-type  {
           padding:$size-xxx-small !important;
           display: flex;
           justify-content: center;
           align-items: center;

         }

         & .custom {
           border-radius: $size-xxx-small;
         }

         & .k-select {
           color:black !important;
           display: flex;
           align-items: center;
           justify-content: center;
           border-radius: $size-xxx-small;
           padding:$size-xxx-small;
         }

         &:hover, &:active {
           background-color: white !important;
         }
       }


    }

    #{$this}__societes-header,
    #{$this}__services-header,
    #{$this}__tags-header {
      padding:$size-xx-small;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      button svg {
        transform: translateX(1px);
      }
    }

    #{$this}__societes {
      margin-top: $size-xx-small;
      width: 100%;

      .k-input-value-text {
        font-size: 13px;
      }
    }


    #{$this}__switch-public {
       width: 80px;
    }

    #{$this}__note {
      & .k-tool {
        padding:0;
      }
    }

    #{$this}__footer
    {
      box-shadow: 0 0 1px rgba(0,0,0, .08), 0 -2px 5px rgba(black, .08);

      background: white;
      padding: $size-x-small;

      text-align: center;
    }

    .k-list-container {
      .k-list-optionlabel {
        padding: $size-xx-small;
        color: #3f51b5;
      }
    }

    .k-dropdown--flat .k-dropdown-wrap.k-hover {
      background: #ebebeb !important;
    }

    .k-dropdown--flat{
      .k-state-disabled {
        opacity: 0.6;
        border: 1px solid #e6e6e6;
      }
      .k-dropdown-wrap {
        .k-focus {
          background: #ebebeb !important;
        }
        .k-input {
          display: flex;
          align-items: center;
        }
      }
    }
    .k-focus {
      .k-input {
        color: black;
        opacity: 1 !important;
      }
    }
  }
    .k-editor {
      border: none;
      display: table;
      width: 100%;
      .k-toolbar {
        background: #f5f5f5;
        margin-bottom: 5px;
      }
      .k-button {
        background:#f5f5f5;
      }
      .k-button-group  {
        .k-selected, .k-hover {
            background: #d6d6d6;
        }
        .k-tool {
          width: 2em;
          height: 2em;
        }
      }
      .k-editor-toolbar {
        .k-icon {
          font-size: 17px;
        }
      }
  }
}
