.c-map-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: $size-xxx-small;

  &__color-button {
    width: 14px;
    height: 14px;
    font-size: 0;
    margin: 2px;
    float: left;
    cursor: pointer;
  }
}