.c-mcq-reader {
  display: flex;
  align-items: center;
  flex-direction: column;

  &__container {
    padding: $size-small;
    border: 2px solid var(--theme-color);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media #{$media-desktop}{
      min-width: 433px;
    }
    min-height: 325px;
    justify-content: center;
  }

  &__steps {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__step {
    display: none;

    &.is-shown {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
    }
  }

  &__title {
    font-size: $size-normal;
    color: var(--theme-color);
    margin: 0;
  }

  &__text {
    font-size: $size-x-small;
    text-align: center;
    margin-bottom: $size-small;
    max-width: 433px;
  }

  &__question {
    font-size: $size-x-small;
    font-weight: normal;
    color: var(--theme-color);
    max-width: 433px;
  }

  &__answers {
    margin-bottom: $size-x-small;
    margin-top: $size-x-small;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__answer {
    font-weight: normal;
    max-width: 433px;
    margin-bottom: 50px;
    margin-top: $size-x-small;
  }

  &__question-result {
    margin-top: 0;
  }

  &__result-txt {
    margin-top: 0;
    font-weight: normal;
    max-width: 433px;
    text-align: center;
  }

  &__icon {
    width: 3em;
    height: 3em;
  }
}
