$background-color: rgba(black, .85);
$directions: ('top', 'right', 'bottom', 'left');
$animation-offset: $size-xx-small;

.tooltip
{
    font-family: inherit;

    z-index: $z-index-12;

    user-select: none;
    pointer-events: none;

    @media not all and (hover: hover) and (pointer: fine)
    {
        display: none !important;
    }
}

.tooltip.in
{
    opacity: 0;
}

@each $direction in $directions
{
    .tooltip.#{$direction}.slide-in
    {
        animation: tooltip-slide-in-#{$direction} $speed-fast ease forwards;
    }

    @keyframes tooltip-slide-in-#{$direction}
    {
        from
        {
            @if $direction == 'top'
            {
                transform: translateY($animation-offset);
            }
            @else if $direction == 'right'
            {
                transform: translateX(-$animation-offset);
            }
            @else if $direction == 'bottom'
            {
                transform: translateY(-$animation-offset);
            }
            @else if $direction == 'left'
            {
                transform: translateX($animation-offset);
            }

            opacity: 0;
        }

        to
        {
            transform: none;

            opacity: 1;
        }
    }

    .tooltip.#{$direction} .tooltip-arrow
    {
        border-#{$direction}-color: $background-color;
    }
}

.tooltip-inner
{
    font-size: 11px;

    padding: $size-xxx-small $size-xx-small;

    background: $background-color;
    box-shadow: 0 4px 20px 0 rgba(black, .24);
}
