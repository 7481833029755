.c-documents-share {
  $this: &;

  @at-root {
    #{$this}__deletepreview {
      position:absolute;
      right:5px;
      z-index: 1;
    }


    #{$this}__prev-url {
      margin-bottom: 10px;
    }

    #{$this}__prev-url {
      margin-right: 40px;
    }

  }
}