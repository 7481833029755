.c-toggle-button {

    $this: &;

    background-color: $color-default;
    display: inline-block;
    height: 20px;/*******/
    width: 48px;/*****/
    border-radius: 10px;/*****/
    box-sizing: border-box;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    transition: border-color 0.25s;
    margin: -2px 4px 0 0;

    @at-root 
    {
        #{$this}--alert {
            background: #d10000 !important;
        }

        .c-toggle-button__input[disabled] + #{$this}{
            cursor: not-allowed;
            &[aria-checked=true] {
                background-color: var(--theme-color-lighten25);
            }
            &[aria-checked=false] {
                background-color: lighten($color-default, 25%);
            }
        }
        #{$this}--lg{
            height: 30px;/*******/
            width: 72px;/*****/
            border-radius: 15px;/*****/
        }
        #{$this}:before {
            //font-family: Rubik', sans-serif;
            font-family: 'Apercu', sans-serif;
            font-size: 11px;/******/
            font-weight: 400;
            color: #ffffff;
            line-height: 1;
            display: inline-block;
            position: absolute;
            top: 5.5px;/*****/
            height: 12px;
            width: 20px;
            text-align: center;
        }
        #{$this}--lg:before{
            font-size: 16px;/******/
            top: 8.5px;/*****/
        }
        #{$this}[aria-checked=false]:before {
            content: 'OFF';
            right: 7px;/*****/
        }
        #{$this}--lg[aria-checked=false]:before {
            content: 'OFF';
            right: 17px;/*****/
        }
        #{$this}[aria-checked=true]:before {
            content: 'ON';
            left: 7px;/******/
        }
        #{$this}--lg[aria-checked=true]:before {
            content: 'ON';
            left: 10px;/******/
        }
        #{$this}--answer[aria-checked=false]:before {
            content: 'Faux';
        }
        #{$this}--answer[aria-checked=true]:before {
            content: 'Vrai';
        }
        #{$this}[aria-checked=true] {

            background-color: var(--theme-color);
        }
        #{$this}:after {
            background-color: #ffffff;
            content: '\0020';
            display: inline-block;
            position: absolute;
            top: 2px;/*****/
            height: 16px;/******/
            width: 16px;/*****/
            border-radius: 50%;
            transition: left 0.25s;
        }
        #{$this}[aria-checked=false]:after {
            left: 2px;/*****/
        }
        #{$this}[aria-checked=true]:after {
            left: 30px;/****/
        }
        #{$this}--lg:after {
            background-color: #ffffff;
            content: '\0020';
            display: inline-block;
            position: absolute;
            top: 3px;/*****/
            height: 24px;/******/
            width: 24px;/*****/
            border-radius: 50%;
            transition: left 0.25s;
        }
        #{$this}--lg[aria-checked=false]:after {
            left: 3px;/*****/
        }
        #{$this}--lg[aria-checked=true]:after {
            left: 45px;/****/
        }
    }
}

.c-toggle-button__label{
    display: inline-block;
}

