.c-fading-edges-wrapper
{
    $this: &;
    $fade-distance: $size-xxx-small;

    position: relative;

    @at-root
    {
        #{$this}::before,
        #{$this}::after
        {
            content: '';

            position: absolute;
            z-index: $z-index-2;
            right: 0;
            left: 0;

            height: $fade-distance;

            pointer-events: none;
        }

        #{$this}::before
        {
            top: 0;

            background: linear-gradient(to bottom, white, rgba(white, 0));
        }

        #{$this}::after
        {
            bottom: 0;

            background: linear-gradient(to top, white, rgba(white, 0));
        }

        #{$this}--horizontal
        {
            &::before,
            &::after
            {
                width: $fade-distance;
                height: auto;
            }

            &::before
            {
                right: auto;
                bottom: 0;

                background: linear-gradient(to right, white, rgba(white, 0));
            }

            &::after
            {
                top: 0;
                left: auto;

                background: linear-gradient(to left, white, rgba(white, 0));
            }
        }

        #{$this}--start-only::after
        {
            display: none;
        }

        #{$this}--end-only::before
        {
            display: none;
        }
    }
}
