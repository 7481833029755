.c-step-progressbar {
  position: relative;
  height: 6px;
  width: 100%;
  background: $color-default-light;

  &--sidebar {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &__progress {
    position: relative;
    height: 6px;
    width: 0;
    background: $color-mint;
    transition: width .25s ease;
  }

  &__step {
    position: absolute;
    top: 3px;
    height: 14px;
    width: 14px;
    text-align: center;
    color: #fff;
    font-size: 15px;
    background-color: $color-default-light;
    border-radius: 50%;
    transform: translate(-50%,-50%);
    cursor: pointer;
    transition: height .1s ease,width .1s ease,background-color .1s ease;

    &.is-active {
      background-color: $color-mint;
    }
    
    & span {
      display: none;
    }

    &.is-current,
    &:hover {
      background-color: $color-mint;
      height: 20px;
      width: 20px;
      line-height: 21px;
      font-family: Arial;

      & span {
        display: block;
      }
    }
  }
}