label
{
    font-size: 15px;
    font-weight: 500;

    display: block;

    margin-bottom: $size-xxx-small;

    transition: color ease 150ms;

    span
    {
        width: 233px;
        display: block;
        float:left;
        text-align: left;
        line-height: 20px;
        padding-right: 10px;
        padding-left: 10px;
    }
}

input,
textarea,
[contenteditable=true]
{
    user-select: text;
}

input,
textarea
{
    display: block;

    width: 100%;
    max-width: 100%;
    margin: $size-xx-small 0;
    padding: $size-xx-small 0;

    transition: ease 150ms;
    transition-property: color, background-color, border-color;
    text-align: left;

    color: $text-color;
    border: none;
    border-bottom: 1px solid $panels-border-color;
    border-radius: 0;
    outline: 0;
    background: none;

    &::placeholder
    {
        transition: color ease $speed-fast;

        color: $color-default-light;
    }

    &:focus
    {
        border-color: var(--theme-color-light);
    }

    &:disabled
    {
        color: $panels-border-color;
        border-bottom-style: dashed;
    }
}

input:not([type='radio']):not([type='checkbox']),
textarea
{
    -webkit-appearance: none;
}

input
{
    height: $size-small + $size-xxx-small;

    &[type='radio'],
    &[type='checkbox']
    {
        width: auto;
        height: auto;
    }
}

textarea
{
    max-width: 100%;
    height: $size-large;

    resize: none;
}

fieldset
{
    padding-left: 5px;
    padding-right: 5px;
}
