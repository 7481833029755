
.c-timeline-file
{
    $this:&;
    font-size: 9px;

    position: relative;


    width: $size-medium;
    height: $size-medium;
    margin-right: 2.5px;
    margin-bottom: $size-xxx-small;
    margin-left: 2.5px;
    padding: $size-xxx-small;

    color: $color-dark-light;
    background-color: $color-light;

    @at-root
    {
        #{$this}__header {
            border-bottom: none !important;
        }

        #{$this}__container {
            padding:0px !important;
        }


        #{$this}__linkcontainer {
            max-width:calc(100vw - 100px);
            font-size:15px;
        }

        #{$this}__linkcontainer a {
            color:$color-dark !important;
        }


        #{$this}__author {
            font-size:12px;
            color:$color-dark-light !important;
        }


        #{$this}__item, #{$this}__linkcontainer {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }



        #{$this}__icon {
            padding:0px !important;margin:0px !important;
        }

        #{$this}--image
        {
            padding: 0;

            background: none;


            &:before
            {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                content: '';

                border: 1px solid $panels-border-color;
            }

            & > img
            {
                z-index: $z-index-1;

                width: 100%;
                height: 100%;

                object-fit: cover;
            }
        }

        #{$this}__remove
        {
            font-size: 14px;

            position: absolute;
            top: 0;
            right: 0;

            width: $size-small;
            height: $size-small;
            padding: 0;

            color: rgba(255,255,255,.8);
            border: none;
            background-color: transparent;
            text-shadow: 0 1px 1px rgba(0,0,0,.16);
        }

        #{$this}:hover #{$this}__remove
        {
            color: white;
            background-color: rgba(255, 0, 0, .5);
        }

        #{$this}__icon
        {
            font-size: 26px;
            padding-top: 5px;
            display: flex;
            align-items: center;
            flex: 1;
            justify-content: center;
        }

        #{$this}__name
        {
            font-weight: bold;
            line-height: 1;

            overflow: hidden;

            white-space: nowrap;
            text-overflow: ellipsis;

            height:25px;
            margin-top:15px;
            display: block;

            color: $color-dark-light;
        }

        #{$this}__preview
        {
            overflow: hidden;
            display: block;
            text-align:center;
        }

        #{$this}__preview .img-responsive
        {
            display:inline;
        }


        #{$this}__preview img:hover
        {
            opacity: 0.6;
        }



        #{$this}__sidebar .c-sidebar__head
        {
            height:30px !important;
            padding:20px !important;
        }

        #{$this}__sidebar_viewer
        {
            z-index: 3000;
        }

        #{$this}__sidebar_viewer .c-sidebar__head
        {
            display: none !important;
        }

        #{$this}__sidebar .c-sidebar__title
        {
            font-size:17px !important;
            position:absolute;
            top:-10px;
        }

        #{$this}__sidebar .c-sidebar__close
        {
            right: 5px !important;
        }
    }
}



.c-timeline-files
{
    $this:&;

    @at-root {

        #{$this}__link {
            cursor: pointer;
        }

        #{$this}__link:hover {
            color: var(--theme-color);
        }
    }

}
