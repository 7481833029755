.c-chart-legend {
  $this:&;

  @at-root
  {
    #{$this}-chart {
      &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        flex-wrap: wrap;
      }

      &__inner {
        height: 100%;
        display: inline-block;
      }
    }

    #{$this}__container {
      display: flex;
      justify-content: center;
    }

    #{$this}__list {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: $size-xx-small;

      & li {
        display: flex;
        align-items: center;
        margin-bottom: $size-x-small;
        margin-left: $size-xxx-small;
        cursor: pointer;

        &.is-hidden {
          #{$this}__text {
            color: $color-default;
          }
        }
      }

      & span {
        display: inline-block;
      }

      &--side {
        @media (min-width: $screen-xs-max) {
          display: block;
          margin-top: 0;
        }
      }
    }

    #{$this}__color {
      width: $size-xxx-small * 2;
      height: $size-xxx-small * 2;
      margin-right: $size-xxx-small;
    }

    #{$this}__text {
      font-size: $size-xxx-small * 2;
      color: $color-dark;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}