.c-spaces-front {

  $this: &;

  @at-root {

    //region POPOVER - spécifique au form d'envoie de message sur la page principale
    $header-color: #212529 ;
    $popover-color-lighten:  lighten($header-color, 5%) ;

    $popover-fontsize : 11px;
    $popover-invert-color: white;


    #{$this}__popover {
      width:350px !important;
      max-width: 360px !important;
      height:340px;
      top: 20px !important;
      left:-10px !important;
      background:  $popover-color-lighten !important;
    }


    #{$this}__popover .popover-arrow:after {
      border-bottom-color:  $popover-color-lighten !important;
    }

    #{$this}__popover .popover-body {
      color:$popover-invert-color;
    }

    #{$this}__popover-mail-creator {
      margin-top:10px;
      font-size: $popover-fontsize;
    }

    #{$this}__popover-message {
      background:$popover-invert-color;
      padding:5px;
      font-size: 15px;
      resize:none;
    }

    #{$this}__popover-message-success {
      font-weight: bold;
      font-size: 14px;
      color:green;
      display: none;
    }

    #{$this}__popover-message-error {
      font-weight: bold;
      font-size: 13px;
      color:red;
      display: none;
    }





    #{$this}__popover-visitor {
      font-size: $popover-fontsize;
      margin-top: 10px;
    }

    #{$this}__popover-visitor div {
      margin-top: 10px;
    }


    #{$this}__popover-visitor input {
      background: $popover-invert-color;
      font-size: $popover-fontsize;
      padding:5px;
    }

    #{$this}__popover-visitor button {
      font-size: $popover-fontsize;
    }




    #{$this}__popover-subscribe {
      height:170px !important;

      & button {
        font-size: 13px !important;
      }

    }
    //endregion


    //region ENTETE ORGANISATION
    #{$this}__header-organisation {
      padding:0 !important;
      height:60px;
    }

    #{$this}__organization-logo-container,
    #{$this}__organization-logo {
      height: inherit;
    }


    #{$this}__header-organisation-text {
      margin-top:5px;
    }

    #{$this}__header-message {
      margin-right: 15px;
      margin-bottom:5px;
      text-decoration: none;
      color:white;
      border:none;

      transition: all 400ms cubic-bezier(.47,1.64,.41,.8);

      &:hover {
        color :white;
        transform: scale(1.2);
      }

      i {
        font-size: 31px
      }
    }


    //endregion


    //region ENTETE SPACES
    #{$this}__sectionstick {
      height: 155px;
    }



    #{$this}__headerstick {
      height: 150px;

      &.stuck  {
        top: 20px;
        position: fixed;
        width: 100%;
        z-index: 15;
      }
    }
    //endregion



    //region ENTETE SPECIFIQUE - aucune bannière n'a été définie
    //#{$this}__headerstick-nobackground {
    //  height: 60px;
    //  position: fixed;
    //  width: 100%;
    //  z-index: 15;
    //  top : 60px;
    //}
    //
    //#{$this}__headerstick-nobackground h3 {
    //  padding-left: 15px;
    //  padding-top: 10px;
    //  width:100%;
    //  color:white;
    //  text-shadow: 0 0 3px   rgba(0, 0, 0, .8);
    //  text-rendering: optimizelegibility;
    //
    //  white-space: nowrap;
    //  overflow: hidden;
    //  text-overflow: ellipsis;
    //}
    //
    //#{$this}__headerstick-nobackground h3.withlogo {
    //  margin-left:50px;
    //}
    //
    //#{$this}__header-spaceslogo-nobackground {
    //  position: absolute;
    //  top: 5px;
    //  left:5px;
    //  z-index: 12;
    //  width: 50px;
    //}
    //
    //#{$this}__header-spaceslogo-thumb-nobackground {
    //  height:50px;
    //}
    //
    //#{$this}__header-spaceslogo-thumb-nobackground .u-img-cover {
    //  max-width: 50px;
    //}
    //endregion



    //region BANNIERE
    #{$this}__banner-color {
      width:100%;
      height:100%;
    }

    #{$this}__banner {
      position: absolute;
      top: 60px;
      height: 250px !important;
      margin: 0 !important;
      padding: 0 !important;
      left: 0;
      right: 0;

      transition: height 0.8s;

      #{$this}__headerstick.stuck & {
        top:-100px;
        height:200px !important;

        transition: height 0.8s;
      }
    }
    //endregion


    //region LOGO quand il y a BANNIERE
    #{$this}__header-spaceslogo {
      width: 110px;
      height: 110px;
      margin-left: $size-x-small;

      position: absolute;
      top: 100px;
      z-index: 12;

      object-fit: cover;
    }
    //endregion




    //region LOGO et TITRE si Sticky et BANNIERE
    #{$this}__header-spaceslogo-bar {
      position: absolute;
      top:44px;
      left: -55px;
      z-index: 12;
      height: 50px;
      width: 50px;
      object-fit: cover;

      #{$this}__headerstick.stuck & {
        left:5px;
        transition: left 0.8s;
      }
    }

    #{$this}__header-title-bar {
      #{$this}__headerstick.stuck & {
        margin-left:50px;
      }
    }
    //endregion



    //region TITRE et SOUS-TITRE
    #{$this}__headerstick h3,
    #{$this}__headerstick h5 {
      position: absolute;
      top: 220px;
      padding-left: 15px;
      width:100%;
      color:white;
      text-shadow: 0 0 3px   rgba(0, 0, 0, .8);
      text-rendering: optimizelegibility;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    #{$this}__headerstick h5 {
      top: 270px;
      transition: top 0.8s;
    }


    #{$this}__headerstick.stuck h3,
    #{$this}__headerstick.stuck h5 {
      position:absolute;
      top:25px; // top:55px -> JFC 21-01-2025 , bug depuis ce jour alors que cela fonctionnait avant, pas d'explication
      padding-left:15px;
    }

    #{$this}__headerstick.stuck h5 {
      display: none;
    }
    //endregion



    //region CONTENT mode CARDS
    #{$this}__card-embed {
      pointer-events: none;

      iframe, embed {
        width:  100% !important;
        height: 150px !important;
      }
    }


    #{$this}__content {
      padding-top: 2rem!important;
      padding-bottom: 3rem!important;
    }

    #{$this}__content-item {
      transition: all .2s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }

      .card {
        overflow: hidden;
      }

      .card-image {
        height: 150px;
        width: 100%;
        overflow: hidden;
        background: #ccc;

        img {
          height: 100%;
        }
      }

      .card-body {
        display: block;
        min-height: 180px;
        padding:0;
        color: $header-color ;
        text-decoration: none;


        > * {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        // badge de type de fichier
        #{$this}__content-common-doctype {
          transform: translate(6px,6px) ;
          font-size: 11px;
          z-index: $z-index-2;

          @media (min-width: $screen-sm-min)
          {
            transform: translate(5px,5px) ;
          }
        }
      }


      .card-text {
        position: absolute;
        width: 100%;
        text-align: left;
        top:150px;
      }

      .card-text-inner {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 15px;
        padding-left: 5px;
        padding-top: 5px;
        padding-right: 5px;
      }

      .u-img-cover {
        max-height: 150px;
      }
    }


    #{$this}__content-icons {
      font-size: 101px !important;
      margin-top:25px;
    }
    //endregion



    //region CONTENT mode LIST
    #{$this}__content-item-list {
      height:70px;
      text-decoration: none;
      color: $header-color;

      @media (min-width: $screen-sm-min)
      {
        margin-left:20px;
      }

      &:hover {
        color: $header-color;
        transition: background-color 0.3s;
        background-color: #dee2e6;
      }

      h6{
        text-align: left;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }


    #{$this}__content-item-list-type {
      height:50px;
      text-align: center;
    }

    #{$this}__content-icons-list {
      font-size: 51px !important;
    }

    #{$this}__content-text-list {
      margin-left:80px;
      margin-top:15px;

      .badge  {
        margin-top: 27px;
        font-size: 16px !important;
        font-weight:normal;
        padding:0;
        color: #8f8f8f;
        background: none;
        border:none;
        position: absolute;
      }
    }

    #{$this}__content-thumb-list {
      position: absolute;
      height: 50px;
      width: 50px;
      margin-top:10px;
    }
    //endregion


    //region VIDEO
    #{$this}__modal-video .modal-content  {
      background:black;
      color: #afafaf;
    }


    #{$this}__modal-video .btn-download {
      background: transparent;
      padding: 0.5rem 0.5rem;
      margin: -0.5rem -0.5rem -0.5rem auto;
      border: none;
      color: gray;
      font-size: 22px;
      margin-right: 1px;

      &:hover {
        filter: invert(1) grayscale(100%) brightness(130%);
      }
    }
    //endregion


    //region EMBED
    #{$this}__modal-external-embed {
      iframe, embed {
        width:  100% !important;
        height:  calc(#{vh(100)} - 150px) !important;
      }

      div:first-child {
        padding:0px !important;
        margin:0px !important;
      }
    }
    //endregion

  }

}
