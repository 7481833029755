.c-badge
{
    $this: &;
    $border-width: 2px;

    position: relative;

    @at-root
    {
        #{$this}::before
        {
            content: attr(badge-content);

            font-size: 10px;
            font-weight: 500;
            line-height: 1;

            position: absolute;
            z-index: $z-index-3;
            top: -$border-width;
            right: -$border-width;

            display: flex;
            align-items: center;
            justify-content: center;

            min-width: $size-x-small + ($border-width * 2);
            height: $size-x-small + ($border-width * 2);
            padding: 3px;

            color: white;
            border: $border-width solid white;
            border-radius: 3px + $border-width;
            background-color: red;
        }


        // =====================================================================
        // Position modifiers
        //
        #{$this}--top-left::before
        {
            top: -$border-width;
            right: auto !important;
            left: -$border-width;
        }

        #{$this}--bottom-left::before
        {
            top: auto !important;
            right: auto !important;
            bottom: -$border-width;
            left: -$border-width;
        }

        #{$this}--bottom-right::before
        {
            top: auto !important;
            right: -$border-width;
            bottom: -$border-width;
        }

        #{$this}--out-offset
        {
            &::before
            {
                top: -$border-width - $size-xxx-small;
                right: -$border-width - $size-xxx-small;
            }

            &#{$this}--top-left::before
            {
                top: -$border-width - $size-xxx-small;
                left: -$border-width - $size-xxx-small;
            }

            &#{$this}--bottom-left::before
            {
                bottom: -$border-width - $size-xxx-small;
                left: -$border-width - $size-xxx-small;
            }

            &#{$this}--bottom-right::before
            {
                right: -$border-width - $size-xxx-small;
                bottom: -$border-width - $size-xxx-small;
            }
        }

        // Don't use this, it's bad
        #{$this}--inline::before
        {
            top: 50%;

            transform: translateY(-50%);
        }

        // =====================================================================
        // Appearance modifiers
        //
        #{$this}--bare::before
        {
            content: '';

            width: $size-xx-small + $border-width;
            min-width: auto;
            height: $size-xx-small + $border-width;

            border-radius: 50%;
        }

        // =====================================================================
        // Behavior modifiers
        //

        ///
        /// If the --allow-0 modifier is not present, the badge won't appear if
        /// its content is 0.
        ///
        /// @example html - The badge will appear with or without the modifier
        /// <button class="c-badge c-badge--allow-0" badge-content="42">Action</button>
        ///
        /// @example html - The badge will appear only if the modifier is present
        /// <button class="c-badge c-badge--allow-0" badge-content="0">Action</button>
        ///
        #{$this}:not(#{$this}--allow-0)[badge-content='0']::before
        {
            display: none;
        }

        ///
        /// If the --allow-empty modifier is not present, the badge won't appear
        /// if it has no content.
        ///
        /// @example html - The badge will appear with or without the modifier
        /// <button class="c-badge c-badge--allow-empty" badge-content="42">Action</button>
        ///
        /// @example html - The badge will appear only if the modifier is present
        /// <button class="c-badge c-badge--allow-empty" badge-content="">Action</button>
        /// <button class="c-badge c-badge--allow-empty" badge-content>Action</button>
        /// <button class="c-badge c-badge--allow-empty">Action</button>
        ///
        #{$this}:not(#{$this}--allow-empty)
        {
            &[badge-content='']::before,
            &:not([badge-content])::before
            {
                display: none;
            }
        }
    }
}
