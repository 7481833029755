.c-tasks-details {
  display: flex;
  width: 100%;
  flex-direction: column;
  
  @media (min-width: $screen-sm-min) {
    flex-direction: row;
  }

  &__infos {
    padding: $size-xx-small;
    flex: 1;
    min-width: 0;
  }

  &__actions {
    padding: $size-xx-small;
    width: 150px;
  }

  &__popup {
    position: absolute;
    z-index: $z-index-9;
    top: 0;
    right: 0;
    border-radius: 4px;
    border: 1px solid $color-default-light;
    background-color: white;
    box-shadow: 0 4px 20px 0 rgba(black, .24);

    &--right {
      right: 24px;
      top: 285px;
    }
  }

  &__popup-overlay {
    position:absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: $z-index-1;
    cursor: pointer;
  }

  &__textarea{
    border: 2px solid #0079bf;
    border-radius: 3px;
    padding:  8px;
    &--descriptionTask{
      font-size: 15px ;
      overflow: hidden;
      overflow-wrap: break-word;
      min-height: 40px;
    }
  }

  &__date{
    display: inline-flex;
  }

  &__dateCheck{
    vertical-align: middle;
    margin: 0 !important;
  }
}