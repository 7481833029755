.draggable-mirror {
  background-color: white;
  border: none;
  z-index: $z-index-12;
  box-shadow: 0 2px 5px rgba(0,0,0,0.16);
}

li.draggable-source--is-dragging {
  opacity: 0.2;
  position: relative;
  &::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 3px dashed #3e3e3e;
    z-index: $z-index-1;
  }
}



.c-sortable{
  $this:&;
  @at-root {

    #{$this}__list{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items:center;
      padding: 10px 5px;
      transition: background-color ease $speed-fast;
      position: static;

      &.is-upload{
        background-color: $color-default;
      }

      &:hover{
        background-color: #f8fdff;
        //cursor: grab;
      }

      .c-sidebar &{
        border-radius: 0;
        border-left: none;
        border-right: none;
      }

      .c-adndropzone-async__categories &{
        padding: 12px 5px;
      }
    }

    #{$this}__left-container{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items:center;
      min-width: 0;
      flex: 1;
      margin-right: 2px;
      transition: transform $speed-medium ease;
      transform: none;

      #{$this}__list.is-showing-actions &{
        transform: translateX(-110%);
      }

      .c-sidebar:not(.c-sidebar--large):not(.c-sidebar--full-screen) & {
        //width: 90%;

      }
    }

    #{$this}__container-img{
      border: none;
      height: 30px;
      width: 30px;
      margin-right: 10px;
      flex-grow: 0;
      flex-shrink: 0;

      &:hover{
        cursor: pointer;
      }
    }

    #{$this}__drag{
      flex-grow: 0;
      flex-shrink: 0;
      visibility: visible;
      width: 35px;
      height: 35px;
      margin-right: 5px;

      &:hover{
        cursor: pointer;
      }
    }

    #{$this}__svg{
      width: 100%;
      height: 100%;
      margin-right: 5px;
      opacity: 1;
      padding: 10px;

      .can-hover & {
        opacity: 0;
        visibility: hidden;
      }

      &:hover{
        cursor: grab;
        color: var(--theme-color);
      }

      #{$this}__list:hover &{
        opacity: 1;
        visibility: visible;
      }
    }

    #{$this}__imgSvg{
      width: 100%;
      height: 100%;
      padding: 2px;

      &:hover{
        color: var(--theme-color);
      }
    }

    #{$this}__text{
      padding: 0;
    }

    #{$this}__block{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items:center;
      position: absolute;
      width: calc(100% - 55px);
      transition: transform $speed-medium ease;
      transform: translateX(110%);

      @media #{$media-desktop} {
        margin-left: auto;
        transform: none;
        flex: 1;
        position: static;
        width: auto;
        right: auto;
      }

      .c-sidebar:not(.c-sidebar--large):not(.c-sidebar--full-screen) &{
        margin-left: 0;
        transform: translateX(110%);
        position: absolute;
        width: calc(100% - 7px);
      }

      #{$this}__list.is-showing-actions & {
        transform: translateX(0) !important;
      }

      .draggable-mirror &{
        visibility: hidden;
      }
    }

    #{$this}__btn-more{
      padding: 6px;
      visibility: visible;
      transition: visibility 0s, opacity 1s ease;
      opacity: 1;

      #{$this}__list.is-showing-actions &{
        visibility: hidden !important;
      }

      @media #{$media-desktop}{
        visibility: hidden;
        display: none;
      }

      .c-sidebar:not(.c-sidebar--large):not(.c-sidebar--full-screen) &{
        visibility: visible;
        display: inline-flex;
      }
    }

    #{$this}__btn{
      width: 30px;
      height: 30px;
      border: transparent;
      border-radius: 50%;
      margin: 2px;
      padding: 0;
      flex-shrink: 0;
      flex-grow: 0;

      @media #{$media-desktop}{
        padding: 7px;
      }

      &--fix{
        background-color: var(--theme-color) ;
        color: white;

        .c-sidebar:not(.c-sidebar--large):not(.c-sidebar--full-screen) & {
          display: block;
        }
      }

      &--return{
        transition: transform ease $speed-medium;
        transform: rotateZ(180deg);

        @media #{$media-desktop}{
          display: none;
          transform: none;
        }
        &:hover{
          transform: rotateZ(180deg);
        }
      }

      &--delete{
        background-color: rgba(red, 0.5);
        color: #FFFFFF;
      }
    }

    #{$this}__svg-cross, #{$this}__svg-return {
      width: 100%;
      height: 100%;
      padding: 7px;

      @media #{$media-desktop}{
        padding: 3px;
      }
    }

    #{$this}__svg-return {
      transition: transform ease $speed-medium;

      #{$this}__btn--delete:hover & {
        transform: rotateZ(180deg);
      }
    }
  }
}
