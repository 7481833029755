.pagination
{
    display: flex;
    align-items: center;

    margin-right: -$size-x-small;
    margin-left: -$size-x-small;

    &--center
    {
        justify-content: center;
    }

    &--right
    {
        justify-content: flex-end;
    }
}

.pagination > .active > a
{
    font-weight: bold;
    color: $text-color;
    border: none;
    border-radius: 4px;
    background: none;
}


.pagination > li > a
{
    line-height: 1;
    padding: $size-xx-small;
    border-radius: 4px;
    background-color: transparent;
    font-size: 15px;
    color: $text-color;
    border: none;
}

.pagination > li > a:hover,
.pagination > li > a:active,
.pagination > li > a:focus
{
    font-weight: normal;

    transition: ease 200ms;
    transition-property: color, background-color;

    color: white !important;
    border: none;
    background-color: var(--theme-color-light);

}


.pagination > .active > a:hover
{
    font-weight: bold;

    background-color: var(--theme-color-light);
}
.pagination > .disabled > a,
.pagination > .disabled > a:hover
{
    color: $color-light;
}

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover
{
    background-color: transparent;
}

.pagination > li:last-child > a,
.pagination > li:first-child > a
{
    padding: 0;
    font-size: 0;
}
.pagination > li:first-child > a:before
{
    content: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" fill="#{$text-color}" viewBox="-7.999 0 30.399 30.999" height="100%" width="100%"><path d="m 15.199,30.398 c 0.205,0 0.41,-0.078 0.566,-0.234 0.312,-0.312 0.312,-0.819 0,-1.131 L 1.931,15.199 15.765,1.365 c 0.312,-0.312 0.312,-0.819 0,-1.131 -0.312,-0.312 -0.819,-0.312 -1.131,0 l -14.4,14.4 c -0.312,0.312 -0.312,0.819 0,1.131 l 14.4,14.4 c 0.157,0.157 0.362,0.234 0.566,0.234 z"></path></svg>');

    float: left;

    width: $size-normal;
    height: $size-normal;
    padding: $size-xx-small;

    background: none;
}

.pagination > li.disabled:first-child > a:before,
.pagination > li.disabled:first-child > a:hover:before {
    content: inline-for('<svg xmlns="http://www.w3.org/2000/svg" fill="#{$color-light}" viewBox="-7.999 0 30.399 30.999" height="100%" width="100%"><path d="m 15.199,30.398 c 0.205,0 0.41,-0.078 0.566,-0.234 0.312,-0.312 0.312,-0.819 0,-1.131 L 1.931,15.199 15.765,1.365 c 0.312,-0.312 0.312,-0.819 0,-1.131 -0.312,-0.312 -0.819,-0.312 -1.131,0 l -14.4,14.4 c -0.312,0.312 -0.312,0.819 0,1.131 l 14.4,14.4 c 0.157,0.157 0.362,0.234 0.566,0.234 z"></path></svg>');
}

.pagination > li:first-child > a:hover:before,
.pagination > li:first-child > a:active:before
{
    content: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" fill="#{var(--theme-color-light)}" viewBox="-7.999 0 30.399 30.999" height="100%" width="100%"><path d="m 15.199,30.398 c 0.205,0 0.41,-0.078 0.566,-0.234 0.312,-0.312 0.312,-0.819 0,-1.131 L 1.931,15.199 15.765,1.365 c 0.312,-0.312 0.312,-0.819 0,-1.131 -0.312,-0.312 -0.819,-0.312 -1.131,0 l -14.4,14.4 c -0.312,0.312 -0.312,0.819 0,1.131 l 14.4,14.4 c 0.157,0.157 0.362,0.234 0.566,0.234 z"></path></svg>');
}

.pagination > li:first-child > a:hover,
.pagination > li:first-child > a:focus,
.pagination > li:last-child > a:hover,
.pagination > li:last-child > a:focus
{
    background: none;
}

.pagination > li.disabled:last-child > a:before,
.pagination > li.disabled:last-child > a:hover:before {
    content: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" fill="#{$color-light}" viewBox="-7.999 0 30.399 30.999" height="100%" width="100%"><path d="m 0.8,30.398 c -0.205,0 -0.41,-0.078 -0.566,-0.234 -0.312,-0.312 -0.312,-0.819 0,-1.131 L 14.068,15.199 0.234,1.365 c -0.312,-0.312 -0.312,-0.819 0,-1.131 0.312,-0.312 0.819,-0.312 1.131,0 l 14.4,14.4 c 0.312,0.312 0.312,0.819 0,1.131 l -14.4,14.4 c -0.157,0.157 -0.362,0.234 -0.566,0.234 z"></path></svg>');
}

.pagination > li:last-child > a:before
{
    content: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" fill="#{$text-color}" viewBox="-7.999 0 30.399 30.999" height="100%" width="100%"><path d="m 0.8,30.398 c -0.205,0 -0.41,-0.078 -0.566,-0.234 -0.312,-0.312 -0.312,-0.819 0,-1.131 L 14.068,15.199 0.234,1.365 c -0.312,-0.312 -0.312,-0.819 0,-1.131 0.312,-0.312 0.819,-0.312 1.131,0 l 14.4,14.4 c 0.312,0.312 0.312,0.819 0,1.131 l -14.4,14.4 c -0.157,0.157 -0.362,0.234 -0.566,0.234 z"></path></svg>');

    float: right;

    width: $size-normal;
    height: $size-normal;
    padding: $size-xx-small;

    background: none;
}

.pagination > li:last-child > a:hover:before,
.pagination > li:last-child > a:active:before
{
    content: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" fill="#{var(--theme-color-light)}" viewBox="-7.999 0 30.399 30.999" height="100%" width="100%"><path d="m 0.8,30.398 c -0.205,0 -0.41,-0.078 -0.566,-0.234 -0.312,-0.312 -0.312,-0.819 0,-1.131 L 14.068,15.199 0.234,1.365 c -0.312,-0.312 -0.312,-0.819 0,-1.131 0.312,-0.312 0.819,-0.312 1.131,0 l 14.4,14.4 c 0.312,0.312 0.312,0.819 0,1.131 l -14.4,14.4 c -0.157,0.157 -0.362,0.234 -0.566,0.234 z"></path></svg>');
}
