.c-chat
{
    $this: &;

    display: flex;

    @at-root
    {
        .l-app.is-chat-xs-or-wider #{$this},
        .c-tchat-popup #{$this},
        .c-course-aside #{$this}
        {
            height: 100%;
        }

        #{$this}__tab-content   {
            padding-top: $size-xxx-small !important;
        }


        //region membres et documents de la conversations
        #{$this}__grid-members,
        #{$this}__grid-documents,{
            border:none !important;
        }


        #{$this}__grid-members tr,
        #{$this}__grid-documents tr {
            cursor: pointer;
        }

        #{$this}__grid-documents tr td[role="gridcell"]:first-of-type {
            padding: 0 0 0 $size-xxx-small;
            background: white;
        }

        #{$this}__grid-members .k-grid-header,
        #{$this}__grid-documents .k-grid-header {
            padding-right: 7px !important;  // webkit-scrollbar est en width 7px, on diminue donc la taille réservée par défaut de 16px à 8px
        }

        #{$this}__grid-members .k-grid-header-wrap,
        #{$this}__grid-documents .k-grid-header-wrap{

        }

        #{$this}__grid-members .k-grid-header,
        #{$this}__grid-documents .k-grid-header
        {
            display: none;
        }

        #{$this}__grid-members .k-grid-content.k-auto-scrollable,
        #{$this}__grid-documents .k-grid-content.k-auto-scrollable{
            @include custom-scrollbar();

            height: calc(#{vh(100)}  - var(--tchat-header-about) - 95px) !important;

        }



        #{$this}__grid-item {
            height: 35px;
        }
        //endregion



        #{$this}__to-bottom,
        #{$this}__to-bottom-mobile
        {
            @include reset-button();

            $size :  $size-normal;

            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;

            transition: $speed-fast ease;
            transition-property: transform, background-color;
            transform: scale(0);

            margin-bottom: $size-x-small;
            margin-top: $size-x-small;

            margin-left: -( $size / 2 );

            background-color: white;
            box-shadow: 0 0 1px rgba(black, .08), 0 3px 8px rgba(black, .08);

            left: 50%;
            bottom:40px;

            border-radius: 50%;

            width: $size;
            height: $size;

            color: $color-dark-light;

            font-size: 21px;

            &--shown {
                transform: scale(1);
            }

            &:hover {
                background-color: $color-light-light;
            }
        }

        #{$this}__to-bottom-mobile
        {
            bottom:0;

            margin-bottom: calc(60px + env(safe-area-inset-bottom));
            margin-top: 0;

            @media #{$media-desktop}
            {
                display: none !important;
            }
        }

    }


}
