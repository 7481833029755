.textcomplete-dropdown--relative {
    position: relative !important;
    top: auto !important;
}

.textcomplete-dropdown a
{
    cursor: pointer;
}

.textcomplete-dropdown--users-mentions
{
    padding: $size-xx-small;

    border: none;

    .c-entity-thumb
    {
        margin-right: $size-xx-small;
    }

    > li > a
    {
        height: auto;
        padding: $size-xxx-small;
        padding-right: $size-x-small;
    }
}

.textcomplete-dropdown--users-mentions,
.textcomplete-dropdown--users-mentions > li > a
{
    border-radius: 5px;
}

.textcomplete-dropdown--users-mentions > li > a:hover,
.textcomplete-dropdown--users-mentions > li > a:focus
{
    background: none;
}

.textcomplete-dropdown--users-mentions > .active > a,
.textcomplete-dropdown--users-mentions > .active > a:focus,
.textcomplete-dropdown--users-mentions > .active > a:hover
{
    color: $text-color !important;
    background: $color-light !important;
}
