.c-timeline-comment
{
    $this: &;
    $horizontal-padding: $size-xx-small;
    $vertical-padding: $size-xxx-small;

    position: relative;

    display: flex;

    margin-bottom: $size-xx-small;

    @at-root
    {
        #{$this}__thumb
        {
            flex-shrink: 0;

            margin-right: $size-x-small;
        }

        #{$this}__text-container
        {
            display: flex;
            flex-direction: column;

            width: 100%;
        }

        #{$this}__content // prefix timeline-comment
        {
            position: relative;

            display: inline-block;
            align-self: flex-start;

            max-width: calc(100% - 35px) ;
            padding: $vertical-padding $horizontal-padding;

            border-radius: 5px;

            &,
            &::before,
            &::after
            {
                background-color: $color-light-light;
            }

            &::before,
            &::after
            {
                content: '';

                position: absolute;
                top: $size-xx-small;
                left: 0;

                width: $size-xx-small;
                height: $size-xx-small;
            }

            &::before
            {
                transform: translateX(-50%) rotateZ(-45deg);

                // border: 1px solid $color-light;
            }

            &::after
            {
                transform: translateX(calc(-#{$size-xx-small} / 2)) rotateZ(-45deg);
            }

            #{$this}.has-url-preview &
            {
                overflow: hidden;

                width: calc(100% - 35px);
                max-width: $size-xx-large + $size-large;
            }
        }

        #{$this}__text {

            word-break:break-word;
            font-size: 15px;
        }

        #{$this}__header
        {
            // Évite l'espace blanc entre les enfants du header. On aurait pu
            // utiliser flexbox à la place, mais la date ne serait pas restée
            // affichée en inline par rapport au nom de l'auteur s'il faisait
            // plus d'une ligne.
            font-size: 0;

            > *
            {
                font-size: 14px;
            }
        }

        #{$this}__name
        {
            font-weight: bold;
        }

        #{$this}__url-preview
        {
            margin: $size-xxx-small (-$horizontal-padding) (-$vertical-padding);

            border-bottom-right-radius: inherit;
            border-bottom-left-radius: inherit;
        }

        #{$this}__file > img,
        #{$this}__gif
        {
            max-width: $size-xx-large;
            max-height: $size-xx-large;
        }

        #{$this}__file
        {
            margin-top: $size-xxx-small;
            margin-right: 0;
            margin-left: 0;

            &--image
            {
                font-size: 0;

                position: relative;

                overflow: hidden;
                align-self: flex-start;

                max-width: 80%;

                border-radius: $size-xxx-small;

                &::after
                {
                    content: '';

                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;

                    border: 1px solid rgba(64, 64, 64, .1);
                    border-radius: inherit;
                }
            }
        }

        #{$this}__gif
        {
            width: auto;
            padding: 0;
        }

        #{$this}__date
        {
            line-height: 1;

            cursor: default;

            color: $color-dark-light;

            &::before
            {
                content: '·';

                margin: 0 $size-xxx-small;
            }
        }

        #{$this}__more
        {
            position: absolute;
            right:0;
        }
    }
}   
