
.c-task-cover {
  $this: &;

  @at-root {

    #{$this}__imageBackground {
      width: 100%;
      background: $color-default-light;
      overflow: hidden;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      &--landscape {
        height: 160px;
      }

      &--portrait {
        height: 260px;
      }
    }

    #{$this}__imageBackgroundcover {
      margin: auto;
      display: block;
      filter: drop-shadow(0 3px 8px rgba(black, .25));
      width: 100%;
      object-fit: contain;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      &--landscape {
        height: 160px;
      }

      &--portrait {
        height: 260px;
      }
    }

    #{$this}__buttonOption--position {
      align-items: center;
      top: -50px;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      padding: 8px;
      position:relative;
      width: 100%;
    }

    #{$this}__buttonOption--style {
      background-color:  rgba(70,70,70, 0.5);
      color: white;
      text-decoration: none;
    }

    #{$this}__buttonOption--glyphicon {
      top: 0px;
      right: 8px;
    }

  }
}

