.c-color-picker{
    $this: &;

    font-size: 0;
    padding-top: 15px;
    margin-bottom: 15px;

    
    @at-root{
        #{$this}__header{
            text-align: left;
            padding: 15px;
            
            #{$this}__title{
                margin: 0;
                font-size: $size-small;
                font-weight: 400;
            }
        }
        #{$this}__content{
            position: relative;

            #{$this}__item-content{
                text-align: center;
                padding-top: 15px;
                padding-bottom: 15px;
            }
            #{$this}__full-content{
                position: absolute;
                left: 8px;
                //bottom: 104px;
                top: 101%;
                background-color: #fff;
                border: 1px solid $color-default;
                border-radius: 8%;
                padding: 5px;
                display: none;
                z-index:10;
                @media (min-width: $screen-xs-min){
                    left: 290px;
                    bottom: -150px;
                    top: auto;
                    //bottom: 42px;
                }

                .iro__handle__inner{
                    stroke-width: 1px;
                }
                .iro__handle__outer{
                    stroke-width: 1px;
                }
            }
            #{$this}__item{
                position:relative;
                display: inline-block;
                vertical-align: middle;
                margin: 5px 20px 15px 0;

                @media (min-width: $screen-xs-min){
                    margin-top: 15px;
                }
                
                
                #{$this}__button{
                    height: 35px;
                    width: 35px;
                    border: none;
                    border-radius: 50%;
                    
                    &.is-active>svg{
                        display: inline-flex;
                    }
                    
                    
                    .check-icon{
                        position: absolute;
                        top: 25%;
                        transform: translate(-50%);
                        font-size:16px;
                        fill: white;
                        stroke:white;
                        stroke-width: 4px;
                        display: none;
                    }

                    &--background--default{
                        border: 1px solid #ccd1d9 !important;

                        .check-icon{
                            stroke:#ccd1d9;
                        }
                    }
                }
            }
            #{$this}__input-content{
    
                position: relative;
                border: 1px solid #ccd1d9; 
                padding: 4px 7px 4px 7px;
                width: 260px; 
                border-radius: 3px;
                font-size: 16px;
                margin-left: 8px;
                margin-bottom: 4px;
    
                .check-icon--full-color-picker{
                    position: absolute;
                    top: 20px;
                    right: 5%;
                    font-size:16px;
                    fill: white;
                    stroke:white;
                    stroke-width: 4px;
                    display: none;
                }
                &.is-active{
                    background: var(--theme-color);
        
                    .check-icon--full-color-picker{
                        display: inline-flex;
                    }
                }
                &.is-active#{$this}__input-content--background{
                    background: var(--background-theme-color);
                }
                #{$this}__input{
                    border-bottom: none;
                    &::placeholder{
                        color:#434a54;
                    }
                }
            }
            #{$this}__error-message{
                visibility: hidden;
                font-size:14px;
                //height: 40px;
                color: $color-danger-light;
                margin-left: 8px;
                padding-top: 4px;
                &.is-active{
                    visibility: visible;
                }
            }
        }
    }
}

