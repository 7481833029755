/// ============================================================================
/// # NOTIFICATION-LIST-ITEM
/// ============================================================================
/// Bloc utilisé pour chaque item de la liste des notifications. Il s'agit du
/// même bloc dans les extensions du menu principal et dans la page dédiée aux
/// notifications.
/// On y retrouve :
/// - l'avatar de l'émetteur
/// - le texte de la notification
/// - la date
/// - la pastille qui indique l'état "lu" ou "non lu" de la notification, que
///   l'utilisateur peut changer en cliquant dessus
/// ============================================================================
/// @group components
/// @require c-entity-list-item Doit être utilisé avec ce bloc
/// @see c-entity-list-item
/// ============================================================================

.c-notification-list-item {
  $this: &;
  $parent: ".c-notifications-list";

  width: 100%;
  padding: $size-xxx-small 0 $size-xxx-small $size-x-small;

  text-align: left;

  border: none;

  #{$parent}:not(#{$parent}--narrow) & {
    @media (min-width: $screen-sm-min) {
      padding-left: $size-small;
    }
  }

  @at-root {
    #{$this}__illustration {
      #{$parent}--narrow & {
        margin-top: 3px;
      }
    }

    #{$parent}__wrapper-btn-mark {
      padding: $size-xxx-small $size-xx-small;
      height: 40px;
      background: white;
    }

    #{$parent}__btn-mark {
      padding: 10px 20px;
      background: $color-adn;
      border-radius: 50px;
      box-shadow: rgba(50, 50, 93, 0.3) 0px 2px 5px -1px, rgba(0, 0, 0, 0.5) 0px 1px 3px -1px;

      &:disabled {
        box-shadow: none;
      }

      &-title {
        padding-right: $size-xxx-small;
      }

      &-icon {
        font-size: 25px;
        font-weight: 500;
        display:flex;
        align-items:center;
      }
    }

    #{$parent}__btn-mark:disabled:hover {
      background: #e3e6ea;
    }

    #{$this}__text {
      #{$this}.is-unread & {
        font-weight: 500;

        color: $color-black;
      }
    }

    #{$this}__title {
      @include clamp-lines-to(3);

      word-wrap: break-word;
      word-break: break-word;

      overflow-wrap: break-word;
    }

    #{$this}__flag-trigger {
      display: flex;
      align-self: stretch;

      padding-right: $size-x-small;
      padding-left: $size-xx-small;
    }

    #{$this}__flag {
      $dot-dimensions: $size-xx-small;

      position: relative;

      display: flex;

      &::before {
        content: '';

        width: $dot-dimensions;
        height: $dot-dimensions;

        opacity: 0;
        border-radius: 50%;
        background: $color-dark-light;
      }

      &::after {
        content: '';

        position: absolute;

        width: $dot-dimensions - 2px;
        height: $dot-dimensions - 2px;

        transition: transform ease $speed-fast;

        border-radius: 50%;
        background: var(--background);
      }

      #{$this}.is-unread &,
      #{$this}:hover &,
      #{$this}:focus & {
        &::before {
          opacity: 1;
        }
      }

      #{$this}.is-unread &::before {
        background: $color-adn;
      }

      #{$this}.is-unread &,
      #{$this}__flag-trigger:hover &,
      #{$this}__flag-trigger:focus & {
        &::after {
          transform: scale(0);
        }
      }

      #{$this}.is-unread #{$this}__flag-trigger:hover &,
      #{$this}.is-unread #{$this}__flag-trigger:focus & {
        &::after {
          transform: none;
        }
      }
    }

    #{$this}__metas,
    #{$this}__date {
      #{$this}.is-unread & {
        color: inherit;
      }
    }

    #{$this}__metas {
      display: flex;

      color: $color-dark-light;
    }

    #{$this}__date {
      flex-shrink: 0;

      color: $color-default;
    }
  }
}
