.c-tasks-filters-tags {
  $this: &;

  @at-root {

    #{$this}__check--unckecked {
      height: 17px;
      width: 17px !important;
      display: inline-block;
      padding-left: 0px !important;
    }

    #{$this}__check--checked {
      height: 17px;
      width: 17px !important;
      padding-left: 0px !important;
      display: inline-block;
    }

    #{$this}__checkbox {
      padding-left: 0px !important;
    }

    #{$this}__tag {
      display: inline-flex;
      cursor: pointer;
      font-weight: 700;
      margin: 7px 0 7px 5px;
      height: 24px;
      padding: 6px 12px;
      position: relative;
      background-color: #b3bac5;
      border-radius: 4px;
      color: #fff;
      width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      align-items: center;
    }

    #{$this}__tagCircle{
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin-right: 5px;
    }

    #{$this}__tagName{
      color: #434a54;
      font-size: 13px;
      font-weight: 400;
    }
  }
}