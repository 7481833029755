$arrow-width: $size-xx-small;
$arrow-left-offset: $size-xxx-small;
$arrow-right-offset: $size-xxx-small;

$option-padding: $size-x-small;

$checkmark-width: $arrow-width;
$checkmark-offset: $arrow-right-offset + $option-padding - 1; // 1 is the border-width

.bootstrap-select
{
    background: none;
    box-shadow: none;
}

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn)
{
    width: 100%;
    max-width: $size-xx-large;
}

.bootstrap-select > .dropdown-toggle
{
    height: $size-small + $size-xxx-small;
    padding: 0 ($arrow-left-offset + $arrow-width + $arrow-right-offset) 0 0;

    border-color: $panels-border-color !important;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    background: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
    text-shadow: none;

    .bs-caret
    {
        display: none;
    }

    &:hover,
    &:focus,
    &:active
    {
        color: $text-color;
    }

    &::after
    {
        content: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" fill="' + $text-color + '" viewBox="0 -7.999 30.399 30.999" height="100%" width="100%"><path d="m 0.001,0.8 c 0,-0.205 0.078,-0.41 0.234,-0.566 0.312,-0.312 0.819,-0.312 1.131,0 L 15.2,14.068 29.034,0.234 c 0.312,-0.312 0.819,-0.312 1.131,0 0.312,0.312 0.312,0.819 0,1.131 l -14.4,14.4 c -0.312,0.312 -0.819,0.312 -1.131,0 L 0.234,1.365 C 0.077,1.208 0,1.003 0,0.799 Z"></path></svg>');

        position: absolute;
        top: 50%;
        right: $arrow-right-offset;

        width: $arrow-width;
        margin-top: 1px; /* To encounter the optical illusion the arrow is not aligned */

        transition: transform ease $speed-fast;
        transform: translateY(-50%);
    }
}

.bootstrap-select.open > .dropdown-toggle
{
    border-bottom-color: var(--theme-color-light) !important;

    &::after
    {
        transform: translateY(-50%) rotate3d(0, 0, 1, 180deg);
    }
}

.bootstrap-select > .dropdown-toggle.bs-placeholder,
.bootstrap-select > .dropdown-toggle.bs-placeholder:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder:hover
{
    transition: color ease $speed-fast;

    color: transparent;
}

.bootstrap-select.open > .dropdown-toggle.bs-placeholder
{
    color: $color-default-light;
}

.bootstrap-select .dropdown-toggle:focus
{
    outline: none !important;
}

.bootstrap-select .dropdown-menu > li.selected > a:not(:hover)
{
    color: white;
    background-color: var(--theme-color) !important;
}

.bootstrap-select.btn-group .dropdown-toggle .filter-option
{
    text-overflow: ellipsis;
}

.bootstrap-select .dropdown-menu
{
    z-index: $z-index-3 !important;

    &:not(.inner)
    {
        width: calc(100% + (#{$option-padding} * 2));
        margin-left: -$option-padding;

        border-radius: 3px;
    }
}

.bootstrap-select .dropdown-backdrop
{
    z-index: $z-index-3 - 1;
}

.bootstrap-select
{
    &:not(.dropup) .dropdown-menu
    {
        margin-top: $size-xxx-small;
    }

    &.dropup .dropdown-menu
    {
        margin-bottom: $size-xxx-small;
    }
}

.bootstrap-select.btn-group.disabled
{
     > .dropdown-toggle
    {
        opacity: 1;
        color: transparent !important;
        border-color: $panels-border-color !important;
        border-bottom-style: dashed;

        &::after
        {
            content: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" fill="' + $panels-border-color + '" viewBox="0 -7.999 30.399 30.999" height="100%" width="100%"><path d="m 0.001,0.8 c 0,-0.205 0.078,-0.41 0.234,-0.566 0.312,-0.312 0.819,-0.312 1.131,0 L 15.2,14.068 29.034,0.234 c 0.312,-0.312 0.819,-0.312 1.131,0 0.312,0.312 0.312,0.819 0,1.131 l -14.4,14.4 c -0.312,0.312 -0.819,0.312 -1.131,0 L 0.234,1.365 C 0.077,1.208 0,1.003 0,0.799 Z"></path></svg>');
        }
    }
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark
{
    display: none;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li
{
    a::after
    {
        content: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 -5 31.997999 30.999001" height="100%" width="100%"><path d="m 8.8,23.999 c -0.205,0 -0.41,-0.078 -0.566,-0.234 l -8,-8 c -0.312,-0.312 -0.312,-0.819 0,-1.131 0.312,-0.312 0.819,-0.312 1.131,0 L 8.799,22.068 30.633,0.234 c 0.312,-0.312 0.819,-0.312 1.131,0 0.312,0.312 0.312,0.819 0,1.131 l -22.4,22.4 c -0.157,0.157 -0.362,0.234 -0.566,0.234 z"/></svg>');

        position: absolute;
        top: 50%;
        right: $checkmark-offset;

        display: none;

        width: $checkmark-width;

        transform: translateY(-50%);
    }

    &.selected a::after
    {
        display: inline-block;
    }
}

.bootstrap-select.c-form-group__input
{
    &:not(.open):not(.has-value)
    {
        + .c-form-group__label
        {
            transform: translateY(8px);
        }

        &:not(.disabled) + .c-form-group__label
        {
            color: $text-color;
        }
    }

    &.open + .c-form-group__label
    {
        color: var(--theme-color-light);
    }
}
