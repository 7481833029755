.c-spaces-detail {

  $this: &;


  @at-root {

    #{$this}__header  {
      margin-bottom: $size-xxx-small;
    }


    #{$this}--dropdown-type {
      & > li.active > a {
        background: var(--theme-color) !important;
      }
    }

    #{$this}__askaccessnotifs {
      text-align:center;
      font-weight: bold;
      min-width: 20px;
      margin-right: 15px;
      margin-top: 5px;
      margin-left: 4px;
      height: 15px;
      background:red;
    }


    #{$this}__users-members {
      display:none;
      padding: 0 0 2px;
      margin:0;
      & .c-spaces-container__users-admin-list {
        text-decoration:none
      }

      @media #{$media-desktop} {
        display:inline-flex;
      }
    }


    #{$this}__sub-header  {
      display: inline-flex;
      justify-content: space-between;
      width: 100%;

      margin-bottom: $size-xxx-small;

      @media #{$media-desktop} {
        align-items: center;
      }


      & .members {
        display:block;

        @media #{$media-desktop} {
          display:none;
        }
      }


      & button {

         & .text-desktop { display:none; }
         & .text-mobile {
           display:inline-block;
         }

        &:has(.text-mobile:empty) {
          min-width: 35px;
          max-width: 35px;
        }

        @media #{$media-desktop} {
          & .text-desktop { display:inline-block; }
          & .text-mobile {
            display:none;
          }

          &:has(.text-mobile:empty) {
            min-width: revert;
            max-width: revert;
          }
        }

      }



      & .type-spaces {
          display: inline-block;
          max-width: 8ch;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          @media #{$media-desktop} {
            display: inline-flex;
            max-width: 50ch;
          }
      }

      & .left {
        display: inline-flex;
        padding-left: 7px;

        @media #{$media-desktop} {
          flex:initial;
          padding-right:15px;
          align-items: center;
        }
      }

      & .right {

        @media #{$media-desktop} {
          flex:initial;
          padding-right:15px;
        }
      }

    }


    #{$this}__list {
      .c-documents__item:hover {
        background: $color-light-light!important;
      }
    }


    #{$this}__header h4 {
      margin-top:10px
    }

    #{$this}__header-logo div {
       background: $color-dark-light;
    }




    #{$this}__header-title .c-header-area__title {
      padding-top: $size-xx-small;
    }

    #{$this}__header-actions-small {
      background: white;
      position: relative;
      z-index: 2000 !important;
    }



    #{$this}__section  {
      width: 100%;
    }

    #{$this}__section input {
      font-size: 19px;
    }

    #{$this}__section-nom  {
      font-size: 19px;
    }

    #{$this}__document-line {
      display:flex;
      align-items: center;
    }

    #{$this}__document-toggle {
      margin-right: $size-xx-small;
    }





    #{$this}__upload-header {
    }


    #{$this}__upload-container-notify {
      width:28%;
    }

    #{$this}__upload-title {
      font-size:14px;
      width:45%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      @media #{$media-desktop}
      {
        width:205px;
      }

    }


    #{$this}__upload-btn-import {
      width:5%;

      @media #{$media-desktop}
      {
        width:auto;
      }
    }

    #{$this}__upload-checkbox-label {
      line-height:12px;
      font-weight:500;
    }


  }

}
