.l-app-content
{
    $this: &;

    overflow-x: hidden;

    width: 100%;
    padding-top: $header-height;

    .has-enabled-mobile-navbar &
    {
        padding-bottom: var(--mobile-menu-height);
    }

    @media #{$media-desktop}
    {
        padding-top: 0;
        padding-bottom: 0 !important;
        padding-left: var(--main-menu-width);
    }

    @media #{$media-main-aside}
    {
        .has-main-asides &
        {
            padding-left: calc(var(--main-menu-width) + #{$main-aside-width});
        }
    }

    @media #{$media-online-users-aside}
    {
        .has-main-asides &
        {
            padding-right: $main-aside-width;
        }
    }

    @at-root
    {
        #{$this}--bare
        {
            width: 100vw;
            height: calc(#{vh(100)});
            padding: 0;
        }

        .has-no-header #{$this},
        #{$this}--messages {
            @media (max-width: $screen-sm-max) {
                padding-top: 0;
            }
        }

        #{$this}--tchat {
            @media (max-width: $screen-sm-max) {
                padding-bottom: 0 !important;
            }
        }
    }
}
