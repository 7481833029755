.c-acls-container
{
    $this: &;

    @at-root
    {
        //region gestion des rôles
        #{$this}__dtbl-listroles th // entête datatable des rôles
        {
          border: none !important;
        }

        #{$this}__dtbl-listroles ul li,  // colonne du datatable des rôles et item des rôles parents
        #{$this}__dtbl-listroles-col
        {
            font-size: 14px;
        }

        #{$this}__dtbl-listroles-col h4
        {
            margin-bottom: 5px;
        }

        #{$this}__addrole // container bouton d'ajout de rôle
        {
            float: right !important;
            margin-right: $size-x-small;
        }
        //endregion



        //region gestion des privileges
        #{$this}__listprivileges li  { // item d'un privilège
            font-size: 14px;
            line-height: 22px;
        }

        #{$this}__listprivileges-title  { // titre de la ressource qui contient les privilèges
            font-size: 14px;
            padding-top:5px;
        }

        #{$this}__listprivileges-container { // container ressource + privilèges
            margin-bottom:10px;
        }
        //endregion

    }
}