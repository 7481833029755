.c-chapter-content{

  $this:&;
  @at-root {

    #{$this}__container{
      display: none;
    }

    #{$this}__header{
      position: relative;
    }

    #{$this}__header-container{
      padding: 15px;
      border-bottom: 1px solid #ccd1d9 !important;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    #{$this}__progressBar{
      position: absolute;
      bottom: 0;
    }

    #{$this}__button-return{
      width: 30px;
      height: 30px;
      border: transparent;
      border-radius: 50%;
      margin: 2px;
      padding: 0;
      flex-shrink: 0;
      flex-grow: 0;
      background-color: var(--theme-color) ;
      color: white;

      .c-chapter-content__button-container:hover &{
        background-color: var(--theme-color) ;
      }
    }

    #{$this}__title-container{
      margin-left: $size-x-small;
    }

    #{$this}__title{
      margin: 0;
      font-size: 26px;
      font-weight: 400;
    }

    #{$this}__content{
      padding: $size-x-small;
    }

    #{$this}__btns-container{
      display: none;

      &.is-visible{
        display: flex;
        justify-content: center;
        flex-direction: row;
        padding-bottom: 15px;
      }
    }

    #{$this}__btn{
      margin-left: $size-x-small;

      &--finish{
        display: none;
      }
    }

    #{$this}__noContent{
      display: none;
      height: $size-large;

      &__text{
        padding: $size-x-small;
      }
    }

    #{$this}__iframe{

      &__content{
        position: relative;
        overflow: hidden;
        padding-top: 56.25%;
      }

      &__iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
        border: 0;
      }

      &__description{
        margin-top: $size-x-small;
        margin-bottom: $size-x-small;
      }
    }
  }
}
