.c-tchat-popup {
  $this: &;

  width: 100%;
  background-color: white;
  height: $size-xxx-large + $size-x-large;
  max-height: calc(#{vh(100)});
  position: absolute;
  bottom: 0;
  left: 0;
  border: 2px solid $color-light;
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transform: translateY(calc(100% - #{$tchat-popup-header-height}));
  transition: transform ease $speed-medium;
  z-index: $z-index-12;

  @at-root {
    &.is-open {
      transform: translateY(0);
    }

    &__min {
      height: $tchat-popup-header-height;
      padding: $size-xx-small $size-x-small;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }

    &__title-group { display: flex; }

    &__title {
      font-size: 19px;
      font-weight: normal;
      margin: 0;
      color: #1d2129;
      margin-right: $size-small;
    }

    #{$this}__changePositionContextForTchatPopup{
      top: 17em !important;
      left: 6.5em !important;
    }

    #{$this}__changeStylizeTextForTchatPopup{
      top: 12.5% !important;
    }

    #{$this}__changeOptimizedTextForTchatPopup {
      top: 37.5% !important;
    }

    #{$this}__changeTonalityForTchatPopup {
      top: 50% !important;
    }

    #{$this}__changeLanguageForTchatPopup {
      top: 62.5% !important;
    }
  }
}
