.c-online-users-aside
{
    $this: &;
    $padding: $size-small;

    @at-root
    {
        #{$this}__search
        {
            flex-shrink: 0;

            margin: $padding 0;
            padding: 0 $padding;

            &::before
            {
                content: '';

                position: absolute;
                z-index: $z-index-2;
                bottom: -$size-xxx-small;
                left: 0;

                width: 100%;
                height: $size-xxx-small;

                background: linear-gradient(white, rgba(white, 0));
            }
        }

        #{$this}__result-container
        {
            display: none;
            overflow-x: hidden;
            overflow-y: auto;
            flex-direction: column;
            margin-bottom: $tchat-popup-header-height;
            margin-top: 80px;

            @media (min-width: $screen-sm-max)
            {
                margin-top: 0;
            }
        }

        #{$this}__result-title
        {
            font-size: 15px;

            position: relative;

            margin: 0;
            padding-bottom: $size-xx-small;
            padding-left: $padding;

            &:not(:first-child)
            {
                margin-top: $size-x-small;
            }
        }

        #{$this}__result-list
        {
            display: flex;
            flex-direction: column;
        }

        #{$this}__result-illustration
        {
            display: flex;
            align-items: center;
            flex-direction: column;

            padding: 0 $size-medium;
            margin: $size-small $padding $size-normal;

            text-align: center;

            color: $color-default;

            &__img
            {
                width: 100%;
            }

            &__text
            {
                margin-top: $size-xx-small;
            }
        }

        #{$this}__list-container
        {
            position: relative;

            display: flex;
            flex: 1;
            flex-direction: column;

            min-height: 0;
            margin-bottom: $tchat-popup-header-height;
        }

        #{$this}__list-container-title
        {
            font-size: 26px;

            position: relative;

            margin: 0 0 $padding;
            padding: 0 $padding;
        }

        #{$this}__list
        {
            overflow-x: hidden;
            overflow-y: auto;
            flex-direction: column;
        }

        #{$this}__list-placeholder
        {
            margin: $size-small $padding $size-normal;
        }

        #{$this}__list-item-trigger
        {
            padding-left: $padding;
            padding-right: 0;
        }
    }
}
