.c-poste{
  $this:&;
  @at-root {

    &__row-protected {
      background: $color-light;
    }

    &__container{
      position: relative;
    }

    &__container-form{
      visibility: hidden;
    }

    &__form{
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      padding-top: 8px;
    }


    &__container-svg-organisation {
      padding: 0;
      border: $color-aqua solid 1px;
      background-color: var(--theme-color);
      border-radius: 45px;
      float:right;

      &--refresh{
        right: $size-medium;
      }

      &:hover{
        cursor: pointer;
        background-color: var(--theme-color-light);
      }
    }

    &__container-svg{
      padding: 0;
      border: $color-aqua solid 1px;
      background-color: var(--theme-color);
      border-radius: 45px;
      position: absolute;
      right: $size-x-small;
      top: 75px;
      @media #{$media-desktop}{
        top: 11px;
      }

      &--refresh{
        right: $size-medium;
      }

      &:hover{
        cursor: pointer;
        background-color: var(--theme-color-light);
      }
    }

    &__svg{
      width: $size-small;
      height: $size-small;
      padding: $size-xxx-small;
      color: white;
    }

    &__table-container{
      padding-bottom: 40px;
      margin-top: 10px;
    }

    &__table{
      & tbody > tr > td{
        vertical-align: middle;
      }

      & tbody > tr:hover{
        .c-poste__container-btns{
          visibility: visible;
        }
      }
    }

    &__container-btns{
      visibility: hidden;
      display: flex;
      justify-content: space-evenly;
    }
  }
}
