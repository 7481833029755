.c-kanban {
  border: none!important;

  font-size: 13px;
  line-height: 20px;
  //font-family: 'Rubik', sans-serif;
  font-family: 'Apercu', sans-serif;
  color: $color-dark;

  display: flex;
  align-items: start;

  overflow: auto;
  -webkit-overflow-scrolling: touch;

  padding: $size-xx-small;

  position: relative;

  & .c-form-group__input:focus ~ .c-form-group__label {
    color: $color-dark;
  }

  & input:focus {
    border-color: $color-dark;
  }

  &__container--list-item {
    display: flex;
    max-height: 100% !important;
    margin-left: 8px;
    margin-right: 8px;
    flex-direction: column;
    height: inherit;
   margin-left: 4px;
  }

  &__list-wrapper {
    min-width: 290px;
    width: 290px;
    margin: 0;

    background-color: $color-light;
    border-radius: 15px;
    box-sizing: border-box;
    vertical-align: top;

    display: flex;
    flex-direction: column;
    max-height: 100%;
  }

  &__list-wrapper {
    min-width: 290px;
    width: 290px;
    margin: 0;

    background-color: $color-light;
    border-radius: 15px;
    box-sizing: border-box;
    vertical-align: top;


    cursor: pointer;

    &--lighter {
      background-color: $color-light-light;
    }
  }

  &__list-header {
    padding: $size-xx-small;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__list-header-group {
    display: flex;
    align-items: center;
  }

  &__list-title {
    font-size: 15px;
    font-weight: 500;
  }

  &__list {
    @include custom-scrollbar();
    padding: $size-xxx-small;
    padding-top: 2px;
    margin: 0;

    background-color: $color-light!important;
    border: none!important;
    box-sizing: border-box;

    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 2px;
    min-height: 3px;
  }

  &__list-actions {
    margin: $size-xx-small 0 ;
    display: flex;
    align-items: center;
    padding: 0 $size-xxx-small;

    cursor: pointer;

    &--card {
      border-radius: 10px;
      margin-left: 10px;
      margin-right: 10px;
    }
    &--card:hover {
      background: white;
    }
  }

  &__action-btn {
    width: 20px;
    height: 20px;
    color: $color-dark;
    cursor: pointer;
    margin: 5px 0;

    &--mini {
      width: 12px;
      height: 12px;
    }
    
    &--sort {
      margin-right: $size-xxx-small;
      color: $color-default;
      
      @media (min-width: $screen-md-min) {
        display: none;
      }
    }
  }

  &__action-title {
    font-size: 13px;
    color: $color-dark;
    margin: 0;
    margin-left: $size-xx-small;
    padding: 0;
  }

  &__add-form {
    margin: $size-xx-small;
    z-index: 115;
    background-color: white;
    padding: 5px;
    border-radius: 10px;
  }

  &__add-form-group {
    background: #fff;
    border-radius: 3px;
    padding: $size-xx-small;
    margin-bottom: $size-xxx-small;
  }

  &__input {
    box-sizing: border-box;
  }

  &__add-actions {
    display: flex;
    align-items: center;
  }

  &__add-action {
    margin-right: $size-xx-small;

    &:hover {
      background: darken($color-mint, 10%)!important;
    }
  }

  &__overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color:rgba(0,0,0,0.5);
    z-index: 1002;
  }

  &__sortable-placeholder {
    display: block;
    background-color: $color-light-light;
    height: 80px;
    min-width: 272px;
    margin: 0;
    margin-right: 5px;
    border-radius: 3px;
  }

  &__overlay-card {
    z-index: $z-index-9;
    width: 262px;
    position: absolute;
  }

  &__overlay-actions {
    position: absolute;

    bottom: auto;
    margin-top: $size-xx-small;
    width: 150px;

    @media (min-width: $screen-sm-min) {
      margin-top: 0;
      top: 0;
      left: 100%;
      margin-left: $size-xx-small;
      width: 150px;
    }
  }
}