.c-campaign{
  $this:&;
  @at-root {

    &__list{

    }

    /* Formulaire */
    &__form{
      padding: 15px;

      &__table{
        margin-top: 35px;

        & tr :hover{
          cursor: pointer;
        }
      }
      
      &__btns{

        &__submit, &__termSubmit, &__termClose {
          display: none;
        }
      }

      &__date {
        display: block;

        &--hide {
          display: none;
        }
      }

      &__hideModule, &__hideChapter {
        display: none;
      }

      &__displayModule, &__displayChapter {
        display: block;
      }
    }







  }
}