.c-tasks-activities {
  &__form-container {
    margin-bottom: $size-small;
    display: flex;
    align-items: center;
  }

  &__form {
    display: flex;
    margin-left: $size-xx-small;
    border-radius: 4px;
    border: 2px solid $color-light;
    padding: $size-xx-small;
    flex: 1;
    cursor: text;
    width: 90%;
  }

  &__form-placeholder {
    width: 100%;
    font-size: 15px;
    color: $color-default;
  }

  &__form-group {
    width: 100%;
  }

  &__form-input {
    margin: 0;
    padding: 0;
    margin-bottom: $size-xx-small;
    padding-bottom: $size-xx-small;
    border-bottom: 0;
  }

  &__list{
    //width: 500px;
    //white-space: nowrap;
    //overflow: hidden;
    //text-overflow: ellipsis;
  }

  &__textarea {
    height: auto;
  }

}