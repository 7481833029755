.c-radio
{
    $this: &;

    position: relative;

    display: block;

    @at-root
    {
        .c-input-group #{$this}
        {
            margin-bottom: 0;
        }

        #{$this}__input
        {
            z-index: -1;

            display: none;
            visibility: hidden;

            width: 0;
            height: 0;
        }

        #{$this}__label
        {
            font-size: 13px;

            display: flex;
            float: none;
            align-items: center;

            width: auto;
            height: $size-small;
            margin: 0;
            margin-left: -$size-x-small; /* = radioWidth */
            padding: 0;

            cursor: pointer;

            &::before,
            &::after
            {
                content: '';

                min-width: $size-x-small;
                min-height: $size-x-small;

                border-radius: 50%;
            }

            &::before
            {
                order: -1;

                margin-right: $size-xxx-small;

                border: 1px solid $panels-border-color;
                background-color: white;
            }

            &:empty::before
            {
                margin-right: 0;
            }

            &::after
            {
                order: -2;

                transform: translateX(100%) scale(.26);

                opacity: 0;
                background-color: var(--theme-color);
            }
        }

        #{$this}__input
        {
            &:checked + #{$this}__label
            {
                &:after
                {
                    opacity: 1;
                }

                &:before
                {
                    border-color: var(--theme-color);

                }
            }

            &:disabled + #{$this}__label
            {
                color: lighten($panels-border-color, 3%);

                &:before
                {
                    border-color: currentColor !important;
                }

                &:after
                {
                    background-color: currentColor;
                }
            }
        }
    }
}
