@mixin gridStyles($grid-selector, $extra-height-mobile, $extra-height-desktop) {
  #{$grid-selector} {
    border: none !important;

    .k-button {
      border-radius: 0 !important;
    }

    .k-sort-icon {
      color: $default-theme-color;
    }

    tbody tr {
      cursor: pointer;
      height: 59px;
    }

    .k-alt {
      background-color: $color-light-light;
    }

    th {
      color: $color-black !important;
    }

    td {
      color: $color-black !important;
      padding: 8px;
      font-size: 14px;
    }

    tr.k-selected > td {
      background-color: $color-light !important;
      border-color: $color-light !important;
    }

    .k-header .k-link {
      color: var(--theme-color-light);
    }

    .k-grid-content.k-auto-scrollable {
      height: calc(#{vh(100)} - #{$header-height} - var(--mobile-menu-height) - #{$extra-height-mobile}) !important;

      @media #{$media-desktop} {
        height: calc(#{vh(100)} - #{$extra-height-desktop}) !important;
      }
    }
  }
}