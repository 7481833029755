.o-input-validation {

  &__container-activation {

    align-items: center;
    display: flex;
    justify-content: flex-start;

    &__block{
      display: flex;
    }

    &__separate{
      border-radius: 4px;
      display: flex;
      margin: 8px;
      width: 20px;
      border: 1px solid $color-default;
    }

    &__input{
      -moz-appearance: textfield !important;
      font-size: 43px;
      height: 80px;
      max-width: 50px;
      text-align: center;
      width: 100%;
      background: white;
      border: 2px solid $color-default;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
      }

      &:first-child {
        border-bottom-left-radius: $size-xxx-small;
        border-top-left-radius: $size-xxx-small;
      }

      &:last-child{
        border-bottom-right-radius: $size-xxx-small;
        border-top-right-radius: $size-xxx-small;
      }

      &:not(:last-child){
        border-right-style: none;
      }
    }

  }

  &__code-info{
    visibility: visible;
    opacity: 1;
    text-align: left;
    color: #e9573f;
    font-size: 14px;
  }

}
