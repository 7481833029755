.c-tasks-grid {
  $this: &;

  @at-root {

    #{$this}__containerColumn {
      color: #434a54;

    }

    #{$this}__containerColumn:not(.c-tasks-list-task__user-group) {
      color: #434a54;

      & > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    #{$this}__popup {
      position: absolute;
      z-index: 2000;
      border-radius: 4px;
      border: 1px solid #ccd1d9;
      background-color: white;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.24);
      text-align: center;

      &--info{
        background-color: var(--theme-color-l95);
        height: 100%;
        display: inline-grid;
        align-items: center;
        padding: 9px;
        justify-content: space-between;
        color: #434a54;
      }
      &--header{
        padding: 9px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        flex-grow: 0;
      }
      &--title{
        display: flex;
        align-items: center;
        font-size: 15px;
        margin: 0;
        color: #434a54;
      }
      &--btnClose{
        background: transparent;
        border: 0;
        cursor: pointer;
        font-size: 17px;
        font-weight: 400;
        text-align: end;
        color: #434a54;
      }
      &--iconSearchMembers{
        margin-left: -40px;
        width: 30px;
        height: 100%;
        border: none;
        background: none;
      }
    }

    #{$this}__containerIconAssignation {
      margin: auto;
    }

    #{$this}__grid .k-alt{
      background-color: #f2f2f2 !important;
    }

    #{$this}__grid .k-master-row.k-selected{
      background-color: #e6e9ed !important;
      border-color: #f5f7fa !important;

    }
    #{$this}__grid .k-grid-content {
      height: calc(100% - 30px) !important;
    }

    #{$this}__grid .k-grid td.k-selected, .k-grid tr.k-selected>td{
      background-color: #e6e9ed !important;
      border-color: #f5f7fa !important;
    }

    #{$this}__grid .k-grid-toolbar {
      display: none;
    }

    #{$this}__grid .k-pager-wrap {
      display: none;
    }

    #{$this}__grid .k-grid-content.k-auto-scrollable {
      //@include custom-scrollbar();

      height: calc(#{vh(100)} - #{$header-height} - 103px)  !important;

    }

    #{$this}__grid .k-filter-row th, .k-grid-header th.k-header {
      color: #999;
      font-weight: 400;
      //border-right: 1px solid #e5e5e5;
      font-size: 13px;
    }

    .c-tasks-list-task__infos {
    margin: 0;
    }

    #{$this}__grid  .k-grid {
      border-width: 0;
    }

    #{$this}__grid  .k-grid td {
     //border: none;
     max-width: 0;
     padding: 0.4em 0.6em;
     font-size: 13px;
    }

    #{$this}__grid  .k-sort-icon {
      color: $color-light;
    }

  }

}