.c-tasks-list-wallpaper {

  float: left;
  height: 75px;
  background-color: #b3bac5;
  border-radius: 15px;
  color: #fff;
  display: flex;
  width: 75px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: $screen-xs-max) {
    height: 60px;
    width: 60px;
  }

  &--gradient{
    float: left;
    height: 100px;
    background-color: #b3bac5;
    border-radius: 15px;
    color: #fff;
    display: flex;
    width: 90%;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  &__container{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 9px;
  }

  &__container--gradient{
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    gap: 9px;
    margin-bottom: 9px;
  }

  &__check{
    font-size: 17px;
    display: none;

    &--checked{
      display: flex;
    }
  }

  &__check--gradient{
    font-size: 25px;
    display: none;

    &--checked{
      display: flex;
    }
  }

  &__green--gradient {
    background: linear-gradient(to bottom right, #3E8C67, #5CC3CC);
    &:hover{
      opacity: 0.7;
    }
  }

  &__grey--gradient {
    background: linear-gradient(to bottom right, #4A5975, #1A2D4F);
    &:hover{
      opacity: 0.7;
    }
  }

  &__orange--gradient {
    background: linear-gradient(to bottom right, #E64F36, #F89F3C);
    &:hover{
      opacity: 0.7;
    }
  }

  &__red--gradient {
    background: linear-gradient(to bottom right, #4E2510, #A93019);
    &:hover{
      opacity: 0.7;
    }
  }

  &__purple--gradient {
    background: linear-gradient(to bottom right, #363676, #C4519A);
    &:hover{
      opacity: 0.7;
    }
  }

  &__blue--gradient {
    background: linear-gradient(to top left, #576999, #6786E8);
    &:hover{
      opacity: 0.7;
    }
  }

  &__sky--gradient {
    background: linear-gradient(to bottom right, #6787E8, #61C2D2);
    &:hover{
      opacity: 0.7;
    }
  }

  &__lime--gradient {
    background: linear-gradient(to bottom right, #5C8C3E, #b5f588);
    &:hover{
      opacity: 0.7;
    }
  }

  &__pink--gradient {
    background: linear-gradient(to bottom right, #E873B2, #E27266);
    &:hover{
      opacity: 0.7;
    }
  }

  &__lightPurple--gradient{
    background: linear-gradient(to bottom right, #7E5FC5, #E173BC);
    &:hover{
      opacity: 0.7;
    }
  }





  &__green {
    background-color: #519839;
    &:hover{
      opacity: 0.7;
    }
  }

  &__grey {
    background-color: #838C91;
    &:hover{
      opacity: 0.7;
    }
  }

  &__orange {
    background-color: #D29034;
    &:hover{
      opacity: 0.7;
    }
  }

  &__red {
    background-color: #B04632;
    &:hover{
      opacity: 0.7;
    }
  }

  &__purple {
    background-color: #89609E;
    &:hover{
      opacity: 0.7;
    }
  }

  &__blue {
    background-color: #0079BF;
    &:hover{
      opacity: 0.7;
    }
  }

  &__sky {
    background-color: #39AECC;
    &:hover{
      opacity: 0.7;
    }
  }

  &__lime {
    background-color: #5CBF6B;
    &:hover{
      opacity: 0.7;
    }
  }

  &__pink {
    background-color: #CD5A94;
    &:hover{
      opacity: 0.7;
    }
  }

  &__black{
    background-color: #354563;
    &:hover{
      opacity: 0.7;
    }
  }

}