.c-timeline-post-extension-preview
{
    $this: &;

    display: block;
    overflow: hidden;

    transition: filter ease $speed-fast;

    border: 1px solid $color-light;
    border-radius: 3px;

    @at-root
    {
        &,
        &:hover,
        &:focus,
        &:active
        {
            text-decoration: none;
        }

        .c-timeline-post &
        {
            border-right: none;
            border-left: none;
            border-radius: 0;
        }

        #{$this}__illustration-container
        {
            position: relative;

            padding-bottom: 56.25%;

            border-bottom: 1px solid $color-light;
        }

        #{$this}__illustration
        {
            position: absolute;

            width: 100%;
            height: 100%;

            object-fit: cover;
        }

        #{$this}__play
        {
            position: absolute;
            top: 50%;
            left: 50%;

            transition: transform ease $speed-fast;
            transform: translate(-50%, -50%);

            @mixin highlight()
            {
                transform: translate(-50%, -50%) scale(1.1);
            }

            @media not all and (hover: hover) and (pointer: fine)
            {
                #{$this}:active &
                {
                    @include highlight();
                }
            }

            @media (hover: hover) and (pointer: fine)
            {
                #{$this}:hover &
                {
                    @include highlight();
                }
            }
        }

        #{$this}__metas
        {
            padding: $size-x-small;

            color: $color-dark;
            background: $color-light-light;

            *:first-child
            {
                margin-top: 0;
            }

            *:last-child
            {
                margin-bottom: 0;
            }
        }

        #{$this}__info
        {
            font-size: 14px;
            font-weight: normal;

            overflow: hidden;

            margin-bottom: $size-xx-small;

            white-space: nowrap;
            text-transform: uppercase;
            text-overflow: ellipsis;

            color: $color-dark-light;
        }

        #{$this}__title
        {
            font-size: 19px;

            margin-top: $size-xx-small;

            &--single-line
            {
                overflow: hidden;

                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        #{$this}__illustration,
        #{$this}__metas
        {
            @mixin highlight()
            {
                filter: brightness(.95);
            }

            @media not all and (hover: hover) and (pointer: fine)
            {
                #{$this}:active &
                {
                    @include highlight();
                }
            }

            @media (hover: hover) and (pointer: fine)
            {
                #{$this}:hover &
                {
                    @include highlight();
                }
            }
        }
    }
}
