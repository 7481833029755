/// ============================================================================
/// # TIMELINE-POST-REACTIONS-LIST-ITEM
/// ============================================================================
/// Bloc utilisé pour chaque item de la liste des réactions d'un post. Enfant de
/// components.timeline-post-reactions-list. Affiché dans une sidebar au clic
/// sur le compteur de réactions.
/// On y retrouve :
/// - l'avatar de l'auteur
/// - l'icône de sa réaction
/// - son nom
/// - son poste au sein de l'organisation
/// ============================================================================
/// @name components.timeline-post-reactions-list-item
/// @group components
/// @require objects.flag Doit être utilisé avec ce bloc
/// @require components.entity-list-item Doit être utilisé avec ce bloc
/// @see components.timeline-post-reactions-list
/// ============================================================================

.c-timeline-post-reactions-list-item
{
    $this: &;

    padding-left: $size-x-small;

    @at-root
    {
        #{$this}__illustration
        {
            position: relative;
        }

        #{$this}__reaction
        {
            $size: $size-x-small + $size-xxx-small;
            $border-width: 2px;

            position: absolute;
            right: -$border-width;
            bottom: -$border-width;

            box-sizing: border-box;
            width: $size + $border-width;
            height: $size + $border-width;

            border: $border-width solid var(--background);
            border-radius: 50%;
            background: var(--background);
        }
    }
}
