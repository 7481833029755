.c-detail-photos {
  $this: &;

  padding-bottom: $size-x-small;
  margin-bottom: $size-x-small;


  @at-root {

    #{$this}__headerphotos {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 0px !important;
      padding-left: 10px !important;
    }

    #{$this}__container {
      display: flex;
      flex-flow: row wrap;
    }

    #{$this}__link > img {
      border-radius: 3px;
    }

    #{$this}__photo {
      width: 100px;
      height: 100px;
      margin: 10px;

      position: relative;
    }

    #{$this}__remove {
      font-size: 14px;

      position: absolute;
      top: 0;
      left: 0;

      width: $size-x-small;
      height: $size-x-small;
      padding: 0;

      color: rgba(255,255,255,.8);
      border: none;
      background-color: transparent;
      text-shadow: 0 1px 1px rgba(0,0,0,.16);
    }

    #{$this}__remove-icon {
      font-size: $size-x-small;

      display: flex;
      align-items: center;
      flex: 1;
      justify-content: center;
    }

    #{$this}__photo:hover #{$this}__remove
    {
      color: white;
      background-color: rgba(255, 0, 0, .5);
    }

  }
}