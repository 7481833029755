.c-post {
  $this: &;

  box-shadow: 0 0 1px rgba(black, .08), 0 2px 5px rgba(black, .08);
  margin:0;
  margin-bottom: $size-small ;

  @at-root
  {
    #{$this}__post
    {
      padding: $size-x-small;

      background-color: white;
    }

    #{$this}__header
    {
      display: flex;
    }

    #{$this}__thumb
    {
      flex-shrink: 0;

      margin-right: $size-x-small;
    }

    #{$this}__metas
    {
      display: flex;
      flex: 1;
      justify-content: space-between;
    }

    #{$this}__title
    {
      font-weight: bold;
    }

    #{$this}__content
    {
      position: relative;

      margin-top: $size-xx-small;
      padding-top: $size-x-small;

      border-top: 1px solid $panels-border-color;

      &:before,
      &:after
      {
        content: '';

        position: absolute;
        top: 0;
        left: 21px;

        width: $size-xx-small;
        height: $size-xx-small;

        background-color: #fefefe;
      }

      &:before
      {
        transform: translateY(calc(-50% - 1px)) rotateZ(-45deg);

        border: 1px solid $panels-border-color;
      }

      &:after
      {
        transform: translateY(calc((-#{$size-xx-small} / 2) + .5px)) rotateZ(-45deg);
      }
    }
  }
}
