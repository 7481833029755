.c-contacts-form-view {

  $this: &;

  @at-root {

    #{$this}__dropdown {
      position: absolute;
      right: 0;
      top: 0;
      margin: $size-x-small;
    }

    #{$this}__societe-service {
      max-width: 40%;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size:14px;
      margin-top: $size-xxx-small;
    }

    #{$this}__icons-container {
      margin-bottom: 20px;
      margin-top: $size-xx-small;
    }

    #{$this}__icon {
      width: 14px;
      height: 14px;
    }


    #{$this}__section-title {
      display: flex;
      align-items: center;
    }




    #{$this}__share-list {
      display: flex;
      margin-left: $size-xxx-small;
      margin-top: 2px;

      .user {
        margin-left: -5px;
        height: 25px;
        width: 25px;
        font-size: 13px;
        border: white 1px solid;
      }
    }


    #{$this}__users-more {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: $color-light;
      text-align: center;
      font-size: 13px;
      padding-top: 5px;
      padding-right: 2px;
      //font-family: 'Rubik', sans-serif;
      font-family: 'Apercu', sans-serif;
      color: #656565;
      margin-right: 1px;
      margin-left: 1px;
      cursor: pointer;
      border: white 1px solid;
    }




    #{$this}__icon-share {
      display: flex;
      margin-left: $size-xx-small;
      color: $color-dark-light;

      & svg {
        width: inherit;
        height: inherit;
      }
    }


    #{$this}__title {
      display: flex;
      justify-content: space-between;
    }

    #{$this}__names {
      margin-left: $size-x-small;
    }

    #{$this} hr {
      margin-top: 0px;
    }


    #{$this}__infos {
      padding:0;
      margin: 0px 0px 25px;
    }

    #{$this}__infos th {
      margin:0 ;
      padding:5px;
      width: 150px;
    }

    #{$this}__infos td {
      margin:0 ;
      padding:5px;
    }

    #{$this}__poste {
      text-transform: capitalize;
    }



  }

}


