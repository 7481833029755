.c-tasks-tagListTask {

  &__tag {
    cursor: pointer;
    border-radius: 3px;
    box-sizing: border-box;
    //display: block;
    float: left;
    height: 20px;
    line-height: 20px;
    margin: 4px 4px 0 0;
    min-width: 40px;
    padding: 0 8px;
    width: auto;
    font-size: 13px;
    color: black;
    font-weight: normal;
    display: flex;
    align-items: center;
  }

  &__mini{
    cursor: pointer;
    float: left;
    font-size: 13px;
    font-weight: 700;
    height: 8px;
    line-height: 100px;
    margin: 0 4px 4px 0;
    max-width: 40px;
    min-width: 40px;
    padding: 0;
    text-shadow: none;
    width: auto;
    display: none;
    border-radius: 4px;
    color: #fff;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__tagCircle{
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin-right: 5px;
  }

  &__tagName{
    color: #434a54;
    font-size: 13px;
    font-weight: 400;
  }
}