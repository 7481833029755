.c-course-aside {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;

  &__title {
    margin: 0;
    padding: 0 $size-x-small;
    font-weight: normal;

    &--main {
      padding-top: $size-x-small;
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  &__container {
    padding-top: $size-x-small;
    flex: 1;
    height: 100%;
    display: flex;
    min-height: 0;
    flex-direction: column;
  }

  &__tab-content {
    padding-top: 0!important;
    flex: 1;
    height: 100%;
    min-height: 0;
  }

  &__tab-pane {
    height: 100%;

    & .message__content {
      border-left: none;
    }
  }
}
