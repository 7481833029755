.c-spaces-thirdparty {

  $this: &;

  @at-root {

    // --------- ENTETE ----------------------
    #{$this}__header {
      display: flex;
      padding: $size-x-small;
    }

    #{$this}__title {  // titre du fichier
    //  padding: $size-x-small;
    //  padding-top:10px;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    #{$this}__title a,  #{$this}__title > span {  // lien dans le titre du fichier
      font-size: 21px;

      display: block;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }


    #{$this}__miniature {  // miniature en haut à gauche
      width:50px;
      height:50px;
    }
    // --------- FIN ENTETE -------------------



    // -------- TABS ----------------
    #{$this}__tabs-content {  // container des éléments du tabs sous l'entête
      border-top:none !important;
      padding-top: 0px !important;
    }

    #{$this}__tabs li {  // éléments du tabs sous l'entête
      width: 50%;
      display: none;

      @media #{$media-desktop}
      {
        display: block;
      }
    }
    // -------- FIN TABS ----------------

  }

}
