.c-form-share {
  $this: &;

  @at-root {

    #{$this}__confidential-container {
      display:flex;
      align-items: center;

      &.column {
        flex: 1;
      }

    }

    #{$this}__dropzone-metaimage--placeholder {
      margin: 5px 0 15px 0;
      width: $size-xx-large;
      height: $size-xx-large;
    }

    #{$this}__dropzone-metaimage .c-adndropzone__dropzone {
      margin-left: 0;
      margin-right: 0;
      height: $size-xx-large;
    }
  }
}