
@mixin custom-scrollbar() {

  &::-webkit-scrollbar {width: 7px; height: 0;}
  &::-webkit-scrollbar-track {margin: 5px 0 5px}
  &::-webkit-scrollbar-thumb {
    background: none;
    border-radius: 10px;
    box-shadow: rgba(white,0.3) 0 0 0 1px;
  }
  &:hover::-webkit-scrollbar-thumb {background: rgba(black, 0.45) }
  &::-webkit-scrollbar-thumb:hover {background: rgba(black, 0.55) }

}
