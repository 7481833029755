/// ============================================================================
/// # ENTITY-THUMB
/// ============================================================================
/// Avatar(s) des différentes entités de l'appli. Sert évidemment à représenter
/// des utilisateurs, mais aussi des groupes, des organisations, des campagnes,
/// etc. En bref, tout ce qui peut-être représenté par une image dynamique.
/// La structure HTML est générée par la fonction Twig entity_thumbnail. Si un
/// avatar n'as pas d'image, il contient l'initiale de l'entité et un fond
/// de couleur aléatoire (stocké dans Redis).
/// Ce composant gère aussi la superposition d'avatars, qui est utilisée dans le
/// tchat pour les conversations qui ne correspondent pas à une entité mère
/// (comme un groupe justement, ou une académie, etc.). Il gère jusqu'à 5
/// avatars, au delà le 5ème est remplacé par une indication du nombre restant
/// ("+2", "+3", etc.). Voir le modifier --multiple pour un peu plus de détails.
/// ============================================================================
/// @name components.entity-thumb
/// @group components
/// @require objects.target Nécessaire pour la balise <picture> dans le cas d'un
/// avatar avec image
/// ============================================================================
.c-entity-thumb
{
  $this: &;

  // Contrôle la taille de tous les avatars enfants, y compris la taille du
  // texte
  --size: 50px;

  position: relative;
  display: flex;

  font-size: calc(var(--size) / 2);

  width: var(--size);
  height: var(--size);

  user-select: none;

  flex-shrink: 0;
  flex-grow: 0;

  @at-root
  {
    #{$this}--mini
    {
      --size: #{$size-small};

      float:left;
    }

    #{$this}--small
    {
      --size: 32px;
    }

    #{$this}--big
    {
      --size: #{$size-medium};
    }

    #{$this} img
    {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }

    #{$this}--folder-shape
    {
      border-radius: 0;

      mask-image: url('/assets/masks/folder.svg');
      mask-repeat: no-repeat;
      mask-size: 95%;
      mask-position: center 52%;
    }

    // Lorsqu'il n'y a qu'un avatar, le border-radius est appliqué aussi au
    // conteneur. Cela évite des problèmes d'affichage en cas d'ajout d'une
    // bordure sur le conteneur, comme c'est le cas sur TodoList avec les listes d'avatars
    // superposés.
    #{$this},
    #{$this}__item
    {
      border-radius: 50%;
    }

    #{$this}--square
    {
      &,
      #{$this}__item
      {
        border-radius: 15%;
      }
    }

    #{$this}--multiple
    {
      overflow: visible;
      border-radius: 0;
    }

    #{$this}__item
    {
      --item-size: auto;

      position: relative;

      overflow: hidden;
      flex-grow: 1;

      width: var(--item-size);
      height: var(--item-size);

      display: flex;
      justify-content: center;
      align-items: center;

      color: white;

      &::before
      {
        content: '';

        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        border-radius: inherit;

        #{$this}--big &
        {
          border-width: 2px;
        }

        #{$this}--folder-shape &
        {
          content: none;
        }
      }

      // Stylise les avatars sans images
      &--placeholder
      {
        font-weight:500;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      // Par défaut, le modifier --multiple stylise deux avatars. Pour les
      // autres nombres (3, 4, 5 et 5 et plus), il faut ajouter un autre
      // modifier en plus de celui-ci. Voici la liste :
      // - 2 : --multiple
      // - 3 : --multiple --triple
      // - 4 : --multiple --quadruple
      // - 5 : --multiple --fivefold
      // - 5 et plus : --multiple --fivefold (et --overflow sur le dernier avatar)
      #{$this}--multiple &
      {
        --item-size: calc(var(--size) * .65);

        position: absolute;
        font-size: calc(var(--item-size) / 2);

        &:nth-child(1)
        {
          bottom: 0;
          left: 0;
          z-index: 1;
        }

        &:nth-child(2)
        {
          top: 0;
          right: 0;
        }
      }

      #{$this}--triple &
      {
        --item-size: calc(var(--size) * .55);

        &:nth-child(1)
        {
          top: 50%;
          transform: translateY(-50%);
          bottom: auto;
          z-index: 2;
        }

        &:nth-child(2)
        {
          z-index: 1;
        }

        &:nth-child(3)
        {
          bottom: 0;
          right: 0;
        }
      }

      #{$this}--quadruple &,
      #{$this}--fivefold &
      {
        --item-size: calc(var(--size) * .5);

        &:nth-child(1)
        {
          top: 0;
          bottom: auto;
          z-index: 3;
        }

        &:nth-child(2)
        {
          z-index: 2;
        }

        &:nth-child(3)
        {
          bottom: 0;
          left: 0;
          z-index: 1;
        }

        &:nth-child(4)
        {
          bottom: 0;
          right: 0;
        }
      }

      // Avec 5 avatars, l'affichage est exactement le même pour les 4 premiers.
      // Le cinquième est juste rajouté par dessus.
      #{$this}--fivefold &
      {
        &:nth-child(5)
        {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 4;
        }
      }

      // S'il y a plus que 5, le dernier est remplacé par une indication du
      // nombre d'avatars non affichés ("+2", "+3", etc.). Ce modifier stylise
      // ce dernier "faux" avatar.
      &--overflow
      {
        font-weight: normal;

        background: $color-light;
        color: $text-color;
      }
    }

    #{$this}__initial
    {
      #{$this}--folder-shape & {
        font-size: 9px;
      }
    }
  }
}
