.c-erreur {
  $this:&;
  @at-root {
    &__main-container{
      max-height: 438px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: $size-large;

      @media #{$media-desktop}{
        width: 869px;
        flex-direction: row;
        justify-content: space-between;
        height: 304px;
        padding-left: 15px;
      }
    }

    &__svg-container{
      max-width: $size-x-large;
      max-height: $size-x-large;

      @media #{$media-desktop}{
        max-height: $size-xx-large;
        height: $size-xx-large;
        max-width: $size-xx-large;
        margin: $size-x-small 0;
      }
    }

    &__svg{
      height: 100%;
      width: 100%;
      color: #DADDE0;
    }

    &__infos-container{
      display: flex;
      flex-direction: column;
      align-items: center;

      @media #{$media-desktop}{
        align-items: flex-start;
        width: $size-xxx-large;
        height: 304px;
      }
    }

    &__code-container{
      margin-top: $size-normal;
      margin-bottom: $size-xx-small;

      @media #{$media-desktop}{
        margin-top: 0;
      }
    }

    &__code{
      font-style: normal;
      font-weight: normal;
      font-size: 49px;
      line-height: 100%;
      margin: 0;

      @media #{$media-desktop}{
        font-size: 97px;
      }
    }

    &__info__txt{
      font-size: 19px;
      line-height: 100%;
      color: #9EA6B0;
      padding: 0;

      @media #{$media-desktop}{
        font-size: 37px;
      }
    }

    &__texte-container{
      text-align: center;
      margin-top: $size-normal;
      margin-bottom: $size-small;

      @media #{$media-desktop}{
        text-align: start;
        margin-top: $size-medium;
      }
    }

    &__texte{
      font-size: 15px;
      line-height: 17px;
      padding: 0 $size-x-small;

      @media #{$media-desktop}{
        padding: 0;
      }
    }

    &__btns-container{
      width: 100%;
      display: flex;
      justify-content: space-evenly;

      @media #{$media-desktop}{
        justify-content: flex-start;
      }
    }

    &__btn
    {
      @media #{$media-desktop}{
        &:first-child{
          margin-right: $size-small;
        }
      }
    }
  }
}
