.k-loading-mask{
    display: none !important;
}
:root{
    --dynamic-artificial-intelligence-chat-container-height: 160px;
    --dynamic-artificial-intelligence-footer-position-bottom: 16.5px;
    --dynamic-artificial-intelligence-footer-textarea-scrollHeight: 0px;

    @media (max-width: $screen-xs-max) {
        --dynamic-artificial-intelligence-chat-container-height: 225px;
    }
}

.c-artificialintelligence {
    $this: &;
    $width-column-left: 280px;

    @at-root {

        #{$this}__dark{
            color-scheme: dark;
        }
        #{$this}__light{
            color-scheme: light;
        }

        #{$this}__container {
            background-color: light-dark($artificial-intelligence-chat-bg-color-light, $artificial-intelligence-chat-bg-color-dark);
            overflow-y: hidden;
        }

        #{$this}__title {
            border:none !important;
        }

    }
}
