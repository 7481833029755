.emojione
{
    height: 1.1em;
    min-height: 16px;
    min-width: 16px;
}

.emojionearea-editor
{
    min-height: unset!important;
}

.c-timeline-publisher-editor__action--emoji + .emojionearea,
.c-timeline-comment-publisher__action--emoji + .emojionearea
{
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;

    display: inline-block;

    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.c-timeline-publisher-editor__action--emoji + .emojionearea,
.c-timeline-comment-publisher__action--emoji + .emojionearea
{
    display: flex;

    border: none;

    align-items: center;
}

.c-timeline-publisher-editor__action--emoji + .emojionearea:before,
.c-timeline-comment-publisher__action--emoji + .emojionearea:before
{
    display: inline-block;

    content: '\f118';
    transform: scale(1.2);
}

.c-timeline-publisher-editor__action--emoji + .emojionearea .emojionearea-editor.has-placeholder,
.c-timeline-comment-publisher__action--emoji + .emojionearea .emojionearea-editor.has-placeholder,
.c-timeline-publisher-editor__action--emoji + .emojionearea .emojionearea-editor,
.c-timeline-comment-publisher__action--emoji + .emojionearea .emojionearea-editor
{
    display: none;
}

.c-timeline-publisher-editor__action--emoji + .emojionearea .emojionearea-button-open,
.c-timeline-comment-publisher__action--emoji + .emojionearea .emojionearea-button-open,
.c-timeline-publisher-editor__action--emoji + .emojionearea .emojionearea-button-close,
.c-timeline-comment-publisher__action--emoji + .emojionearea .emojionearea-button-close
{
    display: none;

    background-image: none!important;
}

.c-timeline-publisher-editor__action--emoji + .emojionearea .emojionearea-picker.emojionearea-picker-position-bottom,
.c-timeline-comment-publisher__action--emoji + .emojionearea .emojionearea-picker.emojionearea-picker-position-bottom
{    
    right: -21.5px;
}

.c-timeline-publisher-editor__input + .emojionearea,
.c-timeline-comment-publisher__input + .emojionearea
{
    box-shadow: none;
}

.c-timeline-publisher-editor__input + .emojionearea > .emojionearea-editor,
.c-timeline-comment-publisher__input + .emojionearea > .emojionearea-editor
{
    padding: 1px;
}

.c-timeline-publisher-editor__input + .emojionearea > .emojionearea-editor,
{
    min-height: 3em !important;
}

.c-timeline-publisher-editor__input + .emojionearea > .emojionearea-editor:before,
.c-timeline-comment-publisher__input + .emojionearea > .emojionearea-editor:before
{
    font-style: italic;
}

.c-timeline-publisher-editor__input + .emojionearea > .emojionearea-editor:before
{
    font-size: 15px;
}

.c-timeline-comment-publisher__input + .emojionearea > .emojionearea-editor:before
{
    color: #ccc;
}

.c-timeline-publisher-editor__input + .emojionearea > .emojionearea-button,
.c-timeline-comment-publisher__input + .emojionearea > .emojionearea-button
{
    display: none;
}
