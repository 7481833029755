@keyframes pulse {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.95;
  }
}

.o-erreur-overlay{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(white, 0.95);
  z-index: $z-index-12;
  animation: pulse $speed-slow ease;


  @media #{$media-desktop}{
    display: flex;
    justify-content: center;
  }
}
