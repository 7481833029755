.intl-tel-input
{
    $this: &;

    width: 100%;

    @at-root
    {
        #{$this} + .c-form-group__label
        {
            left: 52px;
        }

        #{$this}.is-focused ~ .c-form-group__label,
        #{$this}.has-value ~ .c-form-group__label
        {
            transform: translateY(-11px) scale(.8);
        }

        #{$this}.is-focused ~ .c-form-group__label
        {
            color: var(--theme-color-light);
        }

        #{$this}.has-value ~ .c-form-group__label
        {
            color: $panels-border-color;
        }
    }
}
