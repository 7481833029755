.c-contacts-content {


  $this: &;
  $width-column-left : 280px;


  @at-root {

    // correspont au dropdown k-animation-container de la recherche (kendo multiselect)
    #{$this}__search {

      & .c-user-column {
        padding: $size-xxx-small !important;
      }

      & .tagscolor {
        padding: $size-xxx-small;
        cursor: pointer;
        color: white;
        border-radius: $size-xxx-small;
      }

      .k-list-item {
        .k-list-item-text::before {
          content: none;
        }
      }

    }


    #{$this}__header-mobile {
      display: flex;
      height: 40px;

      & .btnAsideMenuMobile, & .btnDropdownAdd {
        margin:5px;
      }

      & .btnAsideMenuMobile {
        min-width: 32px
      }


      @media (min-width: $screen-xs-min)
      {
        display: none;
      }
    }

    #{$this}__container {
      height: 100%;

      display: flex;
      flex-direction: column;
    }



    #{$this}__header {
      display: none;

      @media #{$media-desktop}
      {
        display: flex;

        & .c-search-contacts {
          cursor: pointer;
          margin-left: 100px;
          width: 400px !important;
          display: flex;
          align-items: center;
          border: 1px solid #efefef;

          .k-input-solid:focus-within {
            border:none!important;
            box-shadow: none !important;
          }

          .k-input-solid {
            .k-focus {
                border:none!important;
                box-shadow: none !important;
            }
          }

          & .k-multiselect {
            .k-input-inner {
     background: transparent !important;
            }
          }

          & .k-multiselect-wrap:not(.k-hover):not(.k-focus) .k-readonly {
            opacity: 1 !important;
          }

          & .k-floatwrap {
            border-right: none;
            box-shadow: none;

            display: flex;
            align-items: center;

            & input {
              font-size: 13px;
              color: #434a54;
              background: transparent;
            }

            & li {
              width: max-content;
            }
          }

          & .k-clear-value {
            margin-top: $size-xxx-small;
          }


          & .field {
            width: 100%;
          }

          & .icon {
            height: 36px;
            display: flex;
            align-items: center;
            width: 30px;
            justify-content: center;
            border-left-style: none;
          }
        }

      }

    }


    #{$this}__title {
      border-bottom:none !important;
    }


    //region grid contacts
    #{$this}__grid {
      border:none !important;
    }


    #{$this}__grid tr {
      cursor: pointer;
    }

    #{$this}__grid .k-grid-header {
      padding-right: 7px !important;  // webkit-scrollbar est en width 7px, on diminue donc la taille réservée par défaut de 16px à 8px
    }

    #{$this}__grid .k-grid-header-wrap {

    }

    #{$this}__grid .k-grid-header
    {
      display: none;

      @media #{$media-desktop}
      {
        display: block;
      }

    }

    #{$this}__grid .k-header .k-link {
      color:  var(--theme-color-light);
      font-weight: 400 ;
      font-size: 13px;
      padding:5px 0 5px 5px;
    }

    #{$this}__grid .k-grid-content.k-auto-scrollable {
      @include custom-scrollbar();

      height: calc(#{vh(100)} - #{$header-height} - 40px)  !important;

      .has-enabled-mobile-navbar & {
        height: calc(#{vh(100)} - #{$header-height} - var(--mobile-menu-height)) !important;
      }

      @media #{$media-desktop}
      {
        height: calc(#{vh(100)}  - 95px) !important;

        //.has-enabled-mobile-navbar & {
        //  height: calc(#{vh(100)}  - 95px) !important;
        //}
      }

      .k-table-row > .k-table-td > div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }


    .k-action-buttons .k-button.k-primary {
      color : var(--theme-color-light);
    }

    #{$this}__grid  .k-alt {
      background-color: $color-light-light;
    }

    #{$this}__grid th {
      color: $color-black !important;
    }

    #{$this}__grid td {
      color: $color-black !important;
      padding:8px;
      font-size: 14px;
    }

    #{$this}__grid tr.k-selected > td  {
      background-color: $color-light !important;
      border-color: $color-light-light !important;
    }
    //endregion


    //region grid surchare pour la grilles des partage du contact
    #{$this}__grid-shares  {

      margin-bottom: $size-xxx-small;

      .k-grid-content tr {
        height: 50px;
      }

      & th:last-child {
        border:none;
      }

    }

    #{$this}__grid-shares .k-grid-content.k-auto-scrollable {
      @include custom-scrollbar();

      height: calc(#{vh(100)} - #{$header-height} - var(--mobile-menu-height) - 130px  ) !important;


      @media #{$media-desktop}
      {
        height: calc(#{vh(100)}  - 195px) !important;
        max-height: 331px !important; // de manière à voir clairement 7 lignes du kendo grid en desktop
      }

      & td:last-child {
        border:none;
      }

    }
    //endregion


    //region grid - 1ère colonne
    #{$this}__grid .c-user-column__title--mini {
      font-weight: normal;
      font-size: 14px;
    }

    #{$this}__grid .c-user-column__others {
      margin-left:42px;
      font-size: 12px
    }
    //endregion


    #{$this}__info-title {
      margin-right:10px;
    }


    #{$this}__notification {

      .username {
        padding:3px;
      }
    }


    // colonne de gauche d'une largeur minimum
    #{$this}__aside {
      min-width: $width-column-left;
      max-width: $width-column-left;
    }

    .k-button {
      border-radius: 2px;
      border: none;
      background: #f5f5f5;
      color: #444;
      padding: 10px;

      span:first-child {
        font-weight: 500;
      }
    }

    .k-popup {
      .k-group-header {
        text-align: center;
        background: #f5f5f5;
        padding: 5px;
      }
    }

    .k-popup {
      .k-list-content {
        .k-list-item > .k-list-item-group-label {
          background: #ebebeb;
          color: #3f51b5;
          border-bottom-left-radius: 1px;
          position: absolute;
          top: 0;
          right: 0;
          padding: 0 0.5em;
          line-height: 1.8;
        }
        .k-list-item {
          &::before {
            content: none;
          }
        }
      }
    }


    .k-grid table {
      width: 100%;
    }

    .k-grid-norecords {
      display: inherit!important;
    }

    .k-header {
      padding: 0.5em 0.6em 0.4em 0.6em;
    }

    .c-contacts-content__grid th {
      border-right: rgba(0, 0, 0, 0.08) solid 1px;
      .k-sort-icon {
        color: $default-theme-color;
      }
    }
    .c-contacts-form {
      .k-dropdown-wrap {
        border: 1px solid #fafafa;
        border-radius: 2px;
        background:#f5f5f5;
        padding: 0.4em 0;
        .k-input {
          background: transparent;
          padding: 0 $size-xxx-small;
        }
      }
    }
    .k-toolbar {
      border: none;
    }
    .k-editable-area {
      border: solid 1px #e8e8e8;
    }
    .k-editor-toolbar {
      .k-icon {
        font-size: 15px
      }
      .k-button-text {
        display: none;
      }
    }
    .k-picker-solid:focus-within {
      box-shadow: none!important;
    }
    .c-contacts-form {
      .k-dropdown-wrap {
        border: 1px solid #fafafa;
        border-radius: 2px;
        background:#f5f5f5;
        padding: 0.4em 0;
        .k-input {
          background: transparent;
          padding: 0 $size-xxx-small;
        }
      }
    }
    .k-toolbar {
      border: none;
    }
    .k-editable-area {
      border: solid 1px #e8e8e8;
    }
    .k-editor-toolbar {
      .k-icon {
        font-size: 15px
      }
      .k-button-text {
        display: none;
      }
    }

  }
}
