.c-placeholder-block {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: $size-xx-large * 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-wrap: wrap;
  margin: 0;
  
  @media (min-width: $screen-sm-min) {
    margin: 0 $size-small;
  }

  &__img {
    width: $size-x-large;
    height: 100%;
    max-height: 100%;
    margin: 0 auto;
    color: var(--theme-color);


    @media (min-width: $screen-sm-min) {
      margin: 0;
    }
  }

  &__title-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    @media (min-width: $screen-sm-min) {
      align-items: flex-end;
      margin: 0 $size-x-small;
    }
  }

  &__title {
    font-size: $size-small;
    margin-bottom: 0;
  }

  &__subtitle {
    font-size: $size-x-small;
  }

  &__title,
  &__subtitle {
    font-weight: normal
  }
}
