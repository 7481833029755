.c-tasks-sub-header {
  &__title {
    color: $text-color;
    font-weight: 500;
    font-size: 17px;
    margin: 0 $size-xxx-small;
    cursor: pointer;
  }

  &__title--folderDahboard {
    color: $text-color;
    font-weight: 500;
    font-size: 17px;
    margin: 0;
    cursor: default;
  }

  &__iconStar{
    position: relative;
    top: 5px;
    left: 18px;
    z-index: 1;
    font-size: 1.4em;
    color: #efd163;
    cursor: pointer;
    display: none;
  }

  &__iconStarEmpty{
    position: relative;
    top: 5px;
    left: 15px;
    display: inline-block;
    z-index: 1;
    font-size: 1.4em;
    cursor: pointer;
    color: #434a54;
  }

  &__options-container {
    padding: $size-xx-small;
    display: flex;
    align-items: center;
  }

  &__users {
    display: flex;
    margin-right: $size-xx-small;
    cursor: pointer;
    margin-left: 30px;

    //& > div {
    //  margin-right: $size-xxx-small;
    //  margin-left: 0px;
    //}

    .c-tasks-sub-header__users-more {
      width: 25px!important;
      height: 25px!important;
    }
  }

  &__users-more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $color-light;
    text-align: center;
    padding: 0!important;
    font-size: 13px;
    //font-family: 'Rubik', sans-serif;
    font-family: 'Apercu', sans-serif;
    color: #656565;
    margin-right: 0!important;
    margin-left: 10px;
    border: white 1px solid;

    &:hover {
      border-radius: 50%;
      background: darken($color-light, 15%);
    }
    &:active {
      background: darken($color-light, 25%);
    }
  }

  &__users-more-mini {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: $color-light;
    text-align: center;
    padding: 0!important;
    font-size: 13px;
    //font-family: 'Rubik', sans-serif;
    font-family: 'Apercu', sans-serif;
    color: #656565;
    margin-right: 0!important;
    margin-left: 10px;
    border: white 1px solid;

    &:hover {
      border-radius: 50%;
      background: darken($color-light, 15%);
    }
    &:active {
      background: darken($color-light, 25%);
    }
  }


  &__users-more-kanban {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #e6e9ed;
    text-align: center;
    font-size: 12px;
    padding-top: 2px;
    //font-family: 'Rubik', sans-serif;
    font-family: 'Apercu', sans-serif;
    color: #656565;
    margin-right: 3px;
    margin-left: 3px;
    padding-left: 4px;
    border: white 1px solid;
    top: 2px;
  }

  &__actions {
    display: flex;
    color: $text-color;
  }

  &__action {
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-left: $size-xx-small;
  }

  &__nav-container,
  &__nav-tabs,
  &__nav {
    height: 100%;
  }

  &__nav-item {
    height: 100%;
    padding: 0 15px!important;
    line-height: 54px!important;
  }

  &__filtersButton{
    width: 100%;
    justify-content: normal;
    text-transform: none;
    font-size: 15px;
    height: 32px;
    color: #434a54;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    background: #e6e9ed;
    &:hover{
      background: #ffffff;
      color: #434a54;
    }

    &--filtersActivated{
      background-color: green;
      color: white;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      &:hover{
        background: seagreen !important;
      }
    }

    &--tagNbrsTasksFiltered{
      margin-left: 8px;
      border-radius: 20px;
      padding-right: 6px;
      padding-left: 6px;
      padding-top: 2px;
      background-color: darkgreen;
    }
  }

  &__filtersButtonSidebar {
    width: fit-content;
    justify-content: normal;
    text-transform: none;
    font-size: 15px;
    height: 32px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background: green;
    margin: 0;
    &:hover{
      background: seagreen  !important;
    }
  }

  &__crossFiltersButton{
    justify-content: normal;
    text-transform: none;
    font-size: 15px;
    height: 32px;
    min-width: fit-content;
    padding: 8px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    background: green;
    margin: 0px !important;
    &:hover{
      background: seagreen  !important;
    }
  }
}