$trigger-height: $size-small + $size-xxx-small;
$border-thickness: 1px;

$option-padding: $size-x-small;

$arrow-width: $size-xx-small;
$arrow-left-offset: $size-xxx-small;
$arrow-right-offset: $size-xxx-small;

.select2-container
{
    font-size: 15px;

    width: 100% !important;
    max-width: 100%;

    &[style*='width: 100%'],
    &[style*='width:100%']
    {
        width: 100% !important;
    }
}

/// ============================================================================
/// Height
///
.select2-container .select2-selection--single
{
    height: $trigger-height;
}

.select2-container--default .select2-selection--single .select2-selection__rendered
{
    line-height: $trigger-height - $border-thickness;
}

.select2-container--default.select2-container--above.select2-container--open .select2-selection--single .select2-selection__rendered
{
    line-height: $trigger-height;
}


.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple
{
    min-height: $trigger-height;

    border: $border-thickness solid $panels-border-color;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    background: none;
}

.select2-container--default.select2-container--focus .select2-selection--multiple
{
    border-color: $panels-border-color;
    border-top: none;
    border-right: none;
    border-left: none;
}

.select2-container--default.select2-container--open .select2-selection--single,
.select2-container--default.select2-container--open .select2-selection--multiple
{
    border-bottom-color: var(--theme-color-light);
}

/// ============================================================================
/// Arrow
///
.select2-container--default .select2-selection--single .select2-selection__arrow
{
    display: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered
{
    padding: 0 ($arrow-left-offset + $arrow-width + $arrow-right-offset) 0 0;
}

.select2-container--default .select2-selection--single::after
{
    content: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" fill="' + $text-color + '" viewBox="0 -7.999 30.399 30.999" height="100%" width="100%"><path d="m 0.001,0.8 c 0,-0.205 0.078,-0.41 0.234,-0.566 0.312,-0.312 0.819,-0.312 1.131,0 L 15.2,14.068 29.034,0.234 c 0.312,-0.312 0.819,-0.312 1.131,0 0.312,0.312 0.312,0.819 0,1.131 l -14.4,14.4 c -0.312,0.312 -0.819,0.312 -1.131,0 L 0.234,1.365 C 0.077,1.208 0,1.003 0,0.799 Z"></path></svg>');

    position: absolute;
    top: 50%;
    right: $arrow-right-offset;

    width: $arrow-width;
    margin-top: 1px; /* To encounter the optical illusion the arrow is not aligned */

    transition: transform ease $speed-fast;
    transform: translateY(-50%);
}

.select2-container--default.select2-container--open .select2-selection--single::after
{
    transform: translateY(-50%) rotate3d(0, 0, 1, 180deg);
}

/// ============================================================================
/// Text color
///
.select2-container--default .select2-selection--single .select2-selection__placeholder
{
    color: inherit;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder,
.select2-container--default .select2-search--inline .select2-search__field::placeholder
{
    transition: color ease $speed-fast;
}

.select2-container--default.has-value .select2-selection--single .select2-selection__rendered
{
    color: $text-color;
}

.select2-container--default:not(.has-value) .select2-selection--single .select2-selection__rendered,
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__rendered
{
    color: transparent;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__placeholder,
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2--message .select2-selection__placeholder
{
    color: $color-default-light;
}

/// ============================================================================
/// Dropdown
///
.select2-dropdown
{
    z-index: $z-index-3 + 1;

    overflow: hidden;

    border-color: $panels-border-color;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single
{
    border-top: none;
}

.select2-container--open .select2-dropdown--below
{
    margin-top: $size-xxx-small;

    border-top: $border-thickness solid $panels-border-color;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.select2-container--open .select2-dropdown--above
{
    margin-bottom: $size-xxx-small;

    border-bottom: $border-thickness solid $panels-border-color;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.select2-dropdown
{
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
}

.select2-dropdown:not(.select2-dropdown--flush)
{
    width: calc(100% + (#{$option-padding} * 2)) !important;
    margin-left: -$option-padding;
}

/// ============================================================================
/// Options
///
.select2-results__option
{
    line-height: $size-normal;

    padding: 0 $option-padding;

    transition: ease $speed-fast;
    transition-property: color, background-color;
}

.select2-container--default .select2-results__option--highlighted[aria-selected]
{
    background-color: var(--theme-color-light);
}

.select2-container--default .select2-results__option[aria-selected=true]
{
    color: white;
    background-color: var(--theme-color);
}

.select2-container--default .select2-results__group
{
    padding: 0 $size-xx-small;
}

.select2-results__option:not(:last-child) .select2-results__options--nested
{
    margin-bottom: $size-x-small;
}

/// ============================================================================
/// Search bar
///
.select2-search--dropdown
{
    padding: $size-xx-small $size-x-small;
}

.select2-container--default .select2-search--dropdown .select2-search__field
{
    margin: 0;
    padding: $size-xx-small;
    padding-right: 0;
    padding-left: 0;

    border-color: $panels-border-color;
    border-top: none;
    border-right: none;
    border-left: none;

    &:focus
    {
        border-color: var(--theme-color-light);
    }
}

/// ============================================================================
/// Multiple
///
$cross-padding: $size-xxx-small;
$item-padding: $size-xx-small;
$item-margin: $size-xxx-small;

.select2-container--default .select2-search--inline .select2-search__field
{
    height: $trigger-height - $border-thickness;
    margin: 0;
}

.select2-container--default .select2-search--inline .select2-search__field::placeholder
{
    color: transparent;
}

.select2-container--default.select2-container--open .select2-search--inline .select2-search__field::placeholder,
.select2-container--default .select2--new-tchat .select2-search--inline .select2-search__field::placeholder,
.select2-container--default .select2--user .select2-search--inline .select2-search__field::placeholder
{
    color: $color-default-light!important;
}

.select2-container--default:not(.has-value) .select2-search--inline .select2-search__field
{
    width: auto !important;
}

.select2-container--default.has-value .select2-search--inline .select2-search__field
{
    padding-bottom: $item-margin;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered
{
    padding: 0;
    display: inline-flex;
    flex-wrap: wrap;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-selection__choice
{
    line-height: $trigger-height - $item-margin - $border-thickness;

    display: flex;
    align-items: center;

    height: $trigger-height - $item-margin - $border-thickness;
    margin-top: 0;
    margin-bottom: $item-margin;
    padding: 0 $item-padding 0 ($item-padding - $cross-padding);

    border: none;
    background-color: $color-light;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove
{
    font-size: 0;
    line-height: 1;

    margin-right: 0;

    &::before
    {
        content: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" fill="' + $text-color + '" viewBox="0 0 30.4 30.398001" height="100%" width="100%"><path d="M 16.33,15.199 30.164,1.365 c 0.312,-0.312 0.312,-0.819 0,-1.131 -0.312,-0.312 -0.819,-0.312 -1.131,0 L 15.199,14.068 1.365,0.234 c -0.312,-0.312 -0.819,-0.312 -1.131,0 -0.312,0.312 -0.312,0.819 0,1.131 L 14.068,15.199 0.234,29.033 c -0.312,0.312 -0.312,0.819 0,1.131 0.157,0.157 0.362,0.234 0.566,0.234 0.204,0 0.41,-0.078 0.566,-0.234 L 15.2,16.33 29.034,30.164 c 0.157,0.157 0.362,0.234 0.566,0.234 0.204,0 0.41,-0.078 0.566,-0.234 0.312,-0.312 0.312,-0.819 0,-1.131 L 16.332,15.199 Z"></path></svg>');

        display: inline-block;

        width: $size-x-small + $cross-padding;
        height: auto;
        padding: $cross-padding;
    }

    &:hover::before
    {
        content: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" fill="' + $color-danger + '" viewBox="0 0 30.4 30.398001" height="100%" width="100%"><path d="M 16.33,15.199 30.164,1.365 c 0.312,-0.312 0.312,-0.819 0,-1.131 -0.312,-0.312 -0.819,-0.312 -1.131,0 L 15.199,14.068 1.365,0.234 c -0.312,-0.312 -0.819,-0.312 -1.131,0 -0.312,0.312 -0.312,0.819 0,1.131 L 14.068,15.199 0.234,29.033 c -0.312,0.312 -0.312,0.819 0,1.131 0.157,0.157 0.362,0.234 0.566,0.234 0.204,0 0.41,-0.078 0.566,-0.234 L 15.2,16.33 29.034,30.164 c 0.157,0.157 0.362,0.234 0.566,0.234 0.204,0 0.41,-0.078 0.566,-0.234 0.312,-0.312 0.312,-0.819 0,-1.131 L 16.332,15.199 Z"></path></svg>');
    }
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple
{
    border-top-color: transparent;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear
{
    font-size: 0;
    line-height: 1;

    margin-right: 0;

    &::before
    {
        content: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" fill="black" viewBox="0 0 30.4 30.398001" height="100%" width="100%"><path d="M 16.33,15.199 30.164,1.365 c 0.312,-0.312 0.312,-0.819 0,-1.131 -0.312,-0.312 -0.819,-0.312 -1.131,0 L 15.199,14.068 1.365,0.234 c -0.312,-0.312 -0.819,-0.312 -1.131,0 -0.312,0.312 -0.312,0.819 0,1.131 L 14.068,15.199 0.234,29.033 c -0.312,0.312 -0.312,0.819 0,1.131 0.157,0.157 0.362,0.234 0.566,0.234 0.204,0 0.41,-0.078 0.566,-0.234 L 15.2,16.33 29.034,30.164 c 0.157,0.157 0.362,0.234 0.566,0.234 0.204,0 0.41,-0.078 0.566,-0.234 0.312,-0.312 0.312,-0.819 0,-1.131 L 16.332,15.199 Z"></path></svg>');

        display: inline-block;

        width: $size-x-small + $cross-padding;
        height: auto;
        padding: $cross-padding;
    }

    &:hover::before
    {
        content: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" fill="' + $color-danger + '" viewBox="0 0 30.4 30.398001" height="100%" width="100%"><path d="M 16.33,15.199 30.164,1.365 c 0.312,-0.312 0.312,-0.819 0,-1.131 -0.312,-0.312 -0.819,-0.312 -1.131,0 L 15.199,14.068 1.365,0.234 c -0.312,-0.312 -0.819,-0.312 -1.131,0 -0.312,0.312 -0.312,0.819 0,1.131 L 14.068,15.199 0.234,29.033 c -0.312,0.312 -0.312,0.819 0,1.131 0.157,0.157 0.362,0.234 0.566,0.234 0.204,0 0.41,-0.078 0.566,-0.234 L 15.2,16.33 29.034,30.164 c 0.157,0.157 0.362,0.234 0.566,0.234 0.204,0 0.41,-0.078 0.566,-0.234 0.312,-0.312 0.312,-0.819 0,-1.131 L 16.332,15.199 Z"></path></svg>');
    }
}

/// ============================================================================
/// Disabled
///
.select2-container--default.select2-container--disabled .select2-selection--single
{
    color: $panels-border-color;
    border-bottom-style: dashed;
    background-color: transparent;

    &::after
    {
        content: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" fill="' + $panels-border-color + '" viewBox="0 -7.999 30.399 30.999" height="100%" width="100%"><path d="m 0.001,0.8 c 0,-0.205 0.078,-0.41 0.234,-0.566 0.312,-0.312 0.819,-0.312 1.131,0 L 15.2,14.068 29.034,0.234 c 0.312,-0.312 0.819,-0.312 1.131,0 0.312,0.312 0.312,0.819 0,1.131 l -14.4,14.4 c -0.312,0.312 -0.819,0.312 -1.131,0 L 0.234,1.365 C 0.077,1.208 0,1.003 0,0.799 Z"></path></svg>');
    }
}

/// ============================================================================
/// Label
///
.select2
{
    z-index: $z-index-1;
}

.select2 + .c-form-group__label
{
    transform: translateY(8.5px) scale(1) !important;
}

.select2.has-value + .c-form-group__label,
.select2.select2-container--open + .c-form-group__label
{
    transform: translateY(-13px) scale(.8) !important;
}

.select2:not(.has-value) + .c-form-group__label
{
    color: $text-color !important;
}

.select2.has-value + .c-form-group__label,
.select2.select2-container--disabled + .c-form-group__label
{
    color: $panels-border-color !important;
}

.select2.select2-container--open + .c-form-group__label
{
    color: var(--theme-color-light) !important;
}

.select2.select2-container--disabled + .c-form-group__label
{
    cursor: default;

    color: $panels-border-color;
}

/// ============================================================================
/// --diluted modifier
/// Removes the bottom border and do some alignement to make the select2
/// really... "diluted" in a container.
///
.select2--diluted
{
    border: none !important;
}

.select2-container--default .select2-selection--multiple.select2--diluted .select2-selection__rendered .select2-selection__choice
{
    margin-top: $item-margin / 2;
    margin-bottom: $item-margin / 2;
}

.select2-container--default.has-value .select2-selection--multiple.select2--diluted .select2-search--inline .select2-search__field
{
    padding-bottom: 0;
}

/// ============================================================================
/// --message modifier
/// Used in the conversations search bar (in the conversations list).
///
.select2--message {
    background-color: #f6f7f9!important;
    border-radius: 5px !important;
    text-align: center;
    color: #90949c;

    &::before {
        font-family: FontAwesome;
        content: "\f002";
        color: #90949c;
        margin-right: 5px;
        float: left;
        padding: 5px 0 5px 15px;
    }
}

.select2-dropdown--message {
    background-color: #f6f7f9!important;
    border: none!important;
}

/// ============================================================================
/// --user modifier
/// Used in the modal to add a user.
///
.select2--user {
    border-bottom: 1px solid rgba(0, 0, 0, .10)!important;
    padding: 5px;
}

.select2-container--default .select2-selection--multiple.select2--user .select2-selection__rendered .select2-selection__choice
{
    margin-top: $item-margin / 2;
    margin-bottom: $item-margin / 2;
}

.select2-container--default.has-value .select2-selection--multiple.select2--user .select2-search--inline .select2-search__field
{
    padding-bottom: 0;
}

.select2-dropdown--user {
    border: none!important;
    border-bottom: 1px solid rgba(0, 0, 0, .10)!important;
}
