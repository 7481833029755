.c-tchat-ia {
  $this: &;

  @at-root {
    #{$this}__changeOptimizedTextForTchat {
      top: 25% !important;
    }

    #{$this}__changeStylizeTextForTchat{
      top: 0% !important;
    }

    #{$this}__changeTonalityForTchat {
      top: 37.5% !important;
    }

    #{$this}__changeLanguageForTchat {
      top: 25% !important;
    }
    #{$this}__ia-icon-tchat {
      width:24px;
      vertical-align: inherit;
    }
  }
}


