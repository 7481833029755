.c-calc-content {

  $this: &;
  $width-column-left: 280px;

  @at-root {

    #{$this}__title {
      border:none !important;
    }

  }

}