.c-dynamic-form-group {
  margin: $size-x-small 0;

  &--inline {
    margin: 0;
    display: none;
    position: relative;
  }

  &--localisation {
    margin: $size-x-small 0 0 0;
  }

  &__header {
    display: flex;

    .c-dynamic-form-group--inline & {
      position: absolute;
      right: 0;
      top: 7px;
    }

    .c-dynamic-form-group--localisation & {
      margin-bottom: $size-xx-small;
    }

    .c-dynamic-form-group--inscription & {
      top: 14px;
    }
  }

  &__content {
    margin: $size-x-small;
    border-bottom: 1px solid $color-default-light;
    padding-bottom: $size-x-small;

    .c-dynamic-form-group--inline &, .c-dynamic-form-group--localisation & {
      margin: 0;
      border: none;
      padding: 0;
    }
  }

  &:last-child &__content {
    border: none;
  }

  &__delete {
    margin-right: $size-xxx-small;
    width: $size-x-small;
    height: $size-x-small;

    .c-dynamic-form-group--inline & {
      margin-right: 0;
    }

    &:hover {
      cursor: pointer;
      color: $color-danger;
    }
  }

  &__title {
    font-weight: normal;
  }

  &__label{
    .c-dynamic-form-group--inline & {
      display: none;
    }
  }

  &__select{
    max-width: 130px;
    width: 35%;
  }

  &__field {
    width: 50%;
    margin-right: $size-x-small;
    padding-left: $size-x-small;

    @media #{$media-desktop}{
      width: 55%;
      margin-right: $size-small;
    }

    .c-dynamic-form-group--inscription &{
      width: 100%;
      padding-left: 0;
    }

  }

  &__add {
    display: flex;
    color: var(--theme-color);

    .c-dynamic-form-group--inscription &{
      background-color: transparent;
      border: none;
    }

    &:hover {
      cursor: pointer;
    }

    &__svg {
      height: $size-x-small;
      width: $size-x-small;
      margin-right: $size-xxx-small;
    }

    &__txt {
      padding: 0;
    }
  }
}
