.c-user-column {
  display: flex;
  align-items: center;

  &__title-group {
    display: flex;
    flex-direction: column;
    margin-left: $size-xx-small;
    min-width: 0;
  }

  &__title,
  &__subtitle {
    margin-bottom: 0;
    margin-top: 0;
    text-align: start;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__title {
    font-size: 15px;

    &--mini {
      font-size: 13px;
    }

    &--no-font-weight {
      font-weight: normal;
    }
  }

  &__subtitle {
    margin-top: 5px;
    font-size: 12px;
    font-weight: normal;
    color: $color-default;
  }
}