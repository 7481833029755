.c-mobile-groups-shortcuts
{
    $this: &;

    margin-right: -$size-x-small;
    margin-bottom: $size-x-small;
    margin-left: -$size-x-small;
    padding: $size-xx-small $size-xx-small 0;

    background: white;
    box-shadow: 0 0 1px rgba(black, .08), 0 2px 5px rgba(black, .08);

    @media (min-width: $screen-xs-min)
    {
        margin-right: 0;
        margin-left: 0;

        border-radius: 3px;
    }

    @media (min-width: $screen-md-min)
    {
        display: none;
    }

    @at-root
    {
        #{$this}__title
        {
            font-size: 15px;
            line-height: 1;

            margin-top: 0;
            margin-bottom: $size-xxx-small;
        }

        #{$this}__list
        {
            display: flex;
            overflow-y: auto;

            margin-right: -$size-xx-small;
            margin-left: -$size-xx-small;

            //-webkit-overflow-scrolling: touch;
            //scroll-snap-type: x;

            & .k-listview {
                border: none;
                font-size:13px;
            }
        }

        #{$this}__list-item
        {
            //scroll-snap-align: start;
        }
    }
}
