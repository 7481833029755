.c-directory-shared {

  $this: &;
  @at-root
  {

    //region GRILLE DES USERS
    #{$this}__grid td  {
      border: none !important;
    }

    #{$this}__grid {
      border:none !important;

      & .c-user-column__title-group {
        width:100% !important;
      }
    }

    #{$this}__grid .k-grid-header {
      display: none;
    }

    #{$this}__grid .k-grid-content.k-auto-scrollable {
      @include custom-scrollbar();

      padding:0!important;
      height: calc(#{vh(100)} - #{$header-height} - 65px)  !important;

      & .k-table-td {
        padding-left:$size-xxx-small;
        padding-right:$size-xxx-small;
      }

      @media #{$media-desktop}
      {
        & .k-table-td {
          padding-left:$size-xx-small;
          padding-right:$size-xx-small;
        }
      }

    }
    //endregion


    #{$this}__dropdown-adduser {
      margin:$size-xxx-small;
    }


    #{$this}__column-actions {
      display: flex;

      & .dropdown {
        margin-left: $size-x-small;


        & .dropdown-append-to-body {

          display:none !important;
          @media #{$media-desktop}
          {
            display:block;
          }

        }

      }

      & .k-input-value-text {
        font-size: 14px;
      }
    }

    #{$this}__username {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }


    #{$this}__confirm {
      font-size:14px;
    }

    #{$this}__confirm-alert {
      color: darken($color-danger, 20%);
    }


    #{$this}__warning-directory {
      margin-top:3px;
      font-size:21px;

    }

    #{$this}__warning-directory-tooltip {
      font-size:13px;
    }

  }

}