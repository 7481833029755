.swiper-horizontal .swiper-wrapper
{
    align-items: center;
}

.swiper-pagination-bullet
{
    transition: transform ease $speed-medium;
}

.swiper-pagination-bullet-active
{
    transform: scale(1.3);

    background: $color-info-light;
}

.swiper-button-prev,
.swiper-button-next
{
    top: 0;

    height: 100%;
    margin-top: 0;
    padding: 0 $size-small + $size-xx-small;

    transition: opacity ease $speed-fast;

    opacity: 0;
    color: white;

    @mixin highlight()
    {
        background: rgba($color-dark, .7);
    }

    // On considère que si l'appareil ne peut pas hover, il est tactile.
    @media not all and (hover: hover) and (pointer: fine)
    {
        display: none;
    }

    .swiper:hover &:not(.swiper-button-disabled)
    {
        opacity: 1;
    }

    &::before
    {
        content: '';

        position: absolute;
        top: 50%;
        left: 50%;

        width: 35px;
        height: 35px;

        transition: background-color ease $speed-medium;
        transform: translate(-50%, -50%);

        border-radius: 3px;
        border-radius: 50%;
        background: rgba($color-dark, .5);
        box-shadow: 0 2px 5px rgba(black, .1);

        backdrop-filter: blur(1px);
    }

    // If the device can hover
    @media (hover: hover) and (pointer: fine)
    {
        &:hover::before
        {
            @include highlight();
        }
    }

    // If the device cannot hover
    @media not all and (hover: hover) and (pointer: fine)
    {
        &:active::before
        {
            @include highlight();
        }
    }

    &::after
    {
        font-size: 17px;
        font-weight: bold;

        z-index: $z-index-1;
    }

    &.swiper-button-disabled
    {
        // Par défaut dans la lib, les clics sur ces boutons sont désactivés
        // avec pointer-events: none. Dans ce cas, en cliquant à gauche sur la
        // première slide et à droite sur la dernière, l'utilisateur peut se
        // retrouver à agrandir l'image en cours sans le vouloir puisqu'il
        // clique dessus sans le savoir. Cela peut notamment se produire en
        // passant rapidement toutes les slides ; arrivé à la dernière, un clic
        // de trop ouvre la modale.
        // En réactivant les clics, l'utilisateur clique donc bien sur les
        // boutons même s'ils sont invisibles.
        pointer-events: auto;

        opacity: 0;
    }
}

.swiper-button-prev
{
    left: 0;

    &::after
    {
        margin-right: 2px;
    }
}

.swiper-button-next
{
    right: 0;

    &::after
    {
        margin-left: 2px;
    }
}
