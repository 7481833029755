/// Inspired by CSS Wizardry own flag object.
/// @link https://csswizardry.com/2013/05/the-flag-object/
///
.o-flag
{
    $this: &;

    display: flex;
    align-items: center;
    flex-direction: row;

    @at-root
    {
        #{$this}__illustration
        {
            flex-grow: 0;
            flex-shrink: 0;

            margin-right: $size-x-small;

            #{$this}--reverse &
            {
                margin-right: 0;
                margin-left: $size-x-small;
            }

            #{$this}--flush &
            {
                margin-right: 0;
                margin-left: 0;
            }

            #{$this}--tiny &
            {
                margin-right: $size-xxx-small;

                @at-root
                {
                    #{$this}--reverse#{&}
                    {
                        margin-right: 0;
                        margin-left: $size-xxx-small;
                    }
                }
            }

            #{$this}--small &
            {
                margin-right: $size-xx-small;

                @at-root
                {
                    #{$this}--reverse#{&}
                    {
                        margin-right: 0;
                        margin-left: $size-xx-small;
                    }
                }
            }

            #{$this}--large &
            {
                margin-right: $size-small;

                @at-root
                {
                    #{$this}--reverse#{&}
                    {
                        margin-right: 0;
                        margin-left: $size-small;
                    }
                }
            }

            #{$this}--huge &
            {
                margin-right: $size-normal;

                @at-root
                {
                    #{$this}--reverse#{&}
                    {
                        margin-right: 0;
                        margin-left: $size-normal;
                    }
                }
            }

        }

        #{$this}__body
        {
            flex: 1;

            min-width: 0;
        }

        #{$this}--top
        {
            align-items: flex-start;
        }

        #{$this}--bottom
        {
            align-items: flex-end;
        }

        #{$this}--reverse
        {
            flex-direction: row-reverse;
        }
    }
}
