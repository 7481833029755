.c-timeline-file-preview-metas
{
    $this: &;

    padding: $size-x-small;
    background: $color-light-light;

    border: 1px $color-light;
    border-top-style: solid;
    border-bottom-style: solid;

    @at-root
    {
        #{$this}__icon
        {
            font-size: 31px;
        }

        #{$this}__text
        {
            font-size: 14px;
        }

        #{$this}__meta
        {
            display: block;
            overflow: hidden;

            white-space: nowrap;
            text-overflow: ellipsis;

            &:first-child
            {
                font-weight: bold;
            }
        }
    }
}
