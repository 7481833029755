.c-toggle-btn {
  $this: &;
  @at-root {

    #{$this}__wrapper {
      background-color: #F5F5F5;
      display: flex;
      justify-content: space-around;
      width: 300px;
      height: 40px;
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      margin-right: 10px;
      box-shadow: rgba(50, 50, 93, 0.2) 0px 2px 5px -1px, rgba(0, 0, 0, 0.5) 0px 1px 3px -1px;

      &--large, &--small {
        width: unset;
        margin-right: $size-xx-small;
      }

      &--small {
        width: 150px;
        margin-bottom: 5px;
        background: white;
      }

      input[type="radio"] {
        display: none;
      }

      input[type="radio"]:checked + label {
        color: white;
        position: relative;
        z-index: 2;
      }

      input[type="radio"]:checked + label svg path {
        stroke: white;
      }
    }

    #{$this}__option {
      cursor: pointer;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      color: #374191;
      width: calc(100% / 2);

      &:hover {
        transition: 0.2s ease-in;
      }
    }

    #{$this}__slide {
      position: absolute;
      top: 2px;
      bottom: 2px;
      width: 50%;
      background-color: #374191;
      border-radius: 10px;
      transition: left 0.3s;
      border: solid 2px white;

      &--small {
          width: 50%;
      }
    }

    #favoris-section:checked ~ .c-toggle-btn__slide {
      left: 0.5%;
    }

    #taches-section:checked ~ .c-toggle-btn__slide {
      left: 49.5%;
    }
  }
}