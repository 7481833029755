.c-adndropzone-async {
  $this: &;

  @at-root
  {

    #{$this}__loadermobile,
    #{$this}__loader {
      opacity:0.4;
    }

    #{$this}__loadermobile,
    #{$this}__loadermobile div {

      height:118px;
    }

    #{$this}__loadermobile {
      text-align: center;
    }

    #{$this}__picture {
      min-width: 105px;
      min-height: 118px;
      background: $color-light;

      width:100%;
      height:100%;
    }



    #{$this}__loader-img {
      width:100px;
      margin-top: 0;


      @media (min-width: $screen-sm-min)
      {
        position: absolute;
      }
    }

    #{$this}__categories{
      margin-right: 10px;
      margin-left: 10px;
      margin-bottom: 0;
      width: 100%;
      display: none;

      .c-sidebar--large &, .c-sidebar--full-screen &{
        width: $size-xx-large;
      }
    }



    #{$this}__item {
      border:solid;
      border-width: 1px;
      border-color: $color-light-light;

      height: 118px;

      width:100%;
      //height:100%;

      &:after {
        display: block;
        position: relative;
        background: linear-gradient(to top, rgba(255, 255, 255, 0) 0, #222222 100%);
        margin-top: -118px;
        height: 59px;
        content: '';
      }

      &:hover:after {
        display: block;
        position: relative;
        background: rgba(155, 155, 155, 0.7);
        margin-top: -118px;
        height: 118px;
        content: '';
      }
    }




    #{$this}__item .c-btn--more {
      z-index:2;
      color:white;
    }

    #{$this}__item:hover a  {
      display:block !important;
    }

    #{$this}__item:hover .c-btn--more {
      display:none;
    }



    #{$this}__croplink ,
    #{$this}__removelink {
      z-index: 2;
      border-radius: 20px;

      display: none;
      text-align: center;

      position:absolute;

      background:rgba(255,0,0,0.8);
      padding:$size-xxx-small;

      width:22px;
      height:22px;


      margin-top:0;
      margin-left:0;

      top: calc(50% - 10px);
      left: calc(50% + 10px);

      color:white !important;

      @media #{$media-desktop}
      {
        padding: auto;

        top: unset;
        left: unset;

        margin-top:34px;
        margin-left:47px;
      }
    }


    #{$this}__croplink {
      background:white;

      margin-top:0;
      margin-left:0;

      top: calc(50% - 10px);
      left: calc(50% - 25px);

      color:black !important;

      @media #{$media-desktop}
      {
        padding: auto;

        top: unset;
        left: unset;

        margin-top:34px;
        margin-left:20px;
      }
    }



    #{$this}__containerlinkmobile {
      display:none;
      position:relative;
      text-align:right;

      width:100%;
      height:100%;
    }


    #{$this}__imageisGifOrVideo {

      left: calc(50% - 10px);

      @media #{$media-desktop}
      {
        left: unset;
        margin-left:36px;
      }
    }

    #{$this}__container{
      position: relative;
    }

    #{$this}__overlay{
      position: fixed;
      display: none;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0,0,0,0.5);
      cursor: pointer;

      &--dropzone{
        background-color: rgba(255,255,255,0.6);
        position: absolute;
        cursor: wait;
        z-index: 2;

      }

      &--cropper{
        z-index: 2;
      }

      &--beforeUpload{
        z-index: $z-index-12 + 1;
      }
    }

  }

}
