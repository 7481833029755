.c-datatable
{
  $this: &;

  border:none !important;

  @media (min-width: $screen-sm-min )
  {
      border:unset;
      padding:revert;
  }

  @at-root
  {

      #{$this} table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child,
      #{$this} table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child {
          padding-left: 0px !important;

          @media (min-width: $screen-sm-min )
          {
            padding-left : revert;
          }
      }


      #{$this} .table-condensed > tbody > tr > td{
           padding:5px 5px;

          @media (min-width: $screen-sm-min )
          {
            padding:10px 5px;
          }
      }

  }
}