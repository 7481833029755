@mixin small-medium() {
  font-weight: 500;
  font-size: 12px;
  line-height: 0;
}
.c-utilisateur-info{
  $this:&;
  @at-root {

    &__overlay{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(67, 74, 84, 0) 25%);
      transition: background-color ease $speed-fast;

      @media (min-width: $screen-sm-min) {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(67, 74, 84, 0) 15%);
      }

      .c-header-area__profile-picture:hover &{
        background-color: rgba(0, 0, 0, 0.6);
      }
    }

    &__btn-more{
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      color: #FFFFFF;
      visibility: visible;

      .c-header-area__profile-picture:hover &{
        visibility: hidden;
      }
    }

    &__container-btns{
      visibility: hidden;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding: 3px;

      .c-header-area__profile-picture:hover &{
        visibility: visible;
      }
    }

    &__btn{
      width: 30px;
      height: 30px;
      border: transparent;
      border-radius: 50%;
      flex-shrink: 0;
      flex-grow: 0;
      background-color: #FFFFFF;

      @media #{$media-desktop} {
        width: 40px;
        height: 40px;
      }

      &:hover{
        .c-utilisateur-info__btn__svg{transform: scaleX(-1);}
      }

      &__svg{
        width: 100%;
        height: 100%;
        padding: 0;

        @media #{$media-desktop} {padding: 3px;}

        transition: transform ease $speed-medium;
      }
    }

    &__svg{
      width: 20px;
      height: 20px;
      margin-right: $size-xx-small;

      &--list,&--abonnement{
        width: $size-x-small;
        height: $size-x-small;
      }

      &--abonnement{
        transform: translateY(-2px);
      }

      &--mail{
        transform: translateY(2px);
      }
    }

    &__avatar{
      height: 100%;
      width: 100%;
      border-radius: 0;
      font-size: 51px;

      &::before{
        border: none;
      }
    }

    &__separation{
      margin-top: 0;
      &--aside, &--groupes{
        width: 95%;
      }

      &--groupes{
        margin-bottom: 0;
      }
    }

    &__groupe-infos{
      padding: 0 $size-x-small $size-x-small $size-x-small;

      &--list{
        margin-bottom: $size-xx-small;
      }
    }

    &__label{
      color: $color-default-light;
      padding-bottom: 0;

      @media #{$media-desktop}{
        padding-bottom: 1em;
      }
    }

    &__info{
      padding-top: 0;

      @media #{$media-desktop}{
        padding-top: 1em;
      }
    }

    &__title{
      @include small-medium();
      text-transform: uppercase;
    }

    &__organisation, &__general, &__localisation, &__profil-social, &__messagerie, &__connexion{
      margin-top: $size-small;

      &__poste {
        text-transform: capitalize;
      }
    }



    &__coordonnee{
      margin-top: $size-x-small;

      &__info{
        padding-bottom: $size-xxx-small;

        &--email, &--tel{
          padding: 0 0 $size-xxx-small 0;
        }
      }
    }

    &__profil-social, &__messagerie{

      &__group{
        display: flex;
        flex-direction: row;
        justify-content: center;
      }

      &__link{
        color: $color-default;

        &:hover{
          color: var(--theme-color);
        }
      }

      &__svg{
        width: $size-small;
        height: $size-small;
        margin-right: $size-x-small;
      }
    }

    &__post{
      &__group{
        margin-bottom: $size-x-small;
        padding-left: $size-x-small;
      }

      &__team, &__created{
        color: $color-default-light;
      }

      &__message{
        color: var(--theme-color);
        padding: 0;
      }

      &__created{
        font-style: italic;
      }
    }

    &__groupes{

      @media #{$media-desktop}{
        &:nth-child(odd) {
          border-right: 1px solid $color-default-light;
        }
      }

      &:hover{
        .c-utilisateur-info__groupes__btn{
          visibility: visible;
        }
      }

      &__container{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: $size-xx-small;
      }

      &__img{
        margin-right: $size-x-small;
      }

      &__infos{
        &__link{
          color: $color-dark;
        }
        &__title{
          margin: 0;
        }

        &__membres{
          padding: 0;
          color: $color-dark-light;
        }
      }

      &__btn{
        margin-left: auto;
      }
    }

    &__form{
      &__name-container{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 10px;
      }

      &__names{
        width: 100%;
        padding-right: $size-x-small;
        margin-top: $size-x-small;
      }

      &__tels, &__emails{
        padding-right: $size-x-small;
        padding-left: $size-x-small;
        margin-bottom: $size-x-small;
      }

      &__btns__submit, &__btns__previous{
        display: none;
      }

      &__town-container{
        @media #{$media-desktop}{
          display: flex;
        }
      }

      &__town{
        @media #{$media-desktop}{
          width: 70%;
          margin-right: $size-xx-small;
        }
      }

      &__poste{
        margin-top: 30px;
      }

      &__container-social, &__container-message, &__newsletter{
        margin-top: $size-x-small;
      }

      &__changePsw{
        width: 80%;
        margin-right: $size-x-small;
      }

      &__password, &__passwordChange{
        display: flex;
      }

      &__svgCheck{
        width: 2em;
        height: 2em;
        display: none;

        &--true{
          color: $color-success;
        }

        &--false{
          color: $color-danger;
        }
      }

    }

    #{$this}__groupe-grid {

      & .k-grid .k-grid-header {
        display: none;
      }

      & .k-grid,
      & .k-grid-header-wrap,
      & .k-grid-header th,
      & .k-grid-content > table > tbody > tr > td
      {
        border:none;
        overflow: visible;
      }

      & .k-grid td, .k-grid .k-table-td {
        overflow: visible;
      }
    }

    #{$this}__groupe-grid .k-grid-content.k-auto-scrollable {
      height:100%!important;
      @include custom-scrollbar();
    }

    #{$this}__groupe-grid-container {
      height: calc(#{vh(100)} - #{$header-height} - 470px)  !important;

      @media(max-width: $screen-xs-max)
      {
        height: calc(#{vh(100)} - #{$header-height} - var(--mobile-menu-height)) !important;

      }
    }

    &__groupe-action{
      position: absolute;
      right: 10px;
      top: 40%;
      text-align: right;
    }


    #{$this}__abonnement-grid-container {
      height: calc(#{vh(100)} - #{$header-height} - 580px)  !important;

      @media(max-width: $screen-xs-max)
      {
        height: calc(#{vh(100)} - #{$header-height} - var(--mobile-menu-height)) !important;
      }

    }

    #{$this}__abonnement-grid {

      & .k-grid .k-grid-header {
        display: none;
      }

      & .k-grid,
      & .k-grid-header-wrap,
      & .k-grid-header th,
      & .k-grid-content > table > tbody > tr > td
      {
        border:none;
        overflow: visible;
      }

      & .k-grid td, .k-grid .k-table-td {
        overflow: visible;
      }
    }

    #{$this}__abonnement-grid .k-grid-content.k-auto-scrollable {
      height:100%!important;
      @include custom-scrollbar();
    }

    &__abonnement-action{
      position: absolute;
      right: 10px;
      top: 40%;
      text-align: right;
    }





  }
}
