.c-group-medal
{
  $this: &;

  font-size:20px;
  display: flex;


  @media (min-width: $screen-xs-min)
  {
    font-size:35px;
  }


  @at-root
  {

    #{$this}--0 {color: #8cc152;}

    #{$this}--2 {color: #c12e2a;}


    #{$this}--list {
      left:0px;
    }


    #{$this}__icon
    {
      filter: drop-shadow(1px 1px 1px rgba(black, .8));
    }

    #{$this}--detail {
      position:absolute;
      right:-25px;
      bottom:$size-xxx-small;

      @media (min-width: $screen-xs-min)
      {
        right:-35px;
      }
    }

  }
}
