.c-app-upload-toaster
{
  $this: &;

  display: none;
  width: 100%;
  min-height: 60px;


  .k-dropzone,
  .k-upload-button  {
    display: none;
  }

  .k-upload {
    border:none;
  }

  .k-upload-files {
    @include custom-scrollbar();

    border:none;
    height: 80vh;
    overflow: auto;

    @media #{$media-desktop}{

      height: 250px;

    }

    & .k-file-success .k-file-name,
    & .k-i-success {
      color: $color-success !important;
    }

  }


  @at-root
  {
    #{$this}__generic {

      right: 0;
      width: 100% !important;

      @media #{$media-desktop}{
        right: 15px;
      }

    }



    @keyframes bounce {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-5px);
      }
    }


    #{$this}__drag-over-content {
      display: none;
      position:absolute;


      background-color: rgba( $color-default-light, 0.3);

      z-index: 2;

      height: 100%;
      width: 100%  ;
      border-style: solid;
      border-width: 3px;
      overflow: hidden !important;

      color: var(--theme-color, $color-dark-light);


      & .information {
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      & .information-text {
        font-size: 15px;
        background: var(--theme-color, $color-dark-light);
        padding: 20px;
        border-radius: 16px;
        color: white;
      }


      & .information svg {
        width: 200px;
        height: 200px;

        animation: bounce 0.5s ease-out infinite;
      }


    }


    #{$this}__header {
      display: flex;
      background: $color-light-light;

      padding-top: $size-xx-small;
      padding-left: $size-xx-small;
      padding-bottom: $size-xx-small;

      font-weight: bold;

      height:45px;
    }


    #{$this}__title {
      margin: 3px;
      font-size: 15px;
    }


    #{$this}__import {
      position: absolute;
      right: $size-normal;
    }


    #{$this}__close {
      padding:0;
      border:none;
      background: transparent;
      position: absolute;

      display: flex;
      align-items: center;
      justify-content: center;

      right: 7px;
      top: 8px;

      width: 25px;
      height: 25px;

      border-radius: 50%;
      transition: all 0.2s ease-in-out;

      & .k-icon {
        font-size: 20px;
      }
    }

    #{$this}__close:hover {
      background: $color-default-light;
    }


    #{$this}__import:disabled {
      color: $color-default;
    }


    #{$this}__imported-success {
      color:$color-success;
      font-size:20px;
    }

    #{$this}__imported-error {
      color:$color-danger;
      font-size:20px;
    }

  }

}