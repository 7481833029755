.c-dashboard-section {
  padding: $size-small;
  border-radius: 4px;
  background-color: $color-light-light;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $size-x-small;
  }

  &__title {
    margin: 0;
    font-size: $size-xx-small * 2;
    color: $color-dark;
  }

  &__btn {
    padding: 0;
    height: inherit;
    min-width: 0;
    color: $color-default;
  }

  &__btn:hover,
  &__btn:active,
  &__btn:focus {
    color: $color-default-light;
  }

  &-btn {
    &__logo {
      width: $size-small;
      height: $size-small;
    }
  }
}