.c-link {
  $this: &;

  @include c-link();

  @at-root
  {
    #{$this}--colorful
    {
      &,
      &:hover,
      &:focus,
      &:active
      {
        color: var(--theme-color);
      }

      &:hover,
      &:focus,
      &:active
      {
        color: var(--theme-color-lighten15);
        border-bottom-color: var(--theme-color-lighten15);
      }
    }
  }
}
