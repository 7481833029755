.c-search
{
    $this: &;
    $input-height: $size-small + $size-xxx-small;
    $trigger-size: $input-height;
    $trigger-icon-size: 20px;
    $trigger-padding: ($input-height - $trigger-icon-size) / 2;
    $plain-input-height: $size-small;
    $plain-trigger-size: $plain-input-height;
    $plain-trigger-icon-size: $size-x-small;
    $plain-trigger-padding: ($plain-input-height - $plain-trigger-icon-size) / 2;
    $big-input-height: $size-normal;
    $big-trigger-size: $big-input-height;
    $big-trigger-icon-size: $size-x-small;
    $big-trigger-padding: ($big-input-height - $big-trigger-icon-size) / 2;

    display: flex;
    align-items: center;

    height: $input-height;
    margin-top: $size-xx-small;
    margin-bottom: $size-xx-small;

    @at-root
    {
        #{$this}--plain
        {
            height: $plain-input-height;
        }

        #{$this}--big
        {
            height: $big-input-height;
        }

        #{$this}--sidebar
        {
            margin-top: 0;
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            height: 50px;
            margin-bottom: 50px;
            z-index: $z-index-12;

          &::before
          {
              content: '';

              position: absolute;
              z-index: $z-index-12;
              bottom: -$size-xxx-small;
              left: 0;

              width: 100%;
              height: $size-xxx-small;

              background: linear-gradient(white, rgba(white, 0));
          }
        }

        #{$this}__input
        {
            height: 100%;
            margin-top: 0;
            margin-bottom: 0;
            padding-right: $trigger-size;

            #{$this}--plain &
            {
                padding:
                {
                    top: $size-xxx-small;
                    bottom: $size-xxx-small;
                    left: $size-xx-small;
                };

                border: none;
                border-radius: 3px;
                background: $color-light-light;

                &::placeholder
                {
                    color: $color-dark-light;
                }

                &:focus::placeholder
                {
                    color: $color-default;
                }
            }

            #{$this}--big &
            {
                padding-right: $big-trigger-size - $big-trigger-padding;
            }

            #{$this}--big#{$this}--plain &
            {
                padding-right: $big-trigger-size;
                padding-left: $size-normal;

                @media (min-width: $screen-sm-max) {
                    padding-left: $size-x-small;
                }
            }

            #{$this}--header &
            {
                padding-right: $header-inner-height;
            }
        }

        #{$this}__return
        {
            position: absolute;

            width: 30px;
            height: 50px;

            border: none;
            padding: 0;

            flex-shrink: 0;
            flex-grow: 0;

            background-color: $color-light-light;
            color: $color-dark;
        }

        #{$this}__svg
        {
            height: 25px;
            width: 25px;
        }

        #{$this}__img
        {
            margin-top: 80px!important;
        }

        #{$this}__trigger
        {
            width: $trigger-size;
            height: 100%;
            margin-left: -($trigger-size);
            padding: $trigger-padding;

            transition: color ease $speed-fast;

            border: none;
            background: none;

            #{$this}__input:focus + &
            {
                color: var(--theme-color-light);
            }

            #{$this}--plain &
            {
                width: $plain-trigger-size;
                margin-left: -$plain-trigger-size;

                color: $color-dark-light;
            }

            #{$this}--plain #{$this}__input:focus + &
            {
                color: $text-color;
            }

            #{$this}--big &
            {
                width: $big-trigger-size - $big-trigger-padding;
                margin-left: -$big-trigger-size + $big-trigger-padding;
                padding: $big-trigger-padding 0 $big-trigger-padding $big-trigger-padding;
            }

            #{$this}--big#{$this}--plain &
            {
                width: $big-trigger-size;
                margin-left: -$big-trigger-size;
                padding-right: $big-trigger-padding;
            }

            #{$this}--header &
            {
                width: $header-inner-height;
                margin-left: -$header-inner-height;
                padding: $size-xx-small;
            }
        }

        #{$this}__trigger-icon
        {
            width: $trigger-icon-size;
            height: $trigger-icon-size;

            #{$this}--plain &
            {
                width: $plain-trigger-icon-size;
                height: $plain-trigger-icon-size;
            }

            #{$this}--big &
            {
                width: $big-trigger-icon-size;
                height: $big-trigger-icon-size;
            }
        }
    }
}
