.k-multiselect {

  &--flat {

    &.k-hover .k-multiselect-wrap,
    &.k-focus .k-multiselect-wrap {
      box-shadow: none !important;
    }

    & .k-multiselect-wrap.k-floatwrap {
      display: flex !important;
      align-items: center !important;
    }

  }

}