.bootstrap-datetimepicker-widget.dropdown-menu
{
  width: auto;
}

.input-group.date input[type='text'] {
  width:100%;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.datepickerbutton
{
  cursor: pointer;
}
