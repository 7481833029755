.c-course-stats {
  &__section {
    padding: $size-x-small 0;
  }

  &__title {
    margin: 0;
    padding: 0 $size-x-small;
    font-weight: normal;

    &--main {
      padding-top: $size-x-small;
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  &__content {
    padding-top: 15px;
    width: 100%;
  }

  &__progress {
    width: $size-large;
    margin: 0 auto;
  }

  &__loader {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  &__progressbar {
    display: flex;
    align-items: center;

    & > div {
      width: 100%;
      margin-right: $size-xxx-small;
    }

    & > span {
      font-weight: bold;
    }
  }
}