.k-dropdown {

  &--flat {

    & .k-dropdown-wrap.k-hover,
    & .k-dropdown-wrap.k-focus {
      box-shadow: inherit !important;
      //background-color: inherit !important;
    }

  }

}
