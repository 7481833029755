/// ============================================================================
/// # OVERRIDES.TOASTIFY
/// ============================================================================
/// Librairie permettant d'afficher des toasts de notifications. Cet override
/// adapte son design de base à celui de notre application.
/// Lié au composant components.toast-url-preview, qui est affiché dans les
/// toasts.
/// ============================================================================
/// @group trumps.overrides
/// @see components.toast-url-preview
/// ============================================================================

$offset: 15px;
$color-label-size: $size-xxx-small;
$font-size: 14px;

// Report de la taille des aperçus d'URLs des posts, définie à la base dans
// components.toast-url-preview
$url-preview-height: $size-normal + $size-xx-small;

@mixin gradient($from, $to)
{
    background: linear-gradient(45deg, $from, $to);
}

.toastify
{
    @include reaction-in-text;

    font-size: $font-size;

    display: flex;
    overflow: hidden;
    align-items: center;

    width: calc(100% - #{$offset * 2});
    max-width: $size-xxx-large;
    padding: $size-x-small;

    color: $text-color;
    border-radius: 5px;
    background: white;
    // Même ombre que dans la librairie à la base, juste noire
    box-shadow: 0 3px 6px -1px rgba(black, .12), 0 10px 36px -4px rgba(black, .3);

    &::before
    {
        content: '';

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;

        width: $color-label-size;
    }

    &::after
    {
        font-size: 19px !important;

        order: -1;

        margin-right: $size-x-small;

        color: transparent !important;
        -webkit-background-clip: text !important; // Autoprefixer, avec les paramètres actuels, ne rajoute pas ce préfixe automatiquement (nécessaire sous Chrome)...
                background-clip: text !important;
    }
}

.toastify-left
{
    @media #{$media-desktop}
    {
        // Utilisé pour éloigner les toasts du menu principal desktop
        left: $main-menu-width + $offset;
    }
}

.toastify-bottom
{
    .has-enabled-mobile-navbar &
    {
        // Utilisé pour éloigner les toasts de la barre de navigation mobile
        margin-bottom: var(--mobile-menu-height);

        @media #{$media-desktop}
        {
            margin-bottom: 0;
        }
    }
}

.toast-close
{
    font-size: 0;
    line-height: 1;

    order: 2;

    margin-left: auto;
    padding: $size-xxx-small $size-xxx-small $size-xxx-small $size-x-small;

    transition: color ease $speed-fast;

    opacity: 1;
    color: $color-default-light;

    .toastify--media-preview &
    {
        position: absolute;
        top: 0;
        right: 0;

        padding: ($size-xx-small - 2) $size-xx-small;

        color: $color-dark;

        &::before
        {
            $size: $size-x-small;

            content: '';

            position: absolute;
            top: 50%;
            left: 50%;

            box-sizing: content-box;
            width: $size;
            height: $size;
            padding: 1px;

            transition: background-color ease 150ms;
            transform: translate(-50%, -50%);

            border-radius: 50%;
            background: rgba(white, .8);
        }

        &:hover::before
        {
            background: rgba($color-light, .9);
        }
    }

    // La croix de base de la librairie ne change pas de couleur sous Safari
    // iOS ! C'est le seul navigateur qui transforme automatiquement tous les
    // caractères unicode en émoji... et on ne peut pas changer la couleur d'un
    // émoji en CSS...
    // Un simple "+" n'est pas transformé en emoji donc pas de bug.
    &::after
    {
        content: '+';

        font-size: $font-size;
        font-weight: 500;

        display: block;

        transform: rotate(45deg) scale(1.8);
    }

    .toastify--media-preview &::after
    {
        font-size: 11px;
        font-weight: normal;
    }

    &:hover
    {
        color: $color-dark-light;
    }

    .toastify--url-preview &
    {
        margin-bottom: $url-preview-height;
    }
}

.toastify--success,
.toastify--info,
.toastify--warning,
.toastify--danger
{
    padding-left: $color-label-size + $size-x-small;
}

.toastify--success
{
    &::before,
    &::after
    {
        @include gradient($color-success, $color-success-light);
    }

    &::after
    {
        @include font-awesome('f058');
    }
}

.toastify--info
{
    &::before,
    &::after
    {
        @include gradient($color-info, $color-info-light);
    }

    &::after
    {
        @include font-awesome('f05a');
    }
}

.toastify--warning
{
    &::before,
    &::after
    {
        @include gradient($color-warning, $color-warning-light);
    }

    &::after
    {
        @include font-awesome('f071');
    }
}

.toastify--danger
{
    &::before,
    &::after
    {
        @include gradient($color-danger, $color-danger-light);
    }

    &::after
    {
        @include font-awesome('f056');
    }
}

.toastify__content
{
    word-wrap: break-word;
    word-break: break-word;

    overflow-wrap: break-word;

    .toastify--media-preview &
    {
        display: flex;
        flex: 1;
        justify-content: space-between;

        height: 100%;
    }
}

.toastify--media-preview
{
    height: 80px;
    padding-right: 0;
}

.toastify__text
{
    .toastify--media-preview &
    {
        // Permet d'éviter que le toast ne s'agrandisse
        @include clamp-lines-to(2);
    }
}

.c-entity-thumb
{
    .toastify &
    {
        align-self: flex-start;
    }
}
