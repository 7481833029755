.c-timeline-mostview {

  $this: &;

  padding:5px;
  padding-left:15px;
  margin:0;
  font-size:13px;

  @at-root
  {

    #{$this}__title
    {
       color: var(--theme-color);
       font-weight: bold;
       &:hover{
         color: var(--theme-color-light);
       }
    }


    #{$this}__source
    {
       color:  $color-dark-light;
    }

    #{$this}__infos
    {
       padding-right:0;
       text-align:center
    }

    #{$this}__nbreactions
    {
       font-size:12px;
    }

  }
}
