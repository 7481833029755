/// ============================================================================
/// # TIMELINE-COMMENT-URL-PREVIEW
/// ============================================================================
/// Aperçus d'URLs dans les commentaires des posts. Similaire, mais différent du
/// style des aperçus du publicateur de commentaires.
/// ============================================================================
/// @group components
/// @require objects.flag
/// ============================================================================

.c-timeline-comment-url-preview
{
    $this: &;
    $border: 1px solid $color-light;

    position: relative;

    display: block;

    @at-root
    {
        #{$this},
        #{$this}:hover,
        #{$this}:focus,
        #{$this}:active
        {
            text-decoration: none;
        }

        #{$this}::after
        {
            content: '';

            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            transition: opacity ease $speed-fast;

            opacity: 0;
            background: rgba(black,.05);
        }

        #{$this}:hover,
        #{$this}:focus,
        #{$this}:active
        {
            &::after
            {
                opacity: 1;
            }
        }

        #{$this}__illustration-container
        {
            position: relative;

            // Permet de centrer le bouton play
            display: flex;
            align-items: center;
            justify-content: center;

            border-top: $border;

            // Bordures transparentes gauche et droite pour éviter le blanc sur
            // blanc si l'illustration a un fond blanc. Plus ou moins invisibles
            // en cas de fond coloré ou sombre.
            &::before
            {
                content: '';

                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                border: 1px rgba(black,.1);
                border-right-style: solid;
                border-left-style: solid;
            }

            // Le jour où la propriété aspect-ratio sera suffisamment supportée,
            // il suffira de supprimer ce bloc.
            @supports not (aspect-ratio: 16 / 9)
            {
                height: 0;
                padding-bottom: 56.25%;
            }
        }

        #{$this}__illustration
        {
            width: 100%;
            height: 100%;

            background: white;

            object-fit: cover;
            aspect-ratio: 16 / 9;

            // Le jour où la propriété aspect-ratio sera suffisamment supportée,
            // il suffira de supprimer ce bloc.
            @supports not (aspect-ratio: 16 / 9)
            {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
        }

        #{$this}__play
        {
            position: absolute;
            transition: transform ease $speed-fast;

            @mixin highlight()
            {
                transform: scale(1.1);
            }

            @media not all and (hover: hover) and (pointer: fine)
            {
                #{$this}:active &
                {
                    @include highlight();
                }
            }

            @media (hover: hover) and (pointer: fine)
            {
                #{$this}:hover &
                {
                    @include highlight();
                }
            }
        }

        #{$this}__metas
        {
            padding: $size-xx-small;

            color: $color-dark;
            border: $border;
            border-bottom-right-radius: inherit;
            border-bottom-left-radius: inherit;
            background: $color-light-light;
        }

        #{$this}__origin,
        #{$this}__title
        {
            @include clamp-lines-to(2);

            margin: 0;
        }

        #{$this}__origin
        {
            font-size: 13px;
            font-weight: normal;

            margin-bottom: $size-xxx-small;

            text-transform: uppercase;
        }

        #{$this}__title
        {
            font-size: 16px;
        }
    }
}
