.fa-custom:before
{
    position: relative;
    top: 0;
    color: #90949c;
    font-size: 1.5em;
    text-align: center;
    line-height: 32px;
    margin: auto;
}

.fa-myfile:before
{
    font-family: FontAwesome;
    content: "\f016";
}

.fa-gif:before
{
    font-weight: bold;
    content: 'gif';
    text-transform: uppercase;
}

.fa-emoji:before
{
    font-family: FontAwesome;
    content: "\f118";
}
