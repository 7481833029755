.c-tasks-filtersTasks {
  $this: &;

  @at-root {

    #{$this}__container--filter {
      padding: 12px;
    }

    #{$this}__label {
      display: flex;
    }

    #{$this}__title {
      color: #5e6c84;
      font-size: 17px;
      font-weight: 600;
      line-height: 16px;
      margin-top: 16px;
      margin-bottom: 8px;
    }

    #{$this}__description {
      color: #5e6c84;
      font-size: 12px;
      line-height: 14px;
      padding: 0;
    }

    #{$this}__checkbox {
      margin-left: 15px !important;
      display: inline-flex;
      margin-right: 15px !important;
      vertical-align: sub;
      width: 17px !important;
      height: 17px !important;
    }

    #{$this}__container .k-multiselect {
      width: 88% !important;
      right: 0;
      background-color: $color-light-light;
      border-radius: 4px;
      color: #434a54;
      border: none !important;
      display: inline-flex;
      @media (max-width: $screen-sm-min) {
        width: 82% !important;
      }
    }

    #{$this}__icon--container {
      border-radius: 50%;
      width: 25px;
      height: 25px;
      background-color: #e6e9ed;
      vertical-align: top;
      margin-right: 5px;
      display: inline-flex;
    }

    #{$this}__icon--containerClock {
      border-radius: 50%;
      width: 25px;
      height: 25px;
      background-color: #cb3c23;
      color: white;
      vertical-align: top;
      margin-right: 5px;
      display: inline-flex;
    }

    #{$this}__icon--containerCheck {
      border-radius: 50%;
      width: 25px;
      height: 25px;
      background-color: #19b567;
      color: white;
      vertical-align: top;
      margin-right: 5px;
      display: inline-flex;
    }

    #{$this}__icon--containerToday {
      border-radius: 50%;
      width: 25px;
      height: 25px;
      background-color: #cfa027;
      color: white;
      vertical-align: top;
      margin-right: 5px;
      display: inline-flex;
    }

    #{$this}__icon {
      position: relative;
      left: 6px;
      top: 5px;
    }

    #{$this}__container .k-list > .k-focus, {
      #{$this}__buttonNbrsTaskFiltered {
        display: inline-flex;
      }
    }

    #{$this}__buttonNbrsTaskFiltered {
        .c-tasks-sub-header__filtersButtonSidebar {
          margin: 0 -5px !important;
        }
    }


      #{$this}__container .k-list > .k-state-focused, {
        background-color: transparent;
      }

      #{$this}__container .k-multiselect--flat .k-multiselect-wrap.k-floatwrap {
        background-color: #e6e9ed !important;
        width: 100% !important;
      }

      #{$this}__container .k-block, .k-draghandle,
      .k-inline-block, .k-widget {
      }

      #{$this}__container .k-focus {
        box-shadow: none !important;
      }

      #{$this}__container .k-list-container {
        width: 15px !important
      }

      #{$this}__container .k-list > .k-hover,
      .k-list > .k-selected.k-hover {
        border: none !important;
      }

      #{$this}__container .k-item {
        text-align: center;
      }

      #{$this}__container .k-listbox .k-item, .k-popup .k-list .k-item {
        border: none !important;
        text-overflow: ellipsis !important;
        overflow: hidden !important;
        white-space: nowrap !important;
        min-height: 30px;
        display: flex;
        align-items: center;
        padding-left: 5px;
      }

      #{$this}__container .k-popup .k-list .k-item {
        border-width: unset !important;
      }

      #{$this}__container .k-multiselect-wrap .k-input {
        margin: 4px !important;
        min-width: 140px !important;
        height: 18.5px !important;
        min-height: unset !important;
        border-radius: 2px !important;
      }
    }
  }