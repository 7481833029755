.c-timeline-post
{
    $this: &;
    $avatar-size: $size-normal;
    $header-padding: $size-xx-small;
    $arrow-size: $size-xx-small;

    background-color: white;
    box-shadow: 0 0 1px rgba(black, .08), 0 2px 5px rgba(black, .08);

    margin-bottom: $size-x-small;



    @media (min-width: $screen-xs-min)
    {
        border-radius: 3px;
    }


    @at-root
    {

        #{$this}__file div {
            width:100%;
            text-align:center;
        }

        #{$this}__userline
        {
            margin-bottom: $size-xxx-small;
            border:none;
            display:flex;
        }


        #{$this}__umoretrigger
        {
            float:right !important;
            padding-top: $size-x-small;
            padding-right: $size-x-small;
        }

        #{$this}__post
        {
            padding: 0px;
        }

        #{$this}__bloctextmore
        {
            display:none;
        }

        #{$this}__header
        {
            display: flex;
            padding: $header-padding $header-padding $size-xxx-small;

            color: $color-dark-light;
        }

        #{$this}__metas
        {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        #{$this}__thumb
        {
            width: $avatar-size;
            height: $avatar-size;
            margin-right: $size-xx-small;
        }

        #{$this}__username
        {
            font-size: 13px;
            height: 25px;
            text-align: left;
            padding-top:5px;
        }

        #{$this}__teamfile
        {
            font-size: 12px;
            height: 25px;
            text-align: left;
            padding-top:5px;
        }

        #{$this}__username a
        {
            color: $color-dark;
        }
        #{$this}__name
        {
            font-weight: bold;
            font-size: 15px;
        }

        #{$this}__link
        {
            font-weight:bold;
            color:$color-dark;

            &,
            &:hover,
            &:focus,
            &:active
            {
                text-decoration: none;
                color: $text-color;
            }

            @media not all and (hover: hover) and (pointer: fine)
            {
                &:active
                {
                    background: $color-light;
                }
            }

            @media (hover: hover) and (pointer: fine)
            {
                &:hover,
                &:focus
                {
                    text-decoration: underline;
                }
            }
        }

        #{$this}__date
        {
            font-size: 14px;
        }

        #{$this}__to
        {
            font-weight:normal;
        }

        #{$this}__btnDelMember
        {
            float:left;
            margin-right:10px;
        }



        #{$this}__content
        {
            position: relative;

            margin-top: $size-xx-small;
            padding-top: $size-x-small;

            border-top: 1px solid $panels-border-color;

            &:before,
            &:after
            {
                content: '';

                position: absolute;
                top: 0;
                left: $header-padding + ($avatar-size / 2) - ($arrow-size / 2);

                width: $arrow-size;
                height: $arrow-size;

                background-color: white;
            }

            &:before
            {
                transform: translateY(calc(-50% - 1px)) rotateZ(-45deg);

                border: 1px solid $panels-border-color;
            }

            &:after
            {
                transform: translateY(calc((-#{$size-xx-small} / 2) + .5px)) rotateZ(-45deg);
            }
        }

        #{$this}__lieu,
        #{$this}__text
        {
            margin-bottom: $size-xx-small;
        }

        #{$this}__text
        {
            font-size: 16px;

            padding: 0 $size-xx-small;
        }

        #{$this}__textopen {
            max-height: 160px;
            overflow: hidden;
        }

        #{$this}__textremove {
            max-height:100%;
        }

        #{$this}__textmore {
            @include c-link();
            @include c-link--colorful();
        }


        #{$this}__evenement_dates
        {
            padding:$size-xx-small;
            font-size:13px;
            color:red;
        }


        #{$this}__evenement_lieu
        {
            padding:$size-xxx-small $size-xx-small;
            overflow-wrap: break-word;
        }

        #{$this}__evenement_title,  #{$this}__evenement_participations
        {
            color: $color-dark;
            font-size: 17px;
            font-weight: bold;
            padding-top: $size-xx-small;
            display: block;
        }

        #{$this}__evenement_participations
        {
            font-weight: normal;
        }



        #{$this}__gif
        {
            max-height: 600px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            padding: 0;
        }


        #{$this}__play
        {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) translateX(10px); // The 10px offset avoids the optical illusion that the play icon is not centered

            i
            {
                font-size: 66px;

                @media (min-width: $screen-xs-min)
                {
                    font-size: 105px;
                }
            }
        }


        #{$this}__sidebar_video_header {
            background:#111111;
            padding: $size-x-small;
        }

        #{$this}__sidebar_video_content {
            background:#000000;
            padding:0px !important;
        }

        #{$this}__sidebar_video_close {
            cursor:pointer;
            position:absolute;

            right:$size-x-small;
            top : $size-xx-small;
        }

          #{$this}__sidebar_video_close i {
             font-size:29px;
          }


      #{$this}__modal_video_body {
          padding:0px !important;
      }

      #{$this}__modal_video_body h4 {
        padding: 0;
        padding-left: $size-xx-small;
        margin:0px;
        margin-bottom:$size-xxx-small;
      }

      #{$this}__modal_video_close {
          z-index : 200;
          position:absolute;
          right:$size-xx-small;

          &--center {
              top: $size-xx-small;
          }
      }

        @media (min-width: $screen-xs-min)
        {
            #{$this}__modal_video_close {
                top: 0;
            }
        }

        #{$this}__modal_video_close a {
          font-size: 21px;
      }



      #{$this}__actions
        {
            position: relative;

            display: flex;

            padding: $size-xx-small;

            border-top: none;

            @media (min-width: $screen-sm-min)
            {

            }

            &::before
            {
                $offset: $size-xx-small;

                content: '';

                position: absolute;
                top: 0;
                right: $offset;
                left: $offset;

                height: 1px;

                background: $color-default-light;
            }
        }

        #{$this}__action
        {
            font-weight: bold;

            flex: 1;

            margin-left: $size-xxx-small ;
            margin-right: $size-xxx-small;

            &:first-child
            {
                margin-left: 0;
            }

            &:last-child
            {
                margin-right: 0;
            }
        }

        #{$this}__react-btn
        {
            &.is-like
            {
                color: #3e4f71;
            }

            &.is-love
            {
                color: #c75b5b;
            }

            &.is-haha,
            &.is-wow,
            &.is-sad
            {
                color: #fcdd66;
            }

            &.is-angry
            {
                color: #e6514f;
            }
        }

        #{$this}__interactions-counters
        {
            display: none;
            align-items: center;
            justify-content: flex-end;

            padding: $size-xx-small;

            color: $color-dark-light;

            #{$this}.has-reactions &,
            #{$this}.has-views &,
            #{$this}.has-comments &
            {
                display: flex;
            }

            @media (min-width: $screen-xs-min)
            {
                font-size: 14px;
            }
        }

        #{$this}__interactions-counter
        {
            display: inline-flex;

            &:first-child
            {
                flex: 1;

                margin-right: auto;
            }

            // Partie générique : les points n'existent qu'entre les compteurs
            // de droite. L'affichage s'adaptera si on en rajoute.
            &:not(:first-child):not(:last-child)::after
            {
                content: '•';

                display: none;

                margin: 0 3px;
            }

            // Partie pas générique : sans système MVVM, les compteurs ne sont
            // pas retirés du DOM s'ils ne doivent pas apparaître, ils sont
            // seulement masqués. Pour afficher les points de séparation, il
            // faut donc cibler ces derniers manuellement.
            // En effet la partie générique ci-dessus ne peut pas fonctionner
            // ici, puisque les éléments du DOM ne bougent pas. Les sélecteurs
            // :first-child et :last-child font donc toujours référence aux
            // mêmes éléments.
            #{$this}.has-comments &:nth-child(2)::after
            {
                display: block;
            }
        }

        #{$this}__reactions,
        #{$this}__views,
        #{$this}__comments-count
        {
            @media (hover: hover) and (pointer: fine)
            {
                &:hover
                {
                    text-decoration: underline;

                    color: var(--theme-color);
                }
            }
        }

        #{$this}__user-reaction
        {
            display: none;
            align-items: center;
            justify-content: center;

            #{$this}__react-btn:not(.is-like):not(.is-love):not(.is-haha):not(.is-wow):not(.is-sad):not(.is-angry) > &--no-reaction,
            #{$this}__react-btn.is-like > &--like,
            #{$this}__react-btn.is-love > &--love,
            #{$this}__react-btn.is-haha > &--haha,
            #{$this}__react-btn.is-wow > &--wow,
            #{$this}__react-btn.is-sad > &--sad,
            #{$this}__react-btn.is-angry > &--angry
            {
                display: flex;
            }
        }

        #{$this}__user-reaction-icon
        {
            width: $size-x-small;
            height: $size-x-small;
            margin-right: $size-xxx-small;

            #{$this}__user-reaction--no-reaction > &
            {
                position: relative;

                &:before
                {
                    position: absolute;
                    top: 50%;
                    left: 50%;

                    transform: translate(-50%,-50%);
                }
            }
        }

        #{$this}__user-reaction-label
        {
            line-height: 1;
        }

        #{$this}__reactions
        {
            display: none;
            align-items: center;

            #{$this}.has-reactions &
            {
                display: flex;
            }
        }

        #{$this}__views,
        #{$this}__comments-count
        {
            @include reset-button;
        }

        #{$this}__views
        {
            display: none;

            #{$this}.has-views &
            {
                display: block;
            }
        }

        #{$this}__comments-count
        {
            display: none;

            cursor: pointer;

            #{$this}.has-comments &
            {
                display: block;
            }
        }

        #{$this}__comments
        {
            display: none ;

            padding: $size-xx-small;

            border-top: 1px solid $panels-border-color;

            &.is-open
            {
                display: block;
            }

            @media (min-width: $screen-sm-min)
            {
                padding: $size-x-small;
                border-top: 1px solid $panels-border-color;
            }

        }

        #{$this}__comments-list
        {
            &.has-comments
            {
                display: none ;
                margin-bottom: $size-x-small;
            }
        }
    }


}
