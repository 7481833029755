.c-online-users-aside-placeholder
{
    $this: &;

    display: flex;
    align-items: center;
    flex-direction: column;

    padding: 0 $size-medium;

    text-align: center;

    color: $color-default;

    @at-root
    {
        #{$this}__illustration
        {
            width: 100%;
        }

        #{$this}__text
        {
            margin-top: $size-xx-small;
        }
    }
}
