.c-course-header
{
    $this: &;

    $flags-gap: $size-x-small;

    padding: 0 $size-x-small;

    @at-root
    {
        #{$this}__metas
        {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;

            @media (min-width: $screen-md-min) {
                justify-content: flex-start;
            }
        }

        #{$this}__campaign-title
        {
            font-size: 15px;
            font-weight: normal;

            margin-top: 0;
            margin-bottom: $size-xxx-small;
        }

        #{$this}__course-title
        {
            font-size: 21px;
            font-weight: normal;

            margin: 0;
        }

        #{$this}__course-duration-container
        {
            font-size: 17px;

            display: flex;
            align-items: center;

            margin-left: $size-normal;
        }

        #{$this}__course-duration-icon
        {
            font-size: $size-small;

            margin-right: $size-xxx-small;

            transform: translateY(-1px);
        }

        #{$this}__flags
        {
            display: none;

            margin-left: $size-x-small;

            @media (min-width: $screen-xxs-max)
            {
                display: flex;
            }
        }

        #{$this}__flag
        {
            font-size: $size-small;

            margin: 0 $flags-gap / 2;

            &:first-child
            {
                margin-left: 0;
            }

            &:last-child
            {
                margin-right: 0;
            }
        }

        #{$this}__headings {
            padding-left: $size-xx-small;
        }
    }
}
