.c-mcq-item {
  margin-bottom: $size-x-small;

  &__title {
    font-size: 15px;
    margin-top: 0;
  }

  &__answer {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    position: relative;
  }

  &__action {
    top: 4px;
    position: relative;
  }

  &__answer-text {
    flex: 1;
    margin-left: $size-x-small;
    margin-right: $size-x-small;
  }
}