.c-tasks-tagModal {

  &__tag{
    cursor: pointer;
    border-radius: 3px;
    box-sizing: border-box;
    float: left;
    font-weight: 600;
    font-size: 15px;
    height: 26px;
    line-height: 32px;
    min-width: 40px;
    padding: 0 12px;
    width: auto;
    color: #fff;
    display: flex;
    align-items: center;
    margin : 0 10px 10px -5px;

  }
  &__tag:last-child{
    margin-bottom: 25px;
  }

  &__tagCircle{
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin-right: 5px;
  }

  &__tagName{
    color: #434a54;
    font-size: 15px;
    font-weight: 400;
    max-width: 70vw;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

}
