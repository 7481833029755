.c-main-menu-extension
{
    $this: &;
    $vertical-padding: $size-xx-small;
    $horizontal-padding: $size-small;

    @at-root
    {
        #{$this}__list
        {
            overflow-y: auto;
        }

        #{$this}__list-item-trigger
        {
            padding: $vertical-padding $horizontal-padding;

            .c-sidebar #{$this}__list-item:first-child &
            {
                margin-top: $horizontal-padding - $vertical-padding;
            }

            #{$this}__list-item:last-child &
            {
                margin-bottom: $horizontal-padding - $vertical-padding;
            }
        }

        #{$this}__placeholder
        {
            display: flex;
            overflow-y: auto;
            align-items: center;
            flex-direction: column;

            padding: $horizontal-padding;

            text-align: center;

            color: $color-default;
        }

        #{$this}__placeholder-illustration
        {
            flex-shrink: 0;

            width: 100%;
            max-width: $size-x-large + $size-normal;
        }

        #{$this}__placeholder-text
        {
            font-size: 15px;

            width: 100%;
            max-width: $size-x-large;
            margin-top: $size-x-small;
        }

        #{$this}__list-view
        {
            height:calc(#{vh(100)}  - 142.5px)!important;

            & .k-listview-content {
                @include custom-scrollbar();

                height:calc(#{vh(100)}  - 145px)!important;

            }
        }

       #{$this}__list-view--sidebar{
           height: calc(#{vh(100)}  - 121px )!important;

           &.k-listview-bordered {
               border-width: 0!important;
           }

           & .k-listview-content {
               @include custom-scrollbar();

               height:calc(#{vh(100)}  - 121px)!important;

           }
        }
    }
}
