.pr-group-work,
.pr-alarm,
.pr-graduation-hat,
.pr-chat,
.pr-list3,
.pr-kanban,
.pr-apartment,
.pr-contacts,
.pr-folder,
.pr-magnifier,
.pr-paper-plane,
.pr-add_file,
.pr-gif2,
.pr-sidebar-remove,
.pr-settings,
.pr-pencil6,
.pr-spaces{
  use {
    fill:none;
  }
}

.pr-custom
{
    width:2em;
    height:2em;
    color:$color-black;
}