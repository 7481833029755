.c-tasks-list {
  &__task {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $size-xx-small;
    border-bottom: 1px solid $color-light;
    min-height: 57px;
    cursor: pointer;

    &:hover {
      background-color: $color-light-light;
    }
  }

  &__grid {
    cursor: pointer;
  }

  &__overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1200;
    cursor: pointer;
  }

  &__empty {
    flex: 1;
    background: linear-gradient(180deg, white, white 56px, $color-light 56px, $color-light 56px);
    background-size: 100% 57px;
  }

  &__form {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: $size-xx-small $size-xx-small $size-x-small $size-xx-small;
    background-color: white;
    z-index: 1;
  }

  &__form-container {
    background-color: $color-light-light;
    padding: $size-xx-small;
    border-radius: 4px;
    color: $text-color;
    display: flex;
    align-items: center;
    cursor: text;
  }

  &__form-icon {
    width: 20px;
    height: 20px;
  }

  &__form-icon--trash{
    position: absolute;
    right: 10px;
    width: 20px;
    height: 20px;
  }

  &__form-text {
    font-size: 15px;
    margin-left: $size-xx-small;
    width: 100%;
    line-height: 30px;
  }

  &__form-input {
    margin: 0 $size-xxx-small;
    font-size: 15px;
  }

  &__select2-liste-popUp-addTask .select2-results__option {
    font-size: 16px ;
  }

  &__select2-liste-popUp-addTask .select2-selection__rendered {
    font-size: 16px ;
  }
}