.c-documents-viewer-album {
  $this: &;

  @at-root {

    #{$this}__thumb-video {
      position: relative;
    }

    #{$this}__icon-video-play--gallery {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 10;
      fill: white;
      opacity: 0.7;
      width: clamp(40px, 20%, 130px);
      height: auto;
      -webkit-transform: translate3d(-50%, -50%, 0);
      transform: translate3d(-50%, -50%, 0);
    }

    #{$this}__icon-video-play {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 10;
      color: rgba(255, 255, 255, 0.8);
      font-size: 21px;
      transform: translate3d(-50%, -50%, 0);
    }

  }
}