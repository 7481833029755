.c-document
{
  $this: &;


  padding:0px;

  @media #{$media-desktop}
  {
    padding-left:$size-x-small;
    padding-right:$size-x-small;
  }



  @at-root {

    #{$this}__live {
      background: green;color:white;padding:2px;
    }

    // surcharge c-documents__item : suppr des padding
    #{$this}__item {
      padding-left:0px;
      padding-right:0px;
    }


    // dans le document, même la racine du breadcumb est cliquable
    #{$this} .c-tree-directories__breadcrumb-text span {
       cursor:pointer;
    }


    // container du détail de document
    #{$this}__detail {
      padding: $size-xxx-small;
    }

    #{$this}__back-to-tree-trigger {
      display: none;
      padding: 5px 0;

      @media #{$media-desktop} {
        display: inherit;
      }
    }


    #{$this}__datatableshares thead,
    #{$this}__datatablestats thead {
      display:none;
    }


    #{$this}__datatablestats td {
      vertical-align: middle !important;
      cursor: pointer;
      height: 35px;
    }



    #{$this}__detailstatistics
    {
      width:100%;
    }

    #{$this}__detailstatistics th,
    #{$this}__detailstatistics td
    {
      text-align: left;
      padding:$size-xxx-small;
      padding-left:0px;
    }

    #{$this}__detailstatistics-account {
      margin-left: 35px;
      height:30px;
      padding-top:5px;
    }



    // colonnes cliquables sur les liens partagés
    #{$this}__shares-col {
      cursor:pointer;
    }

    #{$this}__shares-active {
      color: $color-adn-light;
    }

    #{$this}__shares-toggle {
      padding: $size-xx-small;

      @media #{$media-desktop} {
        padding: 0px;
      }
    }

    #{$this}__shares-lock {
        font-size: $size-small;
    }

    #{$this}__video-modal-embed  {
        iframe, embed {
          width:  100% !important;
          min-height: 100%;
          height:  calc(#{vh(60)}) !important;
        }
    }

    #{$this}__video-sidebar-embed  {
      iframe, embed {
        width:  100% !important;
        height: calc(#{vh(100)} - 60px);
      }
    }

    #{$this}__preview-embed  {
      iframe, embed {
        width:  100% !important;
        max-height: 66.66vh !important;
        max-height: calc(#{vh(66.66)}) !important;
      }
    }

  }
}
