.c-mobile-group-shortcut
{
    $this: &;
    $padding: $size-xx-small;

    display: block;
    overflow: hidden;

    box-sizing: content-box;
    padding: $padding;

    transition: background-color ease $speed-medium;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;

    @at-root
    {
        #{$this},
        #{$this}:hover,
        #{$this}:active,
        #{$this}:focus
        {
            text-decoration: none;

            color: $text-color;
        }

        #{$this}:active
        {
            background: $color-light;
        }

        #{$this}__thumb
        {
            width: 100%;
            margin-bottom: $size-xxx-small;
        }

        #{$this}__name
        {
            line-height: 1;
        }

        /// ====================================================================
        /// Thumb sizes
        ///
        /// The below code is used to always let the user see the half of a
        /// c-mobile-group-shortcut at the end of the scrollable container. The
        /// goal is to show the user that this content is scrollable.
        ///

        /// Gets the thumb width or height size expression
        /// @param {Number} $items-count
        ///
        @function getComputedThumbSize($items-count)
        {
            @return calc(100vw / #{$items-count} - #{$padding * 2});
        }

        /// Prints the thumb size rules, depending on the $visible-items-count
        /// value
        /// @param {Number} $visible-items-count
        ///
        @mixin thumb-size($visible-items-count)
        {
            #{$this}
            {
                width: getComputedThumbSize($visible-items-count);
            }

            #{$this}__thumb
            {
                height: getComputedThumbSize($visible-items-count);
            }
        }

        /// The visible items count is adjusted at some breakpoints, when the
        /// thumbs become too big
        @include thumb-size(5.5);

        @media (min-width: $screen-xs-min)
        {
            @include thumb-size(6.5);
        }

        @media (min-width: 644px)
        {
            @include thumb-size(7.5);
        }

        /// After the sm breakpoint, the thumb size becomes fixed, to avoid the
        /// use of too much media queries
        @media (min-width: $screen-sm-min)
        {
            #{$this}
            {
                width: $size-medium;
            }

            #{$this}__thumb
            {
                height: $size-medium;
            }
        }
    }
}
