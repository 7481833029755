
.c-trash {
  $this: &;

  @at-root {
    #{$this}__button-action-parent{
        margin-left:1rem;
        margin-right: 1rem;
        margin-top:1rem;
        margin-bottom:1rem;
    }
    #{$this}__button-action-overlay{
      position: fixed;
      display: none;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      z-index: 9010;
      cursor: pointer;
    }
    #{$this}__button-action-loader{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 9020;
    }
    #{$this}__button-action-loader-spinner{
      border: 4px solid rgba(0, 0, 0, 0.1);
      border-left-color: #fff;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    #{$this}__button-action-f{
      width: 1.2em;
      height: 1.2em;
      stroke: currentColor;
      stroke-width: 1.5;
      stroke-linecap: round;
      stroke-linejoin: round;
      fill: none;

      .f{
        font-style: normal;
        position: absolute;
        transform: translateX(-2.4em);

        &[class*=chevron-right] {
          right: 0;
          transform: none;
        }
        &.f-circle {
          fill: red;
        }
      }
    }
    #{$this}__checkbox-element{
      display: none;
    }

    #{$this}__checkbox-directories{
        margin-left:1rem;
    }
    #{$this}__checkbox-documents{
        margin-right:1rem;
    }

    #{$this}__created{
      width:193px !important;
    }
    #{$this}__dropdown{
      width:32px !important;
    }
  }

}

