.k-loading-mask{
    display: none !important;
}


.c-artificialintelligence-chat {
    $this: &;
    $width-column-left: 280px;

    @at-root {

        #{$this}__overlay {
            position: fixed; /* Couvre toute la fenêtre */
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.25); /* Voile semi-transparent noir */
            z-index: 10; /* Doit être inférieur au z-index de la sidebar */
            opacity: 0; /* Caché par défaut */
            visibility: hidden; /* Empêche les clics lorsqu'il est invisible */
            transition: opacity 0.3s ease, visibility 0.3s ease; /* Transition fluide */

            &-active {
                opacity: 1;
                visibility: visible;
            }
        }

        #{$this}__container-chat{
            font-size:17px;
            display: flex;
            flex-direction: column;
            min-height: calc(100vh - 65px);
            box-sizing: border-box;
            position: absolute;
            width: 100%;
            max-width:100%;
            background-color: light-dark($artificial-intelligence-chat-bg-color-light, $artificial-intelligence-chat-bg-color-dark);
            //max-height: 93vh;
            max-height: 100vh;
            overflow-y: hidden;
        }
        #{$this}__layout-action{
            display: none;
        }
        #{$this}__layout-action-row{
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            height:3.5rem;
            padding-left: 5px;
        }
        #{$this}__layout-action-row-add-link{
            display:flex;
            &-sidebar-switch {
                padding:7.5px;
            }

            &-pencil {
                padding-bottom:7.5px;
                padding-top:7.5px;
                padding-left: 10px;
                padding-right: 10px;
            }

            &:hover{
                background-color: light-dark($artificial-intelligence-left-sidebar-hover-bg-color-light, $artificial-intelligence-left-sidebar-hover-bg-color-dark);
                border-radius:7.5px;
            }
        }
        #{$this}__row-assistant{
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            z-index: 1000;
            color: light-dark($artificial-intelligence-texte-chat-color-light,$artificial-intelligence-texte-chat-color-dark);
            display:flex;
            align-items: center;
            justify-content: space-between;
            padding-right:15px;
            padding-left:15px;
            padding-top:0;
            height:56px;
            min-height:56px;
        }
        #{$this}__lightning-action-row{
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            height:3.5rem;
            padding-left: 5px;
        }
        #{$this}__lightning-action-row-switch{
            padding:7.5px;
            display:flex;

            &:hover{
                background-color: light-dark($artificial-intelligence-left-sidebar-hover-bg-color-light, $artificial-intelligence-left-sidebar-hover-bg-color-dark);
                border-radius: 7.5px;
                text-decoration: none;
            }
        }
        #{$this}__row-chat{
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items:center;
            position: relative;
            max-height: calc(#{vh(93)} - var(--dynamic-artificial-intelligence-footer-textarea-scrollHeight)  ) !important;

            @media (max-width: $screen-xs-max) {
                max-height: calc(#{vh(85)} - var(--dynamic-artificial-intelligence-footer-textarea-scrollHeight)  ) !important
            }
        }
        #{$this}__div-list-container{
            @include custom-scrollbar;
            &::-webkit-scrollbar-thumb {
                background-color: light-dark($artificial-intelligence-chat-scrollbar-bg-color-light,$artificial-intelligence-chat-scrollbar-bg-color-dark);
            }

            &:hover::-webkit-scrollbar-thumb {
                background-color: light-dark($artificial-intelligence-chat-scrollbar-bg-color-light,$artificial-intelligence-chat-scrollbar-bg-color-dark);
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: light-dark($artificial-intelligence-chat-scrollbar-bg-color-light,$artificial-intelligence-chat-scrollbar-bg-color-dark);
            }

            display: flex;
            flex-direction: column;
            height: calc(#{vh(100)} - var(--dynamic-artificial-intelligence-chat-container-height)  ) !important;
            overflow-y: auto;
            width:100%;
            max-width:100%;
            align-items: center;
            transition: max-height 0.2s ease;

        }
        #{$this}__div-list{
            width:760px;

            @media (max-width: 800px) {
                width:95%;
            }

            #tchat-message-template{
                flex-grow: 1;
                overflow-y: auto;
                padding-top: 0;
                padding-bottom: 0;
                padding-left: 10px;
                padding-right: 10px;
                margin:0;
                display: flex;
                flex-direction: column-reverse;
            }

        }
        #{$this}__row-bloc-message-user{
            margin-top: 3svh;
            margin-bottom: 3svh;
        }
        #{$this}__bloc-message-user{
            display:flex;
            max-width: 100%;
            justify-content: flex-end;

            &:hover{
                #{$this}__message-user-action {
                    &:hover {
                        background-color: light-dark($artificial-intelligence-message-bg-color-light, $artificial-intelligence-message-bg-color-dark);
                        border-radius: 50%;
                    }
                }
                #{$this}__action-icon{
                    visibility: visible;
                    color: light-dark($artificial-intelligence-texte-chat-color-light,$artificial-intelligence-texte-chat-color-dark);
                    cursor: pointer;
                }
            }
        }
        #{$this}__bloc-message-user-is-editing {
            max-width:100%;
            width:100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-items: flex-start;

            padding-right: 10px;
            padding-left: 10px;
            background-color: light-dark($artificial-intelligence-chat-textarea-bg-color-light, $artificial-intelligence-chat-textarea-bg-color-dark);
            color: light-dark($artificial-intelligence-texte-chat-color-light,$artificial-intelligence-texte-chat-color-dark);
            padding-top: 10px;
            padding-bottom: 10px;
            border-radius: 25px;

            #{$this}__bloc-message-textarea {
                border-bottom:none;
                width: 100%;
                resize: none;
                border-radius: 5px;
                margin-top: 0;
                margin-bottom: 3svh;
                padding-top: 0;
                padding-bottom: 0;
                height: 10vh;
                color: light-dark($artificial-intelligence-texte-chat-color-light,$artificial-intelligence-texte-chat-color-dark);
            }
            #{$this}__bloc-button{
                width:50%;
                display:flex;
                justify-content: flex-end;
                padding-top: 1svh;
                padding-bottom: 1svh;

                #{$this}__bloc-button-action-validate{
                    border: 1px solid;
                    border-radius: 25px;
                    height: auto;
                    line-height: 1.75rem;
                    padding: 0.5rem 1.5rem;
                    font-size: 1.25rem;
                    margin-left:5px;
                    margin-right:5px;
                    color: light-dark($artificial-intelligence-texte-chat-color-dark,$artificial-intelligence-texte-chat-color-light);
                    background-color: light-dark($artificial-intelligence-chat-bg-color-dark, $artificial-intelligence-chat-bg-color-light);

                    &:hover{
                        background-color: light-dark($artificial-intelligence-chat-bg-button-hover-color-dark, $artificial-intelligence-chat-bg-button-hover-color-light);
                    }

                    @media (max-width: $screen-xs-max) {
                        margin-left:2.5px;
                    }
                }

                #{$this}__bloc-button-action-cancelled{
                    border: 1px solid;
                    border-radius: 25px;
                    height: auto;
                    line-height: 1.75rem;
                    padding: 0.5rem 1.5rem;
                    font-size: 1.25rem;
                    margin-left:5px;
                    margin-right:5px;
                    color: light-dark($artificial-intelligence-texte-chat-color-light,$artificial-intelligence-texte-chat-color-dark);
                    background-color: light-dark($artificial-intelligence-chat-bg-color-light, $artificial-intelligence-chat-bg-color-dark);


                    &:hover{
                        background-color: light-dark($artificial-intelligence-chat-bg-button-hover-color-light, $artificial-intelligence-chat-bg-button-hover-color-dark);
                    }

                    @media (max-width: $screen-xs-max) {
                        margin-right:2.5px;
                    }
                }
            }
        }
        #{$this}__bloc-message-user-is-not-editing {
            max-width:75%;
            display: flex;
            //flex-wrap: wrap;
            justify-content: flex-end;
            align-items: flex-start;
        }
        #{$this}__message-user{
            margin-bottom: 10px;
            padding: 8px;
            border-radius: 5px;
            background-color: light-dark($artificial-intelligence-message-bg-color-light, $artificial-intelligence-message-bg-color-dark);
            color: light-dark($artificial-intelligence-texte-chat-color-light,$artificial-intelligence-texte-chat-color-dark);
            display: inline-block;
            max-width: calc(100% - 1rem);
            word-break: break-all;
            overflow-wrap: break-word;

            p {
                margin:0.5em 0;
                font-size: 17px;

                &:first-child {
                    margin-top: 0 !important;
                }

                &:last-child {
                    margin-bottom: 0 !important;
                }
            }
            ul, ol {
                margin-top: 10px;
                margin-bottom: 10px;
                font-size: 17px;
            }
            li{
                margin-top: 5px;
                margin-bottom: 5px;
                font-size: 17px;
            }
            h1 {
                font-size: 37px;
                font-weight: bold;
            }
            h2 {
                font-size: 25px;
                font-weight: bold;
            }
            h3{
                font-size: 21px;
                font-weight: bold;
            }
            h4, h5, h6 {
                font-size: 17px;
                font-weight: bold;
            }
            pre {
                margin-top: 10px;
                margin-bottom: 10px;
                padding: 0 !important;
                border: 1px solid light-dark($artificial-intelligence-chat-table-border-color-light, $artificial-intelligence-chat-table-border-color-dark);
                background-color: light-dark($artificial-intelligence-chat-table-bg-color-light, $artificial-intelligence-chat-table-bg-color-dark);
                color: light-dark($artificial-intelligence-texte-chat-color-light,$artificial-intelligence-texte-chat-color-dark);

                code {
                    @include custom-scrollbar;

                    &::-webkit-scrollbar {width: 7px; height: 7px;}

                    &::-webkit-scrollbar-thumb {
                        background-color: light-dark($artificial-intelligence-chat-scrollbar-bg-color-light, $artificial-intelligence-chat-scrollbar-bg-color-dark);
                    }

                    &:hover::-webkit-scrollbar-thumb {
                        background-color: light-dark($artificial-intelligence-chat-scrollbar-bg-color-light, $artificial-intelligence-chat-scrollbar-bg-color-dark);
                    }

                    &::-webkit-scrollbar-thumb:hover {
                        background-color: light-dark($artificial-intelligence-chat-scrollbar-bg-color-light, $artificial-intelligence-chat-scrollbar-bg-color-dark);
                    }
                }
                p{
                    font-size:13px;
                    padding: 7.5px 13px;
                }
            }
            table {
                font-size: 15px;
                border-collapse: separate; /* Important pour permettre les bordures arrondies */
                border-spacing: 0; /* Optionnel, pour éviter des espaces entre les cellules */
                width: 100%; /* Optionnel, ajuste la largeur */
                border: 2px solid light-dark($artificial-intelligence-chat-table-border-color-light, $artificial-intelligence-chat-table-border-color-dark); /* Couleur et taille de la bordure */
                border-radius: 10px; /* Ajustez le rayon pour personnaliser l'arrondi */
                overflow: hidden; /* Assure que le contenu respecte les bords arrondis */
                margin-top: 10px;
                margin-bottom: 10px;
            }

            th, td {
                padding: 4px 12px;
                border-bottom: 1px solid light-dark($artificial-intelligence-chat-table-border-color-light, $artificial-intelligence-chat-table-border-color-dark);
                border-right: 1px solid light-dark($artificial-intelligence-chat-table-border-color-light, $artificial-intelligence-chat-table-border-color-dark);
                text-align: left; /* Alignement du texte */
            }

            th {
                background-color: light-dark($artificial-intelligence-chat-table-bg-color-light, $artificial-intelligence-chat-table-bg-color-dark); /* Couleur d'arrière-plan pour l'en-tête */
                font-weight: bold; /* Texte en gras pour l'en-tête */
            }

            td:last-child, th:last-child {
                border-right: none; /* Supprime la bordure inférieure de la dernière cellule */
            }
            tr:last-child {
                td, th {
                    border-bottom: none; /* Supprime la bordure inférieure de la dernière cellule */
                }
            }
            strong{
                font-weight: bold;
            }
        }
        #{$this}__message-user-action{
            display:inline-block;
            //margin-top: -5px;
            margin-right:1rem;
            padding-bottom: 7.5px;
            padding-top: 12.5px;
            padding-left: 12.5px;
            padding-right: 12.5px;
            margin-left: -25px;
        }

        #{$this}__action-icon{
            visibility:hidden;

            @media (max-width: $screen-sm-max) {
                visibility:visible;
                color: light-dark($artificial-intelligence-texte-chat-color-light,$artificial-intelligence-texte-chat-color-dark);
                cursor: pointer;
            }

        }

        #{$this}__row-message-assistant{
            &:hover{
                #{$this}__action-row{
                    visibility: visible;
                }
            }
        }
        #{$this}__bloc-message-assistant{
            display:flex;
            justify-content: flex-start;
            max-width: 100%;
            text-align: left;
            //flex-wrap: wrap;
            align-items:flex-start;
        }
        #{$this}__loader-spinner {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 1px;
            margin-top: -2.5svh;
            width: 50%;
            @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                width:75%;
            }

            @media (max-width: $screen-xs-max) {
                width:95%;
            }
        }

        #{$this}__loader-spinner .dot {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: light-dark($artificial-intelligence-texte-chat-color-light,$artificial-intelligence-texte-chat-color-dark);
            animation: bounce 0.6s infinite ease-in-out;
        }

        #{$this}__loader-spinner .dot:nth-child(1) {
            animation-delay: 0s;
        }
        #{$this}__loader-spinner .dot:nth-child(2) {
            animation-delay: 0.2s;
        }
        #{$this}__loader-spinner .dot:nth-child(3) {
            animation-delay: 0.4s;
        }

        @keyframes bounce {
            0%, 80%, 100% {
                transform: scale(1);
            }
            50% {
                transform: scale(1.5);
            }
        }

        #{$this}__logo-ia{
            margin-right:1rem;
            margin-top:0.5rem;
            width:25px;
        }
        #{$this}__message-assistant{
            margin-bottom: 10px;
            padding: 8px;
            border-radius: 5px;
            color: light-dark($artificial-intelligence-texte-chat-color-light,$artificial-intelligence-texte-chat-color-dark);
            max-width:calc(95% - 1rem);

            p {
                margin:0.5em 0;
                font-size: 17px;

                &:first-child {
                    margin-top: 0 !important;
                }

                &:last-child {
                    margin-bottom: 0 !important;
                }
            }
            ul, ol {
                margin-top: 10px;
                margin-bottom: 10px;
                font-size: 17px;
            }
            li{
                margin-top: 5px;
                margin-bottom: 5px;
                font-size: 17px;
            }
            h1 {
                font-size: 37px;
                font-weight: bold;
            }
            h2 {
                font-size: 25px;
                font-weight: bold;
            }
            h3{
                font-size: 21px;
                font-weight: bold;
            }
            h4, h5, h6 {
                font-size: 17px;
                font-weight: bold;
            }
            pre {
                margin-top: 10px;
                margin-bottom: 10px;
                padding: 0 !important;
                border: 1px solid light-dark($artificial-intelligence-chat-table-border-color-light, $artificial-intelligence-chat-table-border-color-dark);
                background-color: light-dark($artificial-intelligence-chat-table-bg-color-light, $artificial-intelligence-chat-table-bg-color-dark);
                color: light-dark($artificial-intelligence-texte-chat-color-light,$artificial-intelligence-texte-chat-color-dark);

                code {
                    @include custom-scrollbar;

                    &::-webkit-scrollbar {width: 7px; height: 7px;}

                    &::-webkit-scrollbar-thumb {
                        background-color: light-dark($artificial-intelligence-chat-scrollbar-bg-color-light, $artificial-intelligence-chat-scrollbar-bg-color-dark);
                    }

                    &:hover::-webkit-scrollbar-thumb {
                        background-color: light-dark($artificial-intelligence-chat-scrollbar-bg-color-light, $artificial-intelligence-chat-scrollbar-bg-color-dark);
                    }

                    &::-webkit-scrollbar-thumb:hover {
                        background-color: light-dark($artificial-intelligence-chat-scrollbar-bg-color-light, $artificial-intelligence-chat-scrollbar-bg-color-dark);
                    }
                }
                p{
                    font-size:13px;
                    padding: 7.5px 13px;
                }
            }
            table {
                font-size: 15px;
                border-collapse: separate; /* Important pour permettre les bordures arrondies */
                border-spacing: 0; /* Optionnel, pour éviter des espaces entre les cellules */
                width: 100%; /* Optionnel, ajuste la largeur */
                border: 2px solid light-dark($artificial-intelligence-chat-table-border-color-light, $artificial-intelligence-chat-table-border-color-dark); /* Couleur et taille de la bordure */
                border-radius: 10px; /* Ajustez le rayon pour personnaliser l'arrondi */
                overflow: hidden; /* Assure que le contenu respecte les bords arrondis */
                margin-top: 10px;
                margin-bottom: 10px;
            }

            th, td {
                padding: 4px 12px;
                border-bottom: 1px solid light-dark($artificial-intelligence-chat-table-border-color-light, $artificial-intelligence-chat-table-border-color-dark);
                border-right: 1px solid light-dark($artificial-intelligence-chat-table-border-color-light, $artificial-intelligence-chat-table-border-color-dark);
                text-align: left; /* Alignement du texte */
            }

            th {
                background-color: light-dark($artificial-intelligence-chat-table-bg-color-light, $artificial-intelligence-chat-table-bg-color-dark); /* Couleur d'arrière-plan pour l'en-tête */
                font-weight: bold; /* Texte en gras pour l'en-tête */
            }

            td:last-child, th:last-child {
                border-right: none; /* Supprime la bordure inférieure de la dernière cellule */
            }
            tr:last-child {
                td, th {
                    border-bottom: none; /* Supprime la bordure inférieure de la dernière cellule */
                }
            }
            strong{
                font-weight: bold;
            }
        }

        #{$this}__action-row{
            visibility: hidden;
            padding-left: calc(1rem + 24px);
            margin-bottom: 4svh;
            margin-top:0;

            a {
                padding-top: 7.5px;
                padding-bottom: 6px;
                padding-left: 7.5px;
                padding-right: 7.5px;

                &:hover{
                    background-color: light-dark($artificial-intelligence-message-bg-color-light, $artificial-intelligence-message-bg-color-dark);
                    border-radius: 7.5px;
                }
            }

            .pr{
                &-artificial-intelligence-copy{
                    cursor: pointer;
                    font-size: 17px;
                    stroke: light-dark($artificial-intelligence-texte-chat-color-light,$artificial-intelligence-texte-chat-color-dark);
                    fill: light-dark($artificial-intelligence-texte-chat-color-light,$artificial-intelligence-texte-chat-color-dark);
                }
            }
        }

        #{$this}__warning-container{
            width: 760px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            background-color: light-dark($artificial-intelligence-chat-warning-container-background-color-light,$artificial-intelligence-chat-warning-container-background-color-dark);
            border: 1px solid light-dark($artificial-intelligence-chat-warning-container-border-color-light,$artificial-intelligence-chat-warning-container-border-color-dark);
            border-radius: 20px;
            padding: 16px;
            margin-bottom: 2%;
        }
        #{$this}__warning-icon{
            font-size: 21px;
            color: light-dark($artificial-intelligence-chat-warning-icon-color-light,$artificial-intelligence-chat-warning-icon-color-dark);
        }
        #{$this}__warning-text{
            font-size: 17px;
            padding: 0 0 0 16px;
            color: light-dark($artificial-intelligence-texte-chat-color-light,$artificial-intelligence-texte-chat-color-dark);
        }

        #{$this}__floating-button-container {
            position: sticky;
            bottom: 20px;
            z-index: 1000;
        }

        #{$this}__floating-button {
            color: light-dark($artificial-intelligence-texte-chat-color-light,$artificial-intelligence-texte-chat-color-dark);
            background-color: light-dark($artificial-intelligence-chat-bg-color-light, $artificial-intelligence-chat-bg-color-dark);
            border: 1px solid light-dark($artificial-intelligence-chat-floating-button-border-color-light, $artificial-intelligence-chat-floating-button-border-color-dark);
            cursor: pointer;
            display: flex;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            height: 35px;
            width: 35px;

            &:hover{
                background-color: light-dark($artificial-intelligence-message-bg-color-light, $artificial-intelligence-message-bg-color-dark);
            }
        }

        #{$this}__floating-button-icon{
            font-size: 26px;
            @media (max-width: $screen-xs-max) {
                font-size: 22px;
            }
        }
    }
}
