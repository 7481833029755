.c-form
{
    $this: &;

    @at-root
    {
        #{$this}__info
        {
            font-weight: 500;

            margin-top: $size-x-small;

            &--success
            {
                color: $color-success;
            }

            &--error
            {
                color: $color-danger;
            }

            &:empty
            {
                display: none;
            }
        }

        #{$this}__group
        {
            margin-bottom: $size-xx-small;

            input
            {
                margin-top: 0;
                margin-bottom: 0;
            }

            .k-input-solid {
                background: $color-light-light;
            }
        }

        #{$this}__fieldset
        {
            padding: 0;

            &:not(:last-child)
            {
                margin-bottom: $size-x-small;
            }
        }

        #{$this}__legend
        {
            border: none;
        }

        #{$this}__legend-icon
        {
            vertical-align: text-top;
        }

        #{$this}__loader-inline
        {
            width: 25px;
            position: absolute;
            right: 0;
        }
    }
}

.c-form-group
{
    $this: &;
    $input-height: $size-small + $size-xxx-small;
    $icon-width: $input-height;
    $icon-padding: $size-xx-small;

    position: relative;

    display: flex;
    flex-direction: column;

    max-width: 100%;
    //margin-bottom: $size-x-small;

    @at-root
    {
        .c-input-group #{$this} {
          flex: 1;
        }

        #{$this}--top-offset
        {
            margin-top: $size-xx-small;
        }

        #{$this}::after
        {
            content: '';

            position: absolute;
            top: 0;
            right: -$icon-padding;

            visibility: hidden;

            width: $icon-width;
            height: $input-height;
            padding: $icon-padding;

            transition: ease 150ms;
            transition-property: opacity, visibility;

            opacity: 0;
        }

        #{$this}.is-valid::after,
        #{$this}.is-invalid::after
        {
            z-index: 1;

            visibility: visible;

            opacity: 1;
            box-shadow: -20px 0 10px -10px rgba(255,255,255,.9);
        }

        #{$this}.is-invalid-container::after
        {
            visibility: hidden !important;
            opacity: 0;
        }

        #{$this}.is-valid::after
        {
            content: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" fill="'+$color-success+'" viewBox="0 -5 31.997999 30.999001" height="100%" width="100%"><path d="m 8.8,23.999 c -0.205,0 -0.41,-0.078 -0.566,-0.234 l -8,-8 c -0.312,-0.312 -0.312,-0.819 0,-1.131 0.312,-0.312 0.819,-0.312 1.131,0 L 8.799,22.068 30.633,0.234 c 0.312,-0.312 0.819,-0.312 1.131,0 0.312,0.312 0.312,0.819 0,1.131 l -22.4,22.4 c -0.157,0.157 -0.362,0.234 -0.566,0.234 z"/></svg>');
        }

        #{$this}.is-invalid::after
        {
            content: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" fill="'+$color-danger+'" viewBox="0 0 20.799999 20.798" height="100%" width="100%"><path d="m 11.53,10.399 9.034,-9.034 c 0.312,-0.312 0.312,-0.819 0,-1.131 -0.312,-0.312 -0.819,-0.312 -1.131,0 L 10.399,9.268 1.365,0.234 c -0.312,-0.312 -0.819,-0.312 -1.131,0 -0.312,0.312 -0.312,0.819 0,1.131 l 9.034,9.034 -9.034,9.034 c -0.312,0.312 -0.312,0.819 0,1.131 0.157,0.157 0.362,0.234 0.566,0.234 0.204,0 0.41,-0.078 0.566,-0.234 l 9.034,-9.034 9.034,9.034 c 0.157,0.157 0.362,0.234 0.566,0.234 0.204,0 0.41,-0.078 0.566,-0.234 0.312,-0.312 0.312,-0.819 0,-1.131 l -9.034,-9.034 z"></path></svg>');
        }

        #{$this}__input
        {
            margin-top: 0;
            margin-bottom: 0;

            &::placeholder
            {
                user-select: none;

                color: transparent;
            }

            &:focus::placeholder
            {
                color: $color-default-light;
            }

            #{$this}.is-valid &,
            #{$this}.is-invalid &
            {
                padding-right: $icon-width - $icon-padding;
            }

            #{$this}.is-valid &:focus
            {
                border-color: $color-success;
            }

            #{$this}.is-invalid &
            {
                border-color: $color-danger;
            }

          &--desc {
                border: solid 2px $color-adn;
                border-radius: 10px;
            }

        }

        #{$this}__label
        {
            font-weight: normal;
            line-height: 1;

            position: absolute;
            top: 0;
            left: 0;

            margin-bottom: 0;

            cursor: text;
            transition: ease $speed-fast;
            transition-property: color, transform;
            transform: translateY(8.5px);
            transform-origin: left;

            #{$this}[class*="col-"] &
            {
                left: 15px;
            }

            #{$this}__input:disabled ~ &
            {
                cursor: default;

                color: $panels-border-color;
            }

            #{$this}__input:focus ~ &,
            #{$this}__input:not([type='button']):not(:placeholder-shown) ~ &,
            #{$this}__input[type='button']:not([value='']) ~ &,
            &--standalone
            {
                transform: translateY(-11px) scale(.8);
            }

            #{$this}__input:focus ~ &
            {
                color: var(--theme-color-light);

                #{$this}.is-valid &
                {
                    color: $color-success;
                }
            }

            #{$this}__input:not([type='button']):not(:placeholder-shown) ~ &,
            #{$this}__input[type='button']:not([value='']) ~ &,
            &--standalone
            {
                color: $panels-border-color;
            }

            #{$this}.is-invalid &
            {
                color: $color-danger !important;
            }
        }

        #{$this}__info
        {
            font-size: 11px;
            line-height: 1;

            display: block;
            visibility: hidden;

            margin-top: $size-xxx-small;

            transition: ease 150ms;
            transition-property: color, opacity, visibility;
            text-align: right;

            opacity: 0;

            #{$this}.has-info &,
            #{$this}.is-valid &,
            #{$this}.is-invalid &
            {
                visibility: visible;

                opacity: 1;
            }



            #{$this}.is-valid &
            {
                color: $color-success;
            }

            #{$this}.is-invalid &
            {
                color: $color-danger;
            }
        }
    }
}




.errorValidation
{
    border-color: $color-danger !important;

    &:not(input):not(textarea)
    {
        border: solid;
        border-width: 1px;
        border-radius: 3px;

        padding:5px;
        padding-top:15px;
    }
}

.c-input-group
{
    $this: &;

    display: flex;

    @at-root
    {
        #{$this}--top-offset
        {
            margin-top: $size-xx-small;
        }

        #{$this}.disabled
        {
            color: $color-default-light;
        }

        #{$this}__addon
        {
            font-size: 15px;

            display: flex;
            align-items: center;

            height: 30px;
            padding-left: $size-xx-small;

            &--before
            {
                padding-right: $size-xx-small;
                padding-left: 0;
            }
        }
    }
}
