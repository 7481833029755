.c-banner-previewer
{
    $this: &;
    $header-height: 12px;
    $footer-height: 12px;

    width: $size-x-large + $size-normal;
    height: $size-xxx-large;
    margin: 0 auto;

    box-shadow: 0 0 1px rgba(black, .08), 0 3px 8px rgba(black, .08);

    --size-ratio: 2.3;

    @media (min-width: $screen-xs-min)
    {
        width: $size-x-large + $size-medium;
        height: $size-xxx-large;

        --size-ratio: 1.7;
    }

    @media (min-width: $screen-md-min)
    {
        width: 100%;
        height: $size-xx-large;

        --size-ratio: 1;
    }

    @at-root
    {
        #{$this}__wrapper
        {
            position: relative;

            display: flex;
            overflow: hidden;

            height: 100%;
            padding-top: calc(#{$header-height} * var(--size-ratio));

            background: var(--background-theme-color);

            .has-enabled-mobile-navbar &
            {
                padding-bottom: calc(#{$footer-height} * var(--size-ratio));
            }

            @media (min-width: $screen-sm-min)
            {
                padding: 0 !important;
            }
        }

        #{$this}__header,
        #{$this}__footer
        {
            position: absolute;
            z-index: 4;
            right: 0;
            left: 0;

            background: white;

            @media #{$media-desktop}
            {
                display: none !important;
            }
        }

        #{$this}__header
        {
            top: 0;

            display: flex;
            align-items: center;
            justify-content: space-between;

            height: calc(#{$header-height} * var(--size-ratio));
            padding: 0 calc(#{$size-xxx-small} * var(--size-ratio));

            box-shadow: 0 0 1px rgba(black, .08), 0 2px 5px rgba(black, .08);
        }

        #{$this}__header-trigger
        {
            $size: calc(#{$size-xxx-small} * var(--size-ratio));

            width: $size;
            height: $size;

            border-radius: 50%;
            background: $color-light;
        }

        #{$this}__header-title
        {
            $height: calc(3px * var(--size-ratio));

            width: calc(#{$size-small} * var(--size-ratio));
            height: $height;

            border-radius: calc(#{$height} / 2);
            background: $color-light;
        }

        #{$this}__footer
        {
            bottom: 0;

            display: none;
            align-items: center;
            justify-content: space-around;

            height: calc(#{$footer-height} * var(--size-ratio));

            box-shadow: 0 0 1px rgba(black, .08), 0 -2px 5px rgba(black, .08);

            .has-enabled-mobile-navbar &
            {
                display: flex;
            }
        }

        #{$this}__footer-trigger
        {
            $size: calc(#{$size-xxx-small} * var(--size-ratio));

            width: $size;
            height: $size;

            border-radius: 50%;
            background: $color-light;
        }

        #{$this}__main-menu
        {
            z-index: 3;

            display: none;
            flex-direction: column;
            justify-content: space-between;

            width: calc(#{$size-x-small} * var(--size-ratio));

            background-color: white;
            box-shadow: 0 0 1px rgba(black, .08), 0 3px 8px rgba(black, .08);

            @media #{$media-desktop}
            {
                display: flex;
            }
        }

        #{$this}__main-menu-trigger
        {
            $size: calc(#{$size-xx-small} * var(--size-ratio));

            width: $size;
            height: $size;
            margin: calc(#{$size-xxx-small} * var(--size-ratio)) auto;

            border-radius: 50%;
            background: $color-light;
        }

        #{$this}__main-aside
        {
            z-index: 2;

            width: calc(#{$size-medium} * var(--size-ratio));
            padding: calc(#{$size-xx-small} * var(--size-ratio));

            background-color: white;
            box-shadow: 0 0 1px rgba(black, .08), 0 3px 8px rgba(black, .08);
        }

        #{$this}__main-menu-aside
        {
            display: none;

            @media (min-width: $screen-md-min)
            {
                display: block;
            }
        }

        #{$this}__online-users-aside
        {
            display: none;

            @media (min-width: $screen-xl-min)
            {
                display: block;
            }
        }

        #{$this}__main-aside-search-bar
        {
            height: calc(#{$size-xx-small} * var(--size-ratio));
            margin-bottom: calc(#{$size-xx-small} * var(--size-ratio));

            border-radius: 1px;
            background: $color-light-light;
        }

        #{$this}__main-aside-title
        {
            $height: calc(#{$size-xxx-small} * var(--size-ratio));

            width: 50%;
            height: $height;
            margin: calc(#{$size-xx-small} * var(--size-ratio)) 0;

            border-radius: calc(#{$height} / 2);
            background: $color-light-light;
        }

        #{$this}__main-aside-list-item
        {
            margin: calc(#{$size-xxx-small} * var(--size-ratio)) 0;
        }

        #{$this}__main-aside-list-item-illustration
        {
            $size: calc(#{$size-xx-small} * var(--size-ratio));

            width: $size;
            height: $size;
            margin-right: calc(#{$size-xxx-small} * var(--size-ratio));

            border-radius: 50%;
            background: $color-light-light;
        }

        #{$this}__main-aside-list-item-text-line
        {
            $height: calc(2px * var(--size-ratio));

            height: $height;

            border-radius: calc(#{$height} / 2);
            background: $color-light-light;

            &:nth-child(2)
            {
                width: 40%;
                margin-top: calc(2px * var(--size-ratio));
            }
        }

        #{$this}__page-content
        {
            display: flex;
            flex: 1;
            flex-direction: column;
        }

        #{$this}__header-area
        {
            z-index: 1;

            box-shadow: 0 0 1px rgba(black, .08), 0 3px 8px rgba(black, .08);
        }

        #{$this}__banner-container
        {
            position: relative;

            padding-bottom: 30%;
        }

        #{$this}__banner
        {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        #{$this}__header-area-navigation
        {
            display: flex;
            align-items: center;
            flex-direction: row;

            height: calc(#{$size-xx-small} * var(--size-ratio));

            background: white;
        }

        #{$this}__header-area-navigation-item
        {
            $height: calc(3px * var(--size-ratio));

            width: calc(#{$size-xx-small} * var(--size-ratio));
            height: $height;
            margin: 0 calc(#{$size-xxx-small} * var(--size-ratio));

            border-radius: calc(#{$height} / 2);
            background: $color-light;

            &:nth-child(5),
            &:nth-child(6)
            {
                display: none;
            }

            @media (min-width: $screen-xs-min)
            {
                &:nth-child(6)
                {
                    display: block;
                }
            }

            @media (min-width: $screen-md-min)
            {
                &:nth-child(5)
                {
                    display: block;
                }
            }
        }

        #{$this}__timeline
        {
            overflow: hidden;
            flex: 1;

            padding: calc(#{$size-xx-small} * var(--size-ratio));
        }

        #{$this}__publisher,
        #{$this}__post
        {
            background: white;
            box-shadow: 0 0 1px rgba(black, .08), 0 2px 5px rgba(black, .08);
        }

        #{$this}__publisher
        {
            position: relative;

            display: none;

            width: 100%;
            max-width: calc(#{$size-large} * var(--size-ratio));
            height: calc(#{$size-small} * var(--size-ratio));
            margin: 0 auto calc(#{$size-xx-small} * var(--size-ratio));
            padding: calc(#{$size-xxx-small} * var(--size-ratio));

            border-radius: calc(1px * var(--size-ratio));

            @media (min-width: $screen-sm-min)
            {
                display: block;
            }
        }

        #{$this}__publisher-placeholder
        {
            $height: calc(#{$size-xxx-small} * var(--size-ratio));

            width: calc(#{$size-small} * var(--size-ratio));
            height: $height;

            border-radius: calc(#{$height} / 2);
            background: $color-light-light;
        }

        #{$this}__publisher-emojis-trigger
        {
            $size: calc(#{$size-xxx-small} * var(--size-ratio));
            $offset: calc(#{$size-xxx-small} * var(--size-ratio));

            position: absolute;
            right: $offset;
            bottom: $offset;

            width: $size;
            height: $size;

            border-radius: 50%;
            background: $color-light-light;
        }

        #{$this}__post
        {
            display: flex;
            flex-direction: column;

            margin: 0 calc(-#{$size-xx-small} * var(--size-ratio)) calc(#{$size-xx-small} * var(--size-ratio));

            @media (min-width: $screen-xs-min)
            {
                width: 100%;
                max-width: calc(#{$size-large} * var(--size-ratio));
                margin-right: auto;
                margin-left: auto;

                border-radius: calc(1px * var(--size-ratio));
            }
        }

        #{$this}__post-header
        {
            display: flex;
            justify-content: space-between;

            padding: calc(#{$size-xxx-small} * var(--size-ratio));
        }

        #{$this}__post-author-avatar
        {
            $size: calc(#{$size-xx-small} * var(--size-ratio));

            width: $size;
            height: $size;
            margin-right: calc(#{$size-xxx-small} * var(--size-ratio));

            border-radius: 50%;
            background: $color-light-light;
        }

        #{$this}__post-metas
        {
            width: 50%;
        }

        #{$this}__post-metas-line
        {
            $height: calc(2px * var(--size-ratio));

            width: 100%;
            height: $height;

            border-radius: calc(#{$height} / 2);
            background: $color-light-light;

            &:first-child
            {
                width: 65%;
            }

            &:nth-child(2)
            {
                margin-top: calc(2px * var(--size-ratio));
            }
        }

        #{$this}__post-menu-trigger
        {
            $height: calc(2px * var(--size-ratio));

            width: calc($size-xxx-small * var(--size-ratio));
            height: $height;

            border-radius: calc(#{$height} / 2);
            background: $color-light-light;
        }

        #{$this}__post-body
        {
            flex: 1;

            padding: calc(#{$size-xxx-small} * var(--size-ratio));
        }

        #{$this}__post-body-line
        {
            $height: calc(2px * var(--size-ratio));

            width: 100%;
            height: $height;
            margin-bottom: calc(2px * var(--size-ratio));

            border-radius: $height;
            background: $color-light-light;

            &:nth-child(2)
            {
                width: 50%;
            }

            &:nth-child(3)
            {
                width: 90%;
            }

            &:nth-child(4)
            {
                width: 75%;
            }

            &:nth-child(5)
            {
                width: 95%;
            }

            &:nth-child(6)
            {
                width: 25%;
            }
        }

        #{$this}__post-footer
        {
            display: flex;
            justify-content: space-evenly;
        }

        #{$this}__post-footer-action
        {
            $height: calc(3px * var(--size-ratio));

            width: calc(#{$size-small} * var(--size-ratio));
            height: $height;
            margin: calc(#{$size-xxx-small} * var(--size-ratio)) 0;

            border-radius: calc(#{$height} / 2);
            background: $color-light-light;
        }
    }
}
