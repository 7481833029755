p
{
    font-size: 13px;
    margin: 0;
    padding: 1em 0;
}

p:not([class]),
p[class='']
{
    font-size: 15px;
    font-weight: 400;

    margin: 0 0 $size-small;
    padding: 0;
}

small
{
    font-size: 13px;
    font-weight: 400;
}

b, strong
{
    font-weight: 500;
}
