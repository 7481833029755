/// ============================================================================
/// # TEXT-TRUNCATION
/// ============================================================================
/// Outils servant à tronquer du texte. L'avantage par rapport aux troncatures
/// en back-end ou en JS c'est que celles en CSS sont responsives.
/// ============================================================================
/// @group tools
/// ============================================================================



/// Méthode "à l'ancienne" qui ne permet que d'afficher une seule ligne, pas
/// plus pas moins. Nécessite la plupart du temps de forcer la largeur de
/// l'élément à 100%.
@mixin single-line()
{
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}



/// Méthode plus récente, qui permet d'afficher un nombre personnalisé de
/// lignes. Pour la compatibilité avec les navigateurs, il faut viser >= 2018.
/// Actuellement la valeur du display doit obligatoirement être -webkit-box,
/// l'ancienne implémentation de flexbox. Ce mixin est donc incompatible avec
/// Flexbox justement, ou CSS Grid.
/// @link https://caniuse.com/?search=-webkit-line-clamp
/// @param {number} $count
@mixin clamp-lines-to($count)
{
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;

    -webkit-line-clamp: $count;

    @if $count == 1
    {
        // Permet d'autoriser la troncature en plein milieu d'un mot.
        // On ne le fait que s'il n'y a qu'une seule ligne vu que cette
        // propriété influence toutes les lignes du texte et pas seulement la
        // dernière.
        word-break: break-all;
    }
}
