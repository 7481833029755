.c-chat-conversations-list
{
    $this: &;

    flex: 1;

    height: 100%;

    #{$this}--custom-scrollbar {
        // Sert à masquer la scrollbar en desktop, en le couplant à l'utilisation de
        // la classe "scroll".
        // Spoiler : oui, c'est dégueulasse, mais c'est pas moi !
        @media #{$media-desktop}
        {
            margin-right: -6px;
        }
    }



    @at-root
    {
    }
}
