.c-tree-directories {
  $this: &;

  flex: 1;
  min-height: 0;

  @media (min-width: $screen-md-min) {
    display: flex;
  }

  @at-root {




    #{$this}__treeview {

      display: none;
      padding: $size-xxx-small 0;
      width:100%;

      @include custom-scrollbar;

      @media (min-width: $screen-sm-min)
      {
        display: block;
      }
    }

    #{$this}__treeview-disable {
      pointer-events: none;
      opacity: 0.4;
    }



    //
    // style du breadcrumb
    //
    #{$this}__breadcrumb-container {
      border-bottom-color: $color-light !important;
      border-bottom:solid;
      border-bottom-width:1px;
    }

    #{$this}__breadcrumb {
      min-width: 0;

      white-space: nowrap ;
      display: none;
      flex-grow: 1;
      height:35px;

      font-size:14px;

      padding: $size-xx-small $size-x-small;

      @media (min-width: $screen-sm-min)
      {
        display: flex;
      }
    }




    #{$this}__breadcrumb-collapse,
    #{$this}__breadcrumb-directory-parameters,
    #{$this}__breadcrumb-directory-parameters-mobile {
      position:absolute;
      font-size:21px;
      right:0; top:0;
      margin-top:7px; margin-right:5px;
    }


    #{$this}__breadcrumb-collapse {
      display: none;

      @media #{$media-desktop}
      {
        display: block;
      }
    }


    #{$this}__breadcrumb-directory-parameters {
      display: none;

      right: 35px;
      @media #{$media-desktop}
      {
        display: block ;
      }
    }


    #{$this}__breadcrumb-directory-parameters-mobile {
      display: block ;

      right: 10px;
      @media #{$media-desktop}
      {
        display: none;
      }
    }



    #{$this}__breadcrumb-text {
      overflow: hidden;
      text-overflow:ellipsis ;
      display: inline-block;

      font-size: 13px;
    }

    #{$this}__breadcrumb-text a {
      color: $color-dark;
    }

    #{$this}__breadcrumb-text span {
      color: var(--theme-color);
    }



    #{$this}__breadcrumb-separator {
      font-weight: bold;
      padding: 0 $size-xx-small;
    }

    #{$this}__breadcrumb-folder {
      padding-left:5px;
      padding-bottom:4px;
    }

    #{$this}__breadcrumb-folder svg{
      font-size: 21px;
    }
    // fin style breadcrumb




    #{$this}__mobile-root
    {
      display: none;

      // La marge négative permet de masquer la bordure supérieure du premier
      // item de la liste en mobile.
      // Il n'y a pas de fil d'Ariane en mobile, la bordure ne pas être visible.
      // La structure HTML actuelle ne permet pas de supprimer vraiment la
      // bordure (avec :first-child ou autre et border: none) ; la cacher est
      // donc la solution la plus simple.
      margin-top: -2px;

      background: white;

      @media (min-width: $screen-sm-min)
      {
        margin-top: 0;
      }

      &.is-shown
      {
        display: block;
      }
    }





    //
    // style des sous-répertoires
    #{$this}__subdirectories
    {
      // La marge négative permet de masquer la bordure supérieure du premier
      // item de la liste en mobile.
      // Il n'y a pas de fil d'Ariane en mobile, la bordure ne pas être visible.
      // La structure HTML actuelle ne permet pas de supprimer vraiment la
      // bordure (avec :first-child ou autre et border: none) ; la cacher est
      // donc la solution la plus simple.
      margin-top: -2px;
      margin-left: 0;

      @media (min-width: $screen-sm-min)
      {
        margin-top: 0;
      }
    }


    #{$this}__subdirectories-container {
      cursor:pointer;
      display: flex;
      align-items: center;

      border-bottom:solid;
      border-bottom-width: 1px;
      border-bottom-color: $color-light;
      color: $text-color !important;

      &,
      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }

      &--team,
      &--academy {
        padding-top: $size-x-small;
        padding-bottom: $size-x-small;
      }

      &--selector {
        padding-left: 20px;
      }

    }

    #{$this}__subdirectories-container:hover {
      background: $color-light-light;
    }




    #{$this}__subdirectories h2{
      margin-left:$size-xxx-small;
    }

    #{$this}__subdirectories-titre {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
      min-width: 0;
      padding-right: $size-x-small;
      font-size: 15px;
      line-height:35px;
    }

    #{$this}__subdirectories-dropdown {
      width:35px;
      margin-right: 10px;
    }

    // colonne de tri
    #{$this}__subdirectories-sort  {
      display: none;
      width:193px ;
      flex-shrink: 0;
      text-align: left;

      @media (min-width: $screen-lg-min)
      {
        display: block;
      }
    }






    //
    // spécifique sur les sous-répertoires de groupe
    #{$this}__subdirectories-team-creator,
    #{$this}__subdirectories-team-type,
    #{$this}__subdirectories-team-nbmembers {
      color: $color-default;
      display:flex;
      align-items: center;
    }

    #{$this}__subdirectories-team-creator {
      display: none;

      @media #{$media-desktop} {
        display: flex;
        align-items: center;
        width:20%;
        min-width:220px;
      }
    }

    #{$this}__subdirectories-team-type {
      position: absolute;
      right: $size-x-small;
      bottom: 0;

      @media #{$media-desktop} {
        position: static;
        width:20%;
        min-width:190px;
      }
    }

    #{$this}__subdirectories-team-nbmembers {
      @media #{$media-desktop} {
        width:10%;
        min-width:90px;
      }
    }
    // fin spéc sur les groupes



    #{$this}__subdirectories hr {
      margin-top:0px;
      margin-bottom:0px;
    }

    #{$this}__subdirectories-columns {
      width:100%;
      display:flex;
      align-items:center;
      height:50px;

      &--selector {
          cursor:pointer;
          display: flex;
          align-items: center;

          border-bottom:solid;
          border-bottom-width: 1px;
          border-bottom-color: $color-light;
          color: $text-color !important;

          &,
          &:hover,
          &:focus,
          &:active {
            text-decoration: none;
          }

          &--team,
          &--academy {
            padding-top: $size-x-small;
            padding-bottom: $size-x-small;
          }

          &--selector {
            padding-left: 20px;
          }
      }

      &:hover {
          background: $color-light-light;
      }
    }






    #{$this}__subdirectories-text {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;

      flex: 1;
      min-width: 0;

      @media #{$media-desktop}
      {
        flex-direction: row;
      }
    }


    #{$this}__subdirectories-icon {
      padding: $size-x-small;
      height: 20px;
      width: 20px;
      box-sizing: content-box;
      position: relative;

      & .pr-folder,
      & .pr-folder-star,
      & .pr-folder-user,
      & .pr-folder-picture {
        transform: translateY(-1px);
      }

      &--selector {
        //padding: $size-x-small $size-x-small $size-x-small 0px !important;
        margin-left : 21px !important;
      }

      &--selector-selected {
         padding-left: 5px;
      }

      #{$this}__subdirectories-columns--team &,
      #{$this}__subdirectories-columns--academy & {
        height: auto;
        width: auto;
        box-sizing: border-box;
        padding-top: 0;
        padding-bottom: 0;

        .c-entity-thumb {
          width: 35px;
          height: 35px;

          @media #{$media-desktop} {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    #{$this}__subdirectories-checkbox {
      box-sizing: content-box;
      position: absolute;
      width:20px;
      margin: 10px 15px 15px 10px;
    }

    #{$this}__folder-icon-contour
    {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 100%;
      height: 100%;

      transform: translate(-50%, calc(-50% - 1px)) !important;
    }

    #{$this}__subdirectories-icon svg{
      font-size: 21px;
    }
    // fin style sous-réps






    #{$this}__column-directories { // colonne des répertoires
      display:flex;
      flex-direction: column;
      padding-right: 0px;
    }

    #{$this}__column-documents { // colonne des documents

      overflow-x:hidden !important;

      padding-left: 0px;
      padding-right:0px;

      @media (min-width: $screen-md-min)
      {
        border: 1px $panels-border-color;
        border-left-style: solid;

      }
    }



    #{$this}__columns-ensemble {

        @media #{$media-desktop}
        {
            overflow: hidden !important;
            border-left-style: none;
        }

        &--standalone
        {
          @media #{$media-desktop}
          {
            border-left-style: solid;
          }
        }

    }


    #{$this}__column-documents-content {

      @media #{$media-desktop}
      {
        overflow-y: scroll ;
        //overflow-y: auto;
        height:calc(100vh - 137px);
        border:none;

        @include custom-scrollbar();
      }
    }



    #{$this}__column-documents-container {

        @media #{$media-desktop}
        {
         // height:calc(100vh - 99px);
          overflow: hidden;
          border:none;
        }
    }


    #{$this}__column-thirdparty { // colonne 3 avec infos sur le fichier
        display:none;

        @media #{$media-desktop}
        {
          display: block;
          height:calc(100vh - 99px);
        }
    }



    #{$this}__item-container { // container d'un item de répertoire
      display:flex;
      align-items: center;
      font-size:14px;
      height:$size-small + $size-xx-small;
    }

    #{$this}__item-folder-icon {
      position: relative;
      margin-right: 7px;

      .c-entity-thumb {
        width: 20px;
        height: 20px;
      }
    }


    #{$this}__item-more { // bouton "..." à droite dans la ligne de répertoire
      position:absolute;
      right: $size-xx-small;

      @supports (margin-right: env(safe-area-inset-right))
      {
        @media (max-width: $screen-sm-max)
        {
          margin-right: env(safe-area-inset-right);
        }
      }

      @media (hover: hover) and (pointer: fine)
      {
        display: none;
      }
    }



    #{$this}__item-container .icon-folder { // icône du répertoire
      font-size:21px;
    }

    #{$this}__item-container .c-loader { // icône chargement des documents
      display: none;
      width: 17px;
      margin:unset;
      margin-right: 10px;
    }

    #{$this}__item-container .c-loader-folder {
      margin-right: 8px;
    }




    #{$this}__item-text { // texte du répertoire
      max-width: 300px;
      white-space: nowrap;
      overflow:hidden;
      text-overflow: ellipsis;
    }




    #{$this}__item-drag {    // sur déplacement d'un répertoire
      font-weight: bold;
    }


  }




  /*
    Spécifique kendoTreeView
  */
  .k-treeview .k-i-collapse {
    margin-top: 0;
  }

  .k-treeview span.k-in { // item d'un répertoire prend toute la largeur
    display: inline-block;
    width: 100%;
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
  }

  .k-treeview  .k-selected {
    color:var(--theme-color);
  }

  .k-treeview .k-treeview-lines {
    margin-bottom: 0px;

    //@media #{$media-desktop}
    //{
    //  margin-bottom: 145px;
    //}
  }

  .k-treeview .k-i-loading {
    display:none !important;
  }

  .k-in {
    all: unset;
  }

}
