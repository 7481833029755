.c-sort-header {

  $this: &;

  width: 100%;
  display: flex;
  align-items: flex-start;
  align-self: flex-start;
  border-bottom: solid;
  border-bottom-width: 1px;
  border-bottom-color: $color-light;
  padding: $size-xx-small;
  height: 37px;
  font-size: 15px;

  &--disable {
    opacity: 0.2;
    pointer-events: none;
  }

  @at-root {

    #{$this}__sortby {
      height: 100%;
      line-height: 40px;


      @mixin common {
        display: none;
        flex-shrink: 0;

        @media (min-width: $screen-lg-min) {
          display: block;
        }
      }


      &__name {
        width: 100%;
        flex: 1;
      }


      &__typefile {
        width: 190px;
        @include common()
      }


      &__shared {
        width: 187px;
        @include common()
      }

      &__created {
        width: 235px;
        @include common()
      }


    }


    #{$this}__sortby-container {
      cursor: pointer;
    }

  }
}
