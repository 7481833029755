.c-tasks-details-section {
  $this: &;

  margin-bottom: $size-small;


  @at-root {
    #{$this}.is-hidden {
      display: none;
    }

    #{$this}--clear {
      clear: both;
    }

    #{$this}__header {
      display: flex;
      align-items: center;
      color: $text-color;
    }

    #{$this}__icon {
      width: 20px;
      height: 20px;
      margin-right: $size-xxx-small;
    }

    #{$this}__title {
      font-size: 15px;
      font-weight: 500;
      margin: 0;
      margin-right: $size-xxx-small;
    }

    #{$this}__main-title {
      font-size: 17px;
      margin: 0 $size-xxx-small $size-xxx-small 0;
    }

    #{$this}__body {
      margin: $size-xx-small 0 0 5px ;

      &--no-margin {
        @media (min-width: $screen-sm-min) {
          margin: 0;
        }
      }
    }

    #{$this}__label {
      font-size: 15px;
      cursor: pointer;
    }

    #{$this}__label-icon {
      width: $size-xx-small;
      height: $size-xx-small;
      color: $text-color;
      font-weight: 500;
    }

    #{$this}__members {
      position: relative;
      //padding-left: 4px;

      .glyphicon-user {
        font-size: 1.3em;
      }
    }

    #{$this}__members-list {
      display: inline-flex;
      flex-wrap: wrap;

      .c-entity-thumb {
        width: 40px;
        height: 40px;
        border:solid 2px white;
        margin-right: -5px;
      }
    }

    #{$this}__member {
      margin-right: 2px;
      margin-left: 1px;
      cursor: pointer;
    }

    #{$this}__members-action {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $color-light;
      padding: 5px;
      margin-left: 2px;
      border: white 1px solid;

      &:hover {
        border-radius: 50%;
        background: darken($color-light, 15%);
      }
      &:active {
        background: darken($color-light, 25%);
      }
    }

      #{$this}__members-icon {
      color: $text-color;
      width: 1.3em;
      height: 1.3em;
    }

    #{$this}__priority,
    #{$this}__dates {
      margin-top: $size-x-small;
    }

    #{$this}__dates {
      label {
        font-size: 17px;
      }
    }

    #{$this}__dates-row {
      display: flex;
      align-items: baseline;
      margin: 0;

      &.is-hidden {
        display: none;
      }
    }

    #{$this}__dates-col {
      padding-left: 0;
    }

    #{$this}__text {
      margin-bottom: 5px;
      font-size: 15px;
      font-weight: normal;
      white-space: break-spaces;
      width: auto;
      word-wrap: break-word;
      min-height: 50px;
    }

    #{$this}__action {
      width: 100%;
      margin-top: $size-xx-small;
      justify-content: normal;
      text-transform: none;
      font-size: 15px;
      height: 32px;
    }

    #{$this}__hideActivityButton {
      justify-content: normal;
      text-transform: none;
      font-size: 15px;
      height: 32px;
      margin-left: auto;
      margin-bottom: 10px;
    }

    #{$this}__dateButton {
      width: fit-content;
      justify-content: normal;
      text-transform: none;
      font-size: 15px;
      height: auto;
      @media (max-width: $screen-sm-min) {
        max-width: 310px;
      }
}

    #{$this}__action-icon {
      margin-right: $size-xx-small;
    }

    #{$this}__action-icon--big {
        width: 25px;
        height: 25px;
        margin-right: $size-xxx-small;
    }

    #{$this}__check-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    #{$this}__check-icon {
      display: none;
      width: 1.3em;
      height: 1.3em;
      stroke-width: 1px;
      color: $color-success;
    }

    #{$this}__check {
      &--checked {
        #{$this}__check-icon {
          display: block;
        }
      }
    }

    #{$this}__dateStatus{
      font-size: 13px;
      line-height: 16px;
      padding: 0 4px;
      border-radius: 2px;
      margin: auto 0 auto 8px;

      &--respected{
        color: #FFFFFF;
        background-color: #61BD4F;
      }

      &--toLate{
        color: #FFFFFF;
        background-color: #EC9488;
      }

      &--today{
        color: #172B4D;
        background-color: #F2D600;
      }
    }

    &__dateCheckbox{
      background-color: #fafbfc;
      border-radius: 2px;
      height: 16px;
      margin-right: 4px !important;
      margin-top: 0px !important;
      width: 16px;
      display: inline-flex;
      vertical-align: middle;

      &--unchecked{
        box-shadow: inset 0 0 0 2px #dfe1e6;
      }
    }
  }
}