.c-adndropzone-fullImg{
  $this: &;
  @at-root {
    #{$this}__grid {
      display: grid;
      grid-template-columns: repeat(3, auto);
      grid-gap: 3px;
      background-color: #FFFFFF;
      list-style: none;

      @media #{$media-desktop} {
        .c-sidebar--large & {
          grid-template-columns: repeat(5, auto);
        }
      }
    }

    #{$this}__dropzone {
      border: 3px dashed #ccd1d9;
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      font-size: $size-x-small;
      background-color: $color-light;
      height: 100%;
      width: 100%;
      padding: 0 11px;
      position: absolute;
      overflow: hidden;

      &.is-active {
        border-style: solid;
        border-color: var(--theme-color);
        background-color: $color-light-light;
      }

      &.has-error {
        border-style: solid;
        border-color: $color-danger;
        background-color: $color-light-light;
      }

      & a {
        text-decoration: none;
      }
    }

    #{$this}__action {
      font-size: 11px;
      padding: 0;
      @media #{$media-desktop} {
        font-size: 12px;
        padding: 5px 15px;
      }
    }

    #{$this}__action-txt {
      display: none;
      @media #{$media-desktop} {
        display: block;
      }
    }

    #{$this}__contentImg{
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;

      &--radius {
        border-radius: $size-xx-small;
      }
    }

    #{$this}__item {
      height: 0;
      padding-bottom: 100%;
      position: relative;

      &.draggable-mirror {
        padding: 0;
      }
    }

    #{$this}__drag-svg {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 15px;
      height: 15px;
      z-index: $z-index-2;
      color: white;
      visibility: hidden;

      &:hover {
        cursor: grab;
      }

      #{$this}__item:hover &{
        visibility: visible;
      }

      li.draggable-source--is-dragging & {
        visibility: hidden!important;
      }
    }

    #{$this}__info {
      display: flex;
    }

    #{$this}__info-icon {
      color: $color-dark;
      width: 30px;
      margin-right: 3px;
    }

    #{$this}__info-txt {
      font-size: $size-xx-small;
      color: $color-default;
      @media #{$media-desktop} {
        font-size: 12px;
      }
    }

    #{$this}__overlay-elypsis {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(67, 74, 84, 0) 50%);
      transition: background-color ease $speed-fast;

      #{$this}__item:hover &{
        background-color: rgba(0, 0, 0, 0.6);
      }

      @media not all and (hover: hover) and (pointer: fine) {
        #{$this}__item--draggable:not(.is-showing-btns) &{
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(67, 74, 84, 0) 50%) !important;
        }
        #{$this}__item--draggable.is-showing-btns &{
          background-color: rgba(0, 0, 0, 0.6);
        }
      }

      li.draggable-source--is-dragging &{
        display: none;
      }
    }

    #{$this}__btn-more{
      position: absolute;
      right: 10px;
      top: 10px;
      color: #FFFFFF;
      visibility: visible;

      #{$this}__item:hover &{
        visibility: hidden;
      }

      #{$this}__item--draggable:hover &{
        visibility: visible;
      }

      #{$this}__item--draggable &{
        z-index: 2000;
        color: white !important;
      }
    }

    #{$this}__container-btns{
      visibility: hidden;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 3px;

      #{$this}__item:hover &{
        visibility: visible;
      }

      @media not all and (hover: hover) and (pointer: fine) {
        #{$this}__item--draggable:not(.is-showing-btns) &{
          visibility: hidden !important;
        }
        #{$this}__item--draggable.is-showing-btns &{
          visibility: visible;
        }
      }

      li.draggable-source--is-dragging & {
        visibility: hidden!important;
      }
    }

    #{$this}__btn{
      width: 40px;
      height: 40px;
      margin: 10px;
      padding: 10px;
      border: transparent;
      border-radius: 50%;
      flex-shrink: 0;
      flex-grow: 0;

      @media #{$media-desktop} {
        padding: 3px;
      }

      &--edit, &--crop{
        background-color: #FFFFFF;
      }

      &--delete{
        background-color: rgba(red, 0.5);
        color: #FFFFFF;
      }

      &:hover {
        cursor: pointer;
      }
    }

    #{$this}__svg-delete, #{$this}__crop-svg{
      width: 100%;
      height: 100%;
      padding: 0;

      @media #{$media-desktop} {
        padding: 7px;
      }

      transition: transform ease $speed-medium;

      #{$this}__btn--delete:hover &, #{$this}__btn--crop:hover & {
        @media #{$media-desktop} {
          transform: scaleX(-1);
        }
      }
    }

    #{$this}__overlay-loader {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transition: background-color ease $speed-fast;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: $z-index-3;
      cursor: wait;

      &--radius {
        border-radius: $size-xx-small;
      }
    }
  }
}
