.c-chat-editor-url-preview {
  $this: &;

  display: flex;
  border: none;

  @at-root {
    #{$this}:hover,
    #{$this}:active,
    #{$this}:focus {
      text-decoration: none;
    }

    #{$this}__img-container {
      $height: 85px;
      height: $height;
      width: round($height * (16/9));
      flex-shrink: 0;
    }

    #{$this}__img {
      user-select: none;
    }

    #{$this}__infos {
      min-width: 0;
      padding: $size-xx-small;
    }

    #{$this}__title {
      margin-top: 0;
      margin-bottom: $size-xx-small;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: inherit;
    }

    #{$this}__text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: $size-xx-small;
      padding: 0;

      &--no-margin {
        margin-bottom: 0;
      }
    }
  }
}
