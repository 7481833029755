/// As the Kendo treeview HTML structure is not ready to display full line
/// highlighting, a hack is needed to achieve this.
///
/// With the help of SASS loops, a padding is added to each level. This padding
/// is the addition of the caret width (created by Kendo) and the original Kendo
/// treeview level padding. For each level/loop, a new padding is calculated and
/// added to the previous one.
///
/// So with this hack, a treeview level is built like this:
/// - the container starts on the very left
/// - in first place, there is the calculated padding
/// - and after, there is the caret and the title, pushed to the right by the padding
///
/// The number of supported levels is set with the $maximum-deepness variable
/// down here. If a treeview level is deeper, it will appear flat to the user,
/// like if it was on the same level than its parent.
/// This variable value can be adjusted if needed.
$maximum-deepness: 30;
$caret-width: 16px;
$padding: $size-x-small;
$item-selector: '.k-item';
$parent-selector: '';

.k-treeview .k-item
{
    padding-left: 0;
}

@for $deepness from 1 through $maximum-deepness
{
    $parent-selector: $parent-selector + ' ' + $item-selector;
    $offset: ($caret-width * $deepness) + $padding;

    #{$parent-selector} > *:first-child
    {
        padding-left: $offset;

        & > .k-in,
        & > .k-in > *
        {
            margin-left: -$offset !important;
            padding-left: $offset !important;
        }

        @media (max-width: $screen-sm-max)
        {
            @supports (padding: unquote('max(42px, 21px)'))
            {
                .c-documents-container--standalone &
                {
                    $safe-area-devices-offset: $offset - $padding;

                    padding-left: calc(#{$safe-area-devices-offset} + #{unquote("max(#{$safe-area-devices-offset}, env(safe-area-inset-left))")});

                    & > .k-in,
                    & > .k-in > *
                    {
                        margin-left: calc(-#{$safe-area-devices-offset} - #{unquote("max(#{$safe-area-devices-offset}, env(safe-area-inset-left))")}) !important;
                        padding-left: calc(#{$safe-area-devices-offset} + #{unquote("max(#{$safe-area-devices-offset}, env(safe-area-inset-left))")}) !important;
                    }
                }
            }
        }
    }
}

.k-treeview .k-in:hover,
.k-treeview .k-icon.k-i-expand:hover + .k-in,
.k-treeview .k-icon.k-i-collapse:hover + .k-in
{
    cursor: pointer !important;

    background-color: $color-light-light !important;
}

.k-treeview .k-hover
{
    font-weight: 500;
}
