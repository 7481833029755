.c-app-connection-modal
{

  position:absolute;
  top:50% !important;
  left:50%;
  transform: translate(-50%, -50%) !important;
  overflow: hidden;
  width: 80%;

  & .modal-header {
    text-align: center;
  }

  & .modal-body {
    font-size: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }


}