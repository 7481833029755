.dropdown-menu
{
    overflow: hidden;

    padding: 0;

    border-color: $panels-border-color;
}

.dropdown-menu > li:hover
{
    background: none;
}

.dropdown-menu > li
{
    > a,
    > button
    {
        @include reset-button;

        display: flex;
        align-items: center;

        height: $size-normal;
        padding: 0 $size-x-small;

        transition: ease $speed-fast;
        transition-property: color, background-color;

        color: $text-color;

        width: 100%;

        &:focus,
        &:hover
        {
            color: white !important;
            background: none;
            background-color: var(--theme-color-light) !important;
        }

        &:disabled
        {
            cursor: not-allowed;
            color: $color-default !important;
            background: none !important;
        }
    }
}
