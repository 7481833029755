figure
{
    font-size: 15px;
    font-style: italic;

    margin: $size-small 0;

    img
    {
        max-width: 100%;

        border-radius: 3px;
    }
}

figcaption
{
    margin-top: $size-small;
}
