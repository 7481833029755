/// ============================================================================
/// # TOAST-URL-PREVIEW
/// ============================================================================
/// Aperçus d'URLs dans les toasts de notification des posts.
/// Profondément lié à la librairie Toastify.js et à son override, notamment la
/// marge négative à -28.
/// ============================================================================
/// @group components
/// @require objects.flag
/// @see trumps.overrides.toastify
/// ============================================================================

.c-toast-url-preview
{
    $this: &;
    $border-width: 1px;
    $border: $border-width solid $color-light;
    $height: $size-normal + $size-xx-small;

    height: $height;

    // Seul moyen de coller l'aperçu d'URL aux bords du toast, puisqu'on n'a pas
    // la main sur tout le contenu (librairie Toastify.js). Le -28 représente la
    // place que prend la croix de fermeture.
    margin: $size-xx-small (-$size-x-small - 28px) (-$size-x-small) (-$size-x-small);

    border-top: $border;

    @at-root
    {
        #{$this}__illustration // o-flag__illustration
        {
            width: round(($height - $border-width) * (16/9));
            height: 100%;

            object-fit: cover;
        }

        #{$this}__metas // o-flag__body
        {
            display: flex;
            flex-direction: column;
            justify-content: center;

            height: 100%;
            padding: 0 $size-xx-small;

            background: $color-light-light;

            #{$this}__illustration + &
            {
                // L'image est placée avant dans le DOM, mais elle est bien
                // affichée à droite du texte grâce au modifier o-flag--reverse,
                // d'où la bordure à droite et non à gauche.
                border-right: $border;
            }
        }

        #{$this}__origin,
        #{$this}__title
        {
            @include clamp-lines-to(1);
        }

        #{$this}__origin
        {
            font-size: 10px;
            font-weight: normal;

            margin-top: 0;
            margin-bottom: $size-xxx-small;

            text-transform: uppercase;

            color: $color-dark-light;
        }

        #{$this}__title
        {
            font-size: 14px;

            margin: 0;
        }
    }
}
