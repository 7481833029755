body
{
    //font-family: 'Rubik', sans-serif;
    font-family: 'Apercu', sans-serif;
    font-size: 13px;
    line-height: 1.42857143;

    color: $text-color;
    background: var(--background-theme-color);
    background-attachment: fixed;
}
