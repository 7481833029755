ul
{
    margin: 0;
    padding: 0;

    list-style: none;
}

ul:not([class]):not([id]),
ul[class=''],
ul[id='']
{
    font-size: 15px;
}

ul:not([class]):not([id]) li:not([class]):not([id]),
ul:not([class]):not([id]) li[class=''],
ul:not([class]):not([id]) li[id=''],
ul[class=''] li:not([class]):not([id]),
ul[id=''] li:not([class]):not([id]),
ul[class=''] li[class=''],
ul[id=''] li[class=''],
ul[class=''] li[id=''],
ul[id=''] li[id='']
{
    padding-left: 1em;
}

ul:not([class]):not([id]) li:not([class]):not([id]):before,
ul:not([class]):not([id]) li[class='']:before,
ul:not([class]):not([id]) li[id='']:before,
ul[class=''] li:not([class]):not([id]):before,
ul[id=''] li:not([class]):not([id]):before,
ul[class=''] li[class='']:before,
ul[class=''] li[id='']:before,
ul[id=''] li[id='']:before,
ul[id=''] li[class='']:before
{
    display: inline-block;

    margin-right: 12px;

    content: '\2022';
    transform: scale(1.5);

    color: $color-default-light;
}
