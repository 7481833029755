.c-timeline-publisher
{
    $this: &;

    display: flex;

    margin-bottom: $size-x-small;
    padding-bottom: $size-small;

    border-bottom: 1px solid $color-dark-light;




    @at-root
    {

        #{$this}__backcontainer {
            display:none;
           // z-index:10000;
            //position:absolute;
            width:100% !important;
            //bottom:0px;
        }





        #{$this}__form
        {
            flex: 1;
        }

        #{$this}__footer
        {
            display: flex;
            justify-content: space-between;
        }

        #{$this}__sondage
        {
            padding:10px;
            padding-left:0px;
        }

        #{$this}__teams-thumb
        {
           margin-right:$size-xx-small;
        }

        #{$this}__teams-text {
            padding-top: 3px;
        }

        #{$this}__teams-title
        {
            font-size: 15px;
            color: $color-black;
        }

        #{$this}__teams-subtitle
        {
            font-size: 14px;

            color: $color-default;
        }

        #{$this}__ia-icon {
            width:20px;
        }
    }
}
