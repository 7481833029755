.panel-group .panel,
.panel
{
    margin-bottom: $size-small;

    border: solid $panels-border-color;
    border-width: 1px 0;
    border-radius: 0;
    box-shadow: none;
}

.panel--collapsible
{
    margin-left: $size-small;
}

.panel--collapsible .panel-heading
{
    position: relative;

    cursor: pointer;
}

.panel--collapsible .panel-heading:before
{
    content: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" fill="#{$text-color}" viewBox="0 -7.999 30.399 30.999" height="100%" width="100%"><path d="m 0.001,0.8 c 0,-0.205 0.078,-0.41 0.234,-0.566 0.312,-0.312 0.819,-0.312 1.131,0 L 15.2,14.068 29.034,0.234 c 0.312,-0.312 0.819,-0.312 1.131,0 0.312,0.312 0.312,0.819 0,1.131 l -14.4,14.4 c -0.312,0.312 -0.819,0.312 -1.131,0 L 0.234,1.365 C 0.077,1.208 0,1.003 0,0.799 Z"></path></svg>');

    position: absolute;
    left: -$size-normal;

    width: $size-normal;
    height: $size-normal;
    padding: $size-xx-small;

    transition: transform ease 200ms;
    transform: rotate3d(0,0,0,0);
}

.panel--collapsible .panel-heading.collapsed:before
{
    transform: rotate3d(0,0,1,-90deg);
}

.panel-group .panel+.panel,
.panel + .panel
{
    margin-top: -$size-small;

    border-top-width: 0;
}

.panel-title
{
    font-size: 15px;
    line-height: $size-normal;
}

.panel-default .panel-title
{
    color: $text-color;
}

.panel.panel-primary
{
    border-color: var(--theme-color);
}

.panel-primary .panel-title
{
    color: var(--theme-color);
}

.panel.panel-success
{
    border-color: #8cc152;
}

.panel-success .panel-title
{
    color: #8cc152;
}

.panel.panel-info
{
    border-color: #4a89dc;
}

.panel-info .panel-title
{
    color: #4a89dc;
}

.panel.panel-warning
{
    border-color: #f6bb42;
}

.panel-warning .panel-title
{
    color: #f6bb42;
}

.panel.panel-danger
{
    border-color: #e9573f;
}

.panel-danger .panel-title
{
    color: #e9573f;
}

.panel-heading
{
    padding: 0 $size-x-small;
}

.panel-default > .panel-heading,
.panel-primary > .panel-heading,
.panel-success > .panel-heading,
.panel-info > .panel-heading,
.panel-warning > .panel-heading,
.panel-danger > .panel-heading
{
    border: none;
    background: none;
}

.panel-body,
.panel-group .panel-heading+.panel-collapse>.panel-body
{
    padding-top: 0;

    border: none;
}
