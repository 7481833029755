/// ============================================================================
/// # CHAT-CONVERSATION-LIST-ITEM
/// ============================================================================
/// Bloc utilisé pour chaque item de la liste des conversations. Il s'agit du
/// même bloc dans les extensions du menu principal, dans la page du tchat et
/// dans le tchat popup.
/// On y retrouve :
/// - l'avatar
/// - le nom de la conversation
/// - une prévisualisation du dernier message
/// - la date
/// - la pastille qui indique l'état "lu" ou "non lu" de la conversation
/// ============================================================================
/// @group components
/// @require c-entity-list-item Doit être utilisé avec ce bloc
/// @see c-entity-list-item
/// ============================================================================

.c-chat-conversation-list-item
{
    $this: &;

    width: 100%;
    padding: $size-xx-small $size-x-small;

    text-align: left;

    border: none;

    cursor: pointer;

    @media (min-width: $screen-sm-min)
    {
        padding-left: $size-small;
    }

    @at-root
    {

        .c-tchat-popup #{$this}
        {
            padding-left: $size-x-small;
        }

        #{$this}.is-active
        {
            --background: #{$color-light-light};
        }

        #{$this}__text
        {
            #{$this}.is-unread &
            {
                font-weight: 500;

                color: $color-black;
            }
        }

        #{$this}__title,
        #{$this}__subtitle
        {
            display: flex;
            justify-content: space-between;
        }

        #{$this}__name
        {
            overflow: hidden;

            white-space: nowrap;
            text-overflow: ellipsis;
        }

        #{$this}__flag
        {
            $dot-dimensions: $size-xx-small;

            display: none;
            flex-shrink: 0;

            width: $dot-dimensions;
            height: $dot-dimensions;
            margin-left: $size-xx-small;

            border-radius: 50%;
            background: var(--theme-color-l50);

            #{$this}.is-unread &
            {
                display: block;
            }
        }

        #{$this}__subtitle,
        #{$this}__date
        {
            #{$this}.is-unread &
            {
                color: inherit;
            }
        }

        #{$this}__subtitle
        {
            font-size: 14px;

            align-items: baseline;

            color: $color-dark-light;
        }

        #{$this}__preview
        {
            @include clamp-lines-to(2);

            word-wrap: break-word;
            word-break: break-word;
            hyphens: auto;

            overflow-wrap: break-word;
        }

        #{$this}__date
        {
            font-size: 13px;

            flex-shrink: 0;

            margin-left: $size-xxx-small;

            color: $color-default;
        }
    }
}
