.c-timeline-post-views
{
    $this: &;

    @at-root
    {
        #{$this}__container {
            height:100%;
            padding: 0 0 0 $size-xx-small;

            & .k-listview-content {
                @include custom-scrollbar();

                padding-top: $size-xx-small;
                height: calc(#{vh(100)} - #{$header-height} - 30px)  !important;
            }

        }
    }
}
