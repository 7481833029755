.c-adnprogressbar {
  width: 30em;
  height: 1em;
  border-radius: 0.5em;
  position: relative;
  background: $color-light-light;
  box-shadow: inset 0 0 0 1px rgba(0,0,0,0.1);

  &__percentage {
    position: relative;
    font-size: 1em;
    background: var(--theme-color);
    height: 1em;
    border-radius: 0.5em;
  }

  &__tip {
    &:before,
    &:after {
      content: "";
      position: absolute;
      opacity: 0;
    }

    &:before {
      bottom: -10px;
      right: -5px;
      border: 5px solid;
      border-color: transparent transparent var(--theme-color) transparent;
      transition: opacity 0.1s;  
    }

    &:after {
      content: attr(data-perc);
      bottom: 0;
      right: 0;
      white-space: nowrap;
      padding: 0.6em 1em;
      border-radius: 2em;
      line-height: 1;
      color: #fff;
      background: $color-aqua;
      transform: translateX(50%) translateY(100%);
      transition: transform 0.2s, opacity 0.1s;
      background: var(--theme-color);
    }

    &:hover,
    &.is-active {
      &:before {
        opacity: 1;
      }

      &:after {
        opacity: 1;
        transform: translateX(50%) translateY(100%) translateY(10px);
      }
    }
  }

}
