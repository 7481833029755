///
/// Mixin to create a link
///
@mixin c-link()
{
  color: $color-default;

  border-top: 1px solid transparent;  // Used for symmetry

  &,
  &:hover,
  &:focus,
  &:active
  {
    text-decoration: none;
  }

  &:hover,
  &:focus,
  &:active
  {
    color: var(--theme-color-light);
    border-bottom-color: var(--theme-color-light);
  }
}

@mixin c-link--colorful()
{
  color: var(--theme-color);
}
